import VSpacer from "../../common/Spacer/VSpacer";
import TableSmallTitle from "../texts/TableSmallTitle";
import TableSmallValue from "../texts/TableSmallValue";

const Data = ({
  walletAddress,
  txHash,
  min,
  max,
  winningNumber,
}: {
  walletAddress: string;
  txHash: string;
  min: string;
  max: string;
  winningNumber: string;
}) => (
  <>
    <tr>
      <th className="text-left w-full">
        <TableSmallTitle text="WALLET ADDRESS" />
      </th>
      <td className="text-right w-full">
        <TableSmallValue text={walletAddress} />
      </td>
    </tr>
    <tr>
      <th className="text-left w-full">
        <TableSmallTitle text="TX HASH" />
      </th>
      <td className="text-right w-full">
        <TableSmallValue text={txHash} />
      </td>
    </tr>
    <tr>
      <th className="text-left w-full">
        <TableSmallTitle text="MIN" />
      </th>
      <td className="text-right w-full">
        <TableSmallValue text={min} />
      </td>
    </tr>
    <tr>
      <th className="text-left w-full">
        <TableSmallTitle text="MAX" />
      </th>
      <td className="text-right w-full">
        <TableSmallValue text={max} />
      </td>
    </tr>
    <tr>
      <th className="text-left w-full">
        <TableSmallTitle text="WINNING NUMBER" />
      </th>
      <td className="text-right w-full">
        <TableSmallValue text={winningNumber} />
      </td>
    </tr>
    <VSpacer treatLgAsSmall big={0} small={29} />
    <hr className="m-auto  border-0 h-[3px] w-full bg-primary" />
  </>
);
export default function TableSmall() {
  return (
    <table className="hidden sm_md_lg:block w-full px-[29px]">
      <Data
        walletAddress="ASKCJA28189S"
        txHash="19281898S"
        min="1"
        max="999,999,999,999"
        winningNumber="432,329,000"
      />
    </table>
  );
}
