import React from "react";
import Button from "../Button";
import Modal from "@mui/material/Modal";
import Input from "../Input";
import { cancelSubscription, resetPassword } from "../../Services/userService";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";

const CancelSubscriptionPopup = ({
    rejectClick,
    open,
    handleClose,
}: {
    rejectClick: (e: any) => void;
    open: boolean;
    handleClose: () => void;
}) => {
    const [error, setError] = React.useState<string | null>();
    const [success, setSuccess] = React.useState<string | null>();
    const [loading, setLoading] = React.useState<boolean>(false);

    const handleAccept = async () => {
        // check email for null or empty string
        setLoading(true);
        try {
            const result = await cancelSubscription();
            if (result) {
                setSuccess("Subscription Cancelled Successfully");
            }
        } catch (error: any) {
            console.log("error", error);
            setError(error?.response?.data);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className="border-2 rounded-3xl border-primary absolute top-[50%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 z-[100000001] p-4 bg-secondary_bg bg-opacity-100">
                <div className="flex justify-end">
                    <IconButton onClick={handleClose} className="relative">
                        <CloseIcon className="text-white" />
                    </IconButton>
                </div>
                <div className="flex flex-col sm:items-center gap-5 sm:gap-0 p-10">
                    <p className="uppercase text-white text-[21px] font-extrabold  text-center sm:text-[16px] sm:w-[220px]">
                        Are you sure you want to cancel your subscription?
                    </p>
                </div>
                {error && (
                    <p className="uppercase text-red-600 text-center">
                        {error}
                    </p>
                )}
                {success && (
                    <div className="text-white text-[21px] font-extrabold text-center sm:text-[16px]">
                        {success}
                    </div>
                )}
                <div className="mt-10 items-center justify-center flex gap-2 sm:flex">
                    <Button
                        className={`${
                            success ? "hidden" : "block"
                        } text-[#ffffff] !bg-black font-[Sequel100Wide95] text-[24px]
              sm:text-[3vw]
              sm:w-[80%]
              uppercase text-center px-[20px] py-[10px] mt-[23px] rounded-[30px]`}
                        content={"REJECT"}
                        onClick={(e) => {
                            setError(null);
                            setLoading(false);
                            rejectClick(e);
                        }}
                    />
                    <Button
                        loading={loading}
                        className={`text-[#232323] font-[Sequel100Wide95] text-[24px]
                sm:text-[3vw]
                sm:w-[80%]
                uppercase text-center px-[20px] py-[10px] mt-[23px] rounded-[30px]`}
                        content={"CONFIRM"}
                        onClick={(e) => {
                            handleAccept();
                        }}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default CancelSubscriptionPopup;
