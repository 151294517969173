import React, { useEffect, useState } from "react";

const FAQ = ({
  className,
  fontSize,
}: {
  className: string;
  fontSize: string;
}) => {
  const [active, setActive] = useState(-1);

  const faqs = [
    {
      id: 1,
      content: "1. What is ThePrize.io?",
      description:
        "ThePrize.io is a crypto-based competition platform where you can win exciting digital prizes and luxury items by entering various competitions using both cryptocurrency and soon fiat. ",
    },
    {
      id: 2,
      content: "2. How do I enter a competition?",
      description:
        "To enter, browse our available competitions, select the number of tickets you want, answer a simple question, and add your tickets to the cart. Once you've completed the purchase, you're officially in the running! ",
    },
    {
      id: 3,
      content: "3. Does ThePrize.io have a gambling licence? ",
      description:
        "No, due to the way the platform is structured, we are free from gambling regulations by law in Europe and many other jurisdictions. ",
    },
    {
      id: 4,
      content: "4. What cryptocurrencies are accepted? ",
      description:
        "We accept a range of popular cryptocurrencies, including Bitcoin (BTC), Ethereum (ETH), Tron (TRX), Base (BASE) and Solana (SOL) with more being added soon. ",
    },
    {
      id: 5,
      content: "5. How are winners selected?",
      description:
        "Winners are chosen using Chainlink VRF RNG, ensuring a fair and transparent process. You will be able to view the tx hash of the draw on our site, and will be notified if you have won. ",
    },
    {
      id: 6,
      content: "6. When do you announce the winners?",
      description:
        "Winners are announced in the Telegram Announcements group and across our socials once the competition timer runs out or when all tickets are sold, you will also be notified in the MY ACCOUNT section if you have won. ",
    },
    {
      id: 7,
      content: "7. What happens if I win?",
      description:
        "If you win, you’ll receive your prize in the form of cryptocurrency directly to your digital wallet. We may also feature your username in our Winners Hall of Fame! If you have won a luxury item, we will contact you to arrange delivery.. ",
    },
    {
      id: 8,
      content: "8. Is there a limit to the number of tickets I can buy?",
      description:
        "The number of tickets you can purchase per competition may vary. Please check the specific competition's details for any limitations. ",
    },
    {
      id: 9,
      content: "9. What if the competition doesn’t sell out?",
      description:
        "If a competition doesn't sell out by the end of the timer, a winner will still be drawn from the total number of tickets available. If after 20 RNG draws it hasn’t landed on a purchased ticket, the prize will be rolled over to the following draw. ",
    },
    {
      id: 10,
      content: "10. Can I get a refund if I change my mind?",
      description:
        "All ticket purchases are final. Once you've entered a competition, refunds are not available, so please ensure you’re confident before buying your tickets. ",
    },
    {
      id: 11,
      content: "11. How do I contact customer support?",
      description:
        "For any inquiries or issues, you can reach our customer support team through the contact form on our website or by emailing us directly at contact@theprize.io. ",
    },
    {
      id: 12,
      content: "12. How do I stay updated on new competitions?",
      description:
        'To stay updated on our latest competitions and announcements, follow us on social media: <b><a href="http://t.me/theprizeannouncements" target="_blank">Telegram</a></b> & <b><a href="https://x.com/theprize_io" target="_blank">X</a></b>.',
    },
    {
      id: 13,
      content: "13. Can I participate from anywhere in the world?",
      description:
        "Yes, ThePrize.io is an international platform, and you can participate from anywhere as long as local laws permit. ",
    },
  ];

  const changeDropdown = (index: number) => {
    if (active === index) {
      setActive(-1);
    } else {
      setActive(index);
    }
  };

  return (
    <div
      className={`${className} w-full p-10 faqContainer sm:p-5 pt-3 relative z-[100001]`}
    >
      <div className="flex flex-col items-center liveCompetitionPage  pt-0  sm:ml-0 sm:p-3">
        <h1
          className={`pageHeading font-[Sequel100Wide95]`}
          style={{
            fontSize: "clamp(1px, 6.527976190476191vw, 45px)",
          }}
        >
          FAQS
        </h1>
      </div>
      <div className="flex flex-col gap-4">
        {faqs.map((faq, index) => (
          <div
            onClick={() => {
              changeDropdown(index);
            }}
            className="dropdown"
          >
            <h3
              className="w-full bg-primary flex items-center gap-8 //text-[25px] //sm:text-[17px] text-[#1A1A1A] font-[Sequel100Wide95] h-[68px] pl-[1em] rounded-full cursor-pointer"
              style={{
                fontSize: "clamp(14px,3.496512479vw,24.82px)",
              }}
              key={index}
            >
              <img src="/Images/dropdown.png" alt="" />
              {faq.content}
            </h3>
            {active === index && (
              <div className="border-4 flex-wrap font-['Sequel100WideVF45'] border-primary mt-[-30px] border-t-0 flex w-full gap-4 items-end sm:flex-col pt-[4em] py-[2em] sm:px-[8%] rounded-b-[1.5rem]">
                <ol className="text-white  flex flex-col gap-4 sm:ml-0 w-[90%] m-auto sm:text-[10px] ">
                  {/* {faqs.map((des, index) => (
                    <li key={index}>{des.description}</li>
                  ))} */}
                  <li>{faq.description}</li>
                </ol>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
