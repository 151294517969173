interface IButtonProps {
  onClick?: () => void;
  text?: string;
  paddingX?: string;
  maxWidthBig?: string;
  maxWidthSmall?: string;
  heightBig?: string;
  heightSmall?: string;
  border?: string;
  roundness?: string;
  backgroundColor?: string;
  fontFamily?:
    | "MontBlancRegular"
    | "MontBlancBold"
    | "MontBlancSemiBold"
    | "MontBlancBlack"
    | "Sequel100Wide"
    | "Sequel100Wide95"
    | "Sequel100WideVF45"
    | "Sequel100WideVF55"
    | "Sequel100Wide-65"
    | "Sequel100WideVF"
    | "Sequel100Wide85"
    | "Sequel100Wide46";
  fontSizeSmall?: string;
  fontSize?: string;
  color?: string;
  children?: React.ReactNode;
  className?: string;
}
export default function button(props: IButtonProps) {
  return (
    <>
      <button
        className={"sm_md:hidden " + props?.className}
        onClick={props?.onClick}
        style={{
          paddingLeft: props?.paddingX || "1rem",
          paddingRight: props?.paddingX || "1rem",
          maxWidth: props?.maxWidthBig || "100%",
          height: props?.heightBig || "3rem",
          fontFamily: props?.fontFamily || "MontBlancRegular",
          fontSize: props?.fontSize || "1rem",
          color: props?.color || "#000",
          border: props?.border || "none",
          borderRadius: props?.roundness || "0",
          backgroundColor: props?.backgroundColor || "#fff",
          width: "100%",
        }}
      >
        {props?.children} {props?.text}
      </button>
      <button
        className={"hidden sm_md:block " + props?.className}
        onClick={props?.onClick}
        style={{
          paddingLeft: props?.paddingX || "1rem",
          paddingRight: props?.paddingX || "1rem",
          maxWidth: props?.maxWidthSmall || "100%",
          height: props?.heightSmall || "2rem",
          fontFamily: props?.fontFamily || "MontBlancRegular",
          fontSize: props?.fontSizeSmall || "1rem",
          color: props?.color || "#000",
          border: props?.border || "none",
          borderRadius: props?.roundness || "0",
          backgroundColor: props?.backgroundColor || "#fff",
          width: "100%",
        }}
      >
        {props?.children} {props?.text}
      </button>
    </>
  );
}
