import { ReactNode } from "react";
import { LinearProgress, LinearProgressProps, styled } from "@mui/material";
import { Text } from "../../react-responsive-utilities/src";

interface HeroSlideActionsProps {
    value: number;
    costPerTicket: number;
    competitionUID: string;
    competitionEnded: boolean;
    onClick: () => void;
}

const Container = ({ children }: { children: ReactNode }) => (
    <div className="xl:flex lg:flex hidden justify-between items-center relative z-10 max-w-[571px] bg-[#000000c1] rounded-[50px]">
        {children}
    </div>
);

export default function HeroSlideActions(props: HeroSlideActionsProps) {
    const TicketLinearProgress = styled(LinearProgress)<LinearProgressProps>(
        ({ theme }) => ({
            height: "100%",
            borderRadius: 20,
            backgroundColor: "rgba(255, 255, 255, 0.1)",
            ".MuiLinearProgress-barColorPrimary": {
                backgroundColor: "#EF008F",
                borderRadius: 20,
            },
        })
    );
    return (
        <Container>
            <div className="!h-full max-w-[193.11px] w-full mx-6">
                <TicketLinearProgress
                    className="min-h-[13.99px] max-w-[193.11px] w-full"
                    variant="determinate"
                    value={props.value}
                />
                <Text
                    textSize={[0, 11.19, 11.19]}
                    className="text-[#fff] block"
                >
                    {" "}
                    {props.value}% tickets sold
                </Text>
            </div>
            <div>
                <Text
                    textSize={[0, 23.77, 23.77]}
                    className="text-primary font-[MontBlancBlack] block leading-none"
                >
                    ${props.costPerTicket}
                </Text>
                <Text
                    textSize={[0, 11.19, 11.19]}
                    className="text-white block font-[MontBlancRegular] uppercase"
                >
                    Per Ticket
                </Text>
            </div>
            <button
                onClick={props.onClick}
                className={
                    "bg-customGray border-transparent  h-[69.16px] w-full max-w-[258.56px] rounded-full  border-2 uppercase"
                }
                // ${
                // props.competitionEnded
                //     ? "bg-transparent border-transparent"
                //     : "bg-primary border-white"

                // }
            >
                <Text
                    textSize={[0, 23, 26.89]}
                    className={`font-[MontBlancBlack] lg:!text-[23px] "text-black"`}
                >
                    {/* {props.competitionEnded ? "Finished" : "Buy Tickets"} */}
                    BUY TICKETS
                </Text>
            </button>
        </Container>
    );
}
