interface IImageProps {
  src: string;
  alt: string;
  roundness?: string;
  width?: string;
  widthSmall?: string;
  height?: string;
  heightSmall?: string;
  objectFit?: React.CSSProperties["objectFit"];
  objectPosition?: string;
  onClick?: () => void;
}
export default function image(props: IImageProps) {
  return (
    <>
      <img
        className={`sm_md:hidden  ${props.onClick && "cursor-pointer"}`}
        src={props.src}
        alt={props.alt}
        style={{
          borderRadius: props.roundness,
          width: props.width,
          height: props.height,
          objectFit: props.objectFit,
          objectPosition: props.objectPosition,
        }}
        onClick={props.onClick}
      />
      <img
        className={`hidden sm_md:block ${props.onClick && "cursor-pointer"}`}
        src={props.src}
        alt={props.alt}
        style={{
          borderRadius: props.roundness,
          width: props.widthSmall,
          height: props.heightSmall,
          objectFit: props.objectFit,
          objectPosition: props.objectPosition,
        }}
        onClick={props.onClick}
      />
    </>
  );
}
