interface ResultBigProps {
  left: React.ReactNode;
  right: React.ReactNode;
  varient: "won" | "lost";
}
export default function ResultBig(props: ResultBigProps) {
  return (
    <div
      className={`sm_md:hidden rounded-[27.2px] flex justify-center items-center px-[29px] gap-[29px] min-h-[106px] box-border py-[20px] ${
        props?.varient === "won" ? "bg-primary" : "bg-secondary"
      }`}
    >
      <div>{props?.left}</div>
      <div>{props?.right}</div>
    </div>
  );
}
