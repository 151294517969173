import { useEffect, useState } from "react";
import VSpacer from "../../../Components/common/Spacer/VSpacer";
import Category from "../../../Components/User Pages Components/My Entry/Category/Category";
import Entry from "../../../Components/User Pages Components/My Entry/Entry/Entry";
import WithNonAbsolutedSidebarNavbarFooter from "../../../Layout/WithNonAbsolutedSidebarNavbarFooter";
import { useNavigate } from "react-router-dom";
import { getEnteredCompetition } from "../../../Services/userService";
import { Common } from "../../../Components/common/Common";

interface MyEntriesProps {
    type: "live" | "finished";
}

function MyEntries(props: MyEntriesProps) {
    // const [competitions, setcompetitions] = useState([]);
    const [liveCompetitions, setLiveCompetitions] = useState([]);
    const [finishedCompetitions, setFinishedCompetitions] = useState([]);
    useEffect(() => {
        document.getElementById("my_entries_main")?.scrollIntoView();
        getEnteredCompetition().then((res) => {
            if (res.data) {
                const live = res.data.filter((competition: any) => {
                    const endDate = new Date(competition.competitionenddate);
                    return endDate > new Date();
                });
                const finished = res.data.filter((competition: any) => {
                    const endDate = new Date(competition.competitionenddate);
                    return endDate < new Date();
                });
                setLiveCompetitions(live);
                setFinishedCompetitions(finished);
            }
        });
    }, []);

    const navigate = useNavigate();
    const navigateTo = (path: "live" | "finished") => {
        navigate(`/entries/${path}`);
    };

    return (
        // Main View
        <WithNonAbsolutedSidebarNavbarFooter
            Component={
                // Main container
                <div
                    id="my_entries_main"
                    className="overflow-hidden box-border bg-black w-full sm_md_lg:mx-auto sm_md_lg:w-[calc(100%-20px)]  sm_md_lg:my-[20px] xl:my-8 h-full mx-[43px] rounded-[30px] py-[63px] px-[78px] sm_md:py-[44px] sm_md:px-[11px] flex flex-col items-center justify-center "
                >
                    {/* 1 - Title */}
                    <p className="font-[Sequel100Wide95] uppercase text-[45px] sm_md:text-[30px] text-white ">
                        My Entries
                    </p>
                    {/* 2 - Spacer Title and Navbar */}
                    <VSpacer small={28} big={60} />
                    {/* 3 - Navbar */}
                    <div className="flex w-full justify-center items-center sm_md_lg:flex-col  gap-[27.16px] sm_md_lg:gap-[10px]">
                        <Category
                            text="Live Competitions"
                            onClick={() => {
                                navigateTo("live");
                            }}
                            isActive={props.type === "live"}
                        />
                        <Category
                            text="Finished Competitions"
                            onClick={() => {
                                navigateTo("finished");
                            }}
                            isActive={props.type === "finished"}
                        />
                    </div>
                    {/* 4 - Spacer Navbar and Entries */}
                    <VSpacer small={27} big={75} />
                    {/* 5 - Entries */}
                    <div
                        className={`${
                            props.type === "live"
                                ? "border-primary border-[2px]"
                                : "border-secondary border-[2px]"
                        } flex flex-col gap-y-[17px] px-[26px] py-[31px] sm_md_lg:px-[13px] sm_md_lg:py-[17px] w-full *:m-auto border-primary border-[2px] rounded-[30px]`}
                    >
                        {props.type === "live" &&
                            liveCompetitions.length === 0 && (
                                <Common.typography
                                    fontFamily="Sequel100Wide95"
                                    fontSize="30px"
                                    fontSizeSmall="20px"
                                    color="#DDE404"
                                >
                                    No live competition
                                </Common.typography>
                            )}
                        {props.type === "finished" &&
                            finishedCompetitions.length === 0 && (
                                <Common.typography
                                    fontFamily="Sequel100Wide95"
                                    fontSize="30px"
                                    fontSizeSmall="20px"
                                    color="#EF008F"
                                >
                                    No finished competition
                                </Common.typography>
                            )}
                        {props.type === "live" &&
                            liveCompetitions?.map(
                                (competition: any, index: number) => (
                                    <Entry
                                        UID={competition?.UID}
                                        key={index}
                                        date={competition?.competitionenddate}
                                        title={competition?.competitionname}
                                        description={
                                            competition?.competitioninformation
                                        }
                                        isLive={true}
                                    />
                                )
                            )}
                        {props.type === "finished" &&
                            finishedCompetitions?.map(
                                (competition: any, index: number) => (
                                    <Entry
                                        UID={competition?.UID}
                                        key={index}
                                        date={competition?.competitionenddate}
                                        title={competition?.competitionname}
                                        description={
                                            competition?.competitioninformation
                                        }
                                        isLive={false}
                                    />
                                )
                            )}
                    </div>
                </div>
            }
        />
    );
}

export default MyEntries;
