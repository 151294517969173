import { Common } from "../../common/Common";

export default function Title() {
  return (
    <Common.typography
      fontFamily="Sequel100Wide95"
      fontSize="40px"
      fontSizeSmall="30px"
      color="#ffffff"
      className="uppercase text-center"
    >
      Random Number Generator
    </Common.typography>
  );
}
