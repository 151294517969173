interface RightProps {
  children: React.ReactNode;
}
export default function RightContainer(props: RightProps) {
  return (
    <div className="flex flex-col bg-primary h-full justify-center items-center relative rounded-[30px] w-full">
      {props?.children}
    </div>
  );
}
