import React, { useEffect } from "react";
import Payments from "../../../Components/Auth Components/Payments";
import WithNonAbsolutedSidebarNavbarFooter from "../../../Layout/WithNonAbsolutedSidebarNavbarFooter";
import VSpacer from "../../../Components/common/Spacer/VSpacer";
import { Common } from "../../../Components/common/Common";

export default function BuyTickets() {
  useEffect(() => {
    document.getElementById("buy_tickets_main")?.scrollIntoView();
  }, []);

  return (
    <WithNonAbsolutedSidebarNavbarFooter
      sidebarContainerClassName="mb-4"
      Component={
        <div
          className="h-screen overflow-hidden box-border bg-black w-full sm_md_lg:mx-auto sm_md_lg:w-[calc(100%-20px)]  sm_md_lg:my-[20px] xl:my-8 mx-[43px] rounded-[30px] py-[51px] px-[78px] sm_md:py-[51px] sm_md:px-[11px] flex flex-col items-center "
          id="buy_tickets_main"
        >
          <div className="uppercase">
            <Common.typography
              fontFamily="Sequel100Wide95"
              fontSize="40px"
              color="#fff"
              fontSizeSmall="28px"
            >
              Buy Tickets
            </Common.typography>
          </div>
          <VSpacer small={35} big={37} />
          <Payments skipable={false} />
        </div>
      }
    />
  );
}
