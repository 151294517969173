import React from "react";
import { Common } from "../../../common/Common";

interface ResultImageProps {
  varient: "big" | "small";
}
export default function ResultImage(props: ResultImageProps) {
  return (
    <Common.image
      src={
        props.varient === "big"
          ? "/Images/resultBig.svg"
          : "/Images/resultSmall.svg"
      }
      alt="result"
      roundness="0px"
      width="71px"
      widthSmall="69.12px"
      height="58.25px"
      heightSmall="62.64px"
    />
  );
}
