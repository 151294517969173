import Countdown from "react-countdown";
import { Text } from "../../react-responsive-utilities/src";
import VSpacer from "../Spacer/VSpacer";

interface CounterProps {
    competitionEndDate: Date;
    title: String;
}

const Unit = ({
    value,
    unit,
    isLast = false,
}: {
    value: string;
    unit: string;
    isLast?: boolean;
}) => (
    <div key={unit} className="flex flex-col items-center">
        <div className="flex *:m-auto justify-center items-center gap-1">
            <div className="px-[clamp(0px,2vw,13px)] flex w-[45.17px] sm_md:max-w-[35.74px] sm_md:min-w-0 sm_md:w-full sm_md:min-h-0 sm_md:max-h-[35.74px] sm_md:p-[clamp(0px,2.5vw,12px)] sm_md:h-full min-h-[clamp(35.74px,3vw,45px)] bg-white  text-center justify-center items-center rounded-[10px] max-390:rounded-md sm_md:rounded-[clamp(0px,2vw,10px)]">
                <Text
                    textSize={[16, 21.93, 27.61]}
                    className="text-[#2E2122] font-['MontBlancBlack'] leading-[29px] font-[218]"
                >
                    {value[0]}
                </Text>
            </div>
            <div className="px-[clamp(0px,2vw,13px)] flex w-[45.17px] sm_md:max-w-[35.74px] sm_md:min-w-0 sm_md:w-full sm_md:min-h-0 sm_md:max-h-[35.74px] sm_md:p-[clamp(0px,2.5vw,12px)] sm_md:h-full min-h-[clamp(35.74px,3vw,45px)] bg-white  text-center justify-center items-center rounded-[10px] max-390:rounded-md sm_md:rounded-[clamp(0px,2vw,10px)]">
                <Text
                    textSize={[16, 21.93, 27.61]}
                    className="text-[#2E2122] font-['MontBlancBlack'] leading-[29px] font-[218]"
                >
                    {value[1]}
                </Text>
            </div>
            {!isLast && (
                <Text
                    textSize={[16, 21.93, 27.61]}
                    className={`text-[#FFFFFF]  font-['MontBlancBlack'] leading-[29px] font-[218] block `}
                >
                    :
                </Text>
            )}
        </div>

        <Text
            textSize={[0, 14.93, 16.0]}
            className="text-[#FFFFFF]  font-['MontBlancRegular'] font-semibold leading-[20px] //font-[120] uppercase sm:text-center md:text-center w-full block"
        >
            {unit}
        </Text>
    </div>
);

export default function Counter(props: CounterProps) {
    return (
        <div className="w-fit ">
            <Text
                textSize={[18, 20.0, 27.07]}
                className="
              text-[#FFFFFF] font-['Sequel100Wide95'] leading-[24px] font-[60] block sm:text-center md:text-center relative z-10"
            >
                {props.title}
            </Text>
            <VSpacer small={15.5} big={22.11} />
            <div className="flex relative z-10 ">
                <Countdown
                    date={props.competitionEndDate || new Date()}
                    renderer={({ formatted }) => (
                        <div className="flex //flex-wrap gap-1 place-content-center place-items-center">
                            {/* <Unit value={formatted?.days} unit="Days" />
              <Unit value={formatted?.hours} unit="Hours" />
              <Unit value={formatted?.minutes} unit="Minutes" />
              <Unit isLast value={formatted?.seconds} unit="Secs" /> */}

                            <Unit value={"12"} unit="Days" />
                            <Unit value={"12"} unit="Hours" />
                            <Unit value={"12"} unit="Minutes" />
                            <Unit isLast value={"12"} unit="Secs" />
                        </div>
                    )}
                />
            </div>
        </div>
    );
}
