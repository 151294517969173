import React from "react";
import { PageLinkProps } from "./Link";
import { Link } from "react-router-dom";
import TelegramIcon from "@mui/icons-material/Telegram";
import XIcon from "@mui/icons-material/X";
// import YouTubeIcon from "@mui/icons-material/YouTube";

const Footer = ({
    quickLinks,
    bottomLinks,
}: {
    quickLinks: PageLinkProps[];
    bottomLinks: PageLinkProps[];
}) => {
    const contacts = [
        {
            content: "press@theprize.io",
        },
        {
            content: "contact@theprize.io",
        },
    ];
    const links = [
        {
            icon: <TelegramIcon />,
            link: "http://t.me/theprizeannouncements",
        },
        {
            icon: <XIcon />,
            link: "https://x.com/theprize_io",
        },
        // {
        //     icon: <YouTubeIcon />,
        //     link: "https://www.youtube.com/channel/xxxxxxx",
        // },
        // {
        //   content: "WHITEHAVEN",
        // },
        // {
        //   content: "CA28 0DJ",
        // },
        // {
        //   content: "Email: info@Chickendinner.co.uk",
        // },
        // {
        //   content: "Phone: 07751 905934",
        // },
    ];

    return (
        <div className="bg-bg_small relative bottom-0 //h-[450px] sm:h-full w-full  p-14 sm:p-8 flex flex-col gap-14 md:h-full md:p-8 overflow-hidden footer h-auto">
            <img
                //src="/Images/THEPRIZE_TokenLogo_EH-03.svg"
                src="/Images/overlayed coin.svg"
                alt=""
                className="absolute top-0 right-0  sm:hidden md:hidden lg:hidden"
            />
            <div className="flex items-start gap-4 //gap-[8rem] sm:flex-col md:flex-col lg:flex-col">
                <div className="flex items-start flex-col">
                    <img
                        //src="/Images/big_logo.png"
                        src="/Images/footer_logo.svg"
                        alt=""
                        className="w-[500px] mb-[2em]"
                    />
                    <p
                        className="w-[560px] sm:w-full md:w-full"
                        style={{
                            fontSize: "clamp(1px,2.64vw,16px)",
                        }}
                    >
                        Digital currencies may be unregulated in your
                        jurisdiction. The value of digital currencies may go
                        down as well as up. Profits may be subject to capital
                        gains or other taxes application in your jurisdiction.
                    </p>
                </div>
                <div className="flex md:w-[80%] gap-[8rem] sm:gap-[4rem] justify-between ">
                    <div className="flex h-full flex-col gap-6 ">
                        <h3
                            className="font-bold"
                            style={{
                                fontSize: "clamp(1px,3.12vw,20.49px)",
                            }}
                        >
                            QUICK LINKS
                        </h3>
                        <div className="flex flex-col gap-2">
                            {quickLinks &&
                                quickLinks.map((link, index) => (
                                    <Link
                                        to={link.src}
                                        state={{
                                            isFaq: link?.src?.includes("faqs"),
                                        }}
                                        key={index}
                                        className="uppercase cursor-pointer"
                                        style={{
                                            fontSize:
                                                "clamp(1px,2.67vw,17.48px)",
                                        }}
                                    >
                                        {link.content}
                                    </Link>
                                ))}
                        </div>
                    </div>
                    <div className="flex flex-col gap-6 ">
                        <h3
                            className="font-bold"
                            style={{
                                fontSize: "clamp(1px,3.12vw,20.49px)",
                            }}
                        >
                            CONTACT
                        </h3>
                        <div className="flex flex-col gap-2">
                            {contacts.map((contact, index) => (
                                <p
                                    key={index}
                                    style={{
                                        fontSize: "clamp(1px,2.67vw,17.48px)",
                                    }}
                                >
                                    {contact.content}
                                </p>
                            ))}
                            <div className="flex flex-row mt-[4.5rem] gap-6">
                                {links.map((link, index) => (
                                    <a
                                        href={link.link}
                                        key={index}
                                        className="flex items-center gap-2 bg-pink-500 p-2 rounded-md"
                                    >
                                        {link.icon}
                                    </a>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-full flex items-center sm:flex-col-reverse sm:gap-2 justify-between relative z-[10001] md:flex-col-reverse md:gap-2">
                <p className="sm:text-[9.7px] sm:w-full md:w-full">
                    © Copyright 2024 | All Rights Reserved.
                    {/* <Link to={"#"} className="underline">
            {" "}
            HiltonWebDesign.com
          </Link> */}
                </p>
                <div className="ml-[-5em] flex items-center sm:text-[11px] md:w-[90%]">
                    {bottomLinks &&
                        bottomLinks.map((link, index) => (
                            <div
                                key={index}
                                className="flex items-center gap-2"
                            >
                                <Link
                                    to={link.src}
                                    className="underline"
                                    style={{
                                        fontSize: "clamp(1px,2.68vw,17.48px)",
                                    }}
                                >
                                    {link.content}
                                </Link>
                                {bottomLinks.length !== index + 1 && (
                                    <span className="no-underline mr-2">
                                        {" "}
                                        |
                                    </span>
                                )}
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default Footer;
