import React, { useState, useEffect } from "react";
import useEmblaCarousel from "embla-carousel-react";

export default function Carousel({ children }: { children: React.ReactNode }) {
    const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true });
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

    useEffect(() => {
        if (emblaApi) {
            setScrollSnaps(emblaApi.scrollSnapList());
            emblaApi.on("select", () => {
                setSelectedIndex(emblaApi.selectedScrollSnap());
            });
        }
    }, [emblaApi]);

    const scrollTo = (index: number) => emblaApi && emblaApi.scrollTo(index);

    const isFirstSlide = selectedIndex === 0;
    const isLastSlide = selectedIndex === scrollSnaps.length - 1;
    const getIndicatorIndex = () => {
        if (isFirstSlide) return 0; // Highlight first dot
        if (isLastSlide) return 2; // Highlight third dot for last slide
        return 1; // Keep second dot highlighted for all other slides
    };

    return (
        <div className="embla size-full flex flex-col justify-center items-center">
            {Array.isArray(children) && children.length <= 1 ? (
                <>
                    <div className="flex justify-center items-center">
                        <div className="w-[100vw] grid grid-cols-1 place-content-center place-items-center">
                            {children}
                        </div>
                    </div>

                    {/* disabled index indicator */}
                    {/* <div className=" flex  size-full *:my-auto *:translate-y-[50%] w-full relative my-[37px] justify-center items-center"> */}
                    <div className="embla__nav flex my-6 w-full items-center">
                        <button
                            className="size-full mr-[32px] flex justify-end opacity-40"
                            onClick={() => emblaApi && emblaApi.scrollPrev()}
                        >
                            <img src="/Images/prev.svg" alt="left arrow" />
                        </button>
                        <button
                            style={{
                                // width: "100%",
                                // minWidth: "clamp(0.1%, 1vw, 56.05px)",
                                // maxWidth: "56.05px",
                                maxWidth: "56.05px",
                                width: "100%",
                                minWidth: "0.1%",
                                borderRadius: "9999px",
                                height: "6.29px",
                            }}
                            className={`mx-[15.44px]  ${
                                selectedIndex % 3 === 0
                                    ? "bg-primary"
                                    : "bg-[rgba(255,255,255,0.15)] overflow-hidden"
                            }`}
                            onClick={() => scrollTo(0)}
                        >
                            {/* <span
                className="embla__dot"
                style={{ width: "100%", height: "6.29px" }}
              /> */}
                        </button>
                        <button
                            className="size-full ml-[32px] flex justify-start opacity-40"
                            onClick={() => emblaApi && emblaApi.scrollNext()}
                        >
                            <img src="/Images/next.svg" alt="right arrow" />
                        </button>
                    </div>
                </>
            ) : (
                <>
                    <div
                        className="w-[80vw]  justify-center items-center"
                        ref={emblaRef}
                    >
                        <div className="flex ">
                            {Array.isArray(children) &&
                                children.map((child, index) => (
                                    <div key={index}>
                                        <div className="w-[100vw] grid grid-cols-1 place-content-center place-items-center ">
                                            {child}
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                    {/* <div className="embla__nav flex  size-full *:my-auto *:translate-y-[50%] w-full relative my-[37px] justify-center items-center"> */}
                    <div className="embla__nav flex my-6 w-full items-center">
                        {Array.isArray(children) && children.length > 1 && (
                            <button
                                className="size-full mr-[32px] flex justify-end"
                                onClick={() =>
                                    emblaApi && emblaApi.scrollPrev()
                                }
                            >
                                <img src="/Images/prev.svg" alt="left arrow" />
                            </button>
                        )}
                        {Array.isArray(children) &&
                            children?.slice(0, 3).map((_, index) => (
                                <button
                                    key={index}
                                    style={{
                                        // width: "100%",
                                        // minWidth: "clamp(0.1%, 1vw, 56.05px)",
                                        // maxWidth: "56.05px",
                                        maxWidth: "56.05px",
                                        width: "100%",
                                        minWidth: "0.1%",
                                        borderRadius: "9999px",
                                        height: "6.29px",
                                    }}
                                    className={`mx-[15.44px]  w-full ${
                                        getIndicatorIndex() === index
                                            ? "bg-primary"
                                            : "bg-[#333333] overflow-hidden"
                                    }`}
                                    onClick={() => scrollTo(index)}
                                >
                                    {/* <span
                    className="embla__dot"
                    style={{ width: "100%", height: "6.29px" }}
                  /> */}
                                </button>
                            ))}
                        {Array.isArray(children) && children.length > 1 && (
                            <button
                                className="size-full ml-[32px] flex justify-start"
                                onClick={() =>
                                    emblaApi && emblaApi.scrollNext()
                                }
                            >
                                <img src="/Images/next.svg" alt="right arrow" />
                            </button>
                        )}
                    </div>
                </>
            )}
        </div>
    );
}
