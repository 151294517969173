import React, { ReactNode, useEffect, useState } from "react";
import Button from "../Button";
import { poundSign } from "../../Pages/LandingPage";
import RangeSlider from "../RangeSlider";
import moment from "moment";
import Countdown from "react-countdown";
import LinearProgress, {
    LinearProgressProps,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material";
import { useAuth } from "../../Contexts/AuthContext";
import Popup from "../Dashboard Components/Popup";
import { joinCompetition } from "../../Services/userService";
import { useNavigate } from "react-router-dom";
import BuyTicketsPopup from "../Dashboard Components/BuyTicketsPopup";
import { CompetitionImages } from "./Competitions";
import { Text } from "../react-responsive-utilities/src";
import Counter from "../common/Counter/Counter";
import VSpacer from "../common/Spacer/VSpacer";
import Coin from "../Animated/Coin";
import CounterWithoutSecs from "../common/Counter/CounterWithoutSeconds";
import ColoredCoin from "../Animated/ColoredCoin";

const progress = (200 / 460) * 100;
// const small_chicken = "/Images/small_yellow_chicken.png";

const FinishedCompetitionDetails: React.FC<any> = ({
    getCompetition,
    competition,
}) => {
    const [currentImageIndex] = useState(0);
    const navigate = useNavigate();
    const [value, setValue] = useState(competition?.minnumberoftickets);
    const { user, setToken } = useAuth();
    const [buying, setBuying] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [showPopUp, setShowPopUp] = useState(false);
    const [noOfTicket, setNoOfTicket] = useState(0);
    const [competitionImages, setCompetitionImages] = React.useState<
        CompetitionImages[]
    >([]);

    const [featuredImage, setFeaturedImage] = useState<CompetitionImages>();

    const sold = (200 / 460) * 100;
    const { fetchUser } = useAuth();

    const images = [
        {
            imgSrc: "/Images/coin_btc.png",
        },
        {
            imgSrc: "/Images/coin_btc.png",
        },
        {
            imgSrc: "/Images/coin_btc.png",
        },
        {
            imgSrc: "/Images/coin_btc.png",
        },
        {
            imgSrc: "/Images/coin_btc.png",
        },
        {
            imgSrc: "/Images/coin_btc.png",
        },
        {
            imgSrc: "/Images/coin_btc.png",
        },
        {
            imgSrc: "/Images/coin_btc.png",
        },
    ];
    const getImages = async () => {
        const images = competition?.imageUrls;
        if (images.length > 0) {
            setFeaturedImage(images[0]);
            setCompetitionImages(images);
        }
    };

    useEffect(() => {
        if (competition?.UID) {
            getImages();
        }
    }, [competition?.UID]);
    const chickenSpot = sold - sold / 22;

    async function buyTicket() {
        if (user) {
            await setNoOfTicket(value);
            setShowPopUp(true);
        } else {
            alert("Please login to buy ticket");
            navigate("/auth/signin");
        }
    }

    const navigateToResult = (competition?: any, UID?: string) => {
        navigate(`/competitionResult/${UID}`);
    };

    const finishedCompetition = (competitionenddate?: Date) => {
        const currentDate = new Date();
        const competitionEndDate = new Date(competitionenddate ?? new Date());
        if (currentDate > competitionEndDate) {
            return true;
        } else {
            return false;
        }
    };

    const Title = ({ title }: { title: string }) => (
        <Text
            textSize={[30, 30.0, 50.0]}
            className="text-[#FFFFFF] font-['Sequel100Wide95'] //leading-[35px] leading-normal font-[60] block sm:text-center md:text-center"
        >
            {title}
        </Text>
    );

    const Description = ({ description }: { description: string }) => (
        <Text
            textSize={[14, 14.0, 19.96]}
            className="
      text-[#FFFFFF] font-['Sequel100WideVF45'] leading-[17px] font-[10] block sm:text-center md:text-center"
        >
            {description}
        </Text>
    );

    const RightContainer = ({ children }: { children: ReactNode }) => (
        <div className=" lg:pt-[20px]   flex flex-col gap-[2em] sm:gap-0 md:gap-0 lg:gap-0    rounded-[30px] //xl:ml-[30px] //xl:mr-[30px] //lg:ml-[5px] //lg:mr-[5px]  //sm:ml-[10px] //sm:mr-[10px] //md:ml-[10px] //md:mr-[10px] m-auto  min-h-[518px] sm:min-h-[317px] md:min-h-[389.9px] bg-[#232323] sm:bg-[rgba(239,0,143,0.0)] *:m-[initial] *:text-left sm:*:m-auto md:*:m-auto sm:*:text-center md:*:text-center relative">
            {children}
        </div>
    );

    const handleCopy = () => {
        navigator.clipboard
            .writeText("")
            .then(() => {
                alert("Copied to clipboard!");
            })
            .catch((err) => {
                console.error("Failed to copy: ", err);
            });
    };

    // Define the type for the competition detail object
    interface CompetitionDetail {
        label: string;
        value: string;
        isCopyEnabled: boolean;
    }

    // Create an array of competition details

    const timestamp = competition?.competitionenddate;
    const date = new Date(timestamp);

    // Extract day, month, and year and format them as dd-mm-yyyy
    const formattedDate = `${String(date.getDate()).padStart(2, "0")}-${String(
        date.getMonth() + 1
    ).padStart(2, "0")}-${date.getFullYear()}`;

    const competitionDetails: CompetitionDetail[] = [
        { label: "Winner", value: "XXXXXXXX", isCopyEnabled: false },
        {
            label: "Prize Transfer TX Hash",
            value: "XXXXXXXXXXXXXXXXXXXXXX",
            isCopyEnabled: true,
        },
        {
            label: "Blockchain RNG TX Hash",
            value: "XXXXXXXXXXXXXXXXXXXXXX",
            isCopyEnabled: true,
        },
    ];

    return (
        <div className="w-full mx-[43px]">
            <div className="//sm:max-w-[394px] gap-x-[38px]  sm:w-[calc(100%-18px)] m-auto p-[40px] max-w-[1332px] w-[calc(100%-18px)] !bg-[#191919] sm:min-h-[739px] //sm:min-h-[539px] rounded-[30px] grid grid-cols-2 sm:grid-cols-1 max-870:!grid-cols-1">
                <div>
                    <img
                        src={featuredImage?.imageurl ?? "/Images/No Image.svg"}
                        alt=""
                        className="w-[590px] h-[423px] sm:w-full md:w-full lg:w-full sm:h-full sm:max-h-[344px] md:h-full lg:h-full  rounded-3xl object-cover"
                        onError={(e) => {
                            e.currentTarget.src = "/Images/No Image.svg";
                        }}
                    />
                </div>
                <div>
                    <VSpacer big={0} small={29.88} />
                    <Title title={competition?.competitionname} />
                    <VSpacer big={16} small={9.73} />
                    <hr className="max-w-[320.18px] sm:m-auto max-870:m-auto w-full border-b-[5px] border-t-0 border-[#EF008F] sm:block md:block h-[3.14px] rounded-full" />
                    <VSpacer big={15} small={10.68} />
                    <Description
                        description={competition?.competitioninformation}
                    />
                    <VSpacer big={26} small={28} />
                    <div className="sm:m-auto w-fit max-870:m-auto">
                        <CounterWithoutSecs
                            title={"FINISHED DATE"}
                            isFinishedCompetition={true}
                            competitionEndDate={competition?.competitionenddate}
                            key={competition?.UID}
                        />
                    </div>
                    <VSpacer big={18.24} small={0} />
                    <p
                        className="mt-[8px] text-secondary font-['Sequel100Wide95'] text-[14px] font-semibold sm:hidden md:hidden lg:hidden"
                        style={{
                            fontSize: "clamp(1px, 2.815621311vw, 14.18px)",
                        }}
                    >
                        BUY TICKETS AND SPIN TO WIN!
                    </p>
                    <VSpacer big={21.6} small={30.43} />
                    <Button
                        disabled={finishedCompetition(
                            competition?.competitionenddate
                        )}
                        // className={`mt-2  xl:h-[61.3px] xl:w-[613px] lg:h-[61.3px] lg:w-[613px] md:h-[61.3px] md:w-[613px] sm:h-[54.08px] sm:max-w-[355px] sm:min-w-0 sm:w-full text-[#1F1F1F] bg-customGray text-2xl font-['Sequel100Wide'] w-[70%] rounded-[13px] mb-7`}
                        className="w-full sm:h-[54.08px] h-[61.34px] !rounded-[13.31px] sm:!rounded-[12.55px] !bg-[#414141] font-[Sequel100Wide95]"
                        content={"FINISHED"}
                        customStyle={{
                            fontSize: "clamp(1px,5.361012052vw,30px)",
                        }}
                        onClick={() => {
                            navigateToResult(competition, competition?.UID);
                        }}
                    />
                </div>
            </div>
            {/* competition details */}
            <VSpacer small={20} big={30} />
            <div className="     md:items-left md:text-left    md:pr-0 lg:pr-0  md:pl-0 lg:pl-0  max-w-[1332px] w-[calc(100%-18px)] m-auto ">
                <div className="     relative  overflow-hidden w-full ">
                    <RightContainer>
                        <div className="px-[max(0vw,53.7px)] sm:px-[max(0vw,15px)] md:px-[max(0vw,15px)] lg:px-[max(0vw,15px)] ">
                            <VSpacer small={20} big={30} />

                            {competitionDetails.map((detail, index) => (
                                <div className="w-full text-left  ">
                                    <div className="text-[#DDE404] font-['Sequel100Wide95'] leading-[30px] xl:text-[28px] lg:text-[25px] sm:text-[18px] md:text-[20px] text-left md:ml-5 lg:ml-5">
                                        {detail.label}
                                    </div>

                                    <div className="sm:h-[8px] lg:h-[8px] xl:h-[2px]"></div>

                                    <div className="flex items-center justify-between text-[#FFFFFF] font-['Sequel100Wide95'] leading-[35px] xl:text-[32px] lg:text-[30px] sm:text-[18px] md:text-[22px] md:ml-5 lg:ml-5 text-left">
                                        <span className="block">
                                            {detail.value}
                                        </span>
                                        {detail.isCopyEnabled && (
                                            <img
                                                src="/Images/copy-icon.svg"
                                                alt="Copy Icon"
                                                className="h-[26px] w-[26px] sm:h-[20px] sm:w-[20px] md:mr-5 lg:ml-5"
                                            />
                                        )}
                                    </div>

                                    <div className="sm:h-[8px] lg:h-[40px] xl:h-[10px] md: h-[5px]"></div>

                                    {/* <VSpacer small={8}  big={10} /> */}
                                </div>
                            ))}

                            <div className="flex justify-start w-full sm:pr-[360px] xl:mt-[50px] lg:mt-[50px] md:ml-[20px] lg:ml-[20px] max-870:mt-[30px]">
                                {" "}
                                {/* Flex container for left alignment */}
                                <button className="back-button h-[52px]  w-[145px] min-w-[145px] border border-[#DDE404] text-[#DDE404] bg-transparent rounded-[30px] font-bold flex items-center justify-center gap-3.5 md:mb-5 md:ml-5">
                                    <img
                                        src="/Images/Arrow up-circle.svg"
                                        alt="Back Arrow"
                                        className="h-[26px] w-[26px]"
                                    />
                                    <span className="text-[#FFFFFF] font-light font-['Sequel100Wide95'] leading-normal text-[11px]">
                                        BACK
                                    </span>
                                </button>
                            </div>
                            {/* <VSpacer small={11.96} big={24} /> */}
                            {/* <div className="absolute  sm:hidden md:right-[50%] md:top-[50%] lg:-right-[2%] lg:top-[25%] xl:-right-[4%] xl:top-[2%] sm:transform sm:translate-x-1/2 sm:-translate-y-1/2 md:transform md:translate-x-1/2 md:-translate-y-1/2 opacity-[15%] ">
              <ColoredCoin height="1174" width="1174" />
            </div> */}
                        </div>
                        <div className="h-[126px] w-full relative  sm:hidden">
                            <img
                                src="/Images/THEPRIZE_TokenLogo_EH-03.svg"
                                alt="THEPRIZE Token Logo"
                                className="absolute right-0  bottom-0 w-[648px] sm:w-[60%] max-870:w-[60%]  rounded-br-[30px]"
                            />
                        </div>
                    </RightContainer>
                </div>
            </div>
            <VSpacer small={20} big={30} />
        </div>
    );
};

export default FinishedCompetitionDetails;
