interface DataInfoProps {
  text: string;
}
export default function DataInfo(props: DataInfoProps) {
  return (
    <p className="font-[Sequel100Wide95] sm_md:text-[20px] text-[30px] text-white">
      {props.text}
    </p>
  );
}
