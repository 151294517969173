import { Common } from "../../common/Common";

interface ITableSmallValue {
  text: string;
}

export default function TableSmallValue(props: ITableSmallValue) {
  return (
    <Common.typography
      fontFamily="Sequel100Wide95"
      fontSize="14px"
      fontSizeSmall="14px"
      color="#ffffff"
    >
      {props.text}
    </Common.typography>
  );
}
