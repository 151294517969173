import { useRef } from "react";
import HSpacer from "../../../common/Spacer/HSpacer";
import { LinearProgress } from "@mui/material";

interface OrdersTableSmallProps {
  isFetching: boolean;
  onScrolledToBottom: () => void;
  table: tableData[];
}

const TableRow = ({ children }: { children: React.ReactNode }) => (
  <tr className="grid grid-cols-2">{children}</tr>
);

const TableHeading = ({ text }: { text: string }) => (
  <th className="font-[Sequel100WideVF45] text-[14px] text-white text-left uppercase text-wrap flex items-center">
    {text}
  </th>
);

const TableData = ({
  text,
  iscopyable,
}: {
  text: string;
  iscopyable?: boolean;
}) => (
  <td className="font-[Sequel100WideVF45] text-[14px] text-[#8a8a8a] text-right flex justify-end items-center ">
    <p className="w-fit">{text}</p>
    {iscopyable && (
      <>
        <HSpacer small={18} big={0} />
        <img
          src="/Images/copy.svg"
          alt="copy"
          className="cursor-pointer"
          onClick={() => {
            navigator.clipboard.writeText(text);
          }}
        />
      </>
    )}
  </td>
);
export default function OrdersTableSmall(props: OrdersTableSmallProps) {
  const scroller = useRef<HTMLDivElement | null>(null);
  const handleScroll = () => {
    const div = scroller.current;
    if (div) {
      const isEndReached =
        div.scrollHeight - div.scrollTop === div.clientHeight;
      if (isEndReached) {
        props.onScrolledToBottom();
      }
    }
  };
  return (
    <div className="hidden sm_md:block border-2 border-primary rounded-[20px]   w-full px-[clamp(0px,4vw,26px)] py-[29px] ">
      <div
        className="scrollbar scrollbar-thumb-secondary scrollbar-track-[#3B3B3B] scrollbar-w-[4px] overflow-auto max-h-[466px] "
        ref={scroller}
        onScroll={handleScroll}
      >
        {props.table.map((data, index) => {
          return (
            <div>
              <table className="w-full ">
                <TableRow>
                  <TableHeading text="tickets bought" />
                  <TableData text={data.ticketsbought} />
                </TableRow>
                <TableRow>
                  <TableHeading text="network" />
                  <TableData text={data.network} />
                </TableRow>
                <TableRow>
                  <TableHeading text="Transaction Hash" />
                  <TableData text={data.TransactionHash} iscopyable />
                </TableRow>
                <TableRow>
                  <TableHeading text="Day / Time" />
                  <TableData text={data.DateTime} />
                </TableRow>
                <TableRow>
                  <TableHeading text="Amount" />
                  <TableData text={data.Amount} />
                </TableRow>
              </table>
              {index !== props.table.length - 1 && (
                <div className="border-b-2 border-primary w-[calc(100%-18px)] h-[1px] my-[20px]"></div>
              )}
            </div>
          );
        })}

        <LinearProgress
          className={
            "w-full rounded-b-[30px] " +
            (props.isFetching ? "opacity-100" : "opacity-0")
          }
          color="secondary"
        />
      </div>
    </div>
  );
}
