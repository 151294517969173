import React, { useEffect } from "react";
import Button from "../Components/Button";
import AdvertBox from "../Components/Page Components/AdvertBox";
import FAQ from "../Components/Page Components/FAQ";
import WithNavbarAndFooter from "../Layout/WithNavbarAndFooter";
import { Link, useLocation } from "react-router-dom";
import { scrollTo } from "../utils/ScrollTo";
import HowToPlay from "../Components/Page Components/HowToPlay";
import PhoneStores from "../Components/Page Components/PhoneStores";

const HowToPlayPage = () => {
  const location = useLocation();
  const isFaq = location.state?.isFaq;
  const FaqContainerRef = React.createRef<HTMLDivElement>();
  const howToPlay = [
    {
      img: "/Images/No Image.svg",
      title: " How to enter the prize competitions",
      description: [
        { item: "Pick the competition you would like to enter." },
        {
          item: "Select the ticket number(s) you would like in the competition or use the lucky dip option.",
        },
        {
          item: "You will receive an email confirmation with your number for that draw.",
        },
      ],
      left: false,
    },
    {
      img: "/Images/bitcoin-1.png",
      title: "How The Winner Is Picked & When We Go Live",
      description: [
        {
          item: "After all the numbers are sold out, we will announce the draw time on the competition page and our social media.",
        },
        {
          item: "We post all draw numbers on our pages.",
        },
        {
          item: "Our live stream draw will use google random number generator to pick the number, a lucky winner will then be picked.",
        },
      ],
      left: true,
    },
    {
      img: "/Images/bitcoin-2.png",
      title: "Delivery & Winner Pictures",
      description: [
        {
          item: "The prize will be delivered to the winner within 7 days of the winner being announced. Delivery is free to UK.",
        },
        {
          item: "Pictures will be uploaded to our Winners section.",
        },
      ],
      left: false,
    },
  ];

  const scrollToFaq = () => {
    return new Promise((resolve) => {
      FaqContainerRef.current?.scrollIntoView();
      resolve(true);
    });
  };

  useEffect(() => {
    if (isFaq) {
      if (FaqContainerRef.current) {
        scrollToFaq().then(() => {
          window.scrollBy(0, -100);
        });
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [isFaq]);

  return (
    <div className="hero">
      <HowToPlay />

      <div ref={FaqContainerRef}>
        <FAQ className={""} fontSize={"text-[45px]"} />
      </div>
      <AdvertBox />

      <PhoneStores background="bg-gradient-to-r from-[#a51269] to-[#2b2b2b] h-screen " />
    </div>
  );
};

export default WithNavbarAndFooter(HowToPlayPage);
