import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useAuth } from "../../Contexts/AuthContext";
import { loginByEmailPassword } from "../../Services/jwtService";
import Input from "../Input";
import { Alert } from "@mui/material";
import { useSnackbar, EnqueueSnackbar } from "notistack";
import Button from "../Button";
import _ from "lodash";
import ForgetPasswordPopup from "../Profile Components/ForgetPasswordPopup";

const schema = yup.object().shape({
  emailaddress: yup.string().email().required("email is required"),
  password: yup.string().required("password is required"),
});
const LoginForm = () => {
  const methods = useForm({
    mode: "onChange",
    defaultValues: {},
    resolver: yupResolver(schema),
  });

  const {
    watch,
    control,
    formState: { isValid, isSubmitting, errors, dirtyFields },
    handleSubmit,
  } = methods;
  watch();
  const { setToken } = useAuth();
  const navigate = useNavigate();
  const [error, setError] = React.useState<string>("");
  const [showResetPasswordPopUp, setShowResetPasswordPopUp] = useState(false);

  //const auth = useContext(AuthContext);

  // if (!auth) {
  //   throw new Error("AuthContext is not available");
  // }

  const showResetPopup = () => {
    setShowResetPasswordPopUp(true);
  };

  const hideResetPopup = () => {
    setShowResetPasswordPopUp(false);
  };

  const onSubmit = async (data: any) => {
    try {
      const result = await loginByEmailPassword(
        data?.emailaddress,
        data?.password
      );
      const token = result?.accessToken;
      if (token) {
        setToken(token);
        localStorage.setItem("emailaddress", data?.emailaddress);
        navigate("/wallet", { replace: true });
      }
    } catch (error: any) {
      console.log("error", error?.response?.data?.message);
      setError(error?.response?.data?.message);
      setTimeout(() => {
        setError("");
      }, 3000);
    }
  };

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col items-center gap-[2.5em] sm:gap-[1em]">
        {showResetPasswordPopUp && (
          <ForgetPasswordPopup
            rejectClick={hideResetPopup}
            open={showResetPasswordPopUp}
            handleClose={hideResetPopup}
          />
        )}
        {error && (
          <Alert
            className="rounded-full w-full font-['Sequel100WideVF45']"
            variant="filled"
            sx={{
              borderRadius: 20,
              fontFamily: "Sequel100WideVF45",
            }}
            severity="error"
          >
            {error}
          </Alert>
        )}
        <Controller
          name="emailaddress"
          control={control}
          render={({ field }) => (
            <div>
              <div className="flex items-center text-white text-[20px] font-light gap-4 sm:flex-col md:flex-col sm:items-start">
                <label
                  htmlFor=""
                  className="text-right w-[190px] sm:w-auto"
                  style={{
                    fontSize: "clamp(1px,3.26308206687vw,20.92px)",
                  }}
                >
                  Email Address
                </label>
                <Input
                  {...field}
                  type={"email"}
                  className={
                    "w-[529px] h-[41px] sm:w-[302px] md:w-[302px] rounded-full pl-2"
                  }
                />
              </div>
              {errors.emailaddress && (
                <div className="xl:pl-[15rem] lg:pl-[15rem]">
                  <p className="text-red-500 font-['Sequel100WideVF45']">
                    {errors.emailaddress.message}
                  </p>
                </div>
              )}
            </div>
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <div>
              <div className="flex items-center text-white text-[20px] font-light gap-4 sm:flex-col md:flex-col sm:items-start">
                <label
                  htmlFor=""
                  className="text-right  w-[190px] sm:w-auto"
                  style={{
                    fontSize: "clamp(1px,3.26308206687vw,20.92px)",
                  }}
                >
                  Password
                </label>
                <Input
                  {...field}
                  type={"password"}
                  className={
                    "w-[529px] h-[41px] sm:w-[302px] md:w-[302px] rounded-full pl-2"
                  }
                />
              </div>
              {errors.password && (
                <div className="xl:pl-[15rem] lg:pl-[15rem]">
                  <p className="text-red-500 font-['Sequel100WideVF45']">
                    {errors.password.message}
                  </p>
                </div>
              )}
            </div>
          )}
        />
        <Button
          disabled={_.isEmpty(dirtyFields) || !isValid}
          loading={isSubmitting}
          content=" Submit"
          onClick={handleSubmit(onSubmit)}
          className="rounded-full text-[#232323] text-[26px] font-[MontBlancBlack] font-extrabold  w-[306px] h-[42px] ml-[-0.6em] uppercase mt-[1em] sm:w-[175px] sm:text-[15px]"
          customStyle={{
            fontSize: "clamp(1px,3.45804013087vw,25.96px)",
          }}
        />
        <a onClick={showResetPopup} className="text-white cursor-pointer">
          Forgot Password?
        </a>
        <p className="uppercase flex items-center text-[10px] text-white gap-2 mt-[-1em] xl:hidden">
          Payment Options <img src="/Images/dropdown_white.png" alt="" />
        </p>
      </div>
    </FormProvider>
  );
};

export default LoginForm;
