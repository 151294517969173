import React, { useState, useEffect } from "react";

const ConfirmationPopup = () => {
    const [isOpen, setIsOpen] = useState(true); // Popup is shown by default on page load

    const handleClose = () => setIsOpen(false);
    const handleConfirm = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        // Adding the 'blur' class to the background content only
        document.body.classList.add("overflow-hidden");

        // Clean up the blur effect when the popup is closed
        return () => {
            document.body.classList.remove("overflow-hidden");
        };
    }, [isOpen]);

    return (
        <>
            {/* Background content (this will be blurred)
            <div className="absolute inset-0 bg-gray-200 bg-cover bg-center z-0 blur-sm"></div> */}

            {/* Popup */}
            {isOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[10010003300000]">
                    <div className="bg-primary rounded-lg shadow-lg p-6 xl:w-[40%] lg:w-[40%] md:w-[60%] sm:w-[90%] z-10">
                        <span className="font-[Sequel100Wide] text-lg font-semibold mb-4">
                            PLEASE NOTE:{" "}
                        </span>
                        <span className="font-[Sequel100WideVF45] text-lg font-semibold mb-4">
                            This site is a preview for illustration purposes
                            only. Our full platform officially launches
                            mid-December, where you’ll be able to join live
                            competitions and compete for exciting prizes. Stay
                            tuned—the excitement is just getting started!
                        </span>
                        <div className="h-[20px]"></div>
                        <div className="flex justify-end gap-4">
                            <button
                                onClick={handleConfirm}
                                className="bg-[#212121] xl:py-[13px] w-full rounded-[14px] border-2 border-white font-[Sequel100wide] text-white h-[56px]"
                            >
                                Confirm
                            </button>
                            {/* <button
                                onClick={handleConfirm}
                                className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
                            >
                                Confirm
                            </button> */}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ConfirmationPopup;
