import { useNavigate } from "react-router-dom";

export default function EditUserDetailsButton() {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/edituser");
  };
  return (
    <button
      onClick={handleClick}
      className="uppercase max-w-[394px] sm_md:max-w-full h-[55px] bg-white text-black sm_md:w-[calc(100%-30px)] sm_md:h-[45.93px] sm_md:text-[clamp(0px,4vw,18.37px)] text-[22px] font-[Sequel100Wide95] rounded-[25.05px] px-[44px]"
    >
      Edit User Details
    </button>
  );
}
