import React from "react";
import { Common } from "../../../common/Common";
import { Text } from "../../../react-responsive-utilities/src";

export default function Action({
  minnumberoftickets,
  entryValue,
}: {
  minnumberoftickets: any;
  entryValue: any;
}) {
  return (
    <div className="bg-[#161616] w-full sm:h-[46.9px] h-[56.95px]  grid grid-cols-2 rounded-[55.07px]">
      <div className="flex flex-col justify-center items-center  sm:py-auto py-2">
        <Common.typography
          fontFamily="Sequel100Wide-65"
          fontSize="14px"
          fontSizeSmall="11.53px"
          color="#FFFFFF"
          className="text-center uppercase leading-none"
        >
          Min Tickets:
        </Common.typography>
        <Common.typography
          fontFamily="Sequel100Wide95"
          fontSize="21.3px"
          fontSizeSmall="17.94px"
          color="#FFFFFF"
          className="text-center leading-none"
        >
          {minnumberoftickets}
        </Common.typography>
      </div>
      <div className="border-2 bg-[#DDE404] shadow-lg shadow-black rounded-[55.07px] flex flex-col justify-center sm:py-auto py-2">
        <Common.typography
          className="text-black uppercase leading-none block text-center"
          fontFamily="Sequel100Wide-65"
          fontSize="14px"
          fontSizeSmall="11.51px"
        >
          entry value:
        </Common.typography>
        <Common.typography
          className="text-black leading-none font-[MontBlancBlack] block text-center"
          fontFamily="Sequel100Wide95"
          fontSize="21.3px"
          fontSizeSmall="17.51px"
        >
          ${entryValue}
          {/* $0.99 */}
        </Common.typography>
      </div>
    </div>
  );
}
