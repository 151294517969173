import { useEffect, useState } from "react";
import VSpacer from "../../../Components/common/Spacer/VSpacer";
import ExitUserDetailsButton from "../../../Components/User Pages Components/Edit User/Buttons/ExitUserDetailsButton";
import { AvatarContainer } from "../../../Components/User Pages Components/Edit User/Containers/AvatarContainer";
import DataContainer from "../../../Components/User Pages Components/Edit User/Containers/DataContainer";
import Title2Container from "../../../Components/User Pages Components/Edit User/Containers/Title2Container";
import Avatar from "../../../Components/User Pages Components/Edit User/images/Avatar";
import DataField from "../../../Components/User Pages Components/Edit User/texts/DataField";
import Description from "../../../Components/User Pages Components/Edit User/texts/Description";
import Title from "../../../Components/User Pages Components/Edit User/texts/Title";
import Title2 from "../../../Components/User Pages Components/Edit User/texts/Title2";
import Title3 from "../../../Components/User Pages Components/Edit User/texts/Title3";
import { useAuth } from "../../../Contexts/AuthContext";
import WithNonAbsolutedSidebarNavbarFooter from "../../../Layout/WithNonAbsolutedSidebarNavbarFooter";
import {
    editTelegramHandle,
    editUserDetails,
    setAvatar,
} from "../../../Services/userService";
import AvatarSelectorPopup from "../../../Components/User Pages Components/My Account/others/AvatarSelectorPopup";

interface IInformation {
    target:
        | "Email"
        | "Name"
        | "MobileNumber"
        | "TelegramHandle"
        | "AddressLine1"
        | "AddressLine2"
        | "City"
        | "Country"
        | "Postcode";
    value: string;
}

export default function EditUser() {
    const [openPopup, setOpenPopup] = useState(false);
    useEffect(() => {
        document.getElementById("edit_user_main")?.scrollIntoView();
    }, []);

    const { user, fetchUser } = useAuth();
    const initialUserInformation = {
        Email: user?.userName || "xxxxxxxxxxxxxxxxxxxx",
        Name: user?.name || "",
        MobileNumber: user?.phone || "",
        TelegramHandle: user?.telegramHandle || "xxxxxxxxxxxxxxxxxxxx",
        AddressLine1: user?.address1 || "xxxxxxxxxxxxxxxxxxxx",
        AddressLine2: user?.address2 || "xxxxxxxxxxxxxxxxxxxx",
        City: user?.city || "xxxxxxxxxxxxxxxxxxxx",
        Country: user?.country || "xxxxxxxxxxxxxxxxxxxx",
        Postcode: user?.postcode || "xxxxxxxxxxxxxxxxxxxx",
    };
    const [userInformation, setUserInformation] = useState(
        initialUserInformation
    );
    useEffect(() => {
        setUserInformation({
            Email: user?.userName || "",
            Name: user?.name || "",
            MobileNumber: user?.phone || "",
            TelegramHandle: user?.telegramHandle || "",
            AddressLine1: user?.address1 || "",
            AddressLine2: user?.address2 || "",
            City: user?.city || "",
            Country: user?.country || "",
            Postcode: user?.postcode || "",
        });
    }, [user]);
    const changeInfo = ({ target, value }: IInformation) => {
        setUserInformation((prev) => ({ ...prev, [target]: value }));
    };
    const saveInfo = ({ target, value }: IInformation) => {
        editUserDetails({
            ...initialUserInformation,
            [target]: value,
        });
        // editTelegramHandle("@abc");
        editTelegramHandle(userInformation?.TelegramHandle || "test");
    };
    const onConfirm = (selected: string) => {
        const response = setAvatar(selected);
        setOpenPopup(false);
        // reset the user context
        fetchUser();
    };
    return (
        <WithNonAbsolutedSidebarNavbarFooter
            Component={
                // Main Container
                <div
                    id="edit_user_main"
                    className="overflow-hidden box-border bg-black w-full sm_md_lg:mx-auto sm_md_lg:w-[calc(100%-20px)] sm_md:relative  sm_md_lg:my-[20px] xl:my-8 h-full mx-[43px] rounded-[30px] py-[63px]  sm_md:py-[44px]  flex flex-col "
                >
                    {openPopup && (
                        <AvatarSelectorPopup
                            onConfirm={onConfirm}
                            onClose={() => setOpenPopup(false)}
                        />
                    )}
                    {/* Title */}
                    <Title />
                    <VSpacer small={64} big={59} />
                    {/* 2- Title 2 container */}
                    <Title2Container>
                        {/* Title2 */}
                        <Title2 />
                    </Title2Container>

                    <VSpacer small={47} big={65} />
                    <div className="sm_md:w-[calc(100%-30px)] w-[calc(100%-78px)] m-auto">
                        {/* Avatar Container row */}
                        <AvatarContainer.Row>
                            {/* Avatar image */}
                            <Avatar
                                src={user?.imageUrl || "/Images/No Image.svg"}
                                onClick={() => {
                                    setOpenPopup(true);
                                }}
                            />
                            {/* Avatar Container col */}
                            <AvatarContainer.Col>
                                {/* Title3 */}
                                <Title3 />
                                {/* avatar description */}
                                <Description />
                            </AvatarContainer.Col>
                        </AvatarContainer.Row>
                        <VSpacer small={40} big={39} />
                        {/* Data info */}
                        {/* 4.1 Username */}
                        <DataContainer>
                            <DataField
                                onSave={() =>
                                    saveInfo({
                                        target: "Name",
                                        value: userInformation?.Name || "",
                                    })
                                }
                                placeholder="Enter Username"
                                value={userInformation?.Name || ""}
                                label="Username"
                                onChange={(e) =>
                                    changeInfo({
                                        target: "Name",
                                        value: e.target.value,
                                    })
                                }
                            />
                            <VSpacer small={17} big={10} />
                        </DataContainer>
                        {/* 4.2 Telegram Handle */}
                        <DataContainer>
                            <DataField
                                onSave={() =>
                                    saveInfo({
                                        target: "TelegramHandle",
                                        value:
                                            userInformation?.TelegramHandle ||
                                            "",
                                    })
                                }
                                placeholder="Enter Telegram Handle"
                                value={userInformation?.TelegramHandle || ""}
                                label="Telegram Handle"
                                onChange={(e) =>
                                    changeInfo({
                                        target: "TelegramHandle",
                                        value: e.target.value,
                                    })
                                }
                            />
                            <VSpacer small={17} big={10} />
                        </DataContainer>
                        {/* 4.3 Telephone Number */}
                        <DataContainer>
                            <DataField
                                onSave={() =>
                                    saveInfo({
                                        target: "MobileNumber",
                                        value:
                                            userInformation?.MobileNumber || "",
                                    })
                                }
                                placeholder="Enter Telephone Number"
                                value={userInformation?.MobileNumber || ""}
                                label="Telephone Number"
                                onChange={(e) =>
                                    changeInfo({
                                        target: "MobileNumber",
                                        value: e.target.value,
                                    })
                                }
                            />
                            <VSpacer small={17} big={10} />
                        </DataContainer>
                        {/* 4.4 Email Address */}
                        <DataContainer>
                            <DataField
                                onSave={() =>
                                    saveInfo({
                                        target: "Email",
                                        value: userInformation?.Email || "",
                                    })
                                }
                                placeholder="Enter Email Address"
                                value={userInformation?.Email || ""}
                                label="Email Address"
                                onChange={(e) =>
                                    changeInfo({
                                        target: "Email",
                                        value: e.target.value,
                                    })
                                }
                            />
                            <VSpacer small={17} big={10} />
                        </DataContainer>
                        <VSpacer small={37} big={42.06} />
                        {/* Edit button */}
                    </div>
                    <div className="sm_md:w-[calc(100%-30px)] w-[calc(100%-78px)] m-auto *:uppercase">
                        <ExitUserDetailsButton />
                    </div>
                </div>
            }
        />
    );
}
