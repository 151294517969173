import React from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import LoginForm from "../Components/Auth Components/LoginForm";
import RegisterForm from "../Components/Auth Components/RegisterForm";
import MembershipBox, { MembershipBoxProps } from "../Components/MembershipBox";
import WithNavbarAndFooter from "../Layout/WithNavbarAndFooter";
import ResetPassword from "./Dashboard/ResetPassword";

const Auth = () => {
  const params = useParams();
  const { portal } = params;
  const [subscription, setSubscription] = useState(false);
  const [payAsYouGO, setPayAsYouGO] = useState(false);

  const toggleDropdown = (type: string) => {
    if (type === "sub") {
      setSubscription(!subscription);
    }

    if (type === "pay") {
      setPayAsYouGO(!payAsYouGO);
    }
  };

  // console.log(portal);

  const cards: MembershipBoxProps[] = [
    {
      title: "MEMBERSHIP",
      content: "£5 PER MONTH FOR 12 MONTHS.",
      tickets: "30 TICKETS",
      buttonContent: "SIGN UP",
    },
    {
      title: "MEMBERSHIP",
      content: "£10 PER MONTH FOR 12 MONTHS",
      tickets: "40 TICKETS",
      buttonContent: "SIGN UP",
      recommended: true,
    },
    {
      title: "PAY AS YOU GO",
      content: "£15 PER MONTH FOR 12 MONTHS",
      tickets: "50 TICKETS",
      buttonContent: "SIGN UP",
    },
  ];
  const cards2: MembershipBoxProps[] = [
    {
      title: "PAY AS YOU GO",
      content: "£5 FOR 30 TICKETS",
      buttonContent: "SIGN UP",
    },
    {
      title: "PAY AS YOU GO",
      content: "£10 FOR 40 TICKETS",
      buttonContent: "SIGN UP",
      recommended: true,
    },
    {
      title: "PAY AS YOU GO",
      content: "£15 FOR 50 TICKETS",
      buttonContent: "SIGN UP",
    },
  ];

  return (
    <div className="hero p-10 sm:p-0 md:p-0 pt-0 mb-4 mt-4 sm:mt-0 sm:mb-0">
      <div
        className={`auth flex flex-col sm:rounded-[0rem] xl:rounded-[2rem] lg:rounded-[2rem] md:rounded-[2rem] items-center gap-[6em] sm:gap-[4em] p-[4em] sm:px-0 md:px-0 pb-[11em] w-full`}
      >
        <h1
          className="pageHeading mb-[0rem] font-[Sequel100Wide95]"
          style={{
            fontSize: "clamp(1px,5.83090379vw,45px)",
          }}
        >
          {portal === "resetpassword" ? "RESET PASSWORD" : "LOG IN OR SIGN UP"}
        </h1>
        {portal === "signin" ? (
          <>
            <LoginForm />
          </>
        ) : portal === "signup" ? (
          <>
            {/* <h1 className="text-white text-[45px] font-extrabold sm:text-[28px] md:text-[28px]">
              SIGN UP
            </h1> */}
            <RegisterForm />
          </>
        ) : portal === "resetpassword" ? (
          <ResetPassword />
        ) : null}
      </div>
    </div>
  );
};

export default WithNavbarAndFooter(Auth);
