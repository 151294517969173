import {
  RouterProvider,
  createBrowserRouter,
  RouteObject,
} from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute";
import LandingPage from "../Pages/LandingPage";
import CompetitionPage from "../Pages/CompetitionPage";
import HowToPlayPage from "../Pages/HowToPlayPage";
import WinnersPage from "../Pages/WinnersPage";
import Policies from "../Pages/Policies";
import Auth from "../Pages/Auth";
import Home from "../Pages/Dashboard/Home";
import EnteredCompetitions from "../Pages/Dashboard/EnteredCompetitions";
import User from "../Pages/Dashboard/User";
import ResetPassword from "../Pages/Dashboard/ResetPassword";
import Competitions from "../Pages/Dashboard/Competitions";
import { useAuth } from "../Contexts/AuthContext";
import CompetitionDetailPage from "../Pages/CompetitionDetailPage";
import ResetPasswordScreen from "../Pages/ResetPasswordScreen";
import CompetitionResultPage from "../Pages/CompetitionResultPage";
import PurchaseTickets from "../Pages/PurchaseTickets";
import HeroSlider from "../Components/Page Components/Hero/HeroSlider";
import Entry from "../Components/User Pages Components/My Entry/Entry/Entry";
import WalletField from "../Components/User Pages Components/My Wallet/WalletField/WalletField";
import MyWallet from "../Pages/User/MyWallet/MyWallet";
import MyEntries from "../Pages/User/MyEntries/MyEntries";
import FinishedCompetitionDetailPage from "../Pages/FinishedCompetitionDetailPage";
import LiveCompetitionDetailPage from "../Pages/LiveCompetitionDetailPage";
import OrdersTable from "../Components/User Pages Components/My Orders/OrdersTable/OrdersTable";
import MyOrders from "../Pages/User/MyOrders/MyOrders";
import WithNonAbsolutedSidebarNavbarFooter from "../Layout/WithNonAbsolutedSidebarNavbarFooter";
import MyAccount from "../Pages/User/MyAccount/MyAccount";
import { Common } from "../Components/common/Common";
import BuyTickets from "../Pages/User/BuyTickets/BuyTickets";
import EditUser from "../Pages/User/EditUser/EditUser";
import ViewOrder from "../Pages/User/ViewOrder/ViewOrder";
import ViewEntry from "../Pages/User/ViewEntry/ViewEntry";
import ViewResult from "../Pages/User/ViewResult/ViewResult";
import InstantWinCompetitionPage from "../Pages/InstantWinCompetitionPage";
import PrizeCard from "../Components/InstantWinCompetitionPage/PrizeCard/PrizeCard";
import RandomNumberGeneratorPage from "../Pages/RandomNumberGeneratorPage";

// Define a type for route configuration
type Route = RouteObject & { children?: RouteObject[] };

const Routes = () => {
  const { token } = useAuth();

  const routesForPublic: Route[] = [
    { path: "/", element: <LandingPage /> },
    { path: "/resetpassword", element: <ResetPasswordScreen /> },
    { path: "/competitions/:all/*", element: <CompetitionPage /> },
    { path: "/competition/:id", element: <CompetitionDetailPage /> },
    {
      path: "/finished-competition/:id",
      element: <FinishedCompetitionDetailPage />,
    },
    {
      path: "/live-competition/:id",
      element: <LiveCompetitionDetailPage />,
    },
    { path: "/competitionResult/:id", element: <CompetitionResultPage /> },
    { path: "/howtoplay", element: <HowToPlayPage /> },
    { path: "/winners", element: <WinnersPage /> },
    { path: "/policies/:policy", element: <Policies /> },
    { path: "/wallet", element: <MyWallet /> },
    { path: "/entries/live", element: <MyEntries type="live" /> },
    { path: "/entries/finished", element: <MyEntries type="finished" /> },
    { path: "/orders", element: <MyOrders /> },
    { path: "/account", element: <MyAccount /> },
    { path: "/buytickets", element: <BuyTickets /> },
    { path: "/edituser", element: <EditUser /> },
    {
      path: "/view/order/:id",
      element: <ViewOrder />,
    },
    {
      path: "/view/entry/:id",
      element: <ViewEntry />,
    },
    {
      path: "/view/result/:id",
      element: <ViewResult />,
    },
    {
      path: "/inprogress",
      element: (
        <WithNonAbsolutedSidebarNavbarFooter
          Component={
            <div className="h-screen flex justify-center items-center w-screen">
              <p className="text-white text-[2rem] animate-pulse">
                Work in progress 🚧
              </p>
            </div>
          }
        />
      ),
    },
    {
      path: "/view/competition/instant-win/:id",
      element: <InstantWinCompetitionPage />,
    },
    {
      path: "/rng",
      element: <RandomNumberGeneratorPage />,
    },
  ];

  const routesForAuthenticatedOnly: Route[] = [
    {
      path: "/user",
      element: <ProtectedRoute />,
      children: [
        { path: "dashboard", element: <Home /> },
        {
          path: "dashboard/entered_competitions",
          element: <EnteredCompetitions />,
        },
        { path: "dashboard/user", element: <User /> },
        {
          path: "dashboard/reset_password",
          element: <ResetPassword />,
        },
        {
          path: "dashboard/competition/:id",
          element: <Competitions />,
        },
        { path: "resetpassword", element: <ResetPasswordScreen /> },
        { path: "purchase_tickets", element: <PurchaseTickets /> },
      ],
    },
  ];

  const routesForNotAuthenticatedOnly: Route[] = [
    { path: "/", element: <LandingPage /> },
    { path: "/auth/:portal", element: <Auth /> },
  ];

  const router = createBrowserRouter(
    [
      ...routesForPublic,
      ...(!token ? routesForNotAuthenticatedOnly : []),
      ...routesForAuthenticatedOnly,
    ],
    {
      basename: "/", // Specify the base URL of your app
    }
  );

  return <RouterProvider router={router} />;
};

export default Routes;
