import VSpacer from "../../../../common/Spacer/VSpacer";

interface FirstSectionProps {
  left: React.ReactNode;
  right: React.ReactNode;
}
export default function FirstSection(props: FirstSectionProps) {
  return (
    <div className="grid grid-cols-2 sm_md:grid-cols-1 gap-5">
      <div>{props.left}</div>
      {
        <div className="sm_md:block hidden">
          <VSpacer big={0} small={24} />
        </div>
      }
      <div>{props.right}</div>
    </div>
  );
}
