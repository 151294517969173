import React from "react";

const AdvertBox = () => {
    return (
        <>
            <div className="advertBox md:bg-black  sm:bg-black h-[860px] w-full py-[12%] sm:h-[711px] sm:flex sm:flex-col md:h-screen md:flex md:flex-col md:py-0 md:gap-5 md:pt-4">
                <div className=" //w-[638px] flex flex-col gap-1 ml-[5em] sm:text-center sm:w-full sm:ml-0 sm:items-center h-full sm:p-5 md:text-center md:w-full md:ml-0 md:items-center md:p-5">
                    <h3
                        className="//text-[30px] font-[Sequel100WideVF55] mb-[31px] //font-light text-white //sm:text-[18px] //md:text-[25px]"
                        style={{
                            fontSize: "clamp(1px, 4.260140417vw, 35.47px)",
                        }}
                    >
                        GET YOUR TICKETS FAST!
                    </h3>
                    <p
                        className="text-[62px] xl:w-[637px] w-[80%] leading-none font-[Sequel100Wide95] //font-extrabold text-white sm:text-[35px] md:text-[50px] "
                        // style={{
                        //     fontSize: "clamp(1px, 7.459207459vw, 72.41px)",
                        // }}
                    >
                        BEFORE THE NEXT LIVE DRAW!
                    </p>
                    <button
                        className={
                            // "bg-primary h-[80px] px-10  rounded-full font-[Sequel100Wide95] text-black text-[28.53px] font-[900] w-fit mt-[1em] sm:text-base border-2 border-white sm:h-[27px] sm:p-0 sm:mt-0 relative z-[100001] md:w-[300px]"
                            "mt-[63px] sm:mt-[36.79px] shadow-black shadow-lg bg-primary h-[80px] sm:h-[45.87px] //sm:text-[1.3rem] px-10 //sm:px-3 sm:w-max sm:px-[22px] rounded-full font-[Sequel100Wide95] text-black //text-[28.53px] font-[900] w-fit //mt-[1em] sm:text-base border-2 border-white relative z-[100001] //sm:scale-50"
                        }
                        style={{
                            fontSize: "clamp(1px, 4.267120842vw, 35.53px)",
                        }}
                    >
                        BUY TICKETS
                    </button>
                </div>
            </div>
        </>
    );
};

export default AdvertBox;
