interface ResultBigProps {
  top: React.ReactNode;
  bottom: React.ReactNode;
  varient: "won" | "lost";
}
export default function ResultSmall(props: ResultBigProps) {
  return (
    <div
      className={`overflow-hidden rounded-[27.2px] hidden flex-col sm_md:flex justify-center items-center px-[24px] py-[16px] ${
        props?.varient === "won" ? "bg-primary" : "bg-secondary"
      }`}
    >
      {props?.top}
      {props?.bottom}
    </div>
  );
}
