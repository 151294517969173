import React from "react";

interface VSpacerProps {
  small: number;
  big: number;
  treatLgAsSmall?: boolean;
}

const VSpacer: React.FC<VSpacerProps> = ({ small, big, treatLgAsSmall }) => {
  return (
    <>
      <div
        className={`xl:hidden ${!treatLgAsSmall && "lg:hidden"}`}
        style={{ height: `${small}px` }}
      ></div>
      <div
        className={`hidden xl:block ${!treatLgAsSmall && "lg:block"}`}
        style={{ height: `${big}px` }}
      ></div>
    </>
  );
};

export default VSpacer;
