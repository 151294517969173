import React from "react";

export default function HighRoller({
  isHighRoller,
  rollerAmount,
}: {
  isHighRoller: any;
  rollerAmount: any;
}) {
  return (
    <>
      {Boolean(isHighRoller) && (
        <div className="grid grid-cols-3 gap-16 grid-rows-1 w-[calc(100%-29.1px)] place-content-center place-items-center m-auto bg-[#EF008F] rounded-t-[26.75px] h-[40.68px]">
          {rollerAmount == "HIGH ROLLERS" ? (
            <img
              src="/Images/roller_dice.svg"
              alt=""
              className="xl_lg:h-[25.42px] md:h-[22.42px] sm:h-[20.42px]"
            />
          ) : (
            <img
              src="/Images/roller_image.svg"
              alt=""
              className="xl_lg:h-[25.42px] md:h-[22.42px] sm:h-[35%]"
            />
          )}
          <p className="font-[Sequel100Wide95] text-nowrap text-white uppercase text-[20.07] sm:text-[clamp(0px,14px,15.72px)]">
            {rollerAmount}
          </p>
          {rollerAmount == "HIGH ROLLERS" ? (
            <img
              src="/Images/roller_dice.svg"
              alt=""
              className="xl_lg:h-[25.42px] md:h-[22.42px] sm:h-[20.42px]"
            />
          ) : (
            <img
              src="/Images/roller_image.svg"
              alt=""
              className="xl_lg:h-[25.42px] md:h-[22.42px] sm:h-[35%]"
            />
          )}
        </div>
      )}
    </>
  );
}
