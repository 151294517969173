import React, { useEffect } from "react";
import { useRef, useState } from "react";
import AdvertBox from "../Components/Page Components/AdvertBox";
import CompetitionDetails from "../Components/Page Components/CompetitionDetails";
import {
    CompetitionProps,
    Competitions,
} from "../Components/Page Components/Competitions";
import FAQ from "../Components/Page Components/FAQ";
import HowToPlay from "../Components/Page Components/HowToPlay";
import WithNavbarAndFooter from "../Layout/WithNavbarAndFooter";
import { useAppSelector } from "../redux/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { CompetitionObject } from "../redux/reducers/competitionsSlice";
import { scrollTo } from "../utils/ScrollTo";
import { getCompetitionById } from "../Services/userService";
import moment from "moment";
import LiveCompetitionDetails from "../Components/Page Components/LiveCompetitionDetails";
import Carousel from "../Components/common/Carousel/Carousel";
import HowItWorksBoxSmall from "../Components/Page Components/HowItWorksBoxSmall";
import HowItWorksBox, {
    HowItWorksBoxProps,
} from "../Components/Page Components/HowItWorksBox";
import LiveCompetitionFAQ from "../Components/Page Components/LiveCompetitionFAQDiv";
import { Common } from "../Components/common/Common";
import PrizeCardsContainer from "../Components/InstantWinCompetitionPage/PrizeCardsContainer/PrizeCardsContainer";
import PrizeCard from "../Components/InstantWinCompetitionPage/PrizeCard/PrizeCard";

const InstantWinCompetitionPage = () => {
    const [competition, setCompetition] = useState<any>();
    const [showLiveDraw, setShowLiveDraw] = useState<boolean>();
    const params = useParams();
    const competitionid = params?.id;
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const enter = "/Images/enter-icon.svg";
    const crown = "/Images/crown-icon.svg";
    const nuclear = "/Images/nuclear-icon.svg";

    const howItWorks: HowItWorksBoxProps[] = [
        {
            icon: enter,
            title: "HOW TO ENTER THE PRIZE COMPETITIONS",
            content:
                "Browse our awesome selection of competitions, click on buy tickets. Use the Lucky Dip or custom ticket selector to select the ticket number of your choice to enter the competition.",
        },
        {
            icon: crown,
            title: `HOW THE WINNER IS PICKED & WHEN WE GO LIVE`,
            content:
                "The winner is selected using Google random number generator on our live Facebook feed, this will take place when the timer runs out or when the competition sells out.",
        },
        {
            icon: nuclear,
            title: "DELIVERY & WINNER PICTURES",
            content:
                "Join our ever growing WINNERS list and go into the Bounty hall of fame with your winners picture, make your dreams a reality! You have to be in it to win it!",
        },
    ];

    const getCompetition = async (competitionid: string) => {
        try {
            setLoading(true);
            const result = await getCompetitionById({ competitionid });
            setCompetition(result.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const formattedDateTime = moment(competition?.competitionenddate)
        .utcOffset("+00:00")
        .format("DD-MM-YYYY @ h:mmA");

    useEffect(() => {
        getCompetition(competitionid || "");
    }, [competitionid]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="hero mt-[16.87px]">
            <div className="w-full flex flex-col items-center ">
                {loading ? (
                    <div className="loader"></div>
                ) : (
                    <div className="flex flex-col items-center liveCompetition w-full">
                        <LiveCompetitionDetails
                            getCompetition={getCompetition}
                            competition={competition}
                            instant={true}
                        />
                    </div>
                )}
            </div>
            <div className="ml-[3%] mr-[3%] mt-[3%]">
                <div className="bg-black rounded-3xl ">
                    <h1
                        onClick={() => {
                            setShowLiveDraw(!showLiveDraw);
                        }}
                        className="flex text-[45px] md:text-[35px] justify-between font-['Sequel100Wide'] sm:text-[28px] items-center font-[900] text-black uppercase bg-primary  h-[180px] w-full  pt-[-1em] sm:pl-0 md:pl-0 lg:pl-0 sm:text-center md:text-center lg:text-center rounded-3xl xl:pl-[140px] xl:pr-[140px] lg:pl-[90px] lg:pr-[90px] md:pl-[60px] md:pr-[60px] sm:pl-[30px] sm:pr-[30px]"
                    >
                        Live Draw {formattedDateTime}
                        <div className="align-right pr-[40px]"></div>
                        <img src="/Images/arrow-down.svg" alt="" />
                    </h1>

                    {showLiveDraw ? (
                        <div className="  sm:rounded-t-none md:rounded-t-none lg:rounded-t-none rounded-3xl sm:border-primary md:border-primary lg:border-primary font-[Sequel100Wide] w-full p-[5em] px-[10%] text-white flex flex-col gap-[3em] sm:text-center md:text-center lg:text-center">
                            <div>
                                <h4 className="text-[40px] font-bold text-white sm:text-[22px]">
                                    Toyota Supra - GT
                                </h4>
                            </div>
                            <div className="flex flex-col gap-4 font-[MontBlancBold] text-[20px] sm:text-[16px]">
                                <span>
                                    CHECK PRIZE DESCRIPTION LIST FOR EVERY PRIZE
                                    STILL AVAILABLE! ONCE WON, IT'S
                                    AUTOMATICALLY UPDATED IN REAL-TIME
                                </span>{" "}
                                <p className="flex flex-col gap-4 font-[MontBlancRegular] text-[20px] sm:text-[16px]">
                                    WIN ANY OF THE 5 X VEHICLES INSTANTLY plus
                                    other prizes - INCLUDING £10,000 CASH,
                                    £5,000 CASH AND TONS OF TECH PRIZES!
                                </p>
                                <p className="flex flex-col gap-4 font-[MontBlancRegular] text-[20px] sm:text-[16px]">
                                    Including a HUGE £5,000 End Prize! Meaning,
                                    every entry gives you more than 2000 chances
                                    of a win!
                                </p>
                                <p className="flex flex-col gap-4 font-[MontBlancRegular] text-[20px] sm:text-[16px]">
                                    Ticket numbers are randomly generated once
                                    you make your payment. If your ticket
                                    numbers match any of the numbers above, you
                                    will instantly win that prize!
                                </p>
                                <p className="flex flex-col gap-4 font-[MontBlancBold] text-[20px] sm:text-[16px]">
                                    Grab your tickets and reveal within seconds
                                    if you're a winner!
                                </p>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
            <div className="w-full flex flex-col items-center max-w-[1400px] m-auto pl-[3%] pr-[3%] ">
                <h1
                    className={`pageHeading font-['Sequel100Wide95']`}
                    style={{
                        fontSize: "clamp(1px,5.529128597vw,45px)",
                    }}
                >
                    HOW IT WORKS
                </h1>
                {/* <div className="above-1367:scale-[1] flex font-['Sequel100WideVF'] gap-[1em] sm:hidden md:hidden xl:scale-[0.85] lg:scale-[0.80]"> */}
                {/* <div className="grid-cols-8 w-full  place-content-center place-items-center xl:grid lg:grid md:grid hidden">
            {howItWorks.map((item, index) => (
              <>
                <div className="col-span-2">
                  <HowItWorksBox
                    icon={item.icon}
                    title={item.title}
                    content={item.content}
                  />
                </div>
                <div className="flex justify-center items-center col-span-1">
                  {index + 1 !== howItWorks.length && (
                    <img
                      src="/Images/next_white.png"
                      alt=""
                      className="sm:hidden md:hidden"
                    />
                  )}
                </div>
              </>
            ))}
          </div> */}

                <div className="flex container flex-shrink max-w-[100%] sm:hidden  mb-[49.55px]">
                    {howItWorks.map((item, index) => (
                        <>
                            <HowItWorksBox
                                icon={item.icon}
                                title={item.title}
                                content={item.content}
                                key={index}
                            />
                            {index + 1 !== howItWorks.length && (
                                <div
                                    className="min-w-[5%] flex justify-center items-center"
                                    key={index}
                                >
                                    <img
                                        src="/Images/next_white.png"
                                        alt=""
                                        className="object-scale-down "
                                    />
                                </div>
                            )}
                        </>
                    ))}
                </div>

                {/* how it works mobile */}
                <div className="lg:hidden xl:hidden md:hidden">
                    {/* <Slider slides={howItWorks} options={OPTIONS} /> */}
                    <Carousel>
                        {howItWorks.map((item, index) => (
                            <HowItWorksBoxSmall
                                icon={item.icon}
                                title={item.title}
                                content={item.content}
                                key={index}
                            />
                        ))}
                    </Carousel>
                </div>
            </div>
            <div className="w-full">
                <div className="bg-primary h-[184px] flex justify-center items-center w-full">
                    <Common.typography
                        fontFamily="Sequel100Wide95"
                        fontSize="40px"
                        fontSizeSmall="25px"
                        color="#0000000"
                        className="uppercase"
                    >
                        INSTANT WIN PRIZES
                    </Common.typography>
                </div>
                <PrizeCardsContainer>
                    <PrizeCard
                        description="Rev up your excitement! Enter our car raffle for a chance to win the sleek and powerful BMW!"
                        title="$30,000 BTC"
                        image="/Images/1-btc-hero.png"
                    />
                    <PrizeCard
                        description="Description"
                        title="Title"
                        image="https://placehold.co/550x400"
                    />
                    <PrizeCard
                        description="Description"
                        title="Title"
                        image="https://placehold.co/550x400"
                    />
                    <PrizeCard
                        description="Description"
                        title="Title"
                        image="https://placehold.co/550x400"
                    />
                    <PrizeCard
                        description="Description"
                        title="Title"
                        image="https://placehold.co/550x400"
                    />
                    <PrizeCard
                        description="Description"
                        title="Title"
                        image="https://placehold.co/550x400"
                    />
                    <PrizeCard
                        description="Description"
                        title="Title"
                        image="https://placehold.co/550x400"
                    />
                    <PrizeCard
                        description="Description"
                        title="Title"
                        image="https://placehold.co/550x400"
                    />
                    <PrizeCard
                        description="Description"
                        title="Title"
                        image="https://placehold.co/550x400"
                    />
                    <PrizeCard
                        description="Rev up your excitement! Enter our car raffle for a chance to win the sleek and powerful BMW!"
                        title="$30,000 BTC"
                        image="https://placehold.co/550x400"
                        date={new Date(
                            "2022-09-09T00:00:00.000Z"
                        ).toISOString()}
                        winningTicket="29483943"
                        winner="Graham Smith"
                    />
                </PrizeCardsContainer>
            </div>
            <LiveCompetitionFAQ
                className={"faq"}
                fontSize={"text-[60px] sm:text-[28px]"}
            />
            <AdvertBox />

            {/* <PhoneStores background={"phoneStores"} /> */}
        </div>
    );
};

export default WithNavbarAndFooter(InstantWinCompetitionPage);
