import React, { useEffect } from "react";
import { useRef, useState } from "react";
import AdvertBox from "../Components/Page Components/AdvertBox";
import CompetitionDetails from "../Components/Page Components/CompetitionDetails";
import {
    CompetitionProps,
    Competitions,
} from "../Components/Page Components/Competitions";
import FAQ from "../Components/Page Components/FAQ";
import HowToPlay from "../Components/Page Components/HowToPlay";
import WithNavbarAndFooter from "../Layout/WithNavbarAndFooter";
import { useAppSelector } from "../redux/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { CompetitionObject } from "../redux/reducers/competitionsSlice";
import { scrollTo } from "../utils/ScrollTo";
import { getCompetitionById } from "../Services/userService";
import moment from "moment";
import PhoneStores from "../Components/Page Components/PhoneStores";
import CompetitionStatus from "../Components/Dashboard Components/CompetitionStatus";
import FinishedCompetitionDetails from "../Components/Page Components/FinishedCompetitionDetails";
import { Common } from "../Components/common/Common";
import VSpacer from "../Components/common/Spacer/VSpacer";

const FinishedCompetitionDetailPage = () => {
    const [competition, setCompetition] = useState<any>();
    const params = useParams();
    const competitionid = params?.id;
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const getCompetition = async (competitionid: string) => {
        try {
            setLoading(true);
            const result = await getCompetitionById({ competitionid });
            setCompetition(result.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const formattedDateTime = moment(competition?.competitionenddate)
        .utcOffset("+00:00")
        .format("DD-MM-YYYY @ h:mmA");

    useEffect(() => {
        getCompetition(competitionid || "");
    }, [competitionid]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="hero ">
            {/* <div className="relative w-[300px] h-[300px] bg-gray-800 rounded-lg overflow-hidden">
  <div className="absolute bottom-0 left-0 w-1/2 h-1/2 bg-gradient-to-tr from-pink-500 to-purple-500 rounded-full transform translate-x-1/4 translate-y-1/4 blur-md"></div>
  
  <p className="text-white p-4">Hello, World!</p>
</div> */}
            <VSpacer big={29.87} small={23.74} />
            <Common.typography
                fontFamily="Sequel100Wide95"
                fontSize="40px"
                fontSizeSmall="30px"
                color="#ffffff"
                className="uppercase text-center"
            >
                Finished Competition
            </Common.typography>

            <VSpacer big={49} small={38} />
            <div className="w-full flex flex-col items-center ">
                {loading ? (
                    <div className="loader"></div>
                ) : (
                    <div className="relative rounded-lg overflow-hidden flex flex-col items-center liveCompetition w-full">
                        <div className="absolute -bottom-40 xl:-left-96  lg:-left-60 md:-left-40   sm:hidden w-1/2 h-1/2 bg-gradient-to-tr from-[rgba(239,0,143,0.6)] to-[#2B2B2B] rounded-full transform translate-x-1/4 translate-y-1/4 blur-lg"></div>

                        <FinishedCompetitionDetails
                            getCompetition={getCompetition}
                            competition={competition}
                        />
                    </div>
                )}
            </div>

            <AdvertBox />
            <PhoneStores background="bg-gradient-to-r from-[#a51269] to-[#2b2b2b] h-screen " />
        </div>
    );
};

export default WithNavbarAndFooter(FinishedCompetitionDetailPage);
