import { Common } from "../../../Components/common/Common";
import VSpacer from "../../../Components/common/Spacer/VSpacer";
import ViewOrderTable from "../../../Components/User Pages Components/View Order/ViewOrderTable/ViewOrderTable";
import WithNonAbsolutedSidebarNavbarFooter from "../../../Layout/WithNonAbsolutedSidebarNavbarFooter";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { userTransactions } from "../../../Services/userService";
import moment from "moment";
import _ from "lodash";

export default function ViewOrder() {
    useEffect(() => {
        document.getElementById("view_order_main")?.scrollIntoView();
    }, []);
    const params = useParams();
    const [currentOrder, setCurrentOrder] = React.useState<any>([]);
    const [page, setPage] = useState(1);
    const [transactionData, setTransactionData] = useState<any[]>([]);

    const fetchTransactions = async (pageNo: number) => {
        try {
            const response = await userTransactions(pageNo);
            if (response.data.length > 0) {
                const sortedArray = _.orderBy(
                    response.data,
                    (o: any) => {
                        return moment(o.DATE_TIME).format("lll");
                    },
                    ["desc"]
                );
                setTransactionData(sortedArray);
                setPage(parseInt(response.page));
            }
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        fetchTransactions(page);
    }, []);
    useEffect(() => {
        setCurrentOrder(
            transactionData.filter((order) => order?.UID === params["id"])
        );
    }, [transactionData]);

    return (
        <WithNonAbsolutedSidebarNavbarFooter
            Component={
                <div
                    id="view_order_main"
                    className="overflow-hidden box-border bg-black w-full sm_md_lg:mx-auto sm_md_lg:w-[calc(100%-20px)]  sm_md_lg:my-[20px] xl:my-8 h-full mx-[43px] rounded-[30px] py-[63px] px-[78px] sm_md:py-[44px] sm_md:px-[11px] flex flex-col items-center justify-center "
                >
                    <Common.typography
                        className="uppercase"
                        fontFamily="Sequel100Wide95"
                        fontSizeSmall="30px"
                        fontSize="45px"
                        color="#fff"
                    >
                        Orders
                    </Common.typography>
                    <VSpacer small={42} big={54} />
                    <ViewOrderTable
                        orderNumber="ORDER NO. 234980398"
                        // orderDescription="Order placed on December 3rd 2024 and is currently completed"
                        orderDescription={`Order placed on ${
                            currentOrder &&
                            moment(currentOrder[0]?.DATE_TIME).format(
                                "MMMM Do YYYY"
                            )
                        } and is currently completed`}
                        orderDetails={{
                            competitionName: currentOrder[0]?.NAME,
                            subTotal: "50.00",
                            paymentCurrency: "USD",
                            total: "50.00",
                            walletAddress: "0xABC123DEF4567890",
                            transactionHash: "0x9876543210ABCDEF",
                        }}
                    />
                </div>
            }
        />
    );
}
