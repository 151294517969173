import { Common } from "../../../common/Common";

interface TicketProps {
  text: string;
}
export default function Ticket(props: TicketProps) {
  return (
    <div className="w-full //w-[91px] h-[39.5px] rounded-[9.17px] bg-[#393939] text-white flex justify-center items-center">
      <Common.typography
        fontSize="15.94px"
        fontSizeSmall="16px"
        fontFamily="MontBlancSemiBold"
        color="white"
      >
        {props.text}
      </Common.typography>
    </div>
  );
}
