interface PrizeCardContainerProps {
  children: React.ReactNode;
}
function PrizeCardsContainer(props: PrizeCardContainerProps) {
  return (
    <div className="grid grid-cols-2 sm_md:grid-cols-1 gap-4 pr-[36px] sm_md:pr-2 sm_md:mt-[16.58px] max-h-[48rem] scrollbar scrollbar-thumb-secondary scrollbar-track-[#3B3B3B] scrollbar-w-[4px] scrollbar-h-[4px] overflow-auto m-12 mt-[50.63px] sm_md:m-3 ">
      {props.children}
    </div>
  );
}

export default PrizeCardsContainer;
