import React,{ FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../Contexts/AuthContext';

// Define the component as a functional component type
export const ProtectedRoute: FC = () => {
  const { token } = useAuth();

  // Check if the user is authenticated
  if (!token) {
    // If not authenticated, redirect to the login page
    return <Navigate to="/auth/signin" replace />;
  }

  // If authenticated, render the child routes
  return <Outlet />;
};
