import moment from "moment";
import { CompetitionImages, CompetitionProps } from "../Competitions";
import {
    // getCompetitionImages,
    getUserListCompetition,
} from "../../../Services/userService";

export const ticketSold = (
    totalticketsbought?: number,
    competitionticketsize?: number
) => {
    // if (totalticketsbought && competitionticketsize) {
    //   const sold = ((totalticketsbought / competitionticketsize) * 100).toFixed(
    //     0
    //   );
    //   return Number(sold);
    // } else {
    //   return 0;
    // }

    return 20;
};

export const getTimeLeft = (competitionenddate?: Date) => {
    const now = moment();
    const end = moment(competitionenddate);
    const duration = moment.duration(end.diff(now));

    if (duration.asSeconds() > 0) {
        const days = Math.floor(duration.asDays());
        const hours = Math.floor(duration.hours());
        const minutes = Math.floor(duration.minutes());
        const seconds = Math.floor(duration.seconds());

        // Formatting each unit with leading zeros if needed
        const formattedDays = String(days).padStart(2, "0");
        const formattedHours = String(hours).padStart(2, "0");
        const formattedMinutes = String(minutes).padStart(2, "0");
        const formattedSeconds = String(seconds).padStart(2, "0");

        return `${formattedDays}:${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    } else {
        return "00:00:00:00";
    }
};

export const finishedCompetition = (competitionenddate?: Date) => {
    const currentDate = new Date();
    const competitionEndDate = new Date(competitionenddate ?? new Date());
    if (currentDate > competitionEndDate) {
        return true;
    } else {
        return false;
    }
};

export const getCompetitions = async (
    category: string,
    setLoader: any,
    setCompetitionsList: any,
    setSliders: any
) => {
    try {
        setLoader(true);
        const response = await getUserListCompetition({
            category,
        });

        const lc: CompetitionProps[] = [];

        const filteredCompetitions = response?.filter((competition: any) => {
            return [
                "BITCOIN",
                "bitcoin",
                "WEN LAMBO",
                "WATCHES",
                "HIGH ROLLERS",
                "NFTS",
                "ALTCOINS",
            ].includes(competition?.category);
        });

        filteredCompetitions?.forEach((competition: any) => {
            if (!finishedCompetition(competition?.competitionenddate)) {
                lc.push(competition);
            }
        });

        const featured = lc.filter(
            (competition: any) => competition?.featured === 1
        );

        setCompetitionsList(featured);
        setSliders(featured);
        setLoader(false);
    } catch (error) {
        setLoader(false);
    }
};

export const getImages = async (
    setCompetitionImages: any,
    images: CompetitionImages
) => {
    // const images = await getCompetitionImages({ competitionid: UID ?? "" });

    setCompetitionImages(images);
};

export function loadCompetition(navigate?: any, UID?: string) {
    navigate(`/competition/${UID}`);
}
export const navigateToResult = (navigate?: any, UID?: string) => {
    navigate(`/competitionResult/${UID}`);
};
