import React, { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useAuth } from "../../Contexts/AuthContext";
import { loginByEmailPassword } from "../../Services/jwtService";

import { Alert } from "@mui/material";
import { useSnackbar, EnqueueSnackbar } from "notistack";

import _ from "lodash";
import Input from "../../Components/Input";
import Button from "../../Components/Button";
import { resetUserPassword } from "../../Services/userService";
import axios from "axios";

const schema = yup.object().shape({
    password: yup.string().required("password is required"),
    confirmpassword: yup.string().required("Confirm password is required"),
});
const ResetPassword = () => {
    const methods = useForm({
        mode: "onChange",
        defaultValues: {},
        resolver: yupResolver(schema),
    });

    const {
        watch,
        control,
        formState: { isValid, isSubmitting, errors, dirtyFields },
        handleSubmit,
    } = methods;
    watch();
    const { setToken, token } = useAuth();
    const navigate = useNavigate();
    const [error, setError] = React.useState<string>("");
    const [success, setSuccess] = React.useState<string>("");
    //const auth = useContext(AuthContext);

    // if (!auth) {
    //   throw new Error("AuthContext is not available");
    // }

    const onSubmit = async (data: any) => {
        try {
            const result = await resetUserPassword(
                {
                    password: data?.password,
                    confirmPassword: data?.confirmpassword,
                },
                token
            );

            if (result) {
                setSuccess(result?.message);
                // setTimeout(() => {
                //   setSuccess("");
                // }, 3000);
            }

            //navigate("/user/dashboard", { replace: true });
        } catch (error: any) {
            console.log("error", error?.response?.data?.message);
            setSuccess("");
            setError(error?.response?.data?.message);
            setTimeout(() => {
                setError("");
            }, 3000);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <FormProvider {...methods}>
            <div className="flex flex-col items-center gap-[2.5em] sm:gap-[2em]">
                {error && (
                    <Alert
                        className="rounded-full font-['Sequel100WideVF45']"
                        variant="filled"
                        sx={{
                            borderRadius: 20,
                            fontFamily: "Sequel100WideVF45",
                        }}
                        severity="error"
                    >
                        {error}
                    </Alert>
                )}
                {success && (
                    <Alert
                        className="rounded-full font-['Sequel100WideVF45']"
                        variant="filled"
                        sx={{
                            borderRadius: 20,
                            fontFamily: "Sequel100WideVF45",
                        }}
                        severity="success"
                    >
                        {success}
                    </Alert>
                )}
                <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                        <div>
                            <div className="flex items-center text-white text-[20px] font-light gap-4 sm:flex-col md:flex-col sm:items-start">
                                <label
                                    htmlFor=""
                                    className="text-right  w-[190px] sm:w-auto"
                                    style={{
                                        fontSize:
                                            "clamp(1px,3.26308206687vw,20.92px)",
                                    }}
                                >
                                    Password
                                </label>
                                <Input
                                    {...field}
                                    type={"password"}
                                    className={
                                        "w-[529px] h-[41px] sm:w-[302px] md:w-[302px] rounded-full pl-2"
                                    }
                                />
                            </div>
                            {errors.password && (
                                <div className="xl:pl-[15rem] lg:pl-[15rem]">
                                    <p className="text-red-500 font-['Sequel100WideVF45']">
                                        {errors.password.message}
                                    </p>
                                </div>
                            )}
                        </div>
                    )}
                />
                <Controller
                    name="confirmpassword"
                    control={control}
                    render={({ field }) => (
                        <div>
                            <div className="flex items-center text-white text-[20px] font-light gap-4 sm:flex-col md:flex-col sm:items-start">
                                <label
                                    htmlFor=""
                                    className="text-right w-[190px] sm:w-auto"
                                    style={{
                                        fontSize:
                                            "clamp(1px,3.26308206687vw,20.92px)",
                                    }}
                                >
                                    Confirm Password
                                </label>
                                <Input
                                    {...field}
                                    type={"password"}
                                    className={
                                        "w-[529px] h-[41px] sm:w-[302px] md:w-[302px] rounded-full pl-2"
                                    }
                                />
                            </div>
                            {errors.confirmpassword && (
                                <div className="xl:pl-[15rem] lg:pl-[15rem]">
                                    <p className="text-red-500 font-['Sequel100WideVF45']">
                                        {errors.confirmpassword.message}
                                    </p>
                                </div>
                            )}
                        </div>
                    )}
                />

                <Button
                    disabled={_.isEmpty(dirtyFields) || !isValid}
                    loading={isSubmitting}
                    content=" Submit"
                    onClick={handleSubmit(onSubmit)}
                    className="rounded-full text-[#232323] font-[MontBlancBlack] text-[26px] font-extrabold  w-[306px] h-[42px] ml-[-0.6em] uppercase mt-[1em] sm:w-[175px] sm:text-[15px]"
                    customStyle={{
                        fontSize: "clamp(1px,3.45804013087vw,25.96px)",
                    }}
                />
            </div>
        </FormProvider>
    );
};

export default ResetPassword;
