import { useEffect, useState } from "react";
import VSpacer from "../../../Components/common/Spacer/VSpacer";
import WalletField from "../../../Components/User Pages Components/My Wallet/WalletField/WalletField";
import WithNavbarFooterAndSidebar from "../../../Layout/WithNavbarFooterAndSidebar";
import WithNonAbsolutedSidebarNavbarFooter from "../../../Layout/WithNonAbsolutedSidebarNavbarFooter";
import {
    getWallet,
    setWallet as setWalletApi,
} from "../../../Services/userService";

/**
 * This is the main wallet page for the user.
 * Structure:
 * - Main View
 * - Main container
 * - Title = 1
 * - Spacer Title and wallat = 2
 * - Wallet fields = 3
 * - Bitcoin = 3.1
 * - Ethereum = 3.2
 * - Binanace = 3.3
 * - Solana = 3.4
 * - Cardano = 3.5
 * - Avalanche = 3.6
 * - Polygon = 3.7
 * - Fantom = 3.8
 * - Arbitrum = 3.9
 * - Optimism = 3.10
 * @returns {JSX.Element} The rendered MyWallet component.
 */

export default function MyWallet() {
    function convertObjectKeysToCamelCase(obj: any) {
        const newObj: { [key: string]: any } = {};
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                const formattedKey =
                    key.charAt(0).toUpperCase() + key.slice(1).toLowerCase();
                newObj[formattedKey] = obj[key];
            }
        }
        return newObj;
    }

    const [wallet, setWallet] = useState<any>({
        Bitcoin: "",
        Ethereum: "",
        Binance: "",
        Polygon: "",
        Fantom: "",
        Base: "",
        Solana: "",
        Avalanche: "",
        Arbitrum: "",
        Optimism: "",
        Cardano: "",
    });

    const handleGetWallet = async () => {
        try {
            const response = await getWallet();

            const updatedWallet = convertObjectKeysToCamelCase(response);

            setWallet(updatedWallet);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        handleGetWallet();
    }, []);

    const handleSave = async (key: string, value: string) => {
        try {
            setWalletApi({ [key]: value });
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        document.getElementById("my_orders_main")?.scrollIntoView();
    }, []);
    return (
        // Main View
        <WithNonAbsolutedSidebarNavbarFooter
            Component={
                // Main container
                <div className="bg-black w-full sm_md_lg:mx-auto sm_md_lg:w-[calc(100%-20px)]  sm_md_lg:my-[20px] xl:my-8 h-full mx-[43px] rounded-[30px] py-[63px] px-[78px] sm_md:py-[44px] sm_md:px-[31px] flex flex-col items-center justify-center ">
                    {/* 1 - Title */}
                    <p className="font-[Sequel100Wide95] uppercase text-[45px] sm_md:text-[30px] text-white ">
                        My Wallet
                    </p>
                    {/* 2 - Spacer Title and wallat */}
                    <VSpacer small={35} big={55} />
                    {/* 3 - Wallet fields */}
                    <div className="flex flex-col gap-[16px] w-full">
                        {/* 3.1 - bitcoin */}
                        <WalletField
                            onChange={(value, label) => {
                                setWallet({ ...wallet, [label]: value });
                            }}
                            value={wallet.Bitcoin}
                            label="Bitcoin"
                            type="text"
                            onSave={() => {
                                handleSave("Bitcoin", wallet.Bitcoin);
                            }}
                        />
                        {/* 3.2 - Ethereum */}
                        <WalletField
                            onChange={(value, label) => {
                                setWallet({ ...wallet, [label]: value });
                            }}
                            value={wallet.Ethereum}
                            label="Ethereum"
                            type="text"
                            onSave={() => {
                                handleSave("Ethereum", wallet.Ethereum);
                            }}
                        />
                        {/* 3.3 - Binanace */}
                        <WalletField
                            onChange={(value, label) => {
                                setWallet({ ...wallet, [label]: value });
                            }}
                            value={wallet.Binance}
                            label="Binance"
                            type="number"
                            onSave={() => {
                                handleSave("Binanace", wallet.Binance);
                            }}
                        />
                        {/* 3.4 - Solana */}
                        <WalletField
                            onChange={(value, label) => {
                                setWallet({ ...wallet, [label]: value });
                            }}
                            value={wallet.Solana}
                            label="Solana"
                            type="text"
                            onSave={() => {
                                handleSave("Solana", wallet.Solana);
                            }}
                        />
                        {/* 3.5 - Cardano */}
                        <WalletField
                            onChange={(value, label) => {
                                setWallet({ ...wallet, [label]: value });
                            }}
                            value={wallet.Base}
                            label="Base"
                            type="text"
                            onSave={() => {
                                handleSave("Base", wallet.Base);
                            }}
                        />
                        {/* 3.6 - Avalanche */}
                        <WalletField
                            onChange={(value, label) => {
                                setWallet({ ...wallet, [label]: value });
                            }}
                            value={wallet.Avalanche}
                            label="Avalanche"
                            type="text"
                            onSave={() => {
                                handleSave("Avalanche", wallet.Avalanche);
                            }}
                        />
                        {/* 3.7 - Polygon */}
                        <WalletField
                            onChange={(value, label) => {
                                setWallet({ ...wallet, [label]: value });
                            }}
                            value={wallet.Polygon}
                            label="Polygon"
                            type="text"
                            onSave={() => {
                                handleSave("Polygon", wallet.Polygon);
                            }}
                        />
                        {/* 3.8 - Fantom */}
                        <WalletField
                            onChange={(value, label) => {
                                setWallet({ ...wallet, [label]: value });
                            }}
                            value={wallet.Fantom}
                            label="Fantom"
                            type="text"
                            onSave={() => {
                                handleSave("Fantom", wallet.Fantom);
                            }}
                        />
                        {/* 3.9 - Arbitrum */}
                        <WalletField
                            onChange={(value, label) => {
                                setWallet({ ...wallet, [label]: value });
                            }}
                            value={wallet.Arbitrum}
                            label="Arbitrum"
                            type="text"
                            onSave={() => {
                                handleSave("Arbitrum", wallet.Arbitrum);
                            }}
                        />
                        {/* 3.10 - Optimism */}
                        <WalletField
                            onChange={(value, label) => {
                                setWallet({ ...wallet, [label]: value });
                            }}
                            value={wallet.Optimism}
                            label="Optimism"
                            type="text"
                            onSave={() => {
                                handleSave("Optimism", wallet.Optimism);
                            }}
                        />
                    </div>
                </div>
            }
        />
    );
}
