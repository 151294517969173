interface ITypographyProps extends React.HTMLAttributes<HTMLParagraphElement> {
  fontFamily?:
    | "MontBlancRegular"
    | "MontBlancBold"
    | "MontBlancSemiBold"
    | "MontBlancBlack"
    | "Sequel100Wide"
    | "Sequel100Wide95"
    | "Sequel100WideVF45"
    | "Sequel100WideVF55"
    | "Sequel100Wide-65"
    | "Sequel100WideVF"
    | "Sequel100Wide85"
    | "Sequel100Wide46";
  fontSizeSmall?: string;
  fontSize?: string;
  color?: string;
  children: React.ReactNode;
}
export function typography(props: ITypographyProps) {
  const { fontFamily, fontSizeSmall, fontSize, color, children } = props;
  return (
    <>
      <p
        {...props}
        className={"sm_md:hidden " + props.className}
        style={{
          fontFamily: fontFamily || "MontBlancRegular",
          fontSize: fontSize || "1rem",
          color: color || "#000",
        }}
      >
        {children}
      </p>
      <p
        {...props}
        className={"hidden sm_md:block " + props.className}
        style={{
          fontFamily: fontFamily || "MontBlancRegular",
          fontSize: fontSizeSmall || "1rem",
          color: color || "#000",
        }}
      >
        {children}
      </p>
    </>
  );
}
