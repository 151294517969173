import React, { useEffect, useState } from "react";
import Button from "../Button";
import { poundSign } from "../../Pages/LandingPage";
import RangeSlider from "../RangeSlider";
import moment from "moment";
import Countdown from "react-countdown";
import LinearProgress, {
    LinearProgressProps,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material";
import { useAuth } from "../../Contexts/AuthContext";
import Popup from "../Dashboard Components/Popup";
import { joinCompetition } from "../../Services/userService";
import { useNavigate } from "react-router-dom";
import BuyTicketsPopup from "../Dashboard Components/BuyTicketsPopup";
import { CompetitionImages } from "./Competitions";
import { Text } from "../react-responsive-utilities/src";
import Counter from "../common/Counter/Counter";
import VSpacer from "../common/Spacer/VSpacer";

const TicketLinearProgress = styled(LinearProgress)<LinearProgressProps>(
    ({ theme }) => ({
        height: "10px",
        borderRadius: 20,
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        ".MuiLinearProgress-barColorPrimary": {
            backgroundColor: "#EF008F",
            borderRadius: 20,
        },
    })
);

const progress = (200 / 460) * 100;
// const small_chicken = "/Images/small_yellow_chicken.png";

const CompetitionDetails: React.FC<any> = ({ getCompetition, competition }) => {
    const [currentImageIndex] = useState(0);
    const navigate = useNavigate();
    const [value, setValue] = useState(competition?.minnumberoftickets);
    const { user, setToken } = useAuth();
    const [buying, setBuying] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [showPopUp, setShowPopUp] = useState(false);
    const [noOfTicket, setNoOfTicket] = useState(0);
    const [competitionImages, setCompetitionImages] = React.useState<
        CompetitionImages[]
    >([]);

    const [featuredImage, setFeaturedImage] = useState<CompetitionImages>();

    const sold = (200 / 460) * 100;
    const { fetchUser } = useAuth();

    const images = [
        {
            imgSrc: "/Images/coin_btc.png",
        },
        {
            imgSrc: "/Images/coin_btc.png",
        },
        {
            imgSrc: "/Images/coin_btc.png",
        },
        {
            imgSrc: "/Images/coin_btc.png",
        },
        {
            imgSrc: "/Images/coin_btc.png",
        },
        {
            imgSrc: "/Images/coin_btc.png",
        },
        {
            imgSrc: "/Images/coin_btc.png",
        },
        {
            imgSrc: "/Images/coin_btc.png",
        },
    ];
    const getImages = async () => {
        // const images = await getCompetitionImages({
        //   competitionid: competition?.UID ?? "",
        // });
        const images = competition?.imageUrls;
        if (images.length > 0) {
            setFeaturedImage(images[0]);
            setCompetitionImages(images);
        }
    };

    useEffect(() => {
        if (competition?.UID) {
            getImages();
        }
    }, [competition?.UID]);
    const chickenSpot = sold - sold / 22;

    async function buyTicket() {
        if (user) {
            await setNoOfTicket(value);
            setShowPopUp(true);
        } else {
            alert("Please login to buy ticket");
            navigate("/auth/signin");
        }
    }

    const navigateToResult = (competition?: any, UID?: string) => {
        navigate(`/competitionResult/${UID}`);
    };

    const ticketSold = (
        totalticketsbought?: number,
        competitionticketsize?: number
    ) => {
        if (totalticketsbought && competitionticketsize) {
            const sold = (
                (totalticketsbought / competitionticketsize) *
                100
            ).toFixed(0);
            return sold;
        } else {
            return 0;
        }
    };

    const changeNumberOfTickets = (number: number) => {
        if (number < competition?.minnumberoftickets) {
            return;
        } else if (
            number >
            competition?.competitionticketsize - competition?.totalticketsbought
        ) {
            return;
        } else {
            setValue(number);
        }
    };

    const finishedCompetition = (competitionenddate?: Date) => {
        const currentDate = new Date();
        const competitionEndDate = new Date(competitionenddate ?? new Date());
        if (currentDate > competitionEndDate) {
            return true;
        } else {
            return false;
        }
    };

    function popUpFunctions(action: string) {
        if (action === "reject") {
            setShowPopUp(false);
            setSuccess("");
            setError("");
            setBuying(false);
        }

        if (action === "accept") {
            buyTicketFunction();
        }
    }

    const buyTicketFunction = async () => {
        try {
            setBuying(true);
            const result = await joinCompetition({
                competitionid: competition?.UID,
                numberoftickets: noOfTicket,
            });
            setBuying(false);
            setSuccess(result?.message);
            setTimeout(() => {
                setShowPopUp(false);
                setSuccess("");
            }, 3000);
            //setShowPopUp(false);
        } catch (error: any) {
            setBuying(false);
            setError(error?.response?.data?.error);
            //setShowPopUp(false);
        }
    };

    const Title = ({ title }: { title: string }) => (
        <Text
            textSize={[30, 30.0, 50.0]}
            className="text-[#FFFFFF] font-['Sequel100Wide95'] //leading-[35px] leading-normal font-[60] block sm:text-center md:text-center"
        >
            {title}
        </Text>
    );

    const Description = ({ description }: { description: string }) => (
        <Text
            textSize={[14, 14.0, 19.96]}
            className="
      text-[#FFFFFF] font-['Sequel100WideVF45'] leading-[17px] font-[10] block sm:text-center md:text-center"
        >
            {description}
        </Text>
    );

    return (
        <div className="w-full">
            <div className="w-auto flex flex-col gap-[2em] sm:gap-0 md:gap-0 lg:gap-0 sm:w-auto md:w-auto lg:w-auto">
                <div
                    className="flex w-auto  sm:flex-col md:flex-col 
                    lg:flex-col justify-center mx-2 xl:mx-12 xl:w-auto"
                >
                    <div className="w-full ticketBox flex flex-col gap-5 sm:w-full md:w-full lg:w-full sm:h-auto md:h-auto lg:h-auto bg-secondary_bg pl-3 pt-3 pb-3 rounded-l-3xl sm:rounded-tr-3xl md:rounded-tr-3xl lg:rounded-tr-3xl sm:rounded-bl-none md:rounded-bl-none lg:rounded-bl-none sm:mt-[1em] md:mt-[1em] lg:mt-[1em] sm:pr-3 md:pr-3 lg:pr-3">
                        <img
                            src={
                                featuredImage?.imageurl ??
                                "/Images/No Image.svg"
                            }
                            alt=""
                            className="w-full h-[565px] sm:w-full md:w-full lg:w-full sm:h-auto md:h-auto lg:h-auto  rounded-3xl object-cover"
                            onError={(e) => {
                                e.currentTarget.src = "/Images/No Image.svg";
                            }}
                        />
                        <div className="grid grid-cols-4 gap-4 w-full">
                            {competitionImages.map((image, index) => (
                                <div
                                    key={index}
                                    className={`${
                                        // index !== currentImageIndex ? "smallImgs " : ""
                                        featuredImage === image
                                            ? ""
                                            : "smallImgs"
                                    } w-full h-[131px] sm:hidden md:hidden lg:hidden `}
                                    onClick={() => setFeaturedImage(image)}
                                >
                                    <img
                                        src={image.imageurl}
                                        alt=""
                                        className={`w-full h-[131px] object-cover rounded-3xl `}
                                        onError={(e) => {
                                            e.currentTarget.src =
                                                "/Images/No Image.svg";
                                        }}
                                    />
                                </div>
                            ))}
                            {competitionImages
                                .slice(0, 4)
                                .map((image, index) => (
                                    <div
                                        key={index}
                                        className={`${
                                            // index !== currentImageIndex ? "smallImgs" : ""
                                            featuredImage === image
                                                ? ""
                                                : "smallImgs"
                                        } w-full h-[131px] sm:h-auto md:h-auto lg:h-auto sm:w-full md:w-full lg:w-full xl:hidden `}
                                        onClick={() => setFeaturedImage(image)}
                                    >
                                        <img
                                            src={image.imageurl}
                                            alt=""
                                            className={`w-full h-[131px] sm:h-auto md:h-auto lg:h-auto sm:w-full  md:w-full lg:w-full object-cover rounded-md  `}
                                        />
                                    </div>
                                ))}
                        </div>
                    </div>

                    <div className="relative w-full flex flex-col gap-3 pr-7 ticketBox pl-[1.5em] sm:items-center md:items-center lg:items-center sm:pr-0 md:pr-0 lg:pr-0 sm:pl-0 md:pl-0 lg:pl-0 bg-secondary_bg rounded-r-3xl md:rounded-br-none sm:rounded-tr-none md:rounded-tr-none sm:!rounded-b-none lg:rounded-b-none">
                        <div className="flex flex-col gap-5 m-[40px] sm:m-[30px] md:m-[30px] lg:m-[30px] sm:gap-2 md:gap-2 lg:gap-2 mt-[60px]">
                            <Title title={competition?.competitionname} />
                            {/* <VSpacer small={11.96} big={24} /> */}
                            <hr className="max-w-[320.18px] w-full border-b-[5px] border-t-0 border-[#EF008F] hidden sm:block md:block h-[3.14px] rounded-full" />
                            {/* <VSpacer small={12.9} big={0} /> */}
                            <Description
                                description={
                                    competition?.competitioninformation
                                }
                            />
                        </div>
                        <img
                            src="/Images/THEPRIZE_TokenLogo_EH-03.svg"
                            alt=""
                            className="absolute right-0 select-none top-0 object-cover object-center hidden xl:block"
                        />

                        <div className="flex w-auto relative lg:flex-col md:flex-col sm:flex-col xl:timeRemaining2">
                            <div>
                                <div className="m-[40px] mt-[-20px] sm:items-center md:items-center lg:items-center border-t-4 border-t-secondary sm:border-t-0 md:border-t-0 lg:border-t-0 pt-[15px] mb-0 flex flex-col gap-3 sm:pt-0 md:pt-0 lg:pt-0 md:w-full md:ml-0">
                                    <Counter
                                        title={"Time Remaining"}
                                        competitionEndDate={
                                            competition?.competitionenddate
                                        }
                                        key={competition?.UID}
                                    />

                                    <p
                                        className="text-secondary font-['Sequel100Wide95'] text-[14px] font-semibold sm:hidden md:hidden lg:hidden"
                                        style={{
                                            fontSize:
                                                "clamp(1px, 2.815621311vw, 14.18px)",
                                        }}
                                    >
                                        BUY TICKETS AND SPIN TO WIN!
                                    </p>
                                </div>
                                <div className="h-[75px] bg-bg_small flex items-center p-6 gap-4 m-[20px] mt-[0.5em] sm:mt-0 w-[620px] relative sm:w-[300px] sm:h-auto md:h-auto lg:h-auto rounded-full sm:hidden md:hidden lg:hidden">
                                    <div className="w-[80%] //mt-5 md:w-full">
                                        <TicketLinearProgress
                                            variant="determinate"
                                            sx={{
                                                "&.MuiLinearProgress-root": {
                                                    height: "13px",
                                                    borderRadius: 20,
                                                },
                                            }}
                                            value={Number(
                                                ticketSold(
                                                    competition?.totalticketsbought,
                                                    competition?.competitionticketsize
                                                )
                                            )}
                                        />

                                        <span
                                            className="text-white font-['MontBlancRegular'] uppercase text-[12px] md:text-sm"
                                            style={{
                                                fontSize:
                                                    "clamp(11px, 2.441720196vw, 11.19px)",
                                            }}
                                        >
                                            {ticketSold(
                                                competition?.totalticketsbought,
                                                competition?.competitionticketsize
                                            )}
                                            % tickets sold
                                        </span>
                                    </div>
                                    <div>
                                        <h3
                                            className="font-extrabold text-[26px] font-['MontBlancBlack'] text-primary m-0 p-0 mb-[-0.4em] sm:text-[19px]"
                                            style={{
                                                fontSize:
                                                    "clamp(1px, 4.815615702vw, 23.77px)",
                                            }}
                                        >
                                            {poundSign}
                                            0.99
                                        </h3>
                                        <span
                                            className="text-base text-[13px] text-white "
                                            style={{
                                                fontSize:
                                                    "clamp(1px, 2.418197682vw, 11.19px)",
                                            }}
                                        >
                                            PER TICKET
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="xl:ml-5 bg-bg_small rounded-3xl mt-[0em] mb-[20px] sm:mb-[10px] w-max m-2 md:mt-[3em] sm:w-[85%] md:w-[85%] lg:w-[85%] sm:m-auto md:m-auto">
                            <div className="px-4 flex gap-4 items-center xl:hidden">
                                <div className="mt-5 flex-1 md:w-full">
                                    <TicketLinearProgress
                                        variant="determinate"
                                        value={Number(
                                            ticketSold(
                                                competition?.totalticketsbought,
                                                competition?.competitionticketsize
                                            )
                                        )}
                                    />
                                    <Text
                                        textSize={[12, 13.19, 14.19]}
                                        className="text-[#fff] block"
                                    >
                                        {ticketSold(
                                            competition?.totalticketsbought,
                                            competition?.competitionticketsize
                                        )}
                                        % tickets sold
                                    </Text>
                                </div>
                                <div>
                                    <Text
                                        textSize={[16, 23.77, 24]}
                                        className="text-primary font-[MontBlancBlack] block leading-none"
                                    >
                                        {poundSign}
                                        0.99
                                    </Text>
                                    <Text
                                        textSize={[10, 11.19, 12]}
                                        className="text-white block font-[MontBlancRegular] uppercase"
                                    >
                                        per ticket
                                    </Text>
                                </div>
                            </div>
                            {!finishedCompetition(
                                competition?.competitionenddate
                            ) && (
                                <div className="p-5 bg-bg_small mb-5 rounded-2xl sm:rounded-none md:rounded-none lg:rounded-none w-full flex flex-col justify-center items-center">
                                    <h2
                                        className="text-white font-['Sequel100Wide95'] text-[30px] sm:text-[16px] sm:text-center md:text-center lg:text-center text-left w-full"
                                        style={{
                                            fontSize:
                                                "clamp(1px,3.609558064vw,30px)",
                                        }}
                                    >
                                        HOW MANY TICKETS?
                                    </h2>
                                    <div
                                        className="mt-[2em] sm:mt-[2em] md:mt-[2em] lg:mt-[2em] relative w-full"
                                        id="sales"
                                    >
                                        <RangeSlider
                                            min={
                                                competition?.minnumberoftickets
                                            }
                                            max={
                                                competition?.competitionticketsize -
                                                competition?.totalticketsbought
                                            }
                                            value={value}
                                            onChange={changeNumberOfTickets}
                                            buffered={1}
                                            className="w-[570px] sm:w-full md:w-full lg:w-full"
                                        />

                                        <p
                                            className="text-white font-bold text-[15px] w-[570px] sm:w-full md:w-full lg:w-full flex items-center justify-between mt-[1em]"
                                            style={{
                                                fontSize:
                                                    "clamp(10px,1.611926605vw,15px)",
                                            }}
                                        >
                                            <span>{value}</span>
                                            <span>
                                                {competition?.competitionticketsize -
                                                    competition?.totalticketsbought}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="w-[570px] sm:w-full md:w-full lg:w-full flex font-['Sequel100Wide95'] items-center justify-between mt-[1.5em]">
                                        <img
                                            src="/Images/minus.png"
                                            alt=""
                                            className="sm:w-[20px] cursor-pointer rounded-full"
                                            onClick={() =>
                                                changeNumberOfTickets(value - 1)
                                            }
                                        />
                                        <h4
                                            className="text-white text-[20px] sm:text-[11px] sm:text-center font-bold"
                                            style={{
                                                fontSize:
                                                    "clamp(11px,2.33vw,20px)",
                                            }}
                                        >
                                            NUMBER OF TICKETS: {value}
                                        </h4>
                                        <img
                                            src="/Images/plus.png"
                                            alt=""
                                            className="sm:w-[20px] cursor-pointer rounded-full"
                                            onClick={() =>
                                                changeNumberOfTickets(value + 1)
                                            }
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="flex flex-row justify-end xl:mb-6 xl:w-[650px]">
                            <Button
                                disabled={finishedCompetition(
                                    competition?.competitionenddate
                                )}
                                className={`${
                                    finishedCompetition(
                                        competition?.competitionenddate
                                    )
                                        ? "bg-bg_small text-[#2D2022]"
                                        : "bg-primary border-white border-2 text-black"
                                }  h-[75px]  text-[29px] font-['Sequel100Wide'] w-full rounded-full   sm:hidden md:hidden lg:hidden`}
                                content={
                                    finishedCompetition(
                                        competition?.competitionenddate
                                    )
                                        ? "FINISHED"
                                        : "BUY TICKETS"
                                }
                                onClick={
                                    finishedCompetition(
                                        competition?.competitionenddate
                                    )
                                        ? () => {
                                              navigateToResult(
                                                  competition,
                                                  competition?.UID
                                              );
                                          }
                                        : buyTicket
                                }
                            />
                        </div>
                        <Button
                            disabled={finishedCompetition(
                                competition?.competitionenddate
                            )}
                            className={`${
                                finishedCompetition(
                                    competition?.competitionenddate
                                )
                                    ? "bg-bg_small"
                                    : "bg-primary border-white border-2"
                            } h-[75px] text-[#2D2022] text-[29px] font-['Sequel100Wide'] w-[70%] rounded-full xl:hidden`}
                            content={
                                finishedCompetition(
                                    competition?.competitionenddate
                                )
                                    ? "FINISHED"
                                    : "BUY TICKETS"
                            }
                            customStyle={{
                                fontSize: "clamp(1px,5.361012052vw,30px)",
                            }}
                            onClick={
                                finishedCompetition(
                                    competition?.competitionenddate
                                )
                                    ? () => {
                                          navigateToResult(
                                              competition,
                                              competition?.UID
                                          );
                                      }
                                    : buyTicket
                            }
                        />
                        <p
                            className="text-secondary font-['Sequel100Wide95'] text-[14px] font-semibold xl:hidden"
                            style={{
                                fontSize: "clamp(1px, 2.815621311vw, 14.18px)",
                            }}
                        >
                            BUY TICKETS AND SPIN TO WIN!
                        </p>
                    </div>

                    {user && (
                        <BuyTicketsPopup
                            buying={buying}
                            success={success}
                            error={error}
                            competitionName={competition?.competitionname}
                            noOfTickets={noOfTicket}
                            open={showPopUp}
                            handleClose={() => {
                                getCompetition(competition?.UID);
                                setShowPopUp(false);
                                setSuccess("");
                                setError("");
                                setBuying(false);
                                fetchUser();
                            }}
                            rejectClick={() => popUpFunctions("reject")}
                            acceptClick={() => popUpFunctions("accept")}
                        />
                    )}
                </div>
                <div className="xl:mx-12 mx-2 ticketBox p-20 sm:p-10 md:p-10 lg:p-10 sm:mt-0 md:mt-0 lg:mt-0 bg-secondary_bg xl:rounded-3xl sm:rounded-b-3xl md:rounded-b-3xl  lg:rounded-b-3xl w-auto">
                    <div className="flex flex-col w-full sm:gap-4 md:gap-4 lg:gap-4 font-[MontBlancBold]">
                        <div className="flex w-full justify-between pb-[3em] border-b-2 border-secondary sm:border-none md:border-none lg:border-none sm:pb-0 md:pb-0 lg:pb-0 sm:flex-col sm:gap-6 md:gap-6 lg:gap-6">
                            <div className="flex items-center gap-4 text-white text-[25px] sm:text-[17px] md:text-[20px] font-bold">
                                <img
                                    src="/Images/trophy.png"
                                    alt=""
                                    className="sm:w-[35px]"
                                />
                                <p>ENTRIES ONLY $0.99</p>
                            </div>
                            {/* <div className="flex items-center gap-4 text-white text-[25px] sm:text-[17px] md:text-[20px] font-bold">
                <img src="Images/trend.png" alt="" className="sm:w-[35px]" />
                <p>MAX ENTRIES 199,999</p>
              </div> */}
                            <div className="flex items-center gap-4 text-white text-[25px] sm:text-[17px] md:text-[20px] font-bold">
                                <img
                                    src="/Images/crown.png"
                                    alt=""
                                    className="h-[30px] w-auto sm:w-[35px]"
                                />
                                <p>
                                    TOTAL VALUE $
                                    {(competition?.competitionticketsize ?? 0) *
                                        0.99}
                                </p>
                            </div>
                        </div>
                        <div className="flex gap-[5em] mx-auto pt-[3em] sm:flex-col sm:pt-0 sm:gap-6 sm:mx-0 ">
                            <div className="flex items-center gap-4 text-white text-[25px] sm:text-[17px] md:text-[20px] font-bold">
                                <img
                                    src="/Images/people.png"
                                    alt=""
                                    className="sm:w-[35px]"
                                />
                                <p>
                                    MAX {competition?.competitionticketsize} PER
                                    PERSON
                                </p>
                            </div>
                            {/* <div className="flex items-center gap-4 text-white text-[25px] sm:text-[17px] md:text-[20px] font-bold">
                <img src="Images/gift.png" alt="" className="sm:w-[35px]" />
                <p>TOTAL PRIZES 2,001</p>
              </div> */}
                        </div>
                    </div>
                </div>
            </div>

            {/* competition details */}
        </div>
    );
};

export default CompetitionDetails;
