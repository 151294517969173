import button from "./button/button";
import image from "./image/image";
import textfield from "./textfield/textfield";
import { typography } from "./typography/typography";

export const Common = {
  typography,
  button,
  image,
  textfield,
};
