import React from "react";
import Button from "../Button";
import Modal from "@mui/material/Modal";
import Input from "../Input";
import { resetPassword } from "../../Services/userService";

const ForgetPasswordPopup = ({
    rejectClick,
    open,
    handleClose,
}: {
    rejectClick: (e: any) => void;
    open: boolean;
    handleClose: () => void;
}) => {
    const [email, setEmail] = React.useState<string>("");
    const [error, setError] = React.useState<string | null>();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [success, setSuccess] = React.useState<boolean>(false);

    const handleAccept = async () => {
        // check email for null or empty string
        setLoading(true);
        try {
            const result = await resetPassword({ email });
            setSuccess(true);
        } catch (error) {
            await setError("Error occured");
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="border-2 border-primary absolute top-[50%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 z-[100000001] p-4 bg-black bg-opacity-100">
                    <div className="flex flex-col sm:items-center gap-5 sm:gap-0">
                        <p className="uppercase text-white text-[21px] font-extrabold  text-center sm:text-[16px] sm:w-[220px]">
                            Enter your email address to reset your password
                        </p>
                        <Input
                            type="email"
                            value={email}
                            placeholder="Email Address"
                            onChange={function (e: any): void {
                                setEmail(e.target.value);
                            }}
                            className="text-[21px] text-white font-extrabold text-center mt-7 mb-5 sm:text-[16px] //sm:w-[220px] w-full"
                        />
                    </div>
                    {error && (
                        <p className="uppercase text-red-600 text-center">
                            {error}
                        </p>
                    )}
                    {success && (
                        <p className="uppercase text-green-600 text-center">
                            Email sent successfully
                        </p>
                    )}
                    <div className="sm:flex">
                        <Button
                            className={
                                "text-white flex-1 w-1/2 h-[88px] bg-black text-[30px] font-extrabold text-center sm:text-[13px] sm:w-[133px] sm:h-[37px]"
                            }
                            content={"REJECT"}
                            onClick={(e) => {
                                e.preventDefault();
                                setEmail("");
                                setError(null);
                                setLoading(false);
                                rejectClick(e);
                            }}
                        />
                        <Button
                            loading={loading}
                            className={
                                "text-black flex-1 bg-primary w-1/2 h-[88px] text-[30px] font-extrabold text-center sm:text-[13px] sm:w-[133px] sm:h-[37px]"
                            }
                            content={"CONFIRM"}
                            onClick={(e) => {
                                e.preventDefault();
                                handleAccept();
                            }}
                        />
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default ForgetPasswordPopup;
