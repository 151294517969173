import React from "react";
import { useEffect, useState } from "react";
import { Link, NavLink, useLocation, useParams } from "react-router-dom";
import { useAuth } from "../Contexts/AuthContext";
import PageLink, { PageLinkProps } from "./Link";
import { getUserBalance } from "../Services/jwtService";
import numeral from "numeral";

const Navbar = ({
    opener,
    setOpener,
}: {
    opener?: boolean;
    setOpener?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const location = useLocation();
    const params = useParams();
    const { portal } = params;
    const [currentRoute, setCurrentRoute] = useState("");
    const [userSideHamburger, setUserSideHamburger] = useState(false);
    const [hamburger, setHamburger] = useState(false);
    useEffect(() => {
        if (opener) {
            setHamburger(opener);
        }
    }, [opener]);
    const { user, setToken } = useAuth();

    const tickets = (user as { numberOfTickets: number })?.numberOfTickets;

    const username = (user as { userName?: string })?.userName;
    const [balance, setBalance] = useState(tickets * 0.99);

    const user_name = username;
    const account_code = "account code xxxxx";

    useEffect(() => {
        if (user) {
            setBalance(tickets * 0.99);
        }
    }, [user]);

    useEffect(() => {
        async function getRoute() {
            const route = location.pathname;
            await setCurrentRoute(route);
        }

        getRoute();
    }, [portal]);

    const navLinks: PageLinkProps[] = [
        {
            src: "/",
            content: "Home",
        },
        // {
        //     src: "/competitions/all",
        //     content: "Competitions",
        // },
        {
            src: "/howtoplay",
            content: "How to Play",
        },
        // {
        //     src: "/rng",
        //     content: "RNG",
        // },
        // {
        //     src: "/winners",
        //     content: "Winners",
        // },
    ];

    return (
        <>
            <div
                id="navbar"
                className="//p-10 mr-[40px] ml-[20px] pt-0 md:hidden sm:hidden lg:hidden sticky top-2 z-[100000000001] flex mt-[2em]"
            >
                <div className="flex items-center ml-[1.5em] gap-4">
                    <Link to={"/"}>
                        <img
                            src="/Images/header_image.png"
                            alt=""
                            className="h-[120px] min-w-[262px]"
                        />
                    </Link>
                </div>
                <div className="h-[120px] w-full flex items-center justify-between bg-[#1A1A1A] rounded-r-[5px] ">
                    {/* <div className="flex items-center ml-[1.5em] gap-4">
                        <Link to={"/"}>
                            <img src="/Images/logo.png" alt="" className="" />
                        </Link>
                    </div> */}
                    <div className="flex flex-1 justify-evenly font-['Sequel100WideVF45'] font-extralight">
                        {navLinks &&
                            navLinks.map((link, index) => (
                                <PageLink
                                    src={link.src}
                                    content={link.content}
                                    className={`${
                                        link.src === currentRoute
                                            ? "text-primary font-['Sequel100Wide']"
                                            : "font-normal text-white"
                                    } uppercase text-xl `}
                                    key={index}
                                />
                            ))}
                    </div>
                    {user ? (
                        <div className="bg-primary mx-2 px-[1rem] rounded-full font-semibold">
                            <div className="flex gap-4 items-center justify-between">
                                <div className="flex items-center gap-4">
                                    {/* <Link to={"/wallet"}>
                    <img
                      className="w-10"
                      src="/Images/green_dashboard_logo_777.png"
                      alt=""
                    />
                  </Link> */}
                                    <img
                                        onClick={() =>
                                            setUserSideHamburger(
                                                !userSideHamburger
                                            )
                                        }
                                        src="/Images/burger.svg"
                                        alt=""
                                        className="w-8 cursor-pointer scale-[0.6]"
                                    />
                                    <div className="flex flex-col">
                                        <h3 className="text-[22px] font-bold text-black w-full truncate-8-chars">
                                            {user_name}
                                        </h3>
                                        {/* <p className="text-[16px] text-black w-full mt-[-0.5em]">
                    account code xxxxx
                  </p> */}
                                    </div>
                                </div>
                                <div
                                    onClick={() => {
                                        setToken(null);
                                    }}
                                >
                                    <img
                                        className="w-8"
                                        src="/Images/logout.svg"
                                        alt=""
                                    />
                                </div>
                            </div>
                            <div className="w-full bg-transparent border-t-4 border-t-black flex items-center justify-center p-2">
                                <div className="flex items-center gap-2 border-r-4 border-r-black pr-3">
                                    <img
                                        className="w-[0.8rem]"
                                        src="/Images/dollarSign.png"
                                        alt=""
                                    />
                                    <p>
                                        <p className="text-[16px] font-[MontBlancBold] text-black font-bold mb-[-0.5em]">
                                            {numeral(balance).format("0,0.00a")}
                                        </p>
                                        <span className="text-[12px] font-light">
                                            BALANCE
                                        </span>
                                    </p>
                                </div>
                                <div className="flex items-center">
                                    <img
                                        className="w-[2rem]"
                                        src="/Images/note.png"
                                        alt=""
                                    />
                                    <p>
                                        <p className="text-[16px] font-[MontBlancBold] text-black font-bold mb-[-0.5em]">
                                            {numeral(tickets).format("0,0.00a")}
                                        </p>
                                        <span className="text-[12px] font-light">
                                            TICKETS
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    ) : (
                        // <NavLink to={"/auth/signup"}>
                        <button className="flex flex-nowrap items-center justify-center flex-row  bg-customGray m-[1em] text-lg font-semibold font-[MontBlancBold] w-[180px] h-[89.85px] rounded-[5px] ">
                            {/* <div
                                // onClick={connectWalletFunction}
                                className="flex flex-wrap items-center justify-between flex-row  px-[30px] bg-primary text-lg font-semibold w-full h-[72px] font-[MontBlancBold] text-left pl-12"
                            > */}
                            <p
                                className="text-black font-['Sequel100Wide95'] 
                                     uppercase text-m leading-[17px]"
                            >
                                WALLET CONNECT COMING SOON
                            </p>
                            {/* <img
                                src="/Images/download-icon.svg"
                                alt="icon"
                                className="sm:w-[20px] cursor-pointer rounded-full"
                            ></img> */}
                            {/* </div> */}
                        </button>
                        // </NavLink>
                    )}
                </div>
                <div
                    style={{ display: userSideHamburger ? "block" : "none" }}
                    className=" mt-0 mb-0 relative   z-[999999]"
                >
                    <div className="flex flex-col gap-4 w-full p-12 absolute bg-[#2b2b2b]">
                        <img
                            src="/Images/coin_colored_half.svg"
                            alt=""
                            className="absolute bottom-0 right-0 opacity-50 select-none pointer-events-none "
                        />
                        <div className="flex items-center justify-between">
                            <Link
                                to={"/wallet"}
                                className={`${
                                    currentRoute === "/wallet"
                                        ? "text-primary font-extrabold"
                                        : "font-normal text-white"
                                } uppercase text-[18px] `}
                            >
                                {" "}
                                My Wallet
                            </Link>
                        </div>

                        <div className="flex items-center justify-between">
                            <Link
                                to={"/entries/live"}
                                className={`${
                                    currentRoute === "/entries/live"
                                        ? "text-primary font-extrabold"
                                        : "font-normal text-white"
                                } uppercase text-[18px] `}
                            >
                                {" "}
                                Entries
                            </Link>
                        </div>

                        <div className="flex items-center justify-between">
                            <Link
                                to={"/orders"}
                                className={`${
                                    "/orders" === currentRoute
                                        ? "text-primary font-extrabold"
                                        : "font-normal text-white"
                                } uppercase text-[18px] `}
                            >
                                {" "}
                                My Orders
                            </Link>
                        </div>
                        <div className="flex items-center">
                            <Link
                                to={"/account"}
                                className={`${
                                    currentRoute === "/account"
                                        ? "text-primary font-extrabold"
                                        : "font-normal text-white"
                                } uppercase text-[18px] `}
                            >
                                {" "}
                                My Account
                            </Link>
                        </div>
                        <div className="flex items-center justify-between">
                            <Link
                                to={"/buytickets"}
                                className={`${
                                    currentRoute === "/buytickets"
                                        ? "text-primary font-extrabold"
                                        : "font-normal text-white"
                                } uppercase text-[18px] `}
                            >
                                {" "}
                                Buy Tickets
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            {/* for mobile screen */}

            <div className="xl:hidden flex">
                <div className=" items-center ml-[3%] gap-4 ">
                    <Link to={"/"}>
                        <img
                            src="/Images/header_image.png"
                            alt=""
                            className="h-[112px] min-w-[180px] w-[100%]  flex items-center gap-4"
                        />
                    </Link>
                </div>
                <div
                    id="navbar-mobile"
                    className="w-[80%] h-[112px] pt-3  relative z-[100000000001] bg-[#1A1A1A] mr-[3%] rounded-r-[5px] "
                >
                    <div className="flex items-left justify-end px-5">
                        <div
                            className={hamburger ? "open" : "hamburger"}
                            onClick={() => setHamburger(!hamburger)}
                        >
                            <span className="top"></span>
                            <span className="middle"></span>
                            <span className="bottom"></span>
                        </div>
                    </div>
                </div>
            </div>

            {hamburger && (
                <div className=" block sm:w-[100%] relative z-[100000001] mobileNav mt-5 //opacity-[0.95]">
                    <div className="flex flex-col gap-4 w-full p-12 bg-[#2b2b2b] relative">
                        {/* <img
                                    src="/Images/coin_colored_half.svg"
                                    alt=""
                                    className="absolute bottom-0 right-0 opacity-50 select-none pointer-events-none"
                                /> */}
                        {navLinks &&
                            navLinks.map((link, index) => (
                                <div
                                    key={index}
                                    className="flex items-center justify-between"
                                >
                                    <Link
                                        to={link.src}
                                        className={`${
                                            link.src === currentRoute
                                                ? "text-primary font-extrabold"
                                                : "font-normal text-white"
                                        } uppercase text-[18px] `}
                                    >
                                        {" "}
                                        {link.content}
                                    </Link>
                                    {link.src === currentRoute && (
                                        <img src="/Images/pointer.png" alt="" />
                                    )}
                                </div>
                            ))}
                        {user && (
                            <>
                                <hr />
                                <div className="flex items-center justify-between">
                                    <Link
                                        to={"/wallet"}
                                        className={`${
                                            currentRoute === "/wallet"
                                                ? "text-primary font-extrabold"
                                                : "font-normal text-white"
                                        } uppercase text-[18px] `}
                                    >
                                        {" "}
                                        My Wallet
                                    </Link>
                                </div>
                                <div className="flex items-center justify-between">
                                    <Link
                                        to={"/entries/live"}
                                        className={`${
                                            currentRoute === "/entries/live"
                                                ? "text-primary font-extrabold"
                                                : "font-normal text-white"
                                        } uppercase text-[18px] `}
                                    >
                                        {" "}
                                        My Entries
                                    </Link>
                                </div>
                                <div className="flex items-center justify-between">
                                    <Link
                                        to={"/orders"}
                                        className={`${
                                            "/orders" === currentRoute
                                                ? "text-primary font-extrabold"
                                                : "font-normal text-white"
                                        } uppercase text-[18px] `}
                                    >
                                        {" "}
                                        My Orders
                                    </Link>
                                </div>
                                <div className="flex items-center justify-between">
                                    <Link
                                        to={"/account"}
                                        className={`${
                                            currentRoute === "/account"
                                                ? "text-primary font-extrabold"
                                                : "font-normal text-white"
                                        } uppercase text-[18px] `}
                                    >
                                        {" "}
                                        My Account
                                    </Link>
                                </div>
                                <div className="flex items-center justify-between">
                                    <Link
                                        to={"/buytickets"}
                                        className={`${
                                            currentRoute === "/buytickets"
                                                ? "text-primary font-extrabold"
                                                : "font-normal text-white"
                                        } uppercase text-[18px] `}
                                    >
                                        {" "}
                                        Buy Tickets
                                    </Link>
                                </div>
                            </>
                        )}
                    </div>
                    {user ? (
                        <div className="bg-primary w-full //mt-[3em]">
                            <div className="flex gap-4 items-center">
                                <Link to={"/wallet"}>
                                    <img
                                        className="w-10"
                                        src="/Images/green_dashboard_logo_777.png"
                                        alt=""
                                    />
                                </Link>
                                <div>
                                    <h3 className="text-[28px] font-bold text-black w-full truncate-almost-screen-width">
                                        {user_name}
                                    </h3>
                                    {/* <p className="text-[23px] text-black w-full mt-[-0.5em]">
                      account code xxxxx
                    </p> */}
                                </div>
                            </div>

                            <div className="w-full bg-primary border-t-4 border-t-black flex items-center justify-center p-2 px-[8%] mt-2">
                                <div className="flex items-center gap-4 border-r-4 border-r-black pr-3">
                                    <img src="/Images/dollarSign.png" alt="" />
                                    <p>
                                        <p className="text-[19px] text-black font-bold mb-[-0.5em]">
                                            {numeral(balance).format("0,0.00a")}
                                        </p>
                                        <span className="text-[12px] font-light">
                                            BALANCE
                                        </span>
                                    </p>
                                </div>
                                <div className="flex items-center">
                                    <img src="/Images/note.png" alt="" />
                                    <p>
                                        <p className="text-[19px] text-black font-bold mb-[-0.5em]">
                                            {numeral(tickets).format("0,0.00a")}
                                        </p>
                                        <span className="text-[12px] font-light">
                                            TICKETS
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    ) : (
                        // <NavLink to={"/auth/signup"}>
                        <button className="flex flex-wrap items-center bg-customGray text-lg font-semibold w-full h-[72px] font-[MontBlancBold] text-left pl-12">
                            <p
                                className="text-black font-['Sequel100Wide'] font-normal text-[17px]
                                         uppercase leading-[17px]"
                            >
                                WALLET CONNECT COMING SOON
                            </p>
                            {/* <div className="w-[1%]"></div> */}
                            {/* <img
                                src="/Images/download-icon.svg"
                                alt="icon"
                                className="sm:w-[20px] cursor-pointer rounded-full"
                            ></img>{" "} */}
                        </button>
                        // </NavLink>
                    )}
                </div>
            )}
        </>
    );
};

export default Navbar;
