import HSpacer from "../../common/Spacer/HSpacer";
import VSpacer from "../../common/Spacer/VSpacer";
import { CompetitionImages } from "../Competitions";
import Action from "./subComponents/Action";
import Button from "./subComponents/Button";
import CompName from "./subComponents/CompName";
import Counter from "./subComponents/Counter";
import HighRoller from "./subComponents/HighRoller";
import Progress from "./subComponents/Progress";

interface CompetitionCardProps {
  live?: boolean;
  time?: { days: number; hours: number; minutes: number; seconds: number };
  prizeName?: string;
  price?: number;
  ticketsLeft?: number;
  prizeIcon?: string;
  onClick?: (ticket: string) => void;
  timerBg?: string;
  borderColor?: string;
  finished?: boolean;
  width?: string;
  overlay?: boolean;
  UID?: string;
  categroy?: string;
  competitionenddate?: Date;
  competitionEndDateString?: string;
  competitioninformation?: string;
  competitionname?: string | undefined;
  competitionticketsize?: number | undefined;
  featured?: number;
  imageurl?: string;
  legalinformation?: string;
  manual?: string;
  totalticketsbought?: number | undefined;
  numberofticketsbought?: number | undefined;
  minnumberoftickets?: number | undefined;
  isHighRoller?: boolean;
  instant?: boolean;
  rollerAmount?: string;

  isCompetitionFinished?: boolean;
  imageUrls?: CompetitionImages[];
  entryValue?: string;
}

export default function CompetitionCard(props: CompetitionCardProps) {
  const ticketSold = () => {
    if (props?.totalticketsbought && props?.competitionticketsize) {
      const sold = (
        (props?.totalticketsbought / props?.competitionticketsize) *
        100
      ).toFixed(0);
      return sold;
    } else {
      return 0;
    }
  };
  return (
    <div>
      {/* // 1 - main div */}
      <div className="sm:h-[527px] h-[657px]  w-[425.39px] sm:w-[350px] max-350:w-[340px] flex flex-col justify-end relative">
        {/* 2 - counter */}
        <div className=" sm:h-[37px] sm:top-[1.4px] h-[45.04px]  w-[calc(100%-39.66px)] mx-auto absolute top-[-2px] left-0 right-0 bg-white rounded-[26.75px] z-10">
          <Counter text={props?.competitionEndDateString ?? ""} />
        </div>
        {/* 3 - second main div */}
        <div className="h-[calc(100%-19px)] w-full rounded-[22.03px] overflow-hidden border-2">
          {/* 4 - image */}
          <div
            className="sm:h-[50%] h-[55%] w-full bg-cover bg-no-repeat relative"
            //   style={{ backgroundImage: 'url("https://via.placeholder.com/150")' }}
            style={{
              backgroundImage: `url(${
                props?.imageUrls && props?.imageUrls[0]?.imageurl
              })`,
            }}
          >
            <div className="absolute bottom-0 left-0 right-0 ">
              <HighRoller
                isHighRoller={props?.isHighRoller}
                rollerAmount={props?.rollerAmount}
              />
            </div>
          </div>
          {/* 5 - details container */}
          <div className="sm:h-1/2 h-[45%] px-[14.39px] bg-[#343434] rounded-[22.03px] flex justify-center flex-col">
            <VSpacer small={18.12} big={22} />
            <CompName text={props?.competitionname ?? ""} />
            <VSpacer small={19.72} big={24} />
            <Progress ticketSold={ticketSold} />
            <VSpacer small={18.42} big={22.31} />
            <Action
              entryValue={props?.entryValue ? `${Number(props.entryValue).toFixed(2)}` : ""}
              minnumberoftickets={props?.minnumberoftickets}
            />
            <VSpacer small={12.33} big={15.05} />
            <Button />
            <VSpacer small={18.12} big={22} />
          </div>
        </div>
      </div>
    </div>
  );
}
