import { Common } from "../../common/Common";

interface ValueProps {
  number: number;
}

const Value: React.FC<ValueProps> = ({ number }) => {
  return (
    <Common.typography
      fontFamily="Sequel100Wide95"
      fontSize="43.69px"
      fontSizeSmall="25px"
      color="#000"
      className="uppercase "
    >
      {new Intl.NumberFormat().format(number)}
    </Common.typography>
  );
};

export default Value;
