import React from "react";
import Button from "../Button";

const HowToPlay = () => {
    const howToPlay = [
        {
            img: "/Images/bitcoin-3.png",
            title: "Entering the Competitions ",
            description: [
                {
                    item: "Choose Your Competition: Explore our range of exciting crypto and luxury item competitions and select the one that interests you. Each competition will have its own details, including entry requirements and prize information. ",
                },
                {
                    item: "Pick Your Ticket Numbers: Use our Lucky Dip feature to have your numbers randomly assigned or choose your own numbers with our selective number picker. You can purchase as many tickets as you like, depending on the competition's rules. ",
                },
                {
                    item: "Confirmation: After completing your purchase, you will be able to view these inside the MY ACCOUNT section where you will find the the details of your ticket numbers and competition entry. This ensures you have a record of your participation. ",
                },
            ],
            left: false,
        },
        {
            img: "/Images/bitcoin-1.png",
            title: "Winner Selection & Live Draws ",
            description: [
                {
                    item: "Announcement: Once all tickets are sold for a competition or the timer has ended, we’ll announce the winner of the competition via our social media channels. You will also see if you have won or lost inside the MY ACCOUNT section. ",
                },
                {
                    item: "Draw Numbers: All ticket numbers and buyer wallet addresses will be added to the competition page as they are purchased for full transparency. ",
                },
                {
                    item: "Live Draw: At the end of each competition we’ll use Chainlink VRF random number generator to fairly and transparently select the winning ticket, we will then publish the winning Chainlink VRF transaction hash for users to independently verify. We will also publish the corresponding winners on our website and inside the MY ACCOUNT section along with the transaction hash transfer of the crypto prize to the winning wallet address. ",
                },
            ],
            left: true,
        },
        {
            img: "/Images/bitcoin-2.png",
            title: "Prize Distribution & Winner Spotlight ",
            description: [
                {
                    item: "Prize Distribution: Once you’ve been announced as the winner, your prize will be securely transferred in cryptocurrency to your digital wallet within 7 days, however in most cases this will be done within 24 hours. If you've won a physical prize our team will contact you direct via telegram to arrange delivery, or offer you a crypto cash alternative. The transaction hash of any crypto prize transfer will published on our website and inside the MY ACCOUNT section for on-chain verification. ",
                },
                {
                    item: "Winner Spotlight: Your achievement may be highlighted in our Winners section on the website, where your username and winning wallet address would be proudly displayed for the community to see, however your identity will remain anonymous. ",
                },
            ],
            left: false,
        },
    ];

    return (
        // removed p-10
        <div className="w-full p-2 howToPlayPage flex flex-col relative xl:p-12">
            <h3
                className="w-full bg-primary flex font-[Sequel100Wide95] items-center gap-8 //text-[25px] //sm:text-[22px] text-[#1A1A1A] font-bold h-[68px] pl-[2em] rounded-t-3xl"
                style={{
                    fontSize: "clamp(1px, 4.662004662vw, 24.82px)",
                }}
            >
                <img src="/Images/dropdown.png" alt="" />
                HOW TO PLAY
            </h3>
            {howToPlay.map((box, index) => (
                <div
                    className={` flex ${
                        box.left && "flex-row-reverse "
                    }  border-r-2 border-l-2 border-primary ${
                        howToPlay.length === index + 1 &&
                        "border-b-2 border-primary"
                    } ${
                        index === 0 && "border-t-2 border-primary"
                    } relative z-[10001] ${
                        index === howToPlay.length - 1 && "rounded-b-3xl"
                    } sm:flex-col-reverse sm:w-full md:flex-col-reverse md:w-full   `}
                    key={index}
                >
                    <img
                        src={box.img}
                        alt=""
                        className={`w-1/2 sm:w-full object-cover md:w-full  ${
                            index + 1 === howToPlay.length && "rounded-bl-3xl"
                        } `}
                    />
                    <div
                        className={`p-20 flex flex-col gap-10 w-1/2 howToPlay bg-secondary_bg sm:w-full sm:p-7 sm:gap-5 md:w-full md:gap-5 md:p-5 ${
                            index + 1 === howToPlay.length &&
                            "rounded-br-3xl sm:rounded-none md:rounded-none lg:rounded-none"
                        }`}
                    >
                        <h1
                            className="//font-[900] //text-[35px] leading-10 font-[Sequel100Wide95] text-white //w-[475px] w-full //sm:text-[20px] sm:ml-[20px] sm:w-[90%] md:w-full "
                            style={{
                                fontSize: "clamp(1px, 4.662004662vw, 35px)",
                            }}
                        >
                            {box.title}
                        </h1>
                        <ul className="text-white //w-[413px] flex flex-col gap-4 font-[MontBlancRegular] howToPlayList ml-[2em] sm:ml-[26px] //sm:text-[10px] //sm:w-[260px] //md:w-full">
                            {box.description.map((des, index) => (
                                <li
                                    key={index}
                                    style={{
                                        fontSize:
                                            "clamp(14px, 2.331002331vw, 16px)",
                                    }}
                                >
                                    <span className="font-[MontBlancBlack]">
                                        {des.item.split(":")[0] + ":"}
                                    </span>
                                    {des.item.split(":")[1]}
                                </li>
                            ))}
                        </ul>
                        <br />
                        {/* <Button
              className={
                "bg-primary h-[75px] font-[Sequel100Wide95] text-[#373635] //sm:text-[15.14px] //text-[20px] font-[900] w-fit //w-[50%] sm:w-[100%] md:w-full p-4 mt-[1em] sm:text-base border-2 border-white sm:h-[41.81px] sm:p-0 sm:mt-0 relative z-[100001] "
              }
              customStyle={{ fontSize: "clamp(12px, 2.363630071vw, 23px)" }}
              content={""}
            /> */}
                        <img
                            className="absolute bottom-0 right-0"
                            src="/Images/THEPRIZE_TokenLogo_EH-03.svg"
                        />
                    </div>
                </div>
            ))}
        </div>
    );
};

export default HowToPlay;
