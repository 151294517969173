import React from "react";
import WithNavbarAndFooter from "../Layout/WithNavbarAndFooter";
import AdvertBox from "../Components/Page Components/AdvertBox";
import PhoneStores from "../Components/Page Components/PhoneStores";
import Title from "../Components/RandomNumberGenerator/texts/Title";
import GeneratorContainer from "../Components/RandomNumberGenerator/containers/GeneratorContainer";
import RightContainer from "../Components/RandomNumberGenerator/containers/RightContainer";
import LeftContainer from "../Components/RandomNumberGenerator/containers/LeftContainer";
import RangeField from "../Components/RandomNumberGenerator/others/RangeField";
import GenerateButton from "../Components/RandomNumberGenerator/button/GenerateButton";
import VSpacer from "../Components/common/Spacer/VSpacer";
import Value from "../Components/RandomNumberGenerator/texts/Value";
import ChainlinkHash from "../Components/RandomNumberGenerator/others/ChainlinkHash";
import WhatIsRng from "../Components/RandomNumberGenerator/texts/WhatIsRng";
import Description from "../Components/RandomNumberGenerator/texts/Description";
import AddressChainlinkContainer from "../Components/RandomNumberGenerator/containers/AddressChainlinkContainer";
import ContractAddress from "../Components/RandomNumberGenerator/texts/ContractAddress";
import Chainlink from "../Components/RandomNumberGenerator/texts/Chainlink";
import Table from "../Components/RandomNumberGenerator/others/Table";
import RecentlyGeneratedNumbers from "../Components/RandomNumberGenerator/texts/RecentlyGeneratedNumbers";
import TableSmall from "../Components/RandomNumberGenerator/others/TableSmall";
import Loadmore from "../Components/RandomNumberGenerator/button/Loadmore";

function RandomNumberGeneratorPage() {
  function getRandomNumber({ min, max }: { min: number; max: number }) {
    if (min > max) [min, max] = [max, min];
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  const [min, setMin] = React.useState(0);
  const [max, setMax] = React.useState(1);
  const [number, setNumber] = React.useState(0);
  return (
    <div>
      <div className="bg-gradient-to-bl from-transparent via-transparent to-[#360d26] ">
        <div className="min-h-[1397px] flex flex-col items-center bg-black mx-[43px] sm_md_lg:mx-0 sm_md_lg:rounded-0 rounded-[30px] sm_md_lg:rounded-none sm_md_lg:bg-gradient-to-b sm_md_lg:from-[#20131b] sm_md_lg:to-[#360d26]">
          <VSpacer treatLgAsSmall big={72} small={33} />
          <Title />
          <VSpacer treatLgAsSmall big={49} small={30} />
          <div className="max-w-[1240px] w-full flex flex-col justify-center items-center //sm_md_lg:max-w-[387px] sm_md_lg:px-[21px] px-[clamp(0px,1.5vw,25px)]">
            <GeneratorContainer
              left={
                <LeftContainer>
                  <RangeField
                    onChange={(e) => setMin(parseInt(e.target.value))}
                    value={min}
                    max={max}
                    type="min"
                  />
                  <VSpacer treatLgAsSmall big={18} small={11} />
                  <RangeField
                    onChange={(e) => setMax(parseInt(e.target.value))}
                    value={max}
                    type="max"
                  />
                  <VSpacer treatLgAsSmall big={48} small={15} />
                  <GenerateButton
                    onClick={() => setNumber(getRandomNumber({ min, max }))}
                  />
                </LeftContainer>
              }
              right={
                <RightContainer>
                  <Value number={number} />
                  <ChainlinkHash value={number} />
                </RightContainer>
              }
            />
            <VSpacer treatLgAsSmall big={70} small={40} />
            <WhatIsRng />
            <VSpacer treatLgAsSmall big={39} small={29} />
            <Description />
          </div>
          <VSpacer treatLgAsSmall big={59} small={36} />
          <AddressChainlinkContainer>
            <ContractAddress value="239827fkjhweskhcsk39" />
            <Chainlink value="239827fkjhweskhcsk39" />
          </AddressChainlinkContainer>
          <VSpacer treatLgAsSmall big={69.25} small={29} />
          <Table />
          <RecentlyGeneratedNumbers />
          <VSpacer treatLgAsSmall big={0} small={30} />
          <TableSmall />
          <VSpacer treatLgAsSmall big={0} small={19} />
          <Loadmore />
          <VSpacer treatLgAsSmall big={64} small={19} />
        </div>

        <VSpacer treatLgAsSmall big={44} small={0} />
      </div>

      <AdvertBox />
      <PhoneStores background="bg-gradient-to-r from-[#a51269] to-[#2b2b2b] h-screen " />
    </div>
  );
}

export default WithNavbarAndFooter(RandomNumberGeneratorPage);
