import { Common } from "../../common/Common";

export default function WhatIsRng() {
  return (
    <Common.typography
      fontFamily="Sequel100Wide95"
      fontSize="45px"
      fontSizeSmall="30px"
      color="#ffffff"
      className="uppercase"
    >
      What is RNG?
    </Common.typography>
  );
}
