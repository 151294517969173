import axios from "axios";

const loginByEmailPassword = async (emailaddress: string, password: string) => {
  const response = await axios.post("/user/loginemail", {
    emailaddress,
    password,
  });
  return response.data;
};

const getUserBalance = async () => {
  const response = await axios.get("/walletBalance");
  return response.data;
}

const registerByEmailPassword = async (emailaddress: string, password: string, confirmpassword: string) => {
  const response = await axios.post("/user/registeremail", {
    emailaddress,
    password,
    confirmpassword
  });
  return response.data;
}



export { loginByEmailPassword,getUserBalance, registerByEmailPassword };
