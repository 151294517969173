import React from "react";

interface LeftContainerProps {
  children: React.ReactNode;
}
export default function LeftContainer(props: LeftContainerProps) {
  return (
    // <div className="min-w-[241.99px] relative sm_md:min-w-[145.32px] sm_md:h-[104.47px]  h-[173.96px] overflow-hidden max-h-[205.06px]">
    <div className="min-w-[241.99px] relative sm_md:min-w-[145.32px] sm_md:h-full h-full overflow-hidden max-h-full">
      {props.children}
    </div>
  );
}
