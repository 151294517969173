interface Title2ContainerProps {
  children: React.ReactNode;
}
export default function Title2Container(props: Title2ContainerProps) {
  return (
    <div className="py-[22px] px-[87px] w-full sm_md:min-h-[68px] min-h-[100px] bg-primary">
      {props.children}
    </div>
  );
}
