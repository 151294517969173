import { useEffect, useState } from "react";
import { CompetitionProps } from "../Competitions";
import {
    finishedCompetition,
    getCompetitions,
    getImages,
} from "./heroFunctionalities";
import HeroSlide from "./HeroSlide";
import { Text } from "../../react-responsive-utilities/src";
import VSpacer from "../../common/Spacer/VSpacer";
import Carousel from "../../common/Carousel/Carousel";

const Achievements = () => (
    <>
        <div className="mx-[7.5%] items-center gap-[20.78px] xl:hidden flex flex-col">
            <div className="flex flex-col justify-center w-full  ">
                <div className=" flex flex-row justify-center">
                    <img
                        src="/Images/trustpilot_mobile.png"
                        alt="trust pilot"
                        className=" h-[35px] w-[110px] lg:w-[220px] md:w-[170px]"
                    />
                    <div className="lg:w-[50px] md:w-[30px] sm:w-[15px]"></div>

                    <img
                        src="/Images/star_mobile.png"
                        alt=""
                        className=" h-[32px] w-[133px]"
                    />
                </div>
                <Text
                    textSize={[12, 12, 19.79]}
                    className="font-[MontBlancRegular] block text-white text-center mt-[2%]"
                >
                    REVIEWS COMING SOON
                </Text>
            </div>

            <div className="flex flex-row justify-between">
                <div className="flex flex-col justify-center items-center">
                    <Text
                        textSize={[20, 12.49, 40]}
                        className="font-[Sequel100Wide] block text-white sm:leading-[20px]  sm:leading-[10px]"
                    >
                        MILLIONS
                    </Text>
                    <Text
                        textSize={[15, 10, 19.79]}
                        className="font-[MontBlancRegular] block text-white text-center sm:leading-[10px]  lg:leading-[25px] md:leading-[22px] sm:leading-[17px]"
                    >
                        TO GIVE AWAY
                        <br />
                        IN PRIZES
                    </Text>
                </div>

                <div className="lg:w-[50px] md:w-[30px] sm:w-[15px]"></div>

                <div className="flex flex-col justify-center items-center">
                    <Text
                        textSize={[20, 12.49, 40]}
                        className="font-[Sequel100Wide] block text-white sm:leading-[20px] xl:leading-[50px] sm:leading-[10px]"
                    >
                        1000'S
                    </Text>
                    <Text
                        textSize={[15, 10, 19.79]}
                        className="font-[MontBlancRegular] block text-white text-center sm:leading-[10px] xl:leading-[30px] lg:leading-[25px] md:leading-[22px] sm:leading-[17px]"
                    >
                        HAPPY WINNERS
                        <br />
                        TO COME
                    </Text>
                </div>
            </div>
            <img
                src="/Images/icon-bar.svg"
                alt=""
                className="xl:hidden block  w-[260px] center"
            />
        </div>

        {/* FOR DESKTOP VERSION */}
        <div className="mx-[7.5%] flex flex-row justify-between items-center gap-[20.78px]  sm_md_lg:hidden">
            <div className="flex flex-col justify-center items-center">
                <Text
                    textSize={[0, 12.49, 40]}
                    className="font-[Sequel100Wide] block text-white xl:leading-[50px] sm:leading-[10px]"
                >
                    MILLIONS
                </Text>
                <Text
                    textSize={[0, 10, 19.79]}
                    className="font-[MontBlancRegular] block text-white text-center xl:leading-[30px] lg:leading-[25px] md:leading-[22px] sm:leading-[17px]"
                >
                    TO GIVE AWAY
                    <br />
                    IN PRIZES
                </Text>
            </div>

            <img
                src="/Images/x_wheel.png"
                alt=""
                className="xl:block hidden ml-[5%] h-[95px] w-[95px]"
            />

            <div className="flex flex-col justify-center items-center w-full xl:mr-[1%] ">
                <img
                    src="/Images/trustpilot_image.png"
                    alt=""
                    className=" w-full xl:w-[80%] lg:w-[60%] md:w-[60%] sm:w-[70%] h-[10%] "
                />
                <Text
                    textSize={[0, 10, 19.79]}
                    className="font-[MontBlancRegular] block text-white text-center"
                >
                    REVIEWS COMING SOON
                </Text>
            </div>

            <img
                src="/Images/x_wheel.png"
                alt=""
                className="xl:block hidden mr-[5%] h-[95px] w-[95px]"
            />

            <div className="flex flex-col justify-center items-center">
                <Text
                    textSize={[0, 12.49, 40]}
                    className="font-[Sequel100Wide] block text-white xl:leading-[50px] sm:leading-[10px]"
                >
                    1000'S
                </Text>
                <Text
                    textSize={[0, 10, 19.79]}
                    className="font-[MontBlancRegular] block text-white text-center xl:leading-[30px] lg:leading-[25px] md:leading-[22px] sm:leading-[17px]"
                >
                    HAPPY WINNERS
                    <br />
                    TO COME
                </Text>
            </div>
        </div>
    </>
);

export default function HeroSlider() {
    const [index, setIndex] = useState<number>(0);
    const [loader, setLoader] = useState<boolean>();
    // const [competitionsList, setCompetitionsList] =
    //     useState<CompetitionProps[]>();
    const [images, setImages] = useState<any>();
    const [sliders, setSliders] = useState<CompetitionProps[]>();

    // useEffect(() => {
    //     getCompetitions("", setLoader, setCompetitionsList, setSliders);
    // }, []);

    const competitionsList: CompetitionProps[] = [
        {
            UID: "f35f9058ef4e10093e9b51fe6b531a8a",
            competitionname: "1 BTC",
            //  crDate: "2024-08-06T02:36:10.000Z",
            legalinformation: "ytrewq",
            competitioninformation:
                "The 1 BTC Jackpot is here! Enter for your shot at a massive 1 Bitcoin grand prize. With just one lucky entry, you could walk away with a fortune in BTC!",
            competitionenddate: new Date("2024-08-08T20:00:00.000Z"),
            // category: "BITCOIN",
            minnumberoftickets: 100,
            competitionticketsize: 500,
            totalticketsbought: 100,
            featured: 0,
            manual: "1",
            instant: false,
            entryValue: "49",

            imageUrls: [
                {
                    UID: "1",
                    competitionID: "1",
                    imageurl: "Images/1-btc-hero.png",
                },
            ],
        },

        {
            UID: "f35f9058ef4e10093e9b51fe6b531a8b",
            competitionname: "$100K INSTANT WINS WITH $20K END PRIZE!",
            // crDate: "2024-08-06T02:36:10.000Z",
            legalinformation: "ytrewq",
            competitioninformation:
                "Join our low odds instant wins competition and get a 30/1 instant shot at winning a prize!",
            competitionenddate: new Date("2024-08-08T20:00:00.000Z"),
            // category: "BITCOIN",
            minnumberoftickets: 100,
            competitionticketsize: 500,
            totalticketsbought: 0,
            featured: 0,
            manual: "1",
            instant: true,
            entryValue: "49",
            imageUrls: [
                {
                    UID: "2",
                    competitionID: "2",
                    imageurl: "Images/100k-hero.png",
                },
            ],
        },
    ];

    useEffect(() => {
        if (competitionsList) {
            // getImages(competitionsList[index]?.UID as string, setImages);
            getImages(setImages, competitionsList[index]?.imageUrls as any);
        }
    }, [competitionsList, index]);

    const handleNext = () => {
        if (competitionsList && index < competitionsList?.length - 1) {
            setIndex(index + 1);
        } else {
            setIndex(0);
        }
    };

    const handlePrevious = () => {
        if (index > 0) {
            setIndex(index - 1);
        } else {
            if (competitionsList) setIndex(competitionsList?.length - 1);
        }
    };

    return (
        competitionsList && (
            <div
                className="relative rounded-[30px] overflow-hidden shadow-[0px_16px_16px_rgba(0,0,0,0.5)]
"
            >
                {/* Next & Previous Buttons */}
                <div className="relative ">
                    <img
                        className="xl:block lg:block hidden absolute left-0 top-1/2 transform -translate-y-1/2 z-[51]"
                        src="/Images/previous.png"
                        alt="previous"
                        onClick={handlePrevious}
                    />
                    <img
                        className="xl:block lg:block hidden absolute right-0 top-1/2 transform -translate-y-1/2 z-[51]"
                        src="/Images/next.png"
                        alt="next"
                        onClick={handleNext}
                    />
                    {/* Slide */}
                    <HeroSlide
                        competitionEndDate={
                            competitionsList[index]?.competitionenddate as Date
                        }
                        competitionTicketSize={
                            competitionsList[index]
                                ?.competitionticketsize as number
                        }
                        competitionUID={competitionsList[index]?.UID as string}
                        competitionEnded={finishedCompetition(
                            competitionsList[index]?.competitionenddate
                        )}
                        costPerTicket={0.99}
                        description={
                            competitionsList[index]
                                ?.competitioninformation as string
                        }
                        image={images && images[0]?.imageurl}
                        title={
                            competitionsList[index]?.competitionname as string
                        }
                        totalTicketsBought={
                            competitionsList[index]
                                ?.totalticketsbought as number
                        }
                        isInstant={Boolean(competitionsList[index]?.instant)}
                        key={competitionsList[index]?.UID}
                    />
                </div>
                {/* Index indicator */}
                <div className="relative flex w-full bg-[#DDE404] xl:h-[52px] h-[54.44px] justify-center items-center gap-[18.81px]">
                    <img
                        className="xl:hidden lg:hidden block"
                        src="/Images/mobile_prev.png"
                        alt=""
                        onClick={handlePrevious}
                    />
                    {/* {sliders?.map((slider, i) => (
                        <div
                            key={i}
                            className={` ${
                                index === i ? "bg-[#000000]" : "bg-[#D24848]"
                            } w-full max-w-[65.25px] h-[5px]`}
                        ></div>
                    ))} */}

                    {/* <Carousel> */}

                    {competitionsList.map((competition, i) => (
                        <div
                            className={`${
                                index === i ? "bg-black " : "bg-[#D24848] "
                            } h-[5px] w-[62.25px] `}
                        ></div>
                    ))}

                    {/* </Carousel> */}
                    <img
                        className="xl:hidden lg:hidden block"
                        src="/Images/mobile_next.png"
                        alt=""
                        onClick={handleNext}
                    />
                </div>
                {/* Achievements */}
                <div className="xl:pt-[37.91px] xl:pb-[27px] py-[21.8px] bg-[#1A1A1A] relative  z-[20]">
                    <img
                        src="/Images/THEPRIZE_TokenLogo_EH-03.svg"
                        alt=""
                        className="absolute -bottom-4 right-0 w-[255px] h-[clamp(40px,70vw,255px)]  object-cover hidden md_lg:block"
                    />
                    <Achievements />
                    <VSpacer small={20} big={30} />
                    <Text
                        textSize={[30, 30.0, 42.0]}
                        // textSize={[30, 30.0, 50.0]}
                        className="text-[#FFFFFF] font-['Sequel100Wide95']  leading-normal xl:font-[60] md:font-[40] sm:font-[20] block text-center"
                    >
                        FEATURED IN
                    </Text>

                    {/* FOR WEB VERSION */}
                    <div className="flex mt-[1%] mb-[1%] flex-row flex-nowrap justify-center items-center sm:hidden block ">
                        <img
                            className="w-[18%] h-auto "
                            src="/Images/Featuredin_web_1.png"
                            alt=""
                        />{" "}
                        <img
                            className="w-[18%] h-auto "
                            src="/Images/Featuredin_web_2.png"
                            alt=""
                        />{" "}
                        <img
                            className="w-[18%] h-auto "
                            src="/Images/Featuredin_web_3.png"
                            alt=""
                        />{" "}
                        <img
                            className="w-[18%] h-auto "
                            src="/Images/Featuredin_web_4.png"
                            alt=""
                        />{" "}
                        <img
                            className="w-[18%] h-auto "
                            src="/Images/Featuredin_web_5.png"
                            alt=""
                        />
                    </div>

                    {/* FOR MOBILE VERSION */}
                    <img
                        className="mx-auto  w-[80%] h-[20%] sm:block hidden"
                        src="/Images/Featuredin_mobile_1.png"
                        alt=""
                    />

                    <img
                        className="mx-auto w-[80%] h-[10%] sm:block hidden"
                        src="/Images/Featuredin_mobile_2.png"
                        alt=""
                    />
                    <div className="h-[4%] sm:block hidden "></div>

                    <img
                        className="mx-auto w-[30%] h-[10%] sm:block hidden"
                        src="/Images/Featuredin_mobile_3.png"
                        alt=""
                    />
                    {/*                     <Text
                        textSize={[10, 10, 19.79]}
                        className="font-[MontBlancRegular] block text-white text-center mt-[-35] font-[20px] sm:mt-[1%]"
                    >
                        PRESS ARTICLES COMING SOON
                    </Text> */}
                </div>

                {/* Quote */}
                <div className="xl:min-h-[120px] bg-primary flex flex-col  justify-between items-center //absolute w-full //bottom-[-150px]  -z-10 rounded-b-[30px] h-full ">
                    <div className="w-full bg-[#1A1A1A]  h-[15px] rounded-b-[30px] " />
                    <VSpacer small={25} big={5} />
                    <Text
                        textSize={[0, 15, 22.59]}
                        className="text-center font-[Sequel100Wide95] pb-[30.07px] px-[22px]"
                    >
                        “A DOLLAR WON IS TWICE AS SWEET AS A DOLLAR EARNED”
                        <span className=" font-[Sequel100Wide-65]">
                            - PAUL NEWMAN
                        </span>
                    </Text>
                    <img
                        src="/Images/paulNewman.png"
                        alt=""
                        className="absolute w-[50rem] sm:bottom-[1rem] bottom-[-4rem] h-[200px] -z-1 opacity-10"
                    />
                </div>
            </div>
        )
    );
}
