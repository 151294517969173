import React, { useEffect } from "react";
import Payments from "../Components/Auth Components/Payments";
import WithNonAbsolutedSidebarNavbarFooter from "../Layout/WithNonAbsolutedSidebarNavbarFooter";

export default function PurchaseTickets() {
  useEffect(() => {
    document.getElementById("purchase_ticket_main")?.scrollIntoView();
  }, []);
  return (
    <WithNonAbsolutedSidebarNavbarFooter
      sidebarContainerClassName="mb-4"
      Component={
        <div
          className="flex flex-col flex-1 p-5 bg-secondary_bg m-4 mt-8 rounded-3xl"
          id="purchase_ticket_main"
        >
          {" "}
          <h1 className="text-white mb-2 text-[45px] text-center font-extrabold sm:text-[28px] md:text-[28px]">
            PAYMENT TYPE
          </h1>
          <Payments skipable={false} />
        </div>
      }
    />
  );
}
