/**
 * Entry component renders a user entry with an image, title, description, and a button.
 * Structure:
 * - Main container
 * - Image container = 1
 * - user image = 1.1
 * - Information container = 2
 * - Title = 2.1
 * - Spacer Title and Description = 2.2
 * - Spacer Description and Button = 2.3
 * - Description Container = 2.4
 * - Description = 2.4.1
 * - Spacer Information and Button = 2.4.2
 * - Small Arrow Button = 2.4.3
 * - Button Container = 4
 * - Arrow Button = 4.1
 * - Spacer Button and Date = 4.2
 * - Date = 4.3
 * @param {EntryProps} props - The properties object.
 * @param {string} props.title - The title of the entry.
 * @param {string} props.description - The description of the entry.
 * @param {string} props.date - The date of the entry.
 * @param {boolean} [props.isLive] - The flag to show the live status of the entry.
 *
 * @returns {JSX.Element} The rendered Entry component.
 */

import { useNavigate } from "react-router-dom";
import HSpacer from "../../../common/Spacer/HSpacer";
import VSpacer from "../../../common/Spacer/VSpacer";

interface EntryProps {
  UID: string;
  title: string;
  description: string;
  date: string;
  isLive?: boolean;
}
export default function Entry(props: EntryProps) {
  const navigate = useNavigate();
  return (
    // Main component
    <div
      className={`${
        props.isLive ? "border-[#DDE404]" : "border-[#EF008F]"
      } py-[23px] px-[35.86px] below-1440:py-[17px] below-1440:px-[13.44px] flex size-full  //max-w-[818px] max-h-[223px] below-1440:max-h-full below-1440:h-full overflow-hidden border-[2.28px] rounded-[20px] items-start justify-start`}
    >
      {/* 1 - Image Container */}
      <div className="max-w-[183px] size-full max-h-[177px] below-1440:min-w-[109.48px] below-1440:min-h-[111px] rounded-[15px] overflow-hidden">
        {/* 1.1 - user image */}
        <img
          src="/Images/avatar.svg"
          alt="Entry Image"
          className="below-1440:min-w-[109.48px] below-1440:min-h-[111px]"
        />
      </div>
      <HSpacer small={22.09} big={49.07} />
      {/* 2 - Information Container */}
      <div className="min-w-[359px] below-1440:min-w-[178px] size-full max-h-[177px] below-1440:min-h-[111px] ">
        {/* 2.1 - Title */}
        <p className="text-[51.73px]  below-1440:text-[25px] font-[Sequel100Wide95] text-white leading-none">
          {props.title}
        </p>
        {/* 2.2 - Spacer Title and Description */}
        <VSpacer small={9} big={16.59} />
        <hr className="below-1440:max-w-[140.21px] below-1440:h-[4px]  max-w-[331.25px] h-[5.17px] bg-[#EF008F] rounded-full border-transparent" />
        {/* 2.3 - Spacer Description and Button */}
        <VSpacer small={10} big={15.52} />
        {/* 2.4 - Description Container */}
        <div className="flex  items-end">
          {/* 2.4.1 - Description */}
          <p className="text-[18.62px] below-1440:text-[12px] font-[Sequel100WideVF45] text-white leading-none below-1440:min-w-[100px] below-1440:min-h-[42px] text-wrap truncate">
            {props.description}
          </p>
          {/* 2.4.2 - Spacer Description and Button */}
          <HSpacer small={24.67} big={42.23} />
          {/* 2.4.3 - small arrow button*/}
          <img
            onClick={() =>
              props?.isLive
                ? navigate(`/view/entry/${props.UID}`)
                : navigate(`/view/result/${props.UID}`)
            }
            src="/Images/arrow-next-circle.svg"
            alt="Arrow"
            className="hidden below-1440:block sm:h-[17px] sm:w-[16px] cursor-pointer"
          />
        </div>
      </div>
      {/* 3 - Spacer Information and Button */}
      <HSpacer small={22.09} big={169.14} />
      {/* 4 - Button Container */}
      <div className="flex flex-col justify-center items-center  m-auto below-1440:hidden">
        {/* 4.1 - Arrow Button */}
        <img
          onClick={() =>
            props?.isLive
              ? navigate(`/view/entry/${props.UID}`)
              : navigate(`/view/result/${props.UID}`)
          }
          className="size-[29px] cursor-pointer"
          src="/Images/arrow-next-circle.svg"
          alt="Arrow"
        />
        {/* 4.2 - Spacer Button and Date */}
        <VSpacer small={5} big={42.23} />
        {/* 4.3 - Date */}
        <p className="text-white text-[18.62px] font-[Sequel100WideVF45] below-1440:hidden">
          {props.date}
        </p>
      </div>
    </div>
  );
}
