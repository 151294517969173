import { useEffect, useState } from "react";
import AdvertBox from "../Components/Page Components/AdvertBox";
import {
  CompetitionProps,
  Competitions,
} from "../Components/Page Components/Competitions";
//import Hero from "../Components/Page Components/Hero";
import HowItWorksBox, {
  HowItWorksBoxProps,
} from "../Components/Page Components/HowItWorksBox";
import WinnerBox, {
  WinnerBoxProps,
} from "../Components/Page Components/WinnerBox";
import WithNavbarAndFooter from "../Layout/WithNavbarAndFooter";
// Import Swiper React components

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import ConfirmationPopup from "../Components/common/confirmation_popup";

// import required modules
import Slider from "../Components/Slider/Slider";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { getUserCompetitions } from "../redux/reducers/competitionsSlice";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  getPreviousWinners,
  getUserListCompetition,
} from "../Services/userService";
import moment from "moment";
import Button from "../Components/Button";
import PhoneStores from "../Components/Page Components/PhoneStores";
import { Text } from "../Components/react-responsive-utilities/src";
import Categorybar from "../Components/Page Components/Categorybar";
import Carousel from "../Components/common/Carousel/Carousel";
import HowItWorksBoxSmall from "../Components/Page Components/HowItWorksBoxSmall";
import HeroSlider from "../Components/Page Components/Hero/HeroSlider";
import CompetitionCard from "../Components/Page Components/CompetitionCard/CompetitionCard";

const enter = "/Images/enter-icon.svg";
const crown = "/Images/crown-icon.svg";
const nuclear = "/Images/nuclear-icon.svg";

export const poundSign = "$";

export const howItWorks: HowItWorksBoxProps[] = [
  {
    icon: enter,
    title: "ENTERING THE COMPETITIONS",
    content:
      "Explore our exciting range of crypto and luxury item competitions, pick your favourite, and secure your tickets.  ",
  },
  {
    icon: crown,
    title: `WINNER SELECTION & LIVE DRAW`,
    content:
      "The winner is selected using Chainlink VRF RNG this will take place automatically when the timer runs out or when the competition sells out. ",
  },
  {
    icon: nuclear,
    title: "PRIZES & WINNER SPOTLIGHTS ",
    content:
      "Become part of our exclusive WINNERS community and get featured in the Winners Hall of Fame! ",
  },
];

const OPTIONS = { dragFree: true, startIndex: 1, loop: true };

const LandingPage = () => {
  const [currentFilter, setCurrentFilter] = useState<any>("");
  const [loader, setLoader] = useState<boolean>(false);
  const [winners, setWinners] = useState<WinnerBoxProps[]>([]);

  const baseUrl = window.location.origin;

  const dummyWinners = [
    {
      itemWon: null,
      name: "Win loads of SOL",
      location: "",
      date: "",
      backgroundImg: `${baseUrl}/Images/Solana.jpg`,
      promouteWinnerUrl: `${baseUrl}/Images/Solana.jpg`,
      hover: true,
      winnerFont: "",
      homePage: true,
      className: "",
      winnerRadius: "",
      competitionprize: "20 x SOL",
      competitionname: "Win loads of SOL",
      imageurl: `${baseUrl}/Images/Solana.jpg`,
      competitionenddate: new Date("2024-08-13T00:00:00.000Z"),
    },
    {
      itemWon: null,
      name: "Win loads of ETH",
      location: "",
      date: "",
      backgroundImg: `${baseUrl}/Images/static1.jpg`,
      promouteWinnerUrl: `${baseUrl}/Images/static1.jpg`,
      hover: true,
      winnerFont: "",
      homePage: true,
      className: "",
      winnerRadius: "",
      competitionprize: "20 x ETH",
      competitionname: "Win loads of ETH",
      imageurl: `${baseUrl}/Images/static1.jpg`,
      competitionenddate: new Date("2024-08-14T00:00:00.000Z"),
    },
    {
      itemWon: null,
      name: "Win BTC",
      location: "",
      date: "",
      backgroundImg: `${baseUrl}/Images/bitcoin-2007769_1280.jpg`,
      promouteWinnerUrl: `${baseUrl}/Images/bitcoin-2007769_1280.jpg`,
      hover: true,
      winnerFont: "",
      homePage: true,
      className: "",
      winnerRadius: "",
      competitionprize: "2 x BTC",
      competitionname: "Win BTC",
      imageurl: `${baseUrl}/Images/bitcoin-2007769_1280.jpg`,
      competitionenddate: new Date("2024-08-11T00:00:00.000Z"),
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getAllPreviousWinners = async () => {
    try {
      const response = await getPreviousWinners();
      setWinners(response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllPreviousWinners();
  }, []);
  const [filters, setFilters] = useState<any>([
    { category: "BITCOIN" },
    { category: "CARS & WATCHES" },
    { category: "INSTANT WINS" },
    { category: "HIGH ROLLERS" },
    { category: "NFT'S" },
    { category: "ALTCOINS" },
  ]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();
  // const itemsPerPage = 12;
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [competitionsList, setCompetitionsList] = useState<CompetitionProps[]>(
    []
  );
  const [allCompetitionsLoaded, setAllCompetitionsLoaded] = useState(false);
  const [displayedAllCompetitions, setDisplayedAllCompetitions] = useState<
    CompetitionProps[]
  >([]);
  const dispatch = useAppDispatch();

  const [finishedCompetitions, setFinishedCompetitions] = useState<
    CompetitionProps[]
  >([]);

  useEffect(() => {
    if (window.screen.width < 640) {
      setItemsPerPage(999);
    } else {
      setItemsPerPage(12);
    }
  }, [window.screen.width]);

  // useEffect(() => {
  //   if (competitions) {
  //     const uniqueCategories = [
  //       ...new Set(
  //         competitions?.map((competition) =>
  //           competition?.category?.toLowerCase()
  //         )
  //       ),
  //     ];
  //     const categories = uniqueCategories.map((category) => {
  //       return { category: category };
  //     });

  //     categories.push({ category: "All" });
  //     setFilters(categories);
  //     setCurrentFilter(categories[0]?.category);
  //   }
  // }, [competitions]);

  function changeFilter(filter: string) {
    setCurrentFilter(filter);
  }

  function loadCompetition(UID?: string) {
    navigate(`/competition/${UID}`);
  }

  function changeSlider(action: string) {
    if (action === "previous") {
      if (currentIndex < 3 && currentIndex > 0) {
        setCurrentIndex((prev) => prev - 1);
      } else {
        setCurrentIndex(2);
      }
    }
    if (action === "next") {
      if (currentIndex < 2 && currentIndex > -1) {
        setCurrentIndex((prev) => prev + 1);
      } else {
        setCurrentIndex(0);
      }
    }
  }

  useEffect(() => {
    getLiveCompetitions(currentFilter);
  }, [currentFilter]);

  const navigateToResult = (competition?: any, UID?: string) => {
    navigate(`/competitionResult/${UID}`);
  };

  const finishedCompetition = (competitionenddate?: Date) => {
    const currentDate = new Date();
    const competitionEndDate = new Date(competitionenddate ?? new Date());
    if (currentDate > competitionEndDate) {
      return true;
    } else {
      return false;
    }
  };

  const staticCompetitions = [
    {
      UID: "4ae2681707eee9af9441e15ed0e8b4f0",
      competitionname: "0.5 BTC",
      crDate: "2024-08-11T19:11:42.000Z",
      legalinformation: "bitter competition it is legal information",
      competitioninformation: "Best competition where winner will be awesome",
      competitionenddate: null,

      competitionEndDateString: "02:12:44:23",
      category: "BITCOIN",
      minnumberoftickets: 5,
      entryValue: 4.95,
      competitionticketsize: 1000,
      totalticketsbought: 700,
      featured: null,
      isHighRoller: 0,
      rollerAmount: "",
      manual: 1,
      instant: 0,
      imageUrls: [
        {
          UID: "1befb4554e8a19a1ce1e4f9e452eced8",
          competitionID: "4ae2681707eee9af9441e15ed0e8b4f0",
          imageurl: "/Images/0.5-btc.png",
        },
      ],
    },
    {
      UID: "4ae2681707eee9af9441e15ed0e8b4f0",
      competitionname: "1 BTC",
      crDate: "2024-08-11T19:11:42.000Z",
      legalinformation: "bitter competition it is legal information",
      competitioninformation: "Best competition where winner will be awesome",
      competitionenddate: null,
      competitionEndDateString: "07:22:14:53",
      category: "BITCOIN",
      minnumberoftickets: 10,
      entryValue: 9.90,
      competitionticketsize: 1000,
      totalticketsbought: 250,
      featured: null,
      manual: 1,
      instant: 0,
      rollerAmount: "",

      isHighRoller: 0,
      imageUrls: [
        {
          UID: "1befb4554e8a19a1ce1e4f9e452eced8",
          competitionID: "4ae2681707eee9af9441e15ed0e8b4f0",
          imageurl: "/Images/1-btc.png",
        },
      ],
    },
    {
      UID: "4ae2681707eee9af9441e15ed0e8b4f0",
      competitionname: "2 BTC",
      crDate: "2024-08-11T19:11:42.000Z",
      legalinformation: "bitter competition it is legal information",
      competitioninformation: "Best competition where winner will be awesome",
      competitionenddate: null,

      competitionEndDateString: "00:09:24:17",
      category: "BITCOIN",
      minnumberoftickets: 15,
      entryValue: 14.85,
      competitionticketsize: 1000,
      totalticketsbought: 920,
      featured: null,
      manual: 1,
      rollerAmount: "",

      instant: 0,
      isHighRoller: 0,

      imageUrls: [
        {
          UID: "1befb4554e8a19a1ce1e4f9e452eced8",
          competitionID: "4ae2681707eee9af9441e15ed0e8b4f0",
          imageurl: "/Images/2-btc.png",
        },
      ],
    },

    // wen lambo
    {
      UID: "4ae2681707eee9af9441e15ed0e8b4f0",
      competitionname: "LAMBO URUS",
      crDate: "2024-08-11T19:11:42.000Z",
      legalinformation: "bitter competition it is legal information",
      competitioninformation: "Best competition where winner will be awesome",
      competitionenddate: null,
      rollerAmount: "OR $250,000",

      competitionEndDateString: "04:52:40:11",
      category: "CARS & WATCHES",
      minnumberoftickets: 25,
      entryValue: 24.75,
      competitionticketsize: 1000,
      totalticketsbought: 50,
      featured: null,
      manual: 1,

      instant: 0,
      isHighRoller: 1,
      imageUrls: [
        {
          UID: "1befb4554e8a19a1ce1e4f9e452eced8",
          competitionID: "4ae2681707eee9af9441e15ed0e8b4f0",
          imageurl: "/Images/urus.png",
        },
      ],
    },
    {
      UID: "4ae2681707eee9af9441e15ed0e8b4f0",
      competitionname: "HURACAN",
      crDate: "2024-08-11T19:11:42.000Z",
      legalinformation: "bitter competition it is legal information",
      competitioninformation: "Best competition where winner will be awesome",
      competitionenddate: null,
      isHighRoller: 1,
      rollerAmount: "OR $150,000",

      competitionEndDateString: "00:00:11:13",
      category: "CARS & WATCHES",
      minnumberoftickets: 15,
      entryValue: 14.85,
      competitionticketsize: 1000,
      totalticketsbought: 980,
      featured: null,
      manual: 1,
      instant: 0,
      imageUrls: [
        {
          UID: "1befb4554e8a19a1ce1e4f9e452eced8",
          competitionID: "4ae2681707eee9af9441e15ed0e8b4f0",
          imageurl: "/Images/huracan.png",
        },
      ],
    },
    // {
    //     UID: "4ae2681707eee9af9441e15ed0e8b4f0",
    //     competitionname: "ROLEX DAYTONA",
    //     crDate: "2024-08-11T19:11:42.000Z",
    //     legalinformation: "bitter competition it is legal information",
    //     competitioninformation:
    //         "Best competition where winner will be awesome",
    //     competitionenddate: null,
    //     category: "CARS & WATCHES",
    //     minnumberoftickets: 5,
    //     competitionticketsize: 1000,
    //     totalticketsbought: null,
    //     featured: null,
    //     manual: 1,
    //     instant: 0,
    //     imageUrls: [
    //         {
    //             UID: "1befb4554e8a19a1ce1e4f9e452eced8",
    //             competitionID: "4ae2681707eee9af9441e15ed0e8b4f0",
    //             imageurl: "/Images/rolex_daytona.svg",
    //         },
    //     ],
    // },
    {
      UID: "4ae2681707eee9af9441e15ed0e8b4f0",
      competitionname: "PATEK PHILIPPE",
      crDate: "2024-08-11T19:11:42.000Z",
      legalinformation: "bitter competition it is legal information",
      competitioninformation: "Best competition where winner will be awesome",
      competitionenddate: null,
      isHighRoller: 1,
      rollerAmount: "OR $50,000",
      competitionEndDateString: "00:09:24:17",
      category: "CARS & WATCHES",
      minnumberoftickets: 10,
      entryValue: 9.90,
      competitionticketsize: 1000,
      totalticketsbought: 920,
      featured: null,
      manual: 1,
      instant: 0,
      imageUrls: [
        {
          UID: "1befb4554e8a19a1ce1e4f9e452eced8",
          competitionID: "4ae2681707eee9af9441e15ed0e8b4f0",
          imageurl: "/Images/patek.png",
        },
      ],
    },
    // {
    //     UID: "4ae2681707eee9af9441e15ed0e8b4f0",
    //     competitionname: "AVENTADOR",
    //     crDate: "2024-08-11T19:11:42.000Z",
    //     legalinformation: "bitter competition it is legal information",
    //     competitioninformation:
    //         "Best competition where winner will be awesome",
    //     competitionenddate: null,
    //     category: "INSTANT WINS",
    //     minnumberoftickets: 20,
    //     competitionticketsize: 1000,
    //     totalticketsbought: null,
    //     featured: null,
    //     manual: 1,
    //     instant: 1,
    //     imageUrls: [
    //         {
    //             UID: "1befb4554e8a19a1ce1e4f9e452eced8",
    //             competitionID: "4ae2681707eee9af9441e15ed0e8b4f0",
    //             imageurl:
    //                 "https://chickendinner-dev.s3.eu-west-2.amazonaws.com/4lCYESMKKrUva5Ry.png",
    //         },
    //     ],
    // },
    // WATCHES
    // {
    //     UID: "4ae2681707eee9af9441e15ed0e8b4f0",
    //     competitionname: "SABMARINER",
    //     crDate: "2024-08-11T19:11:42.000Z",
    //     legalinformation: "bitter competition it is legal information",
    //     competitioninformation:
    //         "Best competition where winner will be awesome",
    //     competitionenddate: null,
    //     category: "CARS & WATCHES",
    //     minnumberoftickets: 5,
    //     competitionticketsize: 1000,
    //     totalticketsbought: null,
    //     featured: null,
    //     manual: 1,
    //     instant: 0,
    //     imageUrls: [
    //         {
    //             UID: "1befb4554e8a19a1ce1e4f9e452eced8",
    //             competitionID: "4ae2681707eee9af9441e15ed0e8b4f0",
    //             imageurl:
    //                 "https://chickendinner-dev.s3.eu-west-2.amazonaws.com/4lCYESMKKrUva5Ry.png",
    //         },
    //     ],
    // },

    // instant

    {
      UID: "4ae2681707eee9af9441e15ed0e8b4f0",
      competitionname: "$50K INSTA WIN",
      crDate: "2024-08-11T19:11:42.000Z",
      legalinformation: "bitter competition it is legal information",
      competitioninformation: "Best competition where winner will be awesome",
      competitionenddate: null,
      isHighRoller: 0,
      rollerAmount: "",
      competitionEndDateString: "00:09:24:17",
      category: "INSTANT WINS",
      minnumberoftickets: 5,
      entryValue: 4.95,
      competitionticketsize: 1000,
      totalticketsbought: 320,
      featured: null,
      manual: 1,
      instant: 1,
      imageUrls: [
        {
          UID: "1befb4554e8a19a1ce1e4f9e452eced8",
          competitionID: "4ae2681707eee9af9441e15ed0e8b4f0",
          imageurl: "/Images/50k.png",
        },
      ],
    },
    {
      UID: "4ae2681707eee9af9441e15ed0e8b4f0",
      competitionname: "$500K INSTA WIN",
      crDate: "2024-08-11T19:11:42.000Z",
      legalinformation: "bitter competition it is legal information",
      competitioninformation: "Best competition where winner will be awesome",
      competitionenddate: null,
      isHighRoller: 0,
      rollerAmount: "",
      competitionEndDateString: "02:12:44:23",
      category: "INSTANT WINS",
      minnumberoftickets: 20,
      entryValue: 19.80,
      competitionticketsize: 1000,
      totalticketsbought: 320,
      featured: null,
      manual: 1,
      instant: 1,
      imageUrls: [
        {
          UID: "1befb4554e8a19a1ce1e4f9e452eced8",
          competitionID: "4ae2681707eee9af9441e15ed0e8b4f0",
          imageurl: "/Images/500k.png",
        },
      ],
    },
    {
      UID: "4ae2681707eee9af9441e15ed0e8b4f0",
      competitionname: "$100K INSTA WIN",
      crDate: "2024-08-11T19:11:42.000Z",
      legalinformation: "bitter competition it is legal information",
      competitioninformation: "Best competition where winner will be awesome",
      competitionenddate: null,
      rollerAmount: "",
      entryValue: 9.90,
      competitionEndDateString: "07:22:14:53",
      category: "INSTANT WINS",
      minnumberoftickets: 10,
      competitionticketsize: 1000,
      totalticketsbought: 320,
      featured: null,
      manual: 1,
      instant: 1,
      isHighRoller: 0,
      imageUrls: [
        {
          UID: "1befb4554e8a19a1ce1e4f9e452eced8",
          competitionID: "4ae2681707eee9af9441e15ed0e8b4f0",
          imageurl: "/Images/100k.png",
        },
      ],
    },

    // HIGH ROLLERS
    {
      UID: "4ae2681707eee9af9441e15ed0e8b4f0",
      competitionname: "5 ETH",
      crDate: "2024-08-11T19:11:42.000Z",
      legalinformation: "bitter competition it is legal information",
      competitioninformation: "Best competition where winner will be awesome",
      competitionenddate: null,
      isHighRoller: 1,
      competitionEndDateString: "04:52:40:11",
      category: "HIGH ROLLERS",
      minnumberoftickets: 150,
      rollerAmount: "HIGH ROLLERS",

      competitionticketsize: 1000,
      totalticketsbought: 50,
      featured: null,
      manual: 1,
      instant: 0,
      entryValue: 148.50,
      imageUrls: [
        {
          UID: "1befb4554e8a19a1ce1e4f9e452eced8",
          competitionID: "4ae2681707eee9af9441e15ed0e8b4f0",
          imageurl: "/Images/5-eth.png",
        },
      ],
    },
    {
      UID: "4ae2681707eee9af9441e15ed0e8b4f0",
      competitionname: "$50K USDC",
      crDate: "2024-08-11T19:11:42.000Z",
      legalinformation: "bitter competition it is legal information",
      competitioninformation: "Best competition where winner will be awesome",
      competitionenddate: null,
      isHighRoller: 1,
      rollerAmount: "HIGH ROLLERS",

      competitionEndDateString: "00:00:11:13",
      category: "HIGH ROLLERS",
      minnumberoftickets: 250,
      competitionticketsize: 1000,
      totalticketsbought: 980,
      featured: null,
      manual: 1,
      instant: 0,
      entryValue: 247.50,
      imageUrls: [
        {
          UID: "1befb4554e8a19a1ce1e4f9e452eced8",
          competitionID: "4ae2681707eee9af9441e15ed0e8b4f0",
          imageurl: "/Images/50k-usdc.png",
        },
      ],
    },
    {
      UID: "4ae2681707eee9af9441e15ed0e8b4f0",
      competitionname: "2 BTC",
      crDate: "2024-08-11T19:11:42.000Z",
      legalinformation: "bitter competition it is legal information",
      competitioninformation: "Best competition where winner will be awesome",
      competitionenddate: null,
      isHighRoller: 1,
      rollerAmount: "HIGH ROLLERS",

      competitionEndDateString: "00:32:44:19",
      category: "HIGH ROLLERS",
      minnumberoftickets: 500,
      competitionticketsize: 1000,
      totalticketsbought: 610,
      entryValue: 495.00,
      featured: null,
      manual: 1,
      instant: 0,
      imageUrls: [
        {
          UID: "1befb4554e8a19a1ce1e4f9e452eced8",
          competitionID: "4ae2681707eee9af9441e15ed0e8b4f0",
          imageurl: "/Images/2-btc-1.png",
        },
      ],
    },
    // NFTS
    {
      UID: "4ae2681707eee9af9441e15ed0e8b4f0",
      competitionname: "BORED APES",
      crDate: "2024-08-11T19:11:42.000Z",
      legalinformation: "bitter competition it is legal information",
      competitioninformation: "Best competition where winner will be awesome",
      competitionenddate: null,
      isHighRoller: 0,
      rollerAmount: "",
      entryValue: 4.95,
      competitionEndDateString: "02:12:44:23",
      category: "NFT'S",
      minnumberoftickets: 5,
      competitionticketsize: 1000,
      totalticketsbought: 700,
      featured: null,
      manual: 1,
      instant: 0,
      imageUrls: [
        {
          UID: "1befb4554e8a19a1ce1e4f9e452eced8",
          competitionID: "4ae2681707eee9af9441e15ed0e8b4f0",
          imageurl: "/Images/bored-1.png",
        },
      ],
    },
    {
      UID: "4ae2681707eee9af9441e15ed0e8b4f0",
      competitionname: "AXIE INFINITY",
      crDate: "2024-08-11T19:11:42.000Z",
      legalinformation: "bitter competition it is legal information",
      competitioninformation: "Best competition where winner will be awesome",
      competitionenddate: null,
      isHighRoller: 0,
      rollerAmount: "",
      entryValue: 1.98,
      competitionEndDateString: "07:22:14:53",
      category: "NFT'S",
      minnumberoftickets: 2,
      competitionticketsize: 1000,
      totalticketsbought: 250,
      featured: null,
      manual: 1,
      instant: 0,
      imageUrls: [
        {
          UID: "1befb4554e8a19a1ce1e4f9e452eced8",
          competitionID: "4ae2681707eee9af9441e15ed0e8b4f0",
          imageurl: "/Images/axie.png",
        },
      ],
    },
    {
      UID: "4ae2681707eee9af9441e15ed0e8b4f0",
      competitionname: "MEEBITS",
      crDate: "2024-08-11T19:11:42.000Z",
      legalinformation: "bitter competition it is legal information",
      competitioninformation: "Best competition where winner will be awesome",
      competitionenddate: null,
      isHighRoller: 0,
      rollerAmount: "",
      entryValue: 0.99,
      competitionEndDateString: "00:09:24:17",
      category: "NFT'S",
      minnumberoftickets: 1,
      competitionticketsize: 1000,
      totalticketsbought: 920,
      featured: null,
      manual: 1,
      instant: 0,
      imageUrls: [
        {
          UID: "1befb4554e8a19a1ce1e4f9e452eced8",
          competitionID: "4ae2681707eee9af9441e15ed0e8b4f0",
          imageurl: "/Images/meebits.png",
        },
      ],
    },
    // MEME COINS
    {
        UID: "4ae2681707eee9af9441e15ed0e8b4f0",
        competitionname: "$1K SOLANA",
        crDate: "2024-08-11T19:11:42.000Z",
        legalinformation: "bitter competition it is legal information",
        competitioninformation:
            "Best competition where winner will be awesome",
        competitionenddate: null,
        isHighRoller: 0,
        rollerAmount: "",
        competitionEndDateString: "01:41:23:03",
        category: "ALTCOINS",
        minnumberoftickets: 5,
        entryValue: 4.95,
        competitionticketsize: 1000,
        totalticketsbought: 700,
        featured: null,
        manual: 1,
        instant: 0,
        imageUrls: [
          {
              UID: "1befb4554e8a19a1ce1e4f9e452eced8",
              competitionID: "4ae2681707eee9af9441e15ed0e8b4f0",
              imageurl: "/Images/sol.png",
          },
      ],
    },
    {
      UID: "4ae2681707eee9af9441e15ed0e8b4f0",
      competitionname: "$5K POLYGON",
      crDate: "2024-08-11T19:11:42.000Z",
      legalinformation: "bitter competition it is legal information",
      competitioninformation: "Best competition where winner will be awesome",
      competitionenddate: null,
      isHighRoller: 0,
      rollerAmount: "",
      entryValue: 1.98,
      competitionEndDateString: "00:21:13:23",
      category: "ALTCOINS",
      minnumberoftickets: 1,
      competitionticketsize: 1000,
      totalticketsbought: 250,
      featured: null,
      manual: 1,
      instant: 0,
      imageUrls: [
        {
          UID: "1befb4554e8a19a1ce1e4f9e452eced8",
          competitionID: "4ae2681707eee9af9441e15ed0e8b4f0",
          imageurl: "/Images/pol.png",
        },
      ],
    },
    {
      UID: "4ae2681707eee9af9441e15ed0e8b4f0",
      competitionname: "$2K BNB",
      crDate: "2024-08-11T19:11:42.000Z",
      legalinformation: "bitter competition it is legal information",
      competitioninformation: "Best competition where winner will be awesome",
      competitionenddate: null,
      isHighRoller: 0,
      rollerAmount: "",
      entryValue: 0.99,
      competitionEndDateString: "10:09:24:17",
      category: "ALTCOINS",
      minnumberoftickets: 1,
      competitionticketsize: 1000,
      totalticketsbought: 920,
      featured: null,
      manual: 1,
      instant: 0,
      imageUrls: [
        {
          UID: "1befb4554e8a19a1ce1e4f9e452eced8",
          competitionID: "4ae2681707eee9af9441e15ed0e8b4f0",
          imageurl: "/Images/2k_bnb.png",
        },
      ],
    },
  ];

  const filterCompetitionsByCategory = (category: string) => {
    if (currentFilter != "") {
      return staticCompetitions.filter((competition) => {
        return competition.category.toLowerCase() === category.toLowerCase();
      });
    } else {
      return staticCompetitions;
    }
  };

  const getLiveCompetitions = (category: string) => {
    try {
      setLoader(true);

      // Filter competitions based on the provided category
      const filteredCompetitions = filterCompetitionsByCategory(category);

      const fc: CompetitionProps[] = [];
      const lc: CompetitionProps[] = [];

      filteredCompetitions.forEach((competition: any) => {
        // Assuming `finishedCompetition` is a utility function to check if competition is finished
        if (finishedCompetition(competition?.competitionenddate)) {
          fc.push(competition);
        } else {
          lc.push(competition);
        }
      });

      const sortedCompetitionList = lc.sort((a, b) => {
        let dateA = new Date(a.competitionenddate || 0) as any;
        let dateB = new Date(b.competitionenddate || 0) as any;
        return dateA - dateB;
      });

      setFinishedCompetitions(fc);
      setCompetitionsList(sortedCompetitionList);
      setDisplayedAllCompetitions(sortedCompetitionList.slice(0, itemsPerPage));

      setLoader(false);
    } catch (error) {
      console.log("error", error);
      setLoader(false);
    }
  };

  const fetchMoreCompetitions = async () => {
    if (allCompetitionsLoaded) return;
    const nextDisplayedItems = competitionsList.slice(
      0,
      displayedAllCompetitions.length + itemsPerPage
    );
    setDisplayedAllCompetitions(nextDisplayedItems); // Update displayed items

    setAllCompetitionsLoaded(
      nextDisplayedItems.length === competitionsList.length
    ); // Check if all competitions are loaded
  };
  const handleLoadMoreClick = () => {
    fetchMoreCompetitions();
  };

  return (
    <div className="">
      <ConfirmationPopup></ConfirmationPopup>
      <div className="hero p-10 sm:p-[12.61px] md:p-7 sm:pb-0 md:pb-0">
        {/* <Slider2 /> */}
        {/* <Hero /> */}

        <HeroSlider key={competitionsList.length} />

        <div className="w-full flex flex-col items-center max-w-[1500px] m-auto ">
          <h1
            className={`pageHeading font-['Sequel100Wide95']`}
            style={{
              fontSize: "clamp(1px,5.529128597vw,45px)",
            }}
          >
            HOW IT WORKS
          </h1>

          <div className="flex container flex-shrink max-w-[100%] sm:hidden max-911:hidden mb-[49.55px]">
            {howItWorks.map((item, index) => (
              <>
                <HowItWorksBox
                  icon={item.icon}
                  title={item.title}
                  content={item.content}
                  key={index}
                />
                {index + 1 !== howItWorks.length && (
                  <div
                    className="min-w-[5%] flex justify-center items-center"
                    key={index}
                  >
                    <img
                      src="/Images/next_white.png"
                      alt=""
                      className="object-scale-down "
                    />
                  </div>
                )}
              </>
            ))}
          </div>

          {/* how it works mobile */}
          <div className="sm:block max-911:block hidden">
            {/* <Slider slides={howItWorks} options={OPTIONS} /> */}
            <Carousel>
              {howItWorks.map((item, index) => (
                <HowItWorksBoxSmall
                  icon={item.icon}
                  title={item.title}
                  content={item.content}
                  key={index}
                />
              ))}
            </Carousel>
          </div>
        </div>
      </div>

      <div className="competitions p-[2em] sm:pt-[1em] md:pt-[1em] max-1331:pt-[1em] flex flex-col items-center bg-bg_small">
        <h1
          className="flex //text-[40px] font-[Sequel100WideVF] text-primary my-[1em] mt-0 //sm:text-[20px] sm:mb-[0.5em]"
          style={{ fontSize: "clamp(1px,5.361012052vw,45px)" }}
        >
          LIVE COMPETITIONS
        </h1>

        <Categorybar
          filters={filters}
          changeFilter={changeFilter}
          currentFilter={currentFilter}
        />
        {loader && (
          <div className="flex justify-center items-center w-full h-[200px]">
            <CircularProgress />
          </div>
        )}
        {!loader && competitionsList.length === 0 && (
          // finishedCompetitions?.length !== 0 &&
          <div className="w-full mt-[5em]">
            <div
              className={`text-[2rem] font-[Sequel100WideVF55] sm:text-[20px] flex flex-col h-[10rem]  relative  w-full sm:w-full rounded-2xl text-center justify-center text-primary`}
            >
              No Competitions in this Category is Found. Please try another
              category.
            </div>
          </div>
        )}
        {/* <div className="grid 1366:scale-[0.97] 1366:w-max grid-cols-4  gap-8 gap-y-10 sm:grid-cols-2 sm:gap-6 sm:mt-[3em] w-full mt-[4em] md:grid-cols-2"> */}
        <div className="xl:grid lg:grid max-1331:grid-cols-2 md:grid-cols-2 between-641-800:grid-cols-1 grid-cols-3 sm:grid-cols-1 lg:grid-cols-2 md:grid hidden max-911:hidden sm:min-w-[100%]  between-200-299:min-w-[100%]   xl:gap-x-[27.59px] xl:gap-y-[53px] gap-x-[13.63px] gap-y-[11.92px] m-auto w-max max-w-[100%] *:m-auto mt-[3em]">
          {!loader &&
            displayedAllCompetitions.map(
              (competition, index) =>
                !finishedCompetition(competition?.competitionenddate) && (
                  //   <Competitions
                  //     {...competition}
                  //     // isHighRoller={
                  //     //     competition?.categroy ===
                  //     //     "HIGH ROLLERS"
                  //     // }
                  //     onClick={() => loadCompetition(competition?.UID)}
                  //     key={index}
                  //     width={"w-[244px]"}
                  //   />
                  <CompetitionCard {...competition} key={index} />
                )
            )}
        </div>
        <div className="xl:hidden lg:hidden md:hidden max-911:block block mt-[40.37px]">
          <Carousel>
            {competitionsList.map((competition, index) => (
              // <Competitions
              //     {...competition}
              //     // isHighRoller={
              //     //     competition?.categroy === "HIGH ROLLERS"
              //     // }
              //     onClick={() =>
              //         loadCompetition(competition?.UID)
              //     }
              //     key={index}
              //     width={"w-[244px]"}
              // />
              <CompetitionCard {...competition} />
            ))}
          </Carousel>
        </div>
        <div className="sm:hidden max-911:hidden">
          {competitionsList.length > displayedAllCompetitions.length &&
            !allCompetitionsLoaded && (
              <Button
                content="Load More"
                onClick={handleLoadMoreClick}
                className="text-black font-[Sequel100Wide95] text-[24px]
            sm:text-[3vw]
            sm:w-[80%]
            uppercase text-center px-[20px] py-[10px] mt-[23px] rounded-[30px]"
              />
            )}
        </div>
      </div>

      <div>
        <AdvertBox />
      </div>
      <PhoneStores background="" />
    </div>
  );
};

export default WithNavbarAndFooter(LandingPage);
