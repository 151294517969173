import React, { useEffect } from "react";
import { Container, getSize, Text } from "../react-responsive-utilities/src";
import moment from "moment";
import Countdown from "react-countdown";
import { useNavigate } from "react-router-dom";
import VSpacer from "../common/Spacer/VSpacer";
import { Common } from "../common/Common";
import HSpacer from "../common/Spacer/HSpacer";
export interface CompetitionImages {
  UID: string;
  competitionID: string;
  imageurl: string;
}
export interface CompetitionProps {
  live?: boolean;
  time?: { days: number; hours: number; minutes: number; seconds: number };
  prizeName?: string;
  price?: number;
  ticketsLeft?: number;
  prizeIcon?: string;
  onClick?: (ticket: string) => void;
  timerBg?: string;
  borderColor?: string;
  finished?: boolean;
  width?: string;
  overlay?: boolean;
  UID?: string;
  categroy?: string;
  competitionenddate?: Date;
  competitionEndDateString?: string;
  competitioninformation?: string;
  competitionname?: string | undefined;
  competitionticketsize?: number | undefined;
  featured?: number;
  imageurl?: string;
  legalinformation?: string;
  manual?: string;
  totalticketsbought?: number | undefined;
  numberofticketsbought?: number | undefined;
  minnumberoftickets?: number | undefined;
  isHighRoller?: boolean;
  instant?: boolean;
  rollerAmount?: string;

  isCompetitionFinished?: boolean;
  imageUrls?: CompetitionImages[];
  entryValue?: string;
}
export const Competitions: React.FC<CompetitionProps> = ({
  live,
  time,
  prizeName,
  price,
  prizeIcon,
  onClick,
  borderColor,
  finished,
  overlay,
  UID,
  categroy,
  competitionenddate,
  competitionEndDateString,
  competitioninformation,
  competitionname,
  competitionticketsize,
  featured,
  imageurl = "/Images/No Image.svg",
  legalinformation,
  manual,
  totalticketsbought,
  minnumberoftickets,
  isHighRoller,
  instant,
  isCompetitionFinished = false,
  imageUrls,
  rollerAmount,
  entryValue,
}) => {
  const sold = 700;

  const finishedCompetition = () => {
    const currentDate = new Date();
    const competitionEndDate = new Date(competitionenddate ?? new Date());
    if (currentDate > competitionEndDate) {
      return true;
    } else {
      return false;
    }
  };

  const ticketSold = () => {
    if (totalticketsbought && competitionticketsize) {
      const sold = ((totalticketsbought / competitionticketsize) * 100).toFixed(
        0
      );
      return sold;
    } else {
      return 0;
    }
  };

  useEffect(() => {
    const data = [
      {
        UID,
        competitionname,
        ticketSold,
        totalticketsbought,
        competitionticketsize,
      },
    ];
  }, [totalticketsbought, competitionticketsize]);

  const navigate = useNavigate();

  const navigateToResult = (competition?: any, UID?: string) => {
    navigate(`/competitionResult/${UID}`);
  };

  function loadCompetition(UID?: string) {
    const isLogged = localStorage.getItem("token");
    if (isLogged) {
      if (instant) {
        navigate(`/view/competition/instant-win/${UID}`);
      } else if (isCompetitionFinished) {
        navigate(`/finished-competition/${UID}`);
        // navigate(`/competition/${UID}`);
      } else {
        navigate(`/live-competition/${UID}`);
      }
    }
  }
  const handleClick = (ticket: string) => {
    const isLogged = localStorage.getItem("token");
    if (isLogged !== null && finishedCompetition()) {
      navigateToResult(competitionname, UID);
    } else {
      loadCompetition(UID);
    }
  };

  function getRemainingTimeFormatted(targetDate: Date): string {
    const now = new Date();
    const difference = targetDate.getTime() - now.getTime();

    if (difference <= 0) {
      return "00:00:00:00"; // If the target date has passed or is now
    }

    const seconds = Math.floor((difference / 1000) % 60);
    const minutes = Math.floor((difference / 1000 / 60) % 60);
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));

    // Format each unit to ensure two digits
    const formattedTime = [
      days.toString().padStart(2, "0"),
      hours.toString().padStart(2, "0"),
      minutes.toString().padStart(2, "0"),
      seconds.toString().padStart(2, "0"),
    ].join(":");

    return formattedTime;
  }

  const getTimeLeft = () => {
    const now = moment();
    const end = moment(competitionenddate);
    const duration = moment.duration(end.diff(now));

    if (duration.asSeconds() > 0) {
      const days = Math.floor(duration.asDays());
      const hours = Math.floor(duration.hours());
      const minutes = Math.floor(duration.minutes());
      const seconds = Math.floor(duration.seconds());

      // Formatting each unit with leading zeros if needed
      const formattedDays = String(days).padStart(2, "0");
      const formattedHours = String(hours).padStart(2, "0");
      const formattedMinutes = String(minutes).padStart(2, "0");
      const formattedSeconds = String(seconds).padStart(2, "0");

      return `${formattedDays}:${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    } else {
      return "00:00:00:00";
    }
  };

  return (
    // <Container
    //   width={[313.72, 333.72, 425.39]}
    //   height={[474.34, 474.34, 604.63]}
    //   className=" flex flex-col justify-end relative overflow-hidden max-w-[100%] sm:min-w-[80%] sm:!h-[100vw] sm:!box-border "
    // >
    <div className=" flex flex-col justify-end relative overflow-hidden h-[640px] w-[426.38px] sm:min-w-[80%] sm:h-[526.09px] //sm:h-[100vw] sm:!box-border ">
      <Container
        width={[0, 140.34, 243.31]}
        height={[19.42, 19.42, 33.67]}
        className={
          "absolute top-0 !h-[45.04px] !w-[calc(100%-51.76px)] rounded-full flex flex-row justify-between *:m-auto left-2/4 -translate-x-2/4 z-20 sm:!w-[80%] sm:!h-[8%] overflow-hidden " +
          (finishedCompetition() ? "bg-[#EF008F]" : "bg-white")
        }
      >
        {finishedCompetition() ? (
          <Text
            textSize={[0, 12.59, 16]}
            className="text-black font-[Sequel100Wide95] //sm:!text-[3vw] text-nowrap"
          >
            COMPETITION FINISHED
          </Text>
        ) : (
          <>
            {/* timer */}
            <div>
              {/* clock */}
              <img
                src="/Images/clock.svg"
                alt=""
                className="w-[25.62px] h-[28.96px]"
              />
            </div>
            <div>
              {/* counter */}
              <Text
                textSize={[0, 17.07, 19.6]}
                className="block font-[MontBlancBlack] xl:font-[29] lg:font-[29] md:font-[24] sm:font-[20]"
              >
                <p>{competitionEndDateString}</p>
              </Text>
            </div>
            <div className="*:leading-3 font-[MontBlancBlack]">
              <img src="/Images/clock.svg" alt="" className="w-auto" />
            </div>
          </>
        )}
      </Container>

      {/* <Container
        height={[474.34 + 20.67, 474.34 + 20.67, 604.63 - 45.04 / 2]}
        width={[0, "full", "full"]}
        doNotResizeWidth
        className="w-full relative z-10  rounded-[20px] border-2 border-white overflow-hidden sm:!h-[95%]"
      > */}
      <div className=" h-[calc(100%-23px)] z-10  rounded-[20px] border-2 border-white overflow-hidden ">
        {/* sub container */}
        {/* <Container
          height={[674.34, 474.34, 604.63 - 254.63 + 18]}
          width={[0, "full", "full"]}
          doNotResizeWidth
          className=" w-full overflow-hidden relative sm:!h-[calc((100%-254.63px)+50px)] !h-[calc((100%-254.63px)+28px)]"
        > */}
        <div
          className="
        //sm:!h-[calc((100%-254.63px)+50px)]
        //!h-[calc((100%-254.63px)+28px)]
        h-1/2
        w-full overflow-hidden relative
        
        "
        >
          {/* image */}
          <img
            src={
              imageUrls && imageUrls[0]?.imageurl
              // imageUrls &&
              // imageUrls[0]?.imageurl?.includes("http")
              //     ? imageUrls[0]?.imageurl
              //     : "/Images/No Image.svg"
            }
            onError={(e) => {
              e.currentTarget.src = "/Images/No Image.svg";
            }}
            onEnded={(e) => {
              e.currentTarget.src = "/Images/No Image.svg";
            }}
            alt=""
            className="w-full object-cover object-center //absolute top-0 h-full"
          />
        </div>
        {/* </Container> */}
        <div className="//absolute //bottom-0 //left-0 w-full">
          {Boolean(instant) && (
            <div className="grid grid-cols-3 gap-16 grid-rows-1 w-[calc(100%-29.1px)] place-content-center place-items-center m-auto bg-white rounded-t-[26.75px] h-[40.68px]">
              <img src="/Images/gift.svg" alt="" className="h-[25.42px]" />
              <p className="font-[Sequel100Wide95] text-nowrap text-black uppercase text-[20.07] sm:text-[clamp(0px,14px,15.72px)]">
                Instant Win
              </p>
              <img src="/Images/gift.svg" alt="" className="h-[25.42px]" />
            </div>
          )}

          {Boolean(isHighRoller) && (
            <div className="grid grid-cols-3 gap-16 grid-rows-1 w-[calc(100%-29.1px)] place-content-center place-items-center m-auto bg-[#EF008F] rounded-t-[26.75px] h-[40.68px]">
              {rollerAmount == "HIGH ROLLERS" ? (
                <img
                  src="/Images/roller_dice.svg"
                  alt=""
                  className="xl_lg:h-[25.42px] md:h-[22.42px] sm:h-[20.42px]"
                />
              ) : (
                <img
                  src="/Images/roller_image.svg"
                  alt=""
                  className="xl_lg:h-[25.42px] md:h-[22.42px] sm:h-[35%]"
                />
              )}
              <p className="font-[Sequel100Wide95] text-nowrap text-white uppercase text-[20.07] sm:text-[clamp(0px,14px,15.72px)]">
                {rollerAmount}
              </p>
              {rollerAmount == "HIGH ROLLERS" ? (
                <img
                  src="/Images/roller_dice.svg"
                  alt=""
                  className="xl_lg:h-[25.42px] md:h-[22.42px] sm:h-[20.42px]"
                />
              ) : (
                <img
                  src="/Images/roller_image.svg"
                  alt=""
                  className="xl_lg:h-[25.42px] md:h-[22.42px] sm:h-[35%]"
                />
              )}
            </div>
          )}
          {/* <Container
            width={[0, "full", "full"]}
            doNotResizeWidth
            height={[254.63, 254.63, 474.34]}
            className=" bg-[#343434] px-[13.44px] rounded-[20px] //sm:!h-[40%] sm:!flex sm:!flex-col sm:!justify-between //sm:!py-4 !h-fit"
          > */}
          <div className="bg-[#343434] sm:!h-[248.25px] px-[13.44px] rounded-[20px] //sm:!h-[40%] sm:!flex sm:!flex-col sm:!justify-between //sm:!py-4 !h-fit">
            <VSpacer small={19.21} big={21.72} />
            {/* container for details */}
            <Container
              // width={[0, 197.93, 291.13]}
              width={[0, "full", "full"]}
              doNotResizeWidth
              height={[26.37, 26.37, 45.72]}
              className="rounded-full bg-[#FFFFFF] m-auto //mt-[9.36px] flex items-center justify-center sm:h-[50px] sm:!my-0 !h-[54.08px]" /////////////////////////////////////////////////////////////////////////////
              // style={{
              //   marginTop: "clamp(9.37px, 0vw, 16.23px)",
              // }}
            >
              {/* competition name */}
              <Text
                textSize={[0, 23.66, 24.08]}
                className="text-center text-black block font-[Sequel100Wide95] truncate px-[19.41px] sm:!text-[3.5vw] "
              >
                {competitionname}
              </Text>
            </Container>
            <VSpacer small={14.65} big={16.58} />
            <div
              className="w-full flex flex-col gap-[5.21px] //sm:!mt-[14%]"
              // style={{
              //   marginTop: "clamp(7.14px, 0vw, 12.4px)",
              // }}
            >
              {/* progressbar */}

              <Container
                //width={[0, 161.51, 280]}
                width={[0, "full", "full"]}
                doNotResizeWidth
                height={[2.88, 2.88, 5]}
                className="progressbar"
              >
                <div
                  style={{
                    height: "100%",
                    width: `${ticketSold()}%`,
                    transition: "width 0.5s",
                  }}
                  className="bg-secondary"
                ></div>
              </Container>
              {/* <span className="text-white text-[15px]  //text-[11px] sm:text-[9px] between-200-299:text-[0.3rem] font-[MontBlancRegular] uppercase">
              {ticketSold()} <span className="font-[MontBlancRegular]">%</span>{" "}
              tickets sold
            </span> */}
              <Text
                className="text-white font-[Sequel100Wide-65] uppercase sm:!text-[4vw] text-center"
                textSize={[0, 18, 20.07]}
              >
                {ticketSold()}{" "}
                <span className="font-[Sequel100Wide-65]">%</span> tickets sold
              </Text>
            </div>
            <VSpacer small={14.79} big={16.13} />
            {finishedCompetition() ? (
              <>
                <Container
                  height={[34.03, 61.92, 78.69]}
                  //width={[0, 135.55 + 32.14, 235 + 55.72]}
                  width={[0, "full", "full"]}
                  // style={{
                  //   marginTop: "clamp(6.91px, 0vw, 12.79px)",
                  // }}
                  // onClick={(e) => {
                  //   e.preventDefault();
                  //   handleClick(UID ?? "");
                  // }}
                  className="flex items-center w-full mt-[-0.3em] p-3 ticketPriceBox rounded-[15px] //hover:cursor-pointer between-200-299:h-5 "
                >
                  {/* <h3 className="text-[25px] //sm:text-[12px]  font-['Sequel100Wide'] text-black //text-[#373635] mx-auto  sm:!text-[5vw]">
                  FINISHED
                </h3> */}
                  <Text
                    textSize={[0, 26.24, 33.04]}
                    className=" font-['Sequel100Wide'] text-black //text-[#373635] mx-auto"
                  >
                    FINISHED
                  </Text>
                </Container>
                <Container
                  height={[0, 26.24, 48.01]}
                  //width={[0, 305.38, 388.1]}
                  width={[0, "full", "full"]}
                  className="mt-[12px] !w-full bg-[#767676] flex justify-center items-center rounded-[13.34px] cursor-pointer"
                  onClick={(e) => {
                    loadCompetition(UID ?? "");
                  }}
                >
                  <Text
                    textSize={[0, 12.24, 16]}
                    className="uppercase font-[Sequel100WideVF55]"
                  >
                    CLICK HERE FOR DETAILS
                  </Text>
                </Container>
              </>
            ) : (
              <>
                <Container
                  height={[34.03, 34.03, 56.95]}
                  //width={[0, 135.55 + 32.14, 235 + 55.72]}
                  width={[0, "full", "full"]}
                  className="//sm:h-[50px] bg-[#161616] flex rounded-full   //sm:!h-1/3 //!h-[69.78px]"
                  // style={{
                  //   marginTop: "clamp(6.91px, 0vw, 12.79px)",
                  // }}
                >
                  <HSpacer small={13} big={36} />
                  {/* buying */}
                  <div className=" w-1/2 flex justify-center items-center flex-col py-[8px] ">
                    {/* price */}

                    <Text
                      textSize={[0, 11.51, 14]}
                      className="text-white uppercase leading-none  font-[Sequel100Wide-65] xl:text-[29] lg:text-[29] md:text-[22] //font-[MontBlancRegular] sm:text-[18]"
                    >
                      min tickets:
                    </Text>
                    <Text
                      textSize={[0, 17.54, 21.3]}
                      className="text-[#FFFFFF] leading-none font-[MontBlancBlack] xl:text-[49] lg:text-[49] sm:text-[36]"
                    >
                      {/* $0.99 */}
                      {minnumberoftickets}
                    </Text>
                  </div>
                  <HSpacer small={13} big={16} />
                  {/* <Container
                    width={[206.65, 206.65, 233.71]}
                    height={[0, "full", "full"]}
                    doNotResizeHeight
                    className="w-full sm:!w-full py-[8px] bg-[#dde406] border-2 border-white rounded-full shadow-[6px_6px_16px_rgba(0,0,0,1)] "
                    // className="w-1/2"
                  > using div instead of container */}
                  <div
                    className="w-full py-[8px] bg-[#dde406] border-2 border-white rounded-full shadow-[6px_6px_16px_rgba(0,0,0,1)]"
                    onClick={(e) => {
                      e.preventDefault();
                      handleClick(UID ?? "");
                    }}
                  >
                    {/* button */}

                    <div className="w-full h-full flex items-center justify-center flex-col">
                      {/* price */}

                      {/* <Text
                          textSize={[0, 11.51, 14]}
                          className="text-black uppercase leading-none xl:text-[29] lg:text-[29] md:text-[22] font-[Sequel100Wide-65] sm:text-[18] block "
                        > */}
                      {/* entry value: */}
                      {/* </Text> */}
                      {/* <Text
                          textSize={[0, 17.54, 21.3]}
                          className="text-black leading-none font-[MontBlancBlack] xl:text-[49] lg:text-[49] sm:text-[36] block "
                        > */}
                      {/* $4.95 */}
                      {/* {entryValue} */}
                      {/* </Text> */}
                      <Common.typography
                        className="text-black uppercase leading-none xl:text-[29] lg:text-[29] md:text-[22] font-[Sequel100Wide-65] sm:text-[18] block "
                        fontFamily="Sequel100Wide-65"
                        fontSize="14px"
                        fontSizeSmall="11.51px"
                      >
                        entry value:
                      </Common.typography>
                      <Common.typography
                        className="text-black leading-none font-[MontBlancBlack] xl:text-[49] lg:text-[49] sm:text-[36] block "
                        fontFamily="MontBlancBlack"
                        fontSize="21.3px"
                        fontSizeSmall="17.51px"
                      >
                        ${entryValue}
                      </Common.typography>
                    </div>
                  </div>
                </Container>

                <VSpacer small={14.65} big={16.56} />

                <Container
                  height={[32.03, 56.92, 72.69]}
                  width={[0, "full", "full"]}
                  className="flex items-center w-full mt-[-0.3em] p-3 bg-customGrey border-2 border-white rounded-[66px] //hover:cursor-pointer between-200-299:h-5  bg-[#dde406]"
                >
                  <Text
                    textSize={[0, 24.24, 30.04]}
                    className=" font-['Sequel100Wide'] text-black //text-[#373635] mx-auto font-[23]"
                  >
                    BUY TICKETS
                  </Text>
                </Container>
              </>
            )}

            <VSpacer small={14.65} big={16.56} />
            {/* </Container> */}
          </div>
        </div>
        {/* </Container> */}
      </div>
      {/* </Container> */}
    </div>
  );
};
