// the main idea is it scrolls to specified ref but it goes little upward too based on the height of an any element so that if that element is position fixed, it won't hide the content of the div, it uses useRef of react to get the height of the element and then scrolls to the div with the specified ref.

import { RefObject } from "react";

export const scrollTo = (
  elementToScrollTo: RefObject<HTMLDivElement>,
  elementIdThatIsFixed: string
) => {
  const elementToScrollToHeight = elementToScrollTo.current?.offsetHeight;
  const elementIdThatIsFixedHeight =
    document.getElementById(elementIdThatIsFixed)?.offsetHeight;
  const elementIdThatIsFixedTop = document
    .getElementById(elementIdThatIsFixed)
    ?.getBoundingClientRect().top;
  const elementToScrollToTop =
    elementToScrollTo.current?.getBoundingClientRect().top;
  if (elementToScrollToHeight && elementIdThatIsFixedHeight) {
    window.scrollTo({
      top: elementToScrollToTop! - elementIdThatIsFixedHeight - 20,
      behavior: "smooth",
    });
  }
};
