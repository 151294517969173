import Countdown from "react-countdown";
import { Text } from "../../../react-responsive-utilities/src";
import VSpacer from "../../../common/Spacer/VSpacer";

interface TimerProps {
  competitionEndDate: Date;
}

const Unit = ({
  value,
  unit,
  isLast = false,
}: {
  value: string;
  unit: string;
  isLast?: boolean;
}) => (
  <div>
    <div className="flex *:m-1 justify-center items-center">
      <div className="px-[clamp(0px,2vw,13px)] flex max-w-[45.17px] min-h-[clamp(0px,3vw,45px)] bg-white w-full text-center justify-center items-center rounded-[10px]">
        <Text
          textSize={[16, 21.93, 27.61]}
          className="text-[#2E2122] font-['MontBlancBlack'] leading-[29px] font-[218]"
        >
          {value[0]}
        </Text>
      </div>
      <div className="px-[clamp(0px,2vw,13px)] flex max-w-[45.17px] min-h-[clamp(0px,3vw,45px)] bg-white w-full text-center justify-center items-center rounded-[10px]">
        <Text
          textSize={[16, 21.93, 27.61]}
          className="text-[#2E2122] font-['MontBlancBlack'] leading-[29px] font-[218]"
        >
          {value[1]}
        </Text>
      </div>
      {!isLast && (
        <Text
          textSize={[16, 21.93, 27.61]}
          className={`text-[#FFFFFF] font-['MontBlancBlack'] leading-[29px] font-[218] block ${
            unit === "Hours" ? "lg:hidden" : ""
          }`}
        >
          :
        </Text>
      )}
    </div>

    <Text
      textSize={[0, 14.93, 16.0]}
      className="text-[#FFFFFF] font-['MontBlancRegular'] font-semibold leading-[20px] //font-[120] uppercase"
    >
      {unit}
    </Text>
  </div>
);

export default function Timer(props: TimerProps) {
  return (
    <div className="w-fit">
      <Text
        textSize={[18, 20.0, 27.07]}
        className="
              text-[#FFFFFF] font-['Sequel100Wide95'] leading-[24px] font-[60] block sm_md:text-center"
      >
        Time Remaining
      </Text>
      <VSpacer small={15.5} big={22.11} />
      <div className="flex">
        <Countdown
          date={props.competitionEndDate}
          renderer={({ formatted }) => (
            // <div className="grid grid-cols-4 lg:grid-cols-2">
            <div className="flex flex-wrap">
              <Unit value={formatted?.days} unit="Days" />

              <Unit value={formatted?.hours} unit="Hours" />

              <Unit value={formatted?.minutes} unit="Minutes" />

              <Unit isLast value={formatted?.seconds} unit="Secs" />
            </div>
          )}
        />
      </div>
    </div>
  );
}
