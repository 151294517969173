import React from "react";

export interface HowItWorksBoxProps {
    icon: string;
    title: string;
    content: string;
}

const HowItWorksBoxSmall: React.FC<HowItWorksBoxProps> = ({
    icon,
    title,
    content,
}) => {
    return (
        <div className="relative overflow-hidden text-center m-0 bg-secondary //h-[50vh] max-w-[549px] w-[95%] !h-[297px] rounded-[18.61px] flex flex-col justify-center">
            <div className="flex flex-col justify-start items-center h-[80%] w-[90%] m-auto">
                <img
                    src={icon}
                    alt=""
                    className="h-[79.74px] w-[79.13px] mb-[16px]"
                />
                <p className="font-[Sequel100Wide95] text-white mb-[14px] text-[20px]">
                    {title}
                </p>
                <p className="font-[Sequel100WideVF45] text-white text-[14px]">
                    {content}
                </p>
            </div>
            <img
                src="/Images/wheel_small.svg"
                alt=""
                className="w-[80%] scale-75 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[1] opacity-50"
            />
        </div>
    );
};

export default HowItWorksBoxSmall;
