import { Common } from "../../../common/Common";

interface ViewBtnProps {
  onClick?: () => void;
}
export default function ViewBtn(props: ViewBtnProps) {
  return (
    <Common.button
      onClick={props.onClick}
      fontFamily="Sequel100Wide85"
      fontSize="18px"
      fontSizeSmall="18px"
      maxWidthBig="100%"
      maxWidthSmall="100%"
      heightBig="51.93px"
      heightSmall="51.93px"
      paddingX="1rem"
      text="View Competition"
      className="flex items-center justify-center"
      backgroundColor="#DDE404"
      roundness="12.05px"
      color="#000"
    />
  );
}
