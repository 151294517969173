import { Common } from "../../../common/Common";

export default function Result() {
  return (
    <Common.typography
      fontFamily="Sequel100Wide95"
      fontSize="45px"
      fontSizeSmall="25px"
      color="#fff"
    >
      Result:
    </Common.typography>
  );
}
