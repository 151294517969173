import { Common } from "../../common/Common";
import VSpacer from "../../common/Spacer/VSpacer";

export default function Description() {
  return (
    <Common.typography
      fontFamily="MontBlancRegular"
      fontSize="19.5px"
      fontSizeSmall="14px"
      color="#ffffff"
      className="sm_md:text-center"
    >
      Our platform uses our own state-of-the-art Random Number Generator (RNG)
      leveraging blockchain technology to ensure the result of each competition
      is provably fair and unbiased. This system operates independently to
      produce verified, random outcomes every time via{" "}
      {
        <a
          className="underline font-[MontBlancBold] "
          href="https://docs.chain.link/vrf"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          Chainlink VRF (Verifiable Random Function)
        </a>
      }
      .
      <VSpacer big={33} small={20} />
      To maintain transparency and quality, using the RNG involves a small fee.
      This fee covers the costs associated with sourcing a secure, third-party
      RNG system that meets the highest standards for accuracy and reliability.
      We cover all cost associated with our own draws. However if you would like
      to use the THEPRIZE.IO RNG yourself you can do so by connecting your
      wallet and paying the associated fee.
    </Common.typography>
  );
}
