interface LeftProps {
  children: React.ReactNode;
}
export default function LeftContainer(props: LeftProps) {
  return (
    <div className="flex flex-col items-center justify-center px-[37px]">
      {props?.children}
    </div>
  );
}
