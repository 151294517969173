import { useEffect, useState } from "react";
import WithNonAbsolutedSidebarNavbarFooter from "../../../Layout/WithNonAbsolutedSidebarNavbarFooter";
import VSpacer from "../../../Components/common/Spacer/VSpacer";
import CategoryContainer from "../../../Components/User Pages Components/ViewEntry/Containers/Category/CategoryContainer";
import Category from "../../../Components/User Pages Components/ViewEntry/Others/Category";
import DetailsContainer from "../../../Components/User Pages Components/ViewEntry/Containers/Details/DetailsContainer";
import FirstSection from "../../../Components/User Pages Components/ViewEntry/Containers/Details/FirstSection";
import { Common } from "../../../Components/common/Common";
import Heading from "../../../Components/User Pages Components/ViewEntry/Texts/Heading";
import Title from "../../../Components/User Pages Components/ViewEntry/Texts/Title";
import Description from "../../../Components/User Pages Components/ViewEntry/Texts/Description";
import Timer from "../../../Components/User Pages Components/ViewEntry/Others/Timer";
import ViewBtn from "../../../Components/User Pages Components/ViewEntry/Buttons/ViewBtn";
import SecondSection from "../../../Components/User Pages Components/ViewEntry/Containers/Details/SecondSection";
import TicketSectionText from "../../../Components/User Pages Components/ViewEntry/Texts/TicketSectionText";
import Ticket from "../../../Components/User Pages Components/ViewEntry/Others/Ticket";
import BackBtn from "../../../Components/User Pages Components/ViewEntry/Buttons/BackBtn";
import { useNavigate, useParams } from "react-router-dom";
import { getCompetitionById } from "../../../Services/userService";

export default function ViewEntry() {
  const [competition, setCompetition] = useState<any>();
  useEffect(() => {
    document.getElementById("view_entry_main")?.scrollIntoView();
  }, []);
  const params = useParams();
  useEffect(() => {
    getCompetitionById({
      competitionid: params["id"],
    }).then((res: any) => {
      setCompetition(res.data);
    });
  }, []);

  const navigate = useNavigate();
  const tickets = [
    "000001",
    "000001",
    "000001",
    "000001",
    "000001",
    "000001",
    "000001",
    "000001",
    "000001",
    "000001",
    "000001",
    "000001",
    "000001",
    "000001",
    "000001",
    "000001",
  ];
  return (
    <WithNonAbsolutedSidebarNavbarFooter
      Component={
        <div
          id="view_entry_main"
          className="overflow-hidden box-border bg-black w-full sm_md_lg:mx-auto sm_md_lg:w-[calc(100%-20px)]  sm_md_lg:my-[20px] xl:my-8 h-full mx-[43px] rounded-[30px] py-[63px] px-[78px] sm_md:py-[44px] sm_md:px-[11px] flex flex-col items-center justify-center "
        >
          <Heading />
          <VSpacer big={60} small={28} />
          <CategoryContainer>
            <Category
              text="Live Competitions"
              isActive={true}
              onClick={() => {
                navigate("/entries/live");
              }}
            />
            <Category
              text="Finished Competitions"
              isActive={false}
              onClick={() => {
                navigate("/entries/finished");
              }}
            />
          </CategoryContainer>
          <VSpacer big={75} small={27.92} />
          <DetailsContainer>
            <FirstSection
              left={
                <Common.image
                  src="/Images/leftAvatar.svg"
                  height="383.06px"
                  width="393.92px"
                  heightSmall="317px"
                  //widthSmall="326px"
                  widthSmall="100%"
                  alt="Left Avatar"
                />
              }
              right={
                <div className="sm_md:flex sm_md:flex-col sm_md:justify-center sm_md:items-center">
                  <Title text={competition?.competitionname} />
                  <VSpacer big={14.92} small={20.92} />
                  <div className="bg-[#EF008F] h-[4.53px] w-[289.95px] sm_md:w-full" />
                  <VSpacer big={13.58} small={16.55} />
                  <Description text={competition?.competitioninformation} />
                  <VSpacer big={34.1} small={24} />
                  <Timer
                    competitionEndDate={
                      new Date(competition?.competitionenddate)
                    }
                  />
                  <VSpacer big={46.77} small={23} />
                  <ViewBtn
                    onClick={() => {
                      navigate(`/competition/${competition?.UID}`);
                    }}
                  />
                  <VSpacer big={51.07} small={27.07} />
                </div>
              }
            />
            <hr className="border-primary w-full" />
            <VSpacer big={21} small={18} />
            <TicketSectionText />
            <VSpacer big={21.07} small={19} />
            <SecondSection>
              {tickets.map((ticket, index) => {
                return <Ticket key={index} text={ticket} />;
              })}
            </SecondSection>
            <VSpacer big={28.5} small={23.36} />
            <BackBtn />
          </DetailsContainer>
        </div>
      }
    />
  );
}
