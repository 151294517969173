import React from "react";
import HSpacer from "../../../common/Spacer/HSpacer";

interface MainContainerProps {
  varient: "normal" | "winner";
  left: React.ReactNode;
  right: React.ReactNode;
}
export default function MainContainer(props: MainContainerProps) {
  return (
    <div
      className={`flex ${
        props.varient === "winner"
          ? "h-[205.06px] sm_md:h-[151.64px]"
          : "min-h-[173.96px] sm_md:min-h-[104.47px]"
      } bg-[#1A1A1A]`}
    >
      {props.left}
      <HSpacer big={34.01} small={19.98} />
      {props.right}
    </div>
  );
}
