import { Common } from "../../common/Common";
import RangeFieldContainer from "../containers/RangeFieldContainer";
import MaxText from "../texts/MaxText";
import MinText from "../texts/MinText";

interface RangeFieldProps {
  type: "min" | "max";
  value: number;
  max?: number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
export default function RangeField(props: RangeFieldProps) {
  return (
    <RangeFieldContainer>
      {props.type === "min" ? <MinText /> : <MaxText />}
      <Common.textfield
        backgroundColor="#383838"
        fontFamily="Sequel100Wide-65"
        fontSizeBig="30px"
        fontSizeSmall="20px"
        heightBig="60px"
        heightSmall="42px"
        maxWidthBig="100%"
        maxWidthSmall="100%"
        textColor="#fff"
        roundness="10px"
        paddingX="20px"
        paddingXSmall="20px"
        type="number"
        className="text-center"
        value={props.value as unknown as string}
        onChange={(e) => {
          if (
            props.type === "min" &&
            props?.max &&
            parseInt(e.target.value) > props.max
          ) {
            if (e.target.value) {
              e.target.value = props.max ? props.max.toString() : "";
            }
            return;
          }
          props.onChange(e);
        }}
        min={props.type === "min" ? -999999999999 : 1}
        max={props.type === "min" ? props.max && props?.max - 1 : 999999999999}
      />
    </RangeFieldContainer>
  );
}
