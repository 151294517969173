import React from "react";

interface HSpacerProps {
  small: number;
  big: number;
}

const HSpacer: React.FC<HSpacerProps> = ({ small, big }) => {
  return (
    <>
      <div
        className="hidden xl:block lg:block"
        style={{ width: `${big}px` }}
      ></div>
      <div
        className="xl:hidden lg:hidden"
        style={{ width: `${small}px` }}
      ></div>
    </>
  );
};

export default HSpacer;
