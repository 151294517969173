import type { PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUserListCompetition } from "../../Services/userService";

// Define a type for the slice state
export interface CompetitionObject {
  UID: string;
  competitionname: string;
  imageurl: string;
  legalinformation: string;
  competitioninformation: string;
  competitionenddate: Date;
  category: string;
  competitionticketsize: number;
  haveTickets: boolean;
  featured: number;
  totalticketsbought: number;
}

interface CompetitionState {
  loading: "idle" | "pending";
  data: CompetitionObject[];
  error: string;
}

// Define the initial state using that type
const initialState: CompetitionState = {
  data: [],
  loading: "idle",
  error: "",
};

interface CompetitionListRequestBody {
  category: string;
}

export const getUserCompetitions = createAsyncThunk(
  "user/competitions",
  async (params: CompetitionListRequestBody) => {
    const data = await getUserListCompetition(params);
    return data;
  }
);

export const competitionsSlice = createSlice({
  name: "competitions",
  initialState,
  reducers: {
    reset: (state) => {
      state.data = [];
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserCompetitions.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(
        getUserCompetitions.fulfilled,
        (state, action: PayloadAction<CompetitionObject[]>) => {
          state.loading = "idle";
          state.data = action.payload;
        }
      )
      .addCase(getUserCompetitions.rejected, (state, action) => {
        state.loading = "idle";
        state.error = action.error.message || "";
      });
  },
});

export default competitionsSlice.reducer;
