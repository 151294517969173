import { ReactNode } from "react";
import { LinearProgress, LinearProgressProps, styled } from "@mui/material";
import { Text } from "../../react-responsive-utilities/src";

interface HeroSlideActionsSmallProps {
    value: number;
    costPerTicket: number;
    competitionUID: string;
    competitionEnded: boolean;
    onClick: () => void;
}

const Container = ({ children }: { children: ReactNode }) => (
    <div className="flex-col sm:flex md:flex hidden max-w-[349px] relative z-[10] gap-[18.73px]">
        {children}
    </div>
);

const ProgressCostContainer = ({ children }: { children: ReactNode }) => (
    <div className="justify-evenly items-center relative z-10 max-w-[349px] bg-[#000000c1] rounded-[10px] flex p-[13px] max-h-[65px]">
        {children}
    </div>
);

const Progressbsr = ({ value }: { value: number }) => {
    const TicketLinearProgress = styled(LinearProgress)<LinearProgressProps>(
        ({ theme }) => ({
            height: "100%",
            borderRadius: 20,
            backgroundColor: "rgba(255, 255, 255, 0.1)",
            ".MuiLinearProgress-barColorPrimary": {
                backgroundColor: "#EF008F",
                borderRadius: 20,
            },
        })
    );
    return (
        <div className="!h-full max-w-[193.11px] w-full text-left ">
            <TicketLinearProgress
                className="min-h-[13.99px] max-w-[193.11px] w-full"
                variant="determinate"
                value={value}
            />
            <Text textSize={[12, 13.19, 14.19]} className="text-[#fff] block">
                {" "}
                {value}% tickets sold
            </Text>
        </div>
    );
};

const CostPerTicket = ({ costPerTicket }: { costPerTicket: number }) => (
    <div>
        <Text
            textSize={[16, 23.77, 24]}
            className="text-primary font-[MontBlancBlack] block leading-none"
        >
            ${costPerTicket}
        </Text>
        <Text
            textSize={[10, 11.19, 12]}
            className="text-white block font-[MontBlancRegular] uppercase"
        >
            Per Ticket
        </Text>
    </div>
);

const Button = ({
    competitionEnded,
    onClick,
}: {
    competitionEnded: boolean;
    onClick: () => void;
}) => (
    <button
        className={`bg-customGray h-[69.16px] w-full  rounded-full uppercase`}
        // onClick={onClick}
    >
        <Text
            textSize={[14, 23, 26]}
            className={`font-[MontBlancBlack] ${
                // competitionEnded ? "text-[#2D2022]" :
                "text-black"
            }`}
        >
            {/* {competitionEnded ? "Finished" : "Buy Tickets"} */}
            BUY TICKETS
        </Text>
    </button>
);

export default function HeroSlideActionsSmall(
    props: HeroSlideActionsSmallProps
) {
    return (
        <Container>
            <ProgressCostContainer>
                <Progressbsr value={props.value} />
                <CostPerTicket costPerTicket={props.costPerTicket} />
            </ProgressCostContainer>
            <Button
                onClick={props.onClick}
                competitionEnded={props.competitionEnded}
            />
        </Container>
    );
}
