import Countdown from "react-countdown";
import { Text } from "../../react-responsive-utilities/src";
import VSpacer from "../Spacer/VSpacer";

interface CounterWithoutSecsProps {
    competitionEndDate: Date;
    title: String;
    isFinishedCompetition?: boolean;
}

const Unit = ({
    value,
    unit,
    isLast = false,
}: {
    value: string;
    unit: string;
    isLast?: boolean;
}) => (
    <div key={unit} className="flex flex-col items-center">
        <div className="flex *:m-auto justify-center items-center gap-1">
            <div className="px-[clamp(0px,2vw,13px)] flex max-w-[45.17px] min-h-[clamp(35.74px,3vw,45px)] bg-white w-full text-center justify-center items-center rounded-[10px]">
                <Text
                    textSize={[16, 21.93, 27.61]}
                    className="text-[#2E2122] font-['MontBlancBlack'] leading-[29px] font-[218]"
                >
                    {value[0]}
                </Text>
            </div>
            <div className="px-[clamp(0px,2vw,13px)] flex max-w-[45.17px] min-h-[clamp(35.74px,3vw,45px)] bg-white w-full text-center justify-center items-center rounded-[10px]">
                <Text
                    textSize={[16, 21.93, 27.61]}
                    className="text-[#2E2122] font-['MontBlancBlack'] leading-[29px] font-[218]"
                >
                    {value[1]}
                </Text>
            </div>
            {!isLast && (
                <Text
                    textSize={[16, 21.93, 27.61]}
                    className={`text-[#FFFFFF]  font-['MontBlancBlack'] leading-[29px] font-[218] pl-0.5 block ${
                        unit === "Hours" ? "lg:hidden" : ""
                    }`}
                >
                    :
                </Text>
            )}
        </div>

        <Text
            textSize={[0, 14.93, 16.0]}
            className="text-[#FFFFFF]  font-['MontBlancRegular'] font-semibold leading-[20px] //font-[120] uppercase sm:text-center md:text-center w-full block pt-0.5"
        >
            {unit}
        </Text>
    </div>
);

export default function CounterWithoutSecs(props: CounterWithoutSecsProps) {
    return (
        <div className="w-fit ">
            <Text
                textSize={[18, 20.0, 27.07]}
                className="
               text-[#FFFFFF] font-['Sequel100Wide95'] leading-[24px] font-[60] block sm:text-center md:text-center lg:text-center "
            >
                {props.title}
            </Text>
            <VSpacer small={15.5} big={22.11} />
            <div className="flex">
                <Countdown
                    date={props.competitionEndDate}
                    renderer={({ formatted }) => (
                        <div className="grid grid-cols-3  lg:grid-cols-3 sm:gap-[3vw] *:m-auto place-content-center place-items-center">
                            <Unit
                                value={formatted?.days}
                                unit={
                                    props.isFinishedCompetition ? "Day" : "Days"
                                }
                            />

                            <Unit
                                value={formatted?.hours}
                                unit={
                                    props.isFinishedCompetition
                                        ? "Month"
                                        : "Hours"
                                }
                            />

                            {props.isFinishedCompetition ? (
                                <Unit
                                    isLast
                                    value={formatted?.minutes}
                                    unit="Years"
                                />
                            ) : (
                                <Unit
                                    value={formatted?.minutes}
                                    unit="Minutes"
                                />
                            )}

                            {!props.isFinishedCompetition ? (
                                <Unit
                                    isLast
                                    value={formatted?.seconds}
                                    unit="Secs"
                                />
                            ) : (
                                <div></div>
                            )}
                        </div>
                    )}
                />
            </div>
        </div>
    );
}
