import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import PageLink, { PageLinkProps } from "./Link";
import { useAuth } from "../Contexts/AuthContext";
import { getUserBalance } from "../Services/jwtService";
import numeral from "numeral";
import VSpacer from "./common/Spacer/VSpacer";

const Sidebar = ({
    height,
    show,
    absoluted = true,
}: {
    height: string;
    show: boolean;
    absoluted?: boolean;
}) => {
    const location = useLocation();
    const [currentRoute, setCurrentRoute] = useState("");
    //const [balance, setBalance] = useState(0);
    const params = useParams();
    const { competition } = params;
    const { user } = useAuth();

    const tickets = (user as { numberOfTickets: number })?.numberOfTickets;
    const username = (user as { userName?: string })?.userName;
    const [balance, setBalance] = useState(tickets * 0.99);

    const user_name = username;
    const account_code = "account code xxxxx";

    useEffect(() => {
        if (user) {
            setBalance(tickets * 0.99);
        }
    }, [user]);

    useEffect(() => {
        async function getRoute() {
            const route = location.pathname;

            await setCurrentRoute(route);
        }

        getRoute();
    }, []);

    const navLinks: PageLinkProps[] = [
        {
            src: "/wallet",
            content: "My Wallet",
        },
        {
            src: "/entries/live",
            content: "My Entries",
        },
        {
            src: "/orders",
            content: "My Orders",
        },
        {
            src: "/account",
            content: "My Account",
        },
        {
            src: "/buytickets",
            content: "Buy Tickets",
        },
    ];

    return (
        <div
            className={` ${
                absoluted && "xl:absolute"
            } rounded-[50px] left-0 xl:w-[333px] mt-4 ml-0 sideBar w-full xl:ml-10 md:md-10 flex flex-col items-center justify-between `}
            style={{ height: absoluted ? height : "100%" }}
        >
            {/* fixed */}
            <div className="flex flex-col items-center w-full">
                <Link to={"/wallet"}>
                    <img src="/Images/green_dashboard_logo_777.png" alt="" />
                </Link>
                <div className="xl:w-[250px] w-full xl:p-2 p-8">
                    <div className="flex flex-col gap-2">
                        <h3 className="text-[28px] text-wrap text-center font-bold text-white w-full truncate">
                            {user_name}
                        </h3>
                        {/* <p className="text-[23px] text-center text-white w-full mt-[-0.5em]">
              {account_code}
            </p> */}
                    </div>
                    <div className="w-full bg-white flex items-center h-0.5 px-[8%] mt-4 mb-4 rounded-full"></div>
                    <div className="w-full bg-primary flex  p-2 px-[8%] mt-2 rounded-full justify-center items-center">
                        <div className="flex items-center gap-4 border-r-4 border-r-black pr-3">
                            <img src="/Images/dollarSign.png" alt="" />
                            <p>
                                <p
                                    className="text-[19px] sm:text-[1rem] font-[MontBlancBold] text-black font-bold mb-[-0.5em]"
                                    style={{
                                        fontSize: "clamp(1px, 4vw, 16.11px)",
                                    }}
                                >
                                    {numeral(balance).format("0,0.00a")}
                                </p>
                                <span className="text-[12px] font-light">
                                    BALANCE
                                </span>
                            </p>
                        </div>
                        <div className="flex items-center">
                            <img src="/Images/note.png" alt="" />
                            <p>
                                <p
                                    className="text-[19px] sm:text-[1rem] font-[MontBlancBold] text-black font-bold mb-[-0.5em]"
                                    style={{
                                        fontSize: "clamp(1px, 4vw, 16.11px)",
                                    }}
                                >
                                    {numeral(tickets).format("0,0.00a")}
                                </p>
                                <span className="text-[12px] font-light">
                                    TICKETS
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-1 mt-4 flex-col xl:w-[333px] w-full justify-between items-center bg-[#080808] rounded-[50px]">
                <div className="flex font-[Sequel100Wide] flex-col items-center mt-[4em] xl:w-[250px] gap-[2em] w-[80%]">
                    {navLinks &&
                        navLinks.map((link, index) => (
                            <PageLink
                                src={link.src}
                                content={link.content}
                                className={`${"border-primary border-2"} uppercase text-[15px] font-extrabold  ${
                                    link.src === currentRoute
                                        ? "text-black bg-primary"
                                        : "text-white bg-black"
                                } text-center w-full p-[1em] cursor-pointer rounded-full`}
                                key={index}
                            />
                        ))}
                </div>
                {/* <Link
          className={`${
            currentRoute === "/user/dashboard/user"
              ? "rounded-full text-white "
              : "text-white"
          } p-5 font-[Sequel100WideVF45] flex items-center  gap-4 mb-[1em] mt-4 sm:mt-4`}
          to={"/user/dashboard/user"}
        >
          <img src="/Images/settings_wheel.png" alt="" className="w-[40px]" />
          <h5 className="text-[15px] w-full font-bold">ACCOUNT SETTINGS</h5>
        </Link> */}
                <VSpacer small={71.4} big={71.4} />
            </div>
        </div>
    );
};

export default Sidebar;
