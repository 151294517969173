import React from "react";
import { Common } from "../../common/Common";

interface TableValueProps {
  copyable?: boolean;
  title: string;
}
export default function TableValue(props: TableValueProps) {
  return (
    <div className="flex justify-center items-center gap-x-[18px]">
      <Common.typography
        fontFamily="Sequel100WideVF55"
        fontSize="22px"
        fontSizeSmall="16px"
        color="#ffffff"
        className="uppercase"
      >
        {props.title}
      </Common.typography>
      {props.copyable && (
        <Common.image
          src="/Images/copy-white.svg"
          alt="copy"
          width="24px"
          height="24px"
          heightSmall="24px"
          widthSmall="24px"
          objectFit="contain"
          objectPosition="center"
          onClick={() => navigator.clipboard.writeText(props.title)}
        />
      )}
    </div>
  );
}
