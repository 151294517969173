import React from "react";
import { Common } from "../../common/Common";

interface ContractAddressProps {
  value: string;
}

export default function ContractAddress(props: ContractAddressProps) {
  return (
    <div className="flex gap-[12px] justify-center sm_md:w-full sm_md:justify-between">
      <Common.typography
        fontFamily="Sequel100Wide85"
        fontSize="20px"
        fontSizeSmall="16px"
        color="#000"
      >
        Contract Address: {props.value}
      </Common.typography>

      <Common.image
        src="/Images/copy-dark.svg"
        alt="copy"
        width="40px"
        height="40px"
        heightSmall="34px"
        widthSmall="34px"
        objectFit="contain"
        objectPosition="center"
        onClick={() => navigator.clipboard.writeText(props.value)}
      />
    </div>
  );
}
