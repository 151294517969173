import React, { useEffect, useState } from "react";
import Button from "../Button";
import Modal from "@mui/material/Modal";
import Input from "../Input";
import { cancelSubscription, resetPassword } from "../../Services/userService";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, RadioGroup } from "@mui/material";

const BuyTicketsPopup = ({
  rejectClick,
  acceptClick,
  open,
  handleClose,
  noOfTickets,
  competitionName,
  buying,
  success,
  error,
}: {
  rejectClick: (e: any) => void;
  acceptClick: () => void;
  open: boolean;
  handleClose: () => void;
  noOfTickets: number;
  competitionName: string;
  buying: boolean;
  success: string;
  error: string;
}) => {
  const mcq = {
    image: "/Images/bitcoin.png",
    question: "What crypto logo is this?",
    options: ["Bitcoin", "Ethereum", "Chainlink"],
  };
  const [showMcq, setShowMcq] = useState(true);
  const [selectedMCQValue, setSelectedMCQValue] = useState("");

  const submitMcq = () => {
    if (selectedMCQValue.toLowerCase() === "Bitcoin".toLowerCase()) {
      setShowMcq(false);
    } else {
      alert("Wrong Answer");
      handleClose();
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="border-2 rounded-3xl  border-primary absolute top-[50%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 sm:min-w-[85vw] z-[100000001] p-4 bg-secondary_bg bg-opacity-100">
        <div className="flex justify-end">
          <IconButton onClick={handleClose} className="relative">
            <CloseIcon className="text-white" />
          </IconButton>
        </div>
        {showMcq ? (
          <div className="flex flex-col sm:items-center justify-center items-center gap-4">
            <p className="uppercase text-white text-[21px] font-extrabold  text-center sm:text-[16px]">
              {mcq.question}
            </p>
            <img src={mcq.image} className="w-1/2 h-1/2 object-contain" />
            <div className="flex flex-col gap-2 w-1/2">
              {mcq.options.map((option) => (
                <label className="flex items-center gap-2">
                  <input
                    type="radio"
                    value={option}
                    name="mcq"
                    onClick={(e) => {
                      setSelectedMCQValue(option);
                    }}
                    className="form-radio h-5 w-5 text-primary accent-[black]"
                  />
                  <span className="text-white">{option}</span>
                </label>
              ))}
            </div>
            <Button
              className={`text-[#232323] font-[Sequel100Wide95] text-[24px]
              sm:text-[3vw]
              sm:w-[80%]
              uppercase text-center px-[20px] py-[10px] mt-[23px] rounded-[30px]`}
              content={"SUBMIT"}
              onClick={(e) => {
                submitMcq();
              }}
            />
          </div>
        ) : (
          !success && (
            <div className="flex flex-col sm:items-center gap-5 sm:gap-0 p-10">
              <p className="uppercase text-white text-[21px] font-extrabold  text-center sm:text-[16px] sm:w-[220px]">
                CONFIRM YOU ARE HAPPY TO SPEND THE FOLLOWING AMOUNT OF TICKETS
                ON THE ENTRY TO:
              </p>
              <h3 className="text-primary text-[48px] font-extrabold text-center mt-7 mb-5 sm:text-[27px] sm:w-[139px]">
                {`${noOfTickets} Tickets`}{" "}
              </h3>
              <p className="text-[21px]  text-center text-white font-extrabold sm:text-[16px]">
                {`${competitionName}`}
              </p>
            </div>
          )
        )}
        {error && <p className="uppercase text-red-600 text-center">{error}</p>}
        {success && (
          <div className="text-white text-[21px] font-extrabold text-center sm:text-[16px]">
            {success}
          </div>
        )}
        {!showMcq && (
          <div className="mt-10 items-center justify-center flex gap-2 sm:flex">
            <Button
              className={`${
                success ? "hidden" : "block"
              } text-[#ffffff] !bg-black font-[Sequel100Wide95] text-[24px]
              sm:text-[3vw]
              sm:w-[80%]
              uppercase text-center px-[20px] py-[10px] mt-[23px] rounded-[30px]`}
              content={"REJECT"}
              onClick={(e) => {
                handleClose();
              }}
            />
            {!success && (
              <Button
                loading={buying}
                className={`text-[#232323] font-[Sequel100Wide95] text-[24px]
                sm:text-[3vw]
                sm:w-[80%]
                uppercase text-center px-[20px] py-[10px] mt-[23px] rounded-[30px]`}
                content={"CONFIRM"}
                onClick={(e) => {
                  acceptClick();
                }}
              />
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default BuyTicketsPopup;
