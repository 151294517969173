import React, { useEffect, useState } from "react";
import {
    CompetitionProps,
    Competitions,
} from "../../Components/Page Components/Competitions";
import WithNavbarFooterAndHeader from "../../Layout/WithNavbarFooterAndSidebar";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../Components/Sidebar";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getUserCompetitions } from "../../redux/reducers/competitionsSlice";
import { setSideNavHeight } from "../../redux/reducers/sidebarSlice";
import { getUserListCompetition } from "../../Services/userService";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import Button from "../../Components/Button";
import WithNonAbsolutedSidebarNavbarFooter from "../../Layout/WithNonAbsolutedSidebarNavbarFooter";

const Home = () => {
    const [currentFilter, setCurrentFilter] = useState<any>("BITCOIN");
    const [loader, setLoader] = useState<boolean>(false);
    const [filters, setFilters] = useState<any>([
        {
            category: "BITCOIN",
        },
        {
            category: "WEN LAMBO",
        },
        {
            category: "WATCHES",
        },
        {
            category: "HIGH ROLLERS",
        },
        {
            category: "NFTS",
        },
        {
            category: "ALTCOINS",
        },
    ]);
    const navigate = useNavigate();

    const [competitionsList, setCompetitionsList] = useState<
        CompetitionProps[]
    >([]);
    const [allCompetitionsLoaded, setAllCompetitionsLoaded] = useState(false);
    const [displayedCompetitions, setDisplayedCompetitions] = useState<
        CompetitionProps[]
    >([]);
    const itemsPerPage = 16;

    const [finishedCompetitions, setFinishedCompetitions] = useState<
        CompetitionProps[]
    >([]);

    const dispatch = useAppDispatch();

    function buyTicket() {}

    useEffect(() => {
        if (competitionsList?.length === 0) {
            dispatch(setSideNavHeight(""));
        } else if (competitionsList?.length < 4) {
            dispatch(setSideNavHeight(""));
        } else {
            dispatch(setSideNavHeight("1340px"));
        }
    }, [competitionsList]);

    const finishedCompetition = (competitionenddate?: Date) => {
        const currentDate = new Date();
        const competitionEndDate = new Date(competitionenddate ?? new Date());
        if (currentDate > competitionEndDate) {
            return true;
        } else {
            return false;
        }
    };
    const getLiveCompetitions = async (category: string) => {
        try {
            setLoader(true);
            const response = await getUserListCompetition({
                category,
            });
            const fc: CompetitionProps[] = [];
            const lc: CompetitionProps[] = [];

            response?.map((competition: any) => {
                if (finishedCompetition(competition?.competitionenddate)) {
                    fc.push(competition);
                } else {
                    lc.push(competition);
                }
            });
            setFinishedCompetitions(fc);
            const sortedCompetitionList = lc?.sort(
                (a: CompetitionProps, b: CompetitionProps) => {
                    // return moment(a?.competitionenddate).diff(moment(b?.competitionenddate));
                    let dateA = new Date(a.competitionenddate || 0) as any;
                    let dateB = new Date(b.competitionenddate || 0) as any;
                    return dateA - dateB;
                }
            );
            setCompetitionsList(sortedCompetitionList);
            setDisplayedCompetitions(
                sortedCompetitionList.slice(0, itemsPerPage)
            );
            //setCompetitionsList(response);
            setLoader(false);
        } catch (error) {
            console.log("error", error);
            setLoader(false);
        }
    };

    const fetchMoreCompetitions = async () => {
        if (allCompetitionsLoaded) return;
        const nextDisplayedItems = competitionsList.slice(
            0,
            displayedCompetitions.length + itemsPerPage
        );
        setDisplayedCompetitions(nextDisplayedItems); // Update displayed items

        setAllCompetitionsLoaded(
            nextDisplayedItems.length === competitionsList.length
        ); // Check if all competitions are loaded
    };

    useEffect(() => {
        getLiveCompetitions(currentFilter);
        window.scrollTo(0, 0);
    }, [currentFilter]);

    const handleLoadMoreClick = () => {};

    fetchMoreCompetitions();
    // useEffect(() => {
    //   if (competitions) {
    //     const uniqueCategories = [
    //       ...new Set(
    //         competitions?.map((competition) =>
    //           competition?.category?.toLowerCase()
    //         )
    //       ),
    //     ];
    //     const categories = uniqueCategories.map((category) => {
    //       return { category: category };
    //     });

    //     categories.push({ category: "All" });
    //     setFilters(categories);
    //   }
    // }, [competitions]);

    // useEffect(() => {
    //   if (currentFilter && currentFilter !== "All") {
    //     const filteredCompetitions = competitions?.filter(
    //       (competition) => competition.category === currentFilter
    //     );
    //     setCompetitionsList(filteredCompetitions);
    //   } else if (currentFilter === "All") {
    //     setCompetitionsList(competitions);
    //   }
    // }, [currentFilter]);

    // const filters = [
    //   { content: "Cars" },
    //   { content: "Cash" },
    //   { content: "Celebrities" },
    //   { content: "Watches" },
    //   { content: "Tech" },
    //   { content: "Getaways" },
    // ];

    function changeFilter(filter?: string) {
        setCurrentFilter(filter);
    }

    function loadCompetition(UID?: string) {
        navigate(`/user/dashboard/competition/${UID}`);
    }

    function getAllCompetitions() {
        getLiveCompetitions("");
        setCurrentFilter("");
    } // Get all competitions

    useEffect(() => {
        const homeMain = document.getElementById("home_main");
        homeMain?.scrollIntoView();
    }, [currentFilter]);

    return (
        <WithNonAbsolutedSidebarNavbarFooter
            sidebarContainerClassName="mb-[70px]"
            Component={
                <div className="hero w-auto" id="home_main">
                    {/* <Sidebar height={"h-full"} show={true} /> */}

                    <div
                        className={`${
                            competitionsList?.length === 0 && "h-[50rem]"
                        }  1366:scale-x-[0.91] w-auto //1366:!mx-[345px] flex flex-col items-center mb-[3em] liveCompetitionPage p-10 pt-0 //xl:mx-[400px] //w-max lg:w-full mx-0 sm:w-full sm:p-3`}
                    >
                        <h1
                            className="pageHeading"
                            style={{
                                fontSize: "clamp(1px,5.83090379vw,45px)",
                            }}
                            // ref={liveComp}
                        >
                            LIVE CO
                            {/* <img
            src="/Images/green_dashboard_logo_777.png"
            className="h-[50px] xl:mt-2"
            alt=""
          /> */}
                            MPETITIONS
                        </h1>
                        <div className="w-full flex flex-col">
                            <div className="w-full xl:w-auto grid grid-cols-3 gap-10 gap-y-4 //w-auto sm:w-full sm:grid-cols-2 sm:gap-2 ">
                                {filters.map((filter: any, index: number) => (
                                    <button
                                        key={index}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            changeFilter(filter.category);
                                        }}
                                        className={`${
                                            filter.category === currentFilter
                                                ? "bg-primary font-[Sequel100Wide]  text-black border-2 border-white"
                                                : "filter text-white"
                                        }  //w-[345px] //w-[280px] w-full lg:w-full md:w-full lg:text-[1rem] md:text-[1rem] //p-3 font-[Sequel100WideVF55] rounded-full h-[60.31px] sm:w-full transition-all text-[17.95px] sm:text-[11px] sm:h-[35px] text-center uppercase mx-auto`}
                                    >
                                        {filter.category}
                                    </button>
                                ))}
                            </div>
                            {/* view all button */}
                            <button
                                className={`${
                                    currentFilter === ""
                                        ? "bg-primary font-[Sequel100Wide]  text-black border-2 border-white"
                                        : "filter text-white"
                                }  //w-[345px] mt-[17.37px] w-full sm:mt-[8.7px] lg:w-full md:w-full lg:text-[1rem] md:text-[1rem] //p-3 font-[Sequel100WideVF55] rounded-full h-[60.31px] sm:w-full transition-all text-[17.95px] sm:text-[11px] sm:h-[35px] text-center uppercase mx-auto`}
                                onClick={getAllCompetitions}
                            >
                                View All Competitions
                            </button>
                        </div>
                        {loader && (
                            <div className="flex justify-center items-center w-full h-[200px]">
                                <CircularProgress />
                            </div>
                        )}
                        {!loader && displayedCompetitions?.length === 0 && (
                            <div className="w-full mt-[5em] mb-[12em]">
                                <div
                                    className={`text-[2rem] font-[Sequel100WideVF55] m-auto sm:text-[20px] flex flex-col h-[10rem]  relative  w-full xl:w-[880px] above-1367:w-fit rounded-2xl text-center justify-center text-primary`}
                                >
                                    No Live Competitions in this Category is
                                    Found. Please try another category.
                                </div>
                            </div>
                        )}
                        {/* <div className="grid  grid-cols-3 gap-4 1366:gap-[5rem] 1366:mr-[75px] sm:grid-cols-2 gap-y-10 1366:w-full w-auto mt-[5em] md:grid-cols-2 lg:grid-cols-2 sm:mt-[41.7px]"> */}
                        <div className="grid grid-cols-3 sm:grid-cols-1 sm:w-full between-200-299:grid-cols-1 between-200-299:min-w-[100%] md:grid-cols-2 lg:grid-cols-3 xl:gap-x-[20.25px] xl:gap-y-[53px] gap-x-[13.63px] gap-y-[11.92px] m-auto w-max max-w-[100%] *:m-auto mt-[3em]">
                            {!loader &&
                                displayedCompetitions.map(
                                    (competition, index) =>
                                        finishedCompetition(
                                            competition?.competitionenddate
                                        ) ? null : (
                                            <Competitions
                                                {...competition}
                                                onClick={() =>
                                                    loadCompetition(
                                                        competition?.UID
                                                    )
                                                }
                                                key={index}
                                                width={"w-[345px]"}
                                            />
                                        )
                                )}
                        </div>
                        {competitionsList.length >
                            displayedCompetitions.length &&
                            !allCompetitionsLoaded && (
                                <Button
                                    content="Load More"
                                    onClick={handleLoadMoreClick}
                                    className="text-black font-[Sequel100Wide95] text-[24px]
            sm:text-[3vw]
            sm:w-[80%]
            uppercase text-center px-[20px] py-[10px] mt-[23px] rounded-[30px]"
                                />
                            )}
                    </div>
                </div>
            }
        />
    );
};
//1400px
export default Home;
