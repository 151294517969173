import { useEffect, useState } from "react";
import VSpacer from "../../../Components/common/Spacer/VSpacer";
import EditUserDetailsButton from "../../../Components/User Pages Components/My Account/Buttons/EditUserDetailsButton";
import { AvatarContainer } from "../../../Components/User Pages Components/My Account/Containers/AvatarContainer";
import DataContainer from "../../../Components/User Pages Components/My Account/Containers/DataContainer";
import Title2Container from "../../../Components/User Pages Components/My Account/Containers/Title2Container";
import Avatar from "../../../Components/User Pages Components/My Account/images/Avatar";
import DataInfo from "../../../Components/User Pages Components/My Account/texts/DataInfo";
import DataTitle from "../../../Components/User Pages Components/My Account/texts/DataTitle";
import Description from "../../../Components/User Pages Components/My Account/texts/Description";
import Title from "../../../Components/User Pages Components/My Account/texts/Title";
import Title2 from "../../../Components/User Pages Components/My Account/texts/Title2";
import Title3 from "../../../Components/User Pages Components/My Account/texts/Title3";
import { useAuth } from "../../../Contexts/AuthContext";
import WithNonAbsolutedSidebarNavbarFooter from "../../../Layout/WithNonAbsolutedSidebarNavbarFooter";
import AvatarSelectorPopup from "../../../Components/User Pages Components/My Account/others/AvatarSelectorPopup";
import { setAvatar } from "../../../Services/userService";

export default function MyAccount() {
  const [openPopup, setOpenPopup] = useState(false);
  useEffect(() => {
    document.getElementById("my_account_main")?.scrollIntoView();
  }, []);
  const { user, fetchUser } = useAuth();
  const [userInformation, setUserInformation] = useState({
    username: "XXXXXXXXX",
    telegramHandle: "XXXXXXXXX",
    telephoneNumber: "XXXXXXXXX",
    emailAddress: "XXXXXXXXX",
  });
  useEffect(() => {
    setUserInformation({
      username: user?.name || "XXXXXXXXX",
      telegramHandle: "XXXXXXXXX",
      telephoneNumber: user?.phone || "XXXXXXXXX",
      emailAddress: user?.userName || "XXXXXXXXX",
    });
  }, [user]);
  const onConfirm = (selected: string) => {
    const response = setAvatar(selected);
    setOpenPopup(false);
    // reset the user context
    fetchUser();
  };
  return (
    <WithNonAbsolutedSidebarNavbarFooter
      Component={
        // Main Container
        <div
          id="my_account_main"
          className="overflow-hidden box-border bg-black w-full sm_md_lg:mx-auto sm_md_lg:w-[calc(100%-20px)] sm_md:relative sm_md_lg:my-[20px] xl:my-8 h-full mx-[43px] rounded-[30px] py-[63px]  sm_md:py-[44px]  flex flex-col "
        >
          {openPopup && (
            <AvatarSelectorPopup
              onConfirm={onConfirm}
              onClose={() => {
                setOpenPopup(false);
              }}
            />
          )}
          {/* Title */}
          <Title />
          <VSpacer small={64} big={59} />
          {/* 2- Title 2 container */}
          <Title2Container>
            {/* Title2 */}
            <Title2 />
          </Title2Container>

          <VSpacer small={47} big={65} />
          <div className="sm_md:w-[calc(100%-30px)] w-[calc(100%-78px)] m-auto">
            {/* Avatar Container row */}
            <AvatarContainer.Row>
              {/* Avatar image */}

              <Avatar
                src={user?.imageUrl || "/Images/No Image.svg"}
                onClick={() => {
                  setOpenPopup(true);
                }}
              />

              {/* Avatar Container col */}
              <AvatarContainer.Col>
                {/* Title3 */}
                <Title3 />
                {/* avatar description */}
                <Description />
              </AvatarContainer.Col>
            </AvatarContainer.Row>
            <VSpacer small={40} big={39} />
            {/* Data info */}
            {/* 4.1 Username */}
            <DataContainer>
              <DataTitle text="Username" />
              <DataInfo text={userInformation?.username} />
            </DataContainer>
            {/* 4.2 Telegram Handle */}
            <DataContainer>
              <DataTitle text="Telegram Handle" />
              <DataInfo text={userInformation?.telegramHandle} />
            </DataContainer>
            {/* 4.3 Telephone Number */}
            <DataContainer>
              <DataTitle text="Telephone Number" />
              <DataInfo text={userInformation?.telephoneNumber} />
            </DataContainer>
            {/* 4.4 Email Address */}
            <DataContainer>
              <DataTitle text="Email Address" />
              <DataInfo text={userInformation?.emailAddress} />
            </DataContainer>
            <VSpacer small={37} big={42.06} />
            {/* Edit button */}
          </div>
          <div className="sm_md:flex items-center justify-center w-full sm_md:px-0 px-[30px]">
            <EditUserDetailsButton />
          </div>
        </div>
      }
    />
  );
}
