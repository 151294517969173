import axios from "axios";

const getUserDetails = async (email: string) => {
    try {
        const response = await axios.get(`/listuserdetails/${email}`);
        return response.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
};

export const getCompetitionStatus = async (competitionId: string) => {
    try {
        const body = {
            competitionId: competitionId,
        };
        const response = await axios.post(`/competitionstatus`, body);
        return response.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
};

interface CompetitionListRequestBody {
    category: string;
}

const getCompetitionById = async (body: any) => {
    try {
        //  body = {
        //     "competitionid":"29103d182fc479df46fefc50efb5c43e"
        // }
        const response = await axios.post(`/listindividualcompetition`, body);
        return response.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
};

const getUserListCompetition = async (body: CompetitionListRequestBody) => {
    try {
        const response = await axios.post("/userlistcompetitions", body);
        return response.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
};

interface JoinCompetitionRequestBody {
    competitionid?: string;
    numberoftickets: number;
}

const joinCompetition = async (body: JoinCompetitionRequestBody) => {
    try {
        const response = await axios.post("/joinCompetition", body);
        return response.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
};

const getPreviousWinners = async () => {
    try {
        const response = await axios.get("/previouswinners");
        return response.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
};

const getEnteredCompetition = async () => {
    try {
        const response = await axios.get("/listenteredcompetitions");
        return response.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
};

const userTransactions = async (pageNo: number) => {
    try {
        const response = await axios.get(`/accounttransactions?page=${pageNo}`);
        return response.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
};

const resetUserPassword = async (
    body: { password: string; confirmPassword: string },
    token?: string | null
) => {
    try {
        const response = await axios.post(
            `/forgotpasswordconfirm?token=${token}`,
            body
        );
        return response.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
};

const editUserDetails = async (body: any) => {
    try {
        const response = await axios.post("/userdetails", body);
        return response.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
};

const getAllAvatars = async () => {
    try {
        const response = await axios.get("/getAvatar");
        return response.data;
    } catch (error) {
        throw error;
    }
};

const setAvatar = async (avatarUID: string) => {
    try {
        const response = await axios.patch("/setAvatarInUser", {
            url: avatarUID,
        });
        return response.data;
    } catch (error) {
        console.log("setAvatar: ", error);
        throw error;
    }
};

const editTelegramHandle = async (handle: string) => {
    try {
        const response = await axios.post("/submitTelegram", {
            telegram: handle,
        });
        console.log("editTelegramHandle: ", response.data);
    } catch (error) {
        console.log("editTelegramHandle: ", error);
        throw error;
    }
};

const getPaymentOptions = async () => {
    try {
        const response = await axios.get("/paymentoptions");
        return response.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
};

const getWallet = async () => {
    try {
        const response = await axios.get("/getwallet");
        console.log("getWallet: ", response.data?.data);
        return response.data?.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
};

const setWallet = async (body: any) => {
    try {
        const response = await axios.patch("/submitWallet", body);
        console.log("setWallet: ", response.data);
        return response.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
};

const resetPassword = async (body: { email: string }) => {
    try {
        const response = await axios.post("/forgotpassword", body);
        return response.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
};

const cancelSubscription = async () => {
    try {
        const response = await axios.get("/cancelsubscription");
        return response.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
};

const getCompetitionImages = async (body: { competitionid: string }) => {
    try {
        const response = await axios.post("/getimagestream", body);
        return response.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
};

const getAvailableTickets = async (competitionId: string) => {
    try {
        const response = await axios.get(
            `/listavailabletickets/${competitionId}`
        );
        return response.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
};

export {
    getUserDetails,
    getCompetitionById,
    getUserListCompetition,
    resetPassword,
    getPaymentOptions,
    getEnteredCompetition,
    joinCompetition,
    getPreviousWinners,
    resetUserPassword,
    editUserDetails,
    cancelSubscription,
    getCompetitionImages,
    userTransactions,
    editTelegramHandle,
    getAllAvatars,
    setAvatar,
    getWallet,
    setWallet,
    getAvailableTickets,
};
