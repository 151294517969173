interface RangeFieldContainerProps {
  children: React.ReactNode;
}
export default function RangeFieldContainer(props: RangeFieldContainerProps) {
  return (
    <div className="flex flex-col justify-center items-center w-full">
      {props.children}
    </div>
  );
}
