interface DataTitleProps {
  text: string;
}
export default function DataTitle(props: DataTitleProps) {
  return (
    <p className="font-[Sequel100Wide95] sm_md:text-[20px] text-[25px] text-primary leading-none">
      {props.text}
    </p>
  );
}
