import React from "react";
import { Container, Text } from "../../../react-responsive-utilities/src";
import { Common } from "../../../common/Common";

export default function Progress({ ticketSold }: { ticketSold: any }) {
  return (
    <div
      className="w-full flex flex-col gap-[5.21px] //sm:!mt-[14%]"
      // style={{
      //   marginTop: "clamp(7.14px, 0vw, 12.4px)",
      // }}
    >
      {/* progressbar */}

      <Container
        //width={[0, 161.51, 280]}
        width={[0, "full", "full"]}
        doNotResizeWidth
        height={[2.88, 2.88, 5]}
        className="progressbar"
      >
        <div
          style={{
            height: "100%",
            width: `${ticketSold()}%`,

            transition: "width 0.5s",
          }}
          className="bg-secondary"
        ></div>
      </Container>
      {/* <span className="text-white text-[15px]  //text-[11px] sm:text-[9px] between-200-299:text-[0.3rem] font-[MontBlancRegular] uppercase">
    {ticketSold()} <span className="font-[MontBlancRegular]">%</span>{" "}
    tickets sold
  </span> */}
      {/* <Text
        className="text-white font-[Sequel100Wide-65] uppercase sm:!text-[4vw] text-center"
        textSize={[0, 18, 20.07]}
      >
        {ticketSold()} <span className="font-[Sequel100Wide-65]">%</span>{" "}
        tickets sold
      </Text> */}
      <Common.typography
        fontFamily="Sequel100Wide-65"
        fontSize="20.07px"
        fontSizeSmall="18px"
        color="#FFFFFF"
        className="text-center uppercase"
      >
        {ticketSold()} <span className="font-[Sequel100Wide-65]">%</span>{" "}
        tickets sold
      </Common.typography>
    </div>
  );
}
