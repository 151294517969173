import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import AdvertBox from "../Components/Page Components/AdvertBox";
import WithNavbarAndFooter from "../Layout/WithNavbarAndFooter";
import PhoneStores from "../Components/Page Components/PhoneStores";

const Policy = ({
  title,
  content,
}: {
  title: JSX.Element;
  content: React.ReactNode; // Updated to accept JSX elements
}) => {
  return (
    <div className="p-10 mx-10 sm:mx-0 mt-[-1em] sm:mt-0 sm:pb-0 policyPage">
      {title}
      <div className="text-white text-[20px] pb-8 xl:pb-0">{content}</div>
    </div>
  );
};

const Policies = () => {
  const params = useParams();
  const { policy } = params;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.location.href]);

  return (
    <div className="hero flex flex-col ">
      <div className="mb-5">
        {policy === "cookies" ? (
          <Policy
            title={
              <h1
                className="text-[40px] font-extrabold text-white text-center"
                style={{
                  padding: "20px 0", // Add padding to top and bottom
                }}
              >
                COOKIES POLICY
              </h1>
            }
            content={
              <>
                <p>
                  <strong>THEPRIZE.IO</strong> uses cookies to enhance your
                  experience, improve functionality, and analyze site usage. By
                  continuing to use our site, you consent to our use of cookies.
                </p>

                <h3 className="mt-4 text-[22px] font-bold">1. What Are Cookies?</h3>
                <p>
                  Cookies are small data files stored on your device that help
                  us remember your preferences and recognize your browser upon
                  return.
                </p>

                <h3 className="mt-4 text-[22px] font-bold">
                  2. Types of Cookies We Use
                </h3>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Essential Cookies:</strong> Required for the
                    operation of our site.
                  </li>
                  <li>
                    <strong>Performance Cookies:</strong> Collect data on site
                    usage to help us improve user experience.
                  </li>
                  <li>
                    <strong>Functional Cookies:</strong> Enable personalized
                    features, such as saving your competition entries.
                  </li>
                </ul>

                <h3 className="mt-4 text-[22px] font-bold">
                  3. Managing Your Cookies
                </h3>
                <p>
                  You can control your cookie preferences through your browser
                  settings. Disabling certain cookies may limit your ability to
                  use all features of our platform.
                </p>

                <h3 className="mt-4 text-[22px] font-bold">
                  4. Third-Party Cookies
                </h3>
                <p>
                  We may partner with third-party providers for analytics. These
                  providers may use cookies as described in their respective
                  privacy policies.
                </p>

                <h3 className="mt-4 text-[22px] font-bold">Contact Us</h3>
                <p>
                  For questions regarding our Cookies Policy, email us at{" "}
                  <a
                    href="mailto:contact@theprize.io"
                    className="text-blue-400 underline"
                  >
                    contact@theprize.io
                  </a>
                  .
                </p>
              </>
            }
          />
        ) : policy === "privacy" ? (
          <Policy
            title={
              <h1
                className="text-[40px] font-extrabold text-white text-center"
                style={{
                  padding: "20px 0", // Add padding to top and bottom
                }}
              >
                PRIVACY POLICY
              </h1>
            }
            content={
              <>
                <p>
                  THEPRIZE.IO ("we," "our," or "us") is committed to protecting your
                  privacy. This Privacy Policy outlines how we collect, use, store, and
                  disclose your personal information when you visit our website or
                  participate in our competitions. By using our site and entering
                  competitions, you consent to our practices as described in this Privacy
                  Policy.
                </p>

                <h3 className="mt-4 text-[22px] font-bold">1. Information We Collect</h3>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Personal Information:</strong> When you create an account,
                    purchase tickets, or interact with us, we may collect information
                    such as your name, email address, and digital currency wallet
                    address.
                  </li>
                  <li>
                    <strong>Transaction Data:</strong> For transparency and prize
                    distribution, we collect transaction data, including transaction
                    hashes and competition entries.
                  </li>
                  <li>
                    <strong>Usage Data:</strong> We gather data on your interactions with
                    our site, including IP address, device type, browser type, and pages
                    visited to improve user experience.
                  </li>
                  <li>
                    <strong>Cookies and Tracking:</strong> We use cookies to track your
                    activity on our site, enhance functionality, and gather usage
                    information. You can adjust your cookie preferences in your browser
                    settings.
                  </li>
                </ul>

                <h3 className="mt-4 text-[22px] font-bold">2. How We Use Your Information</h3>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>Service Provision:</strong> We use your information to
                    facilitate competition entries, process transactions, and distribute
                    prizes.
                  </li>
                  <li>
                    <strong>User Communications:</strong> To provide customer support,
                    notify winners, and inform users about new competitions or updates.
                  </li>
                  <li>
                    <strong>Site Optimization:</strong> We analyze usage data to improve
                    our website and customize your experience.
                  </li>
                  <li>
                    <strong>Compliance and Legal Obligations:</strong> We may process
                    data as required by applicable law or regulatory requirements.
                  </li>
                </ul>

                <h3 className="mt-4 text-[22px] font-bold">3. Sharing Your Information</h3>
                <ul className="list-disc pl-5">
                  <li>
                    <strong>With Service Providers:</strong> We may share data with
                    third-party service providers to help process transactions and manage
                    our platform.
                  </li>
                  <li>
                    <strong>Compliance and Security:</strong> If required by law, we may
                    disclose information to comply with legal obligations or enforce our
                    policies.
                  </li>
                  <li>
                    <strong>Public Winner Announcements:</strong> For transparency,
                    usernames, wallet addresses, and transaction hashes of winners are
                    displayed on our Winners page.
                  </li>
                </ul>

                <h3 className="mt-4 text-[22px] font-bold">4. Data Security</h3>
                <p>
                  We implement industry-standard security measures to protect your
                  personal data. However, no transmission method is 100% secure, so we
                  cannot guarantee absolute security.
                </p>

                <h3 className="mt-4 text-[22px] font-bold">5. Your Rights</h3>
                <p>
                  Depending on your jurisdiction, you may have rights to access, correct,
                  delete, or limit the use of your personal information. Please contact us
                  at <a href="mailto:contact@theprize.io" className="text-blue-400 underline">contact@theprize.io</a> to exercise your rights.
                </p>

                <h3 className="mt-4 text-[22px] font-bold">6. Updates to This Policy</h3>
                <p>
                  We may revise this Privacy Policy periodically. Any changes will be
                  posted on this page, and by continuing to use our site, you agree to the
                  updated terms.
                </p>

                <h3 className="mt-4 text-[22px] font-bold">Contact Us</h3>
                <p>
                  If you have questions about this Privacy Policy, please contact us at{" "}
                  <a href="mailto:contact@theprize.io" className="text-blue-400 underline">
                    contact@theprize.io
                  </a>
                  .
                </p>
              </>
            }
          />
        ) : policy === "t&c" ? (
          <Policy
            title={
              <h1
                className="text-[40px] font-extrabold text-white text-center"
                style={{
                  padding: "20px 0", // Add padding to top and bottom
                }}
              >
                TERMS & CONDITIONS
              </h1>
            }
            content={
              <>
                <p>
                  By accessing and using THEPRIZE.IO platform, you agree to comply with
                  these Terms and Conditions. Please read them carefully before entering
                  our competitions.
                </p>

                <ol className="list-decimal pl-5 mt-4">
                  <li className="mb-3">
                    <strong>Eligibility</strong>
                    <p>
                      Participation in competitions is open to users who meet the legal
                      age requirements in their jurisdiction. Users are responsible for
                      complying with all local laws regarding online competitions.
                    </p>
                  </li>

                  <li className="mb-3">
                    <strong>Competitions and Prizes</strong>
                    <ul className="list-disc pl-5">
                      <li>
                        <strong>Entry:</strong> To enter, users must purchase a ticket for
                        the desired competition. Once purchased, tickets are
                        non-refundable.
                      </li>
                      <li>
                        <strong>Winner Selection:</strong> Winners are selected through
                        Chainlink oracle RNG for transparency and fairness. Results are
                        final and binding.
                      </li>
                      <li>
                        <strong>Prize Distribution:</strong> Winners will receive their
                        prizes in cryptocurrency. Luxury item winners will be contacted
                        for delivery arrangements.
                      </li>
                      <li>
                        <strong>Transparency:</strong> Transaction hashes for prize
                        distribution and RNG processes are available for verification.
                      </li>
                    </ul>
                  </li>

                  <li className="mb-3">
                    <strong>No Refund Policy</strong>
                    <p>
                      All purchases made on THEPRIZE.IO platform, including competition
                      entries, are final and non-refundable. Users are encouraged to
                      review their participation carefully before proceeding with any
                      transaction.
                    </p>
                  </li>

                  <li className="mb-3">
                    <strong>User Conduct</strong>
                    <p>
                      You agree to use our platform lawfully and refrain from actions that
                      harm other users, the platform, or its operations. Breach of these
                      terms may result in suspension or termination of your account.
                    </p>
                  </li>

                  <li className="mb-3">
                    <strong>Limitation of Liability</strong>
                    <p>
                      THEPRIZE.IO is not liable for any loss or damage resulting from
                      competition participation, including but not limited to the value
                      fluctuation of digital currencies. All transactions are made at the
                      user’s risk.
                    </p>
                  </li>

                  <li className="mb-3">
                    <strong>Intellectual Property</strong>
                    <p>
                      All content, including text, graphics, logos, and software, is the
                      property of THEPRIZE.IO or its licensors and is protected by
                      applicable copyright laws.
                    </p>
                  </li>

                  <li className="mb-3">
                    <strong>Modifications</strong>
                    <p>
                      We may amend these Terms at any time. Continued use of the site
                      after changes constitute acceptance of the new terms.
                    </p>
                  </li>
                </ol>

                <h3 className="mt-4 text-[22px] font-bold">Contact Us</h3>
                <p>
                  For inquiries about these Terms & Conditions, reach us at{" "}
                  <a
                    href="mailto:contact@theprize.io"
                    className="text-blue-400 underline"
                  >
                    contact@theprize.io
                  </a>
                  .
                </p>
              </>
            }
          />
        ) : null}
      </div>
      <AdvertBox />
      <PhoneStores background="bg-gradient-to-r from-[#a51269] to-[#2b2b2b] h-screen " />
    </div>
  );
};

export default WithNavbarAndFooter(Policies);
