import { Common } from "../../../common/Common";
import VSpacer from "../../../common/Spacer/VSpacer";

interface WinnerItemProps {
  itemType?: "winner" | "ticket";
  itemValue?: string;
}
function WinnerItem(props: WinnerItemProps) {
  return (
    <div className="flex gap-4 min-w-0">
      <div>
        <Common.typography
          fontFamily="Sequel100WideVF45"
          className="sm_md:!font-[Sequel100WideVF55]"
          fontSize="11.66px"
          fontSizeSmall="9.66px"
          color="#ffff"
        >
          {props.itemType === "winner" ? "Winner" : "Winning Ticket"}
        </Common.typography>
        <VSpacer big={4.46} small={4.91} />
        <div
          className={`${
            props.itemType === "winner"
              ? "bg-white"
              : "bg-primary border-[1px] border-white"
          } h-[32.07px] flex items-center justify-center px-[14px] sm_md:h-[19.25px]`}
        >
          <Common.typography
            fontFamily="Sequel100Wide95"
            fontSize="15.55px"
            fontSizeSmall="9.66px"
            color="#000000"
          >
            {props.itemValue}
          </Common.typography>
        </div>
      </div>
    </div>
  );
}

export default WinnerItem;
