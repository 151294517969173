import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import competitionReducer from "./reducers/competitionsSlice";
import sidebarReducer from "./reducers/sidebarSlice";

const persistConfig = {
  key: "root",
  version: 1,
  storage: storage,
  blacklist: ["competitions", "sidebar"],
};

const reducers = combineReducers({
  competitions: competitionReducer,
  sidebar: sidebarReducer,
});

export const reset = () => ({ type: "RESET" });
const rootReducer = (state: any, action: any) => {
  if (action.type === "RESET") {
    state = undefined;
  }
  return reducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, PURGE, REHYDRATE, PAUSE, PERSIST, REGISTER],
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
