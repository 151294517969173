import React, { useEffect, useState } from "react";
import { Text } from "../react-responsive-utilities/src";
import VSpacer from "../common/Spacer/VSpacer";
import { Common } from "../common/Common";
import Carousel from "../common/Carousel/Carousel";
import { useMediaQuery } from "react-responsive";

const PhoneStores = ({ background }: { background: string }) => {
    const [deferredPrompt, setDeferredPrompt] = useState<any>(null);
    const [installable, setInstallable] = useState(false);

    const isXlScreen = useMediaQuery({ query: "(min-width: 1280px)" });

    useEffect(() => {
        // Listen for the 'beforeinstallprompt' event
        const handleBeforeInstallPrompt = (event: {
            preventDefault: () => void;
        }) => {
            event.preventDefault(); // Prevent the mini-infobar from appearing
            setDeferredPrompt(event); // Save the event to trigger later
            setInstallable(true); // Show the install button
        };

        window.addEventListener(
            "beforeinstallprompt",
            handleBeforeInstallPrompt
        );

        return () => {
            window.removeEventListener(
                "beforeinstallprompt",
                handleBeforeInstallPrompt
            );
        };
    }, []);

    const staticWinMobil = [
        {
            mobile: "/Images/game.png",
            text: "SMASH.",
            desc: "Step into the THEPRIZE.IO Safe Smash Telegram Game! Smash safes, earn coins, climb the leaderboard, and unlock epic prizes.",
            buttonHandler: () => {},
        },
        {
            mobile: "/Images/telegram.png",
            text: "SCORE.",
            desc: "Download our platform app to your home screen for instant access to competitions, live updates, and your shot at massive wins.",
            buttonHandler: () => {},
        },
        {
            mobile: "/Images/theprize.png",
            text: "WIN.",
            desc: "Join our Telegram channel to be the first to know about new draws, instant wins, and exclusive rewards.",
            buttonHandler: () => {},
        },
    ];

    const staticWinWeb = [
        {
            desc: "Step into the THEPRIZE.IO Safe Smash Telegram Game! Smash safes, earn coins, climb the leaderboard, and unlock epic prizes.",
            btnText: "COMING SOON",
            btnHandler: () => {},
        },
        {
            desc: "Download our platform app to your home screen for instant access to competitions, live updates, and your shot at massive wins.",
            btnText: "COMING SOON",
            btnHandler: () => {},
        },
        {
            desc: "Join our Telegram channel to be the first to know about new draws, instant wins, and exclusive rewards.",
            btnText: "COMING SOON",
            btnHandler: () => {},
        },
    ];

    const handleInstallClick = async () => {
        if (deferredPrompt) {
            deferredPrompt.prompt(); // Show the install prompt
            const { outcome } = await deferredPrompt.userChoice; // Wait for the user to respond
            if (outcome === "accepted") {
                console.log("User accepted the install prompt");
            } else {
                console.log("User dismissed the install prompt");
            }
            setDeferredPrompt(null); // Clear the deferred prompt
            setInstallable(false); // Hide the install button
        }
    };

    const Title = ({ title }: { title: string }) => (
        <h1 className="text-[#323232] font-['Sequel100Wide85'] leading-[36px] sm:text-[30px] md:text-[42px] lg:text-[47px] xl:text-[60px] block text-center">
            {title}
        </h1>
    );

    const Description = ({ description }: { description: string }) => (
        <Text
            textSize={[14, 14.0, 19.96]}
            className="
        text-[#1A1A1A] font-['Sequel100WideVF45'] //leading-[17px] font-[16] block sm:text-center md:text-center"
        >
            {description}
        </Text>
    );

    return (
        <div className="bg-gradient-to-br from-[#a51269] to-[#2b2b2b] via-[#2b2b2b] bg-[length:100%_100%]">
            <div
                style={{
                    backgroundImage: isXlScreen
                        ? "url('/Images/never_miss_bg.svg')"
                        : undefined,
                }}
                className={` relative  bg-cover bg-center !h-fit xl:!h-[1550px] pt-[40px] w-full flex items-end justify-between sm:flex-col-reverse  md:flex-col-reverse  md:h-full md:pt-8 lg:flex-col-reverse  lg:h-full exact-1280:!flex-col-reverse  exact-1280:!h-full lg:pt-8`}
            >
                <div className=" w-full flex justify-center  px-[60px] z-[50]">
                    <div className="xl:mb-20 h-full sm:flex sm:flex-col  md:flex md:flex-col md:items-center sm:items-center lg:flex lg:flex-col lg:items-center exact-1280:!flex exact-1280:!flex-col exact-1280:!items-center ">
                        <Common.typography
                            fontFamily="Sequel100Wide95"
                            fontSize="66.1px"
                            fontSizeSmall="clamp(22px,8vw,66px)"
                            color="#fff"
                            className="leading-none sm:w-full sm:text-center sm:max-h-[0px]  md:text-center lg:text-center xl:text-center exact-1280:!text-center uppercase xl:mb-[56px] lg_xl:mb-[23px]"
                        >
                            Never Miss A Win!
                        </Common.typography>
                        <div className="sm:h-[70px]"></div>
                        {/* FOR DESKTOP VERSION */}

                        <div className="flex h-[1270px] flex-col xl:block hidden">
                            <img
                                src="/Images/mobiles_xl_image.png"
                                alt="Small Phones"
                                className="h-[615px] w-full justify-self-start m-0 p-0"
                            />

                            <div className="bg-primary xl:h-[655px] xl:w-[100%] xl:block hidden rounded-[28.6px] pt-[62px] pb-[53px] px-[52.5px] shadow-[16px_16px_16px_rgba(0,0,0,0.5)]">
                                <Title title="SMASH. SCORE. WIN." />
                                <div className="h-[45px]"></div>
                                <img
                                    src="/Images/smash_win_web.svg"
                                    alt=" "
                                    className="h-[420px] w-full justify-self-start m-0 p-0"
                                />
                            </div>
                        </div>

                        {/* FOR MOBILE VERSION */}

                        <div className="h-[1150px] sm:h-[880px] w-[85%] center xl:hidden ">
                            <Carousel>
                                {staticWinMobil.map((item, index) => (
                                    <div>
                                        <img
                                            src={item.mobile}
                                            alt="mobile"
                                            className=" h-[600px] lg:w-full sm:min-w-[350px] lg:w-[600px] md:w-[550px] mx-auto md: mt-0 sm:h-[400px]"
                                        />
                                        <div className="relative overflow-hidden text-center m-0 bg-primary h-[357px] mx-[7.5%]  w-[85%] px-[50px]  rounded-[18.61px] flex flex-col justify-start items-center">
                                            <VSpacer big={50} small={30} />
                                            <Title title={item.text} />
                                            <VSpacer big={50} small={30} />
                                            <Description
                                                description={item.desc}
                                            />
                                            <div className="h-[10%]"></div>
                                            <button className="bg-[#212121] xl:py-[13px] w-full rounded-[14px] border-2 border-white font-[Sequel100wide] text-white h-[56px]">
                                                COMING SOON
                                            </button>
                                        </div>
                                        <div className="sm:h-[15px]"></div>
                                    </div>
                                ))}
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PhoneStores;
