interface AvatarContainerProps {
  children: React.ReactNode;
}
function AvatarContainerRow(props: AvatarContainerProps) {
  return (
    <div className="flex flex-row sm_md:gap-[20px] gap-[30px]">
      {props.children}
    </div>
  );
}

function AvatarContainerCol(props: AvatarContainerProps) {
  return <div className="flex flex-col">{props.children}</div>;
}

export const AvatarContainer = {
  Row: AvatarContainerRow,
  Col: AvatarContainerCol,
};
