import React from "react";
import Navbar from "../Components/Navbar";
import { useAppSelector } from "../redux/hooks";
import Sidebar from "../Components/Sidebar";
import Footer from "../Components/Footer";
import { PageLinkProps } from "../Components/Link";

function WithNonAbsolutedSidebarNavbarFooter({
  Component,
  ComponentBeforeFooter,
  sidebarContainerClassName,
}: {
  Component: any;
  ComponentBeforeFooter?: any;
  sidebarContainerClassName?: string;
}) {
  const quickLinks: PageLinkProps[] = [
    {
      src: "/",
      content: "Home",
    },
    {
      src: "/competitions/all",
      content: "Competitions",
    },
    {
      src: "/howtoplay",
      content: "How to Play",
    },
    {
      src: "/winners",
      content: "Winners",
    },
    {
      src: "/howtoplay#faqs",
      content: "faq",
    },
  ];

  const bottomLinks: PageLinkProps[] = [
    {
      src: "/policies/cookies",
      content: "Cookie Policy",
    },
    {
      src: "/policies/privacy",
      content: "Privacy Policy",
    },
    {
      src: "/policies/t&c",
      content: "Terms & Conditions",
    },
  ];
  const { height } = useAppSelector((state) => state.sidebar);
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  return (
    <div className="h-full">
      <Navbar opener={sidebarOpen} setOpener={setSidebarOpen} />

      <div className="h-fit flex xl:flex-row flex-col relative mb-[67px]">
        <div
          className={`${sidebarOpen ? "z-[-1]" : ""} relative mb-[42px] p-2 ${
            sidebarContainerClassName || ""
          }`}
        >
          <Sidebar absoluted={false} height={height} show={false} />
        </div>
        {Component}
      </div>
      {ComponentBeforeFooter}
      <Footer quickLinks={quickLinks} bottomLinks={bottomLinks} />
    </div>
  );
}

export default WithNonAbsolutedSidebarNavbarFooter;
