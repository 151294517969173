import { useNavigate } from "react-router-dom";
import { Common } from "../../../common/Common";

export default function BackBtn() {
  const navigate = useNavigate();
  return (
    <Common.button
      className="flex sm_md:flex flex-row items-center justify-center gap-x-[13px] sm_md:gap-x-[10.95px] sm_md:m-auto"
      roundness="30px"
      backgroundColor="transparent"
      border="1px solid #DDE404"
      maxWidthBig="145px"
      maxWidthSmall="141px"
      heightBig="52px"
      heightSmall="43.98px"
      color="#fff"
      fontFamily="Sequel100WideVF45"
      fontSizeSmall="18px"
      fontSize="18px"
      paddingX="1rem"
      onClick={() => navigate("/entries/finished")}
    >
      <Common.image
        alt="back"
        src="/Images/Back.svg"
        width="22px"
        widthSmall="18px"
        height="22px"
        heightSmall="18px"
        roundness="999px"
      />
      <Common.typography
        className="uppercase"
        fontFamily="Sequel100WideVF45"
        fontSize="18"
        fontSizeSmall="18"
        color="#fff"
      >
        Back
      </Common.typography>
    </Common.button>
  );
}
