import { Common } from "../../common/Common";

export default function MaxText() {
  return (
    <Common.typography
      fontFamily="Sequel100WideVF55"
      fontSize="30px"
      fontSizeSmall="20px"
      color="#ffffff"
      className="uppercase"
    >
      Max
    </Common.typography>
  );
}
