import { Common } from "../../../common/Common";

function Heading() {
  return (
    <Common.typography
      fontFamily="Sequel100Wide95"
      fontSizeSmall="30px"
      fontSize="45px"
      color="#fff"
    >
      My Entries
    </Common.typography>
  );
}

export default Heading;
