import React, { useEffect } from "react";
import { ComponentType } from "react";
import Footer from "../Components/Footer";
import { PageLinkProps } from "../Components/Link";
import Navbar from "../Components/Navbar";

const WithNavbarAndFooter = <Props extends object>(
  WrappedComponent: ComponentType<Props>
) => {
  const ComponentWithNavbarAndFooter = (props: Props) => {
    const quickLinks: PageLinkProps[] = [
      {
        src: "/",
        content: "Home",
      },
      // {
      //   src: "/competitions/all",
      //   content: "Competitions",
      // },
      {
        src: "/howtoplay",
        content: "How to Play",
      },
      // {
      //   src: "/winners",
      //   content: "Winners",
      // },
      // {
      //   src: "/howtoplay#faqs",
      //   content: "faq",
      // },
    ];

    const bottomLinks: PageLinkProps[] = [
      {
        src: "/policies/cookies",
        content: "Cookie Policy",
      },
      {
        src: "/policies/privacy",
        content: "Privacy Policy",
      },
      {
        src: "/policies/t&c",
        content: "Terms & Conditions",
      },
    ];

    return (
      <>
        <Navbar />
        <WrappedComponent {...(props as Props)} />
        <Footer quickLinks={quickLinks} bottomLinks={bottomLinks} />
      </>
    );
  };

  ComponentWithNavbarAndFooter.displayName = `WithNavbarAndFooter(${
    WrappedComponent.displayName || WrappedComponent.name || "Component"
  })`;

  return ComponentWithNavbarAndFooter;
};

export default WithNavbarAndFooter;
