import { Common } from "../../../common/Common";
import HSpacer from "../../../common/Spacer/HSpacer";
import VSpacer from "../../../common/Spacer/VSpacer";

interface DataFieldProps {
  label: string;
  placeholder: string;
  onSave: () => void;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function DataField(props: DataFieldProps) {
  return (
    <div className="flex flex-col">
      <div className="grid grid-cols-1 sm_md:grid-cols-2">
        <Common.typography
          fontFamily="Sequel100Wide95"
          fontSize="25px"
          fontSizeSmall="14px"
          color="#dde406"
        >
          {props.label}
        </Common.typography>

        <div className="sm_md:block hidden place-self-end">
          <Common.button
            text="Save"
            backgroundColor="#dde406"
            color="#000000"
            roundness="30px"
            maxWidthBig="101.66px"
            heightBig="42px"
            fontFamily="Sequel100Wide95"
            fontSize="16px"
            fontSizeSmall="12px"
            heightSmall="31px"
            maxWidthSmall="108px"
            onClick={props.onSave}
          />
        </div>
      </div>
      <VSpacer small={17} big={10} />
      <div className="flex flex-row">
        <Common.textfield
          onChange={props.onChange}
          placeholder={props.placeholder}
          backgroundColor="#505050"
          textColor="#ffffff"
          fontSizeBig="16px"
          fontSizeSmall="12px"
          fontFamily="MontBlancRegular"
          paddingX="16.3px"
          paddingXSmall="17px"
          roundness="20px"
          heightBig="42px"
          heightSmall="41px"
          // maxWidthBig="682px"
          maxWidthBig="100%"
          maxWidthSmall="100%"
          value={props.value}
        />
        <HSpacer small={0} big={18} />
        <div className="sm_md:hidden">
          <Common.button
            text="Save"
            backgroundColor="#dde406"
            color="#000000"
            roundness="30px"
            maxWidthBig="101.66px"
            heightBig="42px"
            fontFamily="Sequel100Wide95"
            fontSize="16px"
            fontSizeSmall="12px"
            heightSmall="31px"
            maxWidthSmall="108px"
            onClick={props.onSave}
          />
        </div>
      </div>
    </div>
  );
}
