import { Common } from "../../common/Common";

export default function RecentlyGeneratedNumbers() {
  return (
    <Common.typography
      fontFamily="Sequel100Wide95"
      fontSize="30px"
      fontSizeSmall="30px"
      color="#ffffff"
      className="xl:hidden text-center"
    >
      RECENTLY GENERATED NUMBERS
    </Common.typography>
  );
}
