import { useNavigate } from "react-router-dom";
import HSpacer from "../../../common/Spacer/HSpacer";
import VSpacer from "../../../common/Spacer/VSpacer";
import { useRef } from "react";
import { LinearProgress } from "@mui/material";

interface OrdersTableTicketsSmallProps {
  isFetching: boolean;
  onScrolledToBottom: () => void;
  table: tableData[];
}

const TableRow = ({ children }: { children: React.ReactNode }) => (
  <tr className="grid grid-cols-2">{children}</tr>
);

const TableHeading = ({ text }: { text: string }) => (
  <th className="font-[Sequel100WideVF45] text-[14px] text-white text-left uppercase text-wrap flex items-center">
    {text}
  </th>
);

const TableData = ({ text }: { text: string }) => (
  <td className="font-[Sequel100WideVF45] text-[14px] text-[#8a8a8a] text-right flex justify-end items-center ">
    <p className="w-fit">{text}</p>
  </td>
);
export default function OrdersTableTicketsSmall(
  props: OrdersTableTicketsSmallProps
) {
  const navigate = useNavigate();
  const navigateToDetail = (uid: string) => {
    navigate(`/view/order/${uid}`);
  };
  const scroller = useRef<HTMLDivElement | null>(null);
  const handleScroll = () => {
    const div = scroller.current;
    if (div) {
      const isEndReached =
        div.scrollHeight - div.scrollTop === div.clientHeight;
      if (isEndReached) {
        props.onScrolledToBottom();
      }
    }
  };
  return (
    <div className="hidden sm_md:block border-2 border-primary rounded-[20px]   w-full px-[clamp(0px,4vw,26px)] py-[29px] ">
      <div
        className="scrollbar scrollbar-thumb-secondary scrollbar-track-[#3B3B3B] scrollbar-w-[4px] overflow-auto max-h-[466px] "
        ref={scroller}
        onScroll={handleScroll}
      >
        {props.table.map((data, index) => {
          return (
            <div>
              <table className="w-full ">
                <TableRow>
                  <TableHeading text="Competition Name" />
                  <TableData text={data.competitionName} />
                </TableRow>
                <TableRow>
                  <TableHeading text="Date / Time" />
                  <TableData text={data.DateTime} />
                </TableRow>
                <TableRow>
                  <TableHeading text="Amount" />
                  <TableData text={data.Amount} />
                </TableRow>
                <TableRow>
                  <TableHeading text="Running total" />
                  <TableData text={data.RunningTotal} />
                </TableRow>
                <button
                  onClick={() => navigateToDetail(data.uid)}
                  className="bg-primary w-[calc(100%-19px)] h-[24px] uppercase rounded-[17.12px] text-[14px] font-[Sequel100Wide95]"
                >
                  Top Up
                </button>
              </table>
              {index !== props.table.length - 1 && (
                <div className="border-b-2 border-primary w-[calc(100%-19px)] h-[1px] my-[20px]"></div>
              )}
              <LinearProgress
                className={
                  "w-full rounded-b-[30px] " +
                  (props.isFetching ? "opacity-100" : "opacity-0")
                }
                color="secondary"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
