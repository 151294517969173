import React, { useEffect, useState } from "react";
import { Text } from "../react-responsive-utilities/src";

const LiveCompetitionFAQ = ({
  className,
  fontSize,
}: {
  className: string;
  fontSize: string;
}) => {
  const [active, setActive] = useState(-1);
  const [page, setPage] = useState(0);

  const pageData = [
    {
      range: "1-500",
      index: 0,
    },
    {
      range: "501-1000",
      index: 1,
    },
    {
      range: "1001-1500",
      index: 2,
    },
    {
      range: "1501-2000",
      index: 3,
    },
    {
      range: "2001-2500",
      index: 4,
    },
    {
      range: "2501-3000",
      index: 5,
    },
    {
      range: "3001-3500",
      index: 6,
    },
    {
      range: "3501-4000",
      index: 7,
    },
    {
      range: "4001-4500",
      index: 8,
    },
    {
      range: "4501-5000",
      index: 8,
    },
  ];

  const ticketsData = [
    {
      number: 1,
      date: "2024-08-24 13:52:33",
      name: "Donald Lewis",
    },
    {
      number: 2,
      date: "2024-08-24 13:52:33",
      name: "Donald Lewis",
    },
    {
      number: 3,
      date: "2024-08-24 13:52:33",
      name: "Donald Lewis",
    },
    {
      number: 4,
      date: "2024-08-24 13:52:33",
      name: "Donald Lewis",
    },
    {
      number: 5,
      date: "2024-08-24 13:52:33",
      name: "Donald Lewis",
    },
    {
      number: 6,
      date: "2024-08-24 13:52:33",
      name: "Donald Lewis",
    },
    {
      number: 7,
      date: "2024-08-24 13:52:33",
      name: "Donald Lewis",
    },
    {
      number: 8,
      date: "2024-08-24 13:52:33",
      name: "Donald Lewis",
    },
    {
      number: 9,
      date: "2024-08-24 13:52:33",
      name: "Donald Lewis",
    },
    {
      number: 10,
      date: "2024-08-24 13:52:33",
      name: "Donald Lewis",
    },
    {
      number: 11,
      date: "2024-08-24 13:52:33",
      name: "Donald Lewis",
    },
    {
      number: 12,
      date: "2024-08-24 13:52:33",
      name: "Donald Lewis",
    },
    {
      number: 13,
      date: "2024-08-24 13:52:33",
      name: "Donald Lewis",
    },
    {
      number: 14,
      date: "2024-08-24 13:52:33",
      name: "Donald Lewis",
    },
    {
      number: 15,
      date: "2024-08-24 13:52:33",
      name: "Donald Lewis",
    },
    {
      number: 16,
      date: "2024-08-24 13:52:33",
      name: "Donald Lewis",
    },
    {
      number: 17,
      date: "2024-08-24 13:52:33",
      name: "Donald Lewis",
    },
    {
      number: 18,
      date: "2024-08-24 13:52:33",
      name: "Donald Lewis",
    },
    {
      number: 19,
      date: "2024-08-24 13:52:33",
      name: "Donald Lewis",
    },
    {
      number: 20,
      date: "2024-08-24 13:52:33",
      name: "Donald Lewis",
    },
  ];

  const faqs = [
    {
      id: 1,
      content: "1. What is ThePrize.io?",
      description:
        "ThePrize.io is a crypto-based competition platform where you can win exciting digital prizes and luxury items by entering various competitions using both cryptocurrency and soon fiat. ",
    },
    {
      id: 2,
      content: "2. How do I enter a competition?",
      description:
        "To enter, browse our available competitions, select the number of tickets you want, answer a simple question, and add your tickets to the cart. Once you've completed the purchase, you're officially in the running! ",
    },
    {
      id: 3,
      content: "3. Does ThePrize.io have a gambling licence? ",
      description:
        "No, due to the way the platform is structured, we are free from gambling regulations by law in Europe and many other jurisdictions. ",
    },
    {
      id: 4,
      content: "4. What cryptocurrencies are accepted? ",
      description:
        "We accept a range of popular cryptocurrencies, including Bitcoin (BTC), Ethereum (ETH), Tron (TRX), Base (BASE) and Solana (SOL) with more being added soon. ",
    },
    {
      id: 5,
      content: "5. How are winners selected?",
      description:
        "Winners are chosen using Chainlink VRF RNG, ensuring a fair and transparent process. You will be able to view the tx hash of the draw on our site, and will be notified if you have won. ",
    },
    {
      id: 6,
      content: "6. When do you announce the winners?",
      description:
        "Winners are announced in the Telegram Announcements group and across our socials once the competition timer runs out or when all tickets are sold, you will also be notified in the MY ACCOUNT section if you have won. ",
    },
    {
      id: 7,
      content: "7. What happens if I win?",
      description:
        "If you win, you’ll receive your prize in the form of cryptocurrency directly to your digital wallet. We may also feature your username in our Winners Hall of Fame! If you have won a luxury item, we will contact you to arrange delivery.. ",
    },
    {
      id: 8,
      content: "8. Is there a limit to the number of tickets I can buy?",
      description:
        "The number of tickets you can purchase per competition may vary. Please check the specific competition's details for any limitations. ",
    },
    {
      id: 9,
      content: "9. What if the competition doesn’t sell out?",
      description:
        "If a competition doesn't sell out by the end of the timer, a winner will still be drawn from the total number of tickets available. If after 20 RNG draws it hasn’t landed on a purchased ticket, the prize will be rolled over to the following draw. ",
    },
    {
      id: 10,
      content: "10. Can I get a refund if I change my mind?",
      description:
        "All ticket purchases are final. Once you've entered a competition, refunds are not available, so please ensure you’re confident before buying your tickets. ",
    },
    {
      id: 11,
      content: "11. How do I contact customer support?",
      description:
        "For any inquiries or issues, you can reach our customer support team through the contact form on our website or by emailing us directly at contact@theprize.io. ",
    },
    {
      id: 12,
      content: "12. How do I stay updated on new competitions?",
      description:
        'To stay updated on our latest competitions and announcements, follow us on social media: <b><a href="http://t.me/theprizeannouncements" target="_blank">Telegram</a></b> & <b><a href="https://x.com/theprize_io" target="_blank">X</a></b>.',
    },
    {
      id: 13,
      content: "13. Can I participate from anywhere in the world?",
      description:
        "Yes, ThePrize.io is an international platform, and you can participate from anywhere as long as local laws permit. ",
    },
  ];

  const changeDropdown = (index: number) => {
    if (active === index) {
      setActive(-1);
    } else {
      setActive(index);
    }
  };

  const pageContainer = (items: Array<any>) => {
    return (
      <div className="flex flex-row flex-wrap gap-y-3 gap-x-2 justify-center ml-[20px] mr-[20px]">
        {items.map((item, index) => (
          <div
            key={index}
            onClick={() => setPage(item.index)}
            className={`xl:w-[237px] lg:w-[237px] md:w-[237px] sm:w-[207px] min-w-[119px] flex-shrink:1 h-[47px] flex items-center justify-center rounded-[40px] border ${
              index === page ? "border-[#FFFFFF]" : "border-none"
            } ${index === page ? "bg-primary" : ""} `}
            style={{
              backgroundColor:
                index !== page ? "rgba(255, 255, 255, 0.15)" : undefined,
            }}
          >
            <p
              className={`font-['Sequel100Wide-65'] text-[12px] font-normal ${
                index === page ? "text-[#280508]" : "text-[#FFFFFF]"
              }`}
              style={{
                fontSize: "clamp(1px, 2.815621311vw, 14.18px)",
              }}
            >
              {item.range}
            </p>
          </div>
        ))}
      </div>
    );
  };

  return (
    // <div
    //   className={`${className} w-full p-10 faqContainer sm:p-5 pt-3 relative z-[100001]`}
    // >

    <div className="w-full   relative mt-[3%]  overflow-hidden">
      <div className="absolute -bottom-40 xl:-left-96  lg:-left-60 md:-left-40   sm:hidden w-1/2 h-1/2 bg-gradient-to-tr from-[rgba(239,0,143,0.6)] to-[#2B2B2B] rounded-full transform translate-x-1/4 translate-y-1/4 blur-lg"></div>

      <div className="w-auto h-auto flex flex-col align-center items-center bg-[rgba(35,35,35,0.6)] mr-[3%] ml-[3%] rounded-3xl">
        <div className="xl:h-[58.65px] lg:h-[58.65px] md:h-[40px] sm:h-[30px]"></div>
        <Text
          textSize={[30, 30.0, 50.0]}
          className="text-primary text-center font-['Sequel100Wide95'] //leading-[35px] leading-normal xl:font-[60] lg:font-[60] md:font-[45] sm:font-[30] block "
        >
          ENTRIES
        </Text>
        <div className="xl:h-[58.65px] lg:h-[58.65px] md:h-[40px] sm:h-[30px]"></div>

        {pageContainer(pageData)}

        <div className="xl:h-[58.65px] lg:h-[58.65px] md:h-[40px] sm:h-[30px]"></div>

        {/* Entries mobile Box */}

        <div
          className="relative 
  w-[90%] 
  xl:hidden
  lg:hidden
  h-[553px] 
  border border-primary 
  rounded-3xl 
 overflow-hidden
"
        >
          <div
            className="md:p-8 
  lg:p-8 
  xl:p-8 
  sm:p-0                      
  h-auto 
  max-h-[483px] 
                           overflow-y-auto
  custom-scrollbar"
          >
            {ticketsData.map((item, index) => (
              <div className="my-[3%] mx-[5%]">
                <div className="flex flex-row justify-between   ">
                  <p className="font-['Sequel100WideVF45'] text-[12px] text-left text-white text-opacity-50 ">
                    Ticket Number
                  </p>
                  <p className="font-['Sequel100Wide-65'] text-[12px] text-right text-white ">
                    {item.number}
                  </p>
                </div>

                <div className="flex flex-row justify-between    ">
                  <p className="font-['Sequel100WideVF45'] text-[12px] text-left text-white text-opacity-50 ">
                    Date
                  </p>
                  <p className="font-['Sequel100Wide-65'] text-[12px] text-right text-white ">
                    {item.date}
                  </p>
                </div>

                <div className="flex flex-row justify-between    ">
                  <p className="font-['Sequel100WideVF45'] text-[12px] text-left text-white text-opacity-50 ">
                    Name
                  </p>
                  <p className="font-['Sequel100Wide-65'] text-[12px] text-right text-white ">
                    {item.name}
                  </p>
                </div>

                <hr className="max-w-full w-full border-b-[1px] border-t-0 border-primary mt-[10px] sm:block md:block h-[3.14px] rounded-full" />
              </div>
            ))}
          </div>
          <div className="h-[15px]"></div>

          <div className="flex  flex-row align-center justify-center ml-[12px] mr-[12px]">
            <img
              //src="/Images/THEPRIZE_TokenLogo_EH-03.svg"
              src="/Images/left_arrow.svg"
              alt=""
              className="h-[21px] w-[16px] "
            />
            <p className="font-['Sequel100WideVF45'] text-[15px] text-center text-white text-opacity-50 ml-8 mr-8">
              PAGE 1
            </p>

            <img
              //src="/Images/THEPRIZE_TokenLogo_EH-03.svg"
              src="/Images/right_arrow.svg"
              alt=""
              className="h-[21px] w-[16px] "
            />
          </div>
          <div className="h-[15px]"></div>
        </div>

        {/* Entries desktop Box */}

        <div
          className="relative 
  w-[90%] 
  md:hidden
  sm:hidden
  max-h-[1236px] 
  border border-primary 
  rounded-3xl 
 
"
        >
          <div className="flex flex-row justify-between   my-[23px] ">
            <p className="font-['Sequel100Wide'] text-[20px] text-center text-white ml-[50px]">
              Ticket Number
            </p>
            <p className="font-['Sequel100Wide'] text-[20px] text-center text-white mr-[190px]">
              Date
            </p>
            <p className="font-['Sequel100Wide'] text-[20px] text-center text-white mr-[120px]">
              Name
            </p>
          </div>

          <hr className="max-w-full w-full border-b-[1px] border-t-0 border-primary  sm:block md:block h-[3.14px] rounded-full" />

          {ticketsData.map((item, index) => (
            <div className="flex flex-row justify-between  mx-[100px] my-[23px]">
              <p className="font-['Sequel100WideVF45'] text-[15px] text-center text-white text-opacity-50 ">
                {"             " + item.number}
              </p>
              <p className="font-['Sequel100WideVF45'] text-[15px] text-center text-white text-opacity-50">
                {item.date}
              </p>
              <p className="font-['Sequel100WideVF45'] text-[15px] text-center text-white text-opacity-50">
                {item.name}
              </p>
            </div>
          ))}

          <div className="flex flex-row justify-center"></div>

          {/* {numContainer(numbers)} */}

          <div className="xl:h-[27px] lg: h-[27px]"></div>

          <hr className="max-w-[1140px] w-full border-b-[1px] border-t-0 border-primary h-[3.14px] mx-auto" />

          <div
            className="flex  flex-row align-center justify-between  p-4 
  sm:pl-[6px] 
sm:pr-[6px] 
  md:pl-[10px]
     md:pr-[10px]
  lg:pl-[32px] 
    lg:pr-[32px] 
    xl:pl-[45px] 
    xl:pr-[45px] 
  mt-[20px]
    mb-[40px]
  "
          >
            <img
              //src="/Images/THEPRIZE_TokenLogo_EH-03.svg"
              src="/Images/left_arrow.svg"
              alt=""
              className="h-[21px] w-[16px] "
            />
            <p className="font-['Sequel100Wide'] text-[15px] text-center text-white text-opacity-50 ">
              PAGE 1
            </p>

            <img
              //src="/Images/THEPRIZE_TokenLogo_EH-03.svg"
              src="/Images/right_arrow.svg"
              alt=""
              className="h-[21px] w-[16px] "
            />
          </div>

          <div></div>
        </div>
        <div className="xl:h-[58.65px] lg:h-[58.65px] md:h-[40px] sm:h-[30px]"></div>
      </div>

      {/* FAQS */}
      <div className="flex flex-col items-center liveCompetitionPage  pt-0  sm:ml-0 sm:p-3">
        <h1
          className={`pageHeading font-[Sequel100Wide95]`}
          style={{
            fontSize: "clamp(1px, 6.527976190476191vw, 45px)",
          }}
        >
          FAQS
        </h1>
      </div>
      <div className="flex flex-col gap-4 mx-[3%]">
        {faqs.map((faq, index) => (
          <div
            onClick={() => {
              changeDropdown(index);
            }}
            className="dropdown"
          >
            <h3
              className="w-full bg-primary flex items-center gap-8 //text-[25px] //sm:text-[17px] text-[#1A1A1A] font-[Sequel100Wide95] h-[68px] pl-[1em] rounded-full cursor-pointer"
              style={{
                fontSize: "clamp(14px,3.496512479vw,24.82px)",
              }}
              key={index}
            >
              <img src="/Images/dropdown.png" alt="" />
              {faq.content}
            </h3>
            {active === index && (
              <div className="border-4 flex-wrap font-['Sequel100WideVF45'] border-primary mt-[-30px] border-t-0 flex w-full gap-4 items-end sm:flex-col pt-[4em] py-[2em] sm:px-[8%] rounded-b-[1.5rem]">
                <ol className="text-white  flex flex-col gap-4 sm:ml-0 w-[90%] m-auto sm:text-[10px] ">
                  {faqs.map((des, index) => (
                    <li key={index}>{des.description}</li>
                  ))}
                </ol>
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="h-[15px]"></div>
    </div>
  );
};

export default LiveCompetitionFAQ;
