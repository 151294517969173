import { useNavigate } from "react-router-dom";
import HSpacer from "../../../common/Spacer/HSpacer";
import { useRef } from "react";
import { LinearProgress } from "@mui/material";

interface OrdersTableTicketsProps {
  isFetching: boolean;
  onScrolledToBottom: () => void;
  table: tableData[];
}

const TableHeading = ({ text }: { text: string }) => (
  <th className="font-[Sequel100Wide-65] text-[20px] text-white text-center uppercase text-wrap flex items-center">
    {text}
  </th>
);

const TableData = ({
  text,
  isTicketsBought,
}: {
  text: string;
  isTicketsBought?: boolean;
}) => (
  <td
    className={`font-[Sequel100WideVF45] h-[50px] text-[14px]  flex justify-end  ${
      isTicketsBought ? "place-self-start" : "place-self-center"
    } `}
  >
    <p
      className={`w-fit ${
        isTicketsBought ? "text-[#8a8a8a] text-left" : "text-white text-center"
      }`}
    >
      {text}
    </p>
  </td>
);
export default function OrdersTableTickets(props: OrdersTableTicketsProps) {
  const navigate = useNavigate();
  const navigateToDetail = (uid: string) => {
    navigate(`/view/order/${uid}`);
  };
  const scroller = useRef<HTMLDivElement | null>(null);
  const handleScroll = () => {
    const div = scroller.current;
    if (div) {
      const isEndReached =
        div.scrollHeight - div.scrollTop === div.clientHeight;
      if (isEndReached) {
        props.onScrolledToBottom();
      }
    }
  };
  return (
    <div
      ref={scroller}
      onScroll={handleScroll}
      className="sm_md:hidden border-2 border-primary rounded-[20px] overflow-auto  w-full  "
    >
      {/* Headings */}
      <div className="grid grid-cols-5 gap-[43px] uppercase place-content-center place-items-center border-b-[2px] border-primary px-[clamp(0px,4vw,38px)] py-[29px]">
        <TableHeading text="Competition Name" />
        <TableHeading text="Date / Time" />
        <TableHeading text="Amount" />
        <TableHeading text="Running total" />
      </div>
      {/* Data */}
      <div className="px-[clamp(0px,4vw,38px)] py-[29px] w-full">
        <div className="scrollbar scrollbar-thumb-secondary scrollbar-track-[#3B3B3B] scrollbar-w-[4px] overflow-auto max-h-[586px] w-full">
          <div className="grid grid-cols-5 gap-x-[43px] ">
            {props.table.map((data, index) => {
              return (
                <>
                  <TableData text={data.competitionName} />
                  <TableData text={data.DateTime} />
                  <TableData text={data.Amount} />
                  <TableData text={data.RunningTotal} />
                  <div className="h-[50px] w-full 500 m-auto">
                    <button
                      onClick={() => navigateToDetail(data.uid)}
                      className="bg-primary max-w-[139px] w-full h-[24px] uppercase rounded-[17.12px] text-[14px] font-[Sequel100Wide95]"
                    >
                      Top Up
                    </button>
                  </div>
                </>
              );
            })}
          </div>
        </div>
        <LinearProgress
          className={
            "w-full rounded-b-[30px] " +
            (props.isFetching ? "opacity-100" : "opacity-0")
          }
          color="secondary"
        />
      </div>
    </div>
  );
}
