import { Common } from "../../../common/Common";

interface CompetitionResultCaptionProps {
  varient: "lost" | "won";
}
export default function CompetitionResultCaption(
  props: CompetitionResultCaptionProps
) {
  return (
    <Common.typography
      fontFamily="Sequel100WideVF45"
      fontSizeSmall="12px"
      fontSize="13.6px"
      color="#000"
      className="leading-none"
    >
      {props?.varient === "won"
        ? "A member of our team will reach out to you to give you your prize. Congrats!"
        : "Sadly you didn't win it this time, but see our current competitions to enter more!"}
    </Common.typography>
  );
}
