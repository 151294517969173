import { Common } from "../../../common/Common";

interface FinishedBtnProps {
  onClick?: () => void;
}
export default function FinishedBtn(props: FinishedBtnProps) {
  return (
    <Common.button
      onClick={props.onClick}
      fontFamily="Sequel100Wide85"
      fontSize="24.52px"
      fontSizeSmall="19.43px"
      maxWidthBig="100%"
      maxWidthSmall="100%"
      heightBig="51.93px"
      heightSmall="51.93px"
      paddingX="1rem"
      text="Finished"
      className="flex items-center justify-center uppercase"
      backgroundColor="#444444"
      roundness="12.05px"
      color="#838383"
    />
  );
}
