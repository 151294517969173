interface CategoryContainerProps {
  children?: React.ReactNode;
}
export default function CategoryContainer(props: CategoryContainerProps) {
  return (
    <div className="flex gap-x-[27.16px] gap-y-[10px] justify-center items-center sm_md:flex-col w-full">
      {props.children}
    </div>
  );
}
