import { Common } from "../../../common/Common";
interface DescriptionProps {
  text: string;
}
export default function Description(props: DescriptionProps) {
  return (
    <Common.typography
      className="sm_md:text-center"
      fontFamily="Sequel100WideVF45"
      fontSizeSmall="14px"
      fontSize="16.3px"
      color="#fff"
    >
      {props.text}
    </Common.typography>
  );
}
