import React from "react";
import axios from "axios";
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
  FC,
  ReactNode,
} from "react";
import { getUserDetails } from "../Services/userService";

// Define a type for the authentication context
interface User {
  billingAddress: string | null;
  billingDetails: string | null;
  numberOfTickets: number;
  address1: string | null | undefined;
  address2: string | null | undefined;
  boomfisubscriptionId: string | null | undefined;
  country: string | null | undefined;
  city: string | null | undefined;
  postcode: string | null | undefined;
  name: string | null | undefined;
  phone: string | null | undefined;
  userName: string | null | undefined;
  telegramHandle: string | null | undefined;
  imageUrl: string | null | undefined;
}

type AuthContextType = {
  user: User | null;
  token: string | null;
  setToken: (newToken: string | null) => void;
  fetchUser: () => void;
};

// Create the authentication context with initial types
const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Define the component's props type to accept children nodes
type AuthProviderProps = {
  children: ReactNode;
};

const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  // State to hold the authentication token, initialized from local storage
  const [token, setToken_] = useState<string | null>(
    localStorage.getItem("token")
  );
  const [user, setUser] = useState<User | null>(null);

  // Function to set the authentication token
  const setToken = (newToken: string | null) => {
    setToken_(newToken);
  };

  useEffect(() => {
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      localStorage.setItem("token", token);
      fetchUser();
    } else {
      delete axios.defaults.headers.common["Authorization"];
      localStorage.removeItem("token");
      setUser(null);
    }
  }, [token]);

  const fetchUser = async () => {
    try {
      const email = localStorage.getItem("emailaddress");
      const user = await getUserDetails(email || "");
      setUser(user);
    } catch (error) {
      console.log(error);
      setToken(null);
    }
  };

  // Memoized value of the authentication context
  const contextValue = useMemo(
    () => ({
      token,
      setToken,
      user,
      fetchUser,
    }),
    [token, user]
  );

  // Provide the authentication context to the children components
  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export default AuthProvider;
