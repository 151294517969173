import React, { useState } from "react";

export interface InputProps {
  type: "text" | "number" | "phone" | "password" | "email";
  placeholder?: string;
  value?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
}

const Input: React.FC<InputProps> = ({
  type,
  value,
  onChange,
  placeholder,
  className,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="relative">
      <input
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        type={type === "password" && showPassword ? "text" : type}
        className={`${className} xl:px-[24px] xl:py-[9px]
        !bg-[#505050] placeholder-[#2b2b2b] px-[17px]
        py-[7px] input rounded-full text-white pl-4 pr-10`} // Adjust padding for icon
      />
      {type === "password" && (
        <span
          onClick={togglePasswordVisibility}
          className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer text-white"
        >
          {showPassword ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-eye-off"
            >
              <path d="M17.94 17.94a10.47 10.47 0 01-5.94 2.06C7.61 20 3.73 17.24 1 12c1.57-2.92 3.9-5.19 6.71-6.58"></path>
              <path d="M1 1l22 22"></path>
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-eye"
            >
              <path d="M1 12c2.73-5.24 6.61-8 11-8s8.27 2.76 11 8c-2.73 5.24-6.61 8-11 8s-8.27-2.76-11-8z"></path>
              <circle cx="12" cy="12" r="3"></circle>
            </svg>
          )}
        </span>
      )}
    </div>
  );
};

export default Input;
