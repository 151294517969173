interface SecondSectionProps {
  children?: React.ReactNode;
}
export default function SecondSection(props: SecondSectionProps) {
  return (
    <div className="grid grid-cols-8 sm_md:grid-cols-3 gap-[10px]">
      {props.children}
    </div>
  );
}
