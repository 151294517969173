import React from "react";
import { Container, Text } from "../react-responsive-utilities/src";

export interface HowItWorksBoxProps {
  icon: string;
  title: string;
  content: string;
}

const HowItWorksBox: React.FC<HowItWorksBoxProps> = ({
  icon,
  title,
  content,
}) => {
  return (
    <Container
      width={[0, 140.9, 443.09]}
      height={[0, 297.13, 478.92]}
      className="font-['Sequel100WideVF'] overflow-hidden min-w-0  bg-secondary relative flex flex-col items-center h-[32rem] //max-w-[465px] w-full gap-6 pt-[4em] sm:w-[465px] sm:h-[350px] rounded-3xl shadow-2xl shadow-black md:!h-[38vw]"
    >
      <div className="flex flex-col w-full justify-center items-center h-[35%] gap-4 ">
        <Container
          width={[0, 57.48, 92.64]}
          height={[0, 37.57, 60.56]}
          className="//sm:w-[64px] //h-[5rem] relative z-[10001] sm:bottom-6"
        >
          <img src={icon} alt="" className="h-full w-full" />
        </Container>
        <img
          src={"/Images/wheel_big.png"}
          className="absolute opacity-[0.2] w-[30rem] bottom-0 sm:top-10"
        />

        <Text
          textSize={[0, 15.51, 25]}
          className="//text-[25px] leading-tight //sm:text-[15px] sm:bottom-6 text-white text-center  md:w-full sm:w-[12rem] z-[10001] font-['Sequel100Wide95'] md:!text-[2vw]"
        >
          {title}
        </Text>
      </div>

      <div className="flex flex-col w-full justify-start items-center h-fit gap-3">
        <Container
          width={[0, 119.74, 193]}
          height={[0, 1.24, 2]}
          className="md:!w-[80%] lg:!w-[80%]"
        >
          <hr className="w-full h-full border-white border-[100%] z-[10001]" />
        </Container>
        <Text
          textSize={[0, 9.31, 15]}
          className=" text-white p-2 text-center //text-[15px] //sm:text-[9.31px] sm:w-[195px] z-[10001] md:!text-[1vw] md:w-full"
        >
          {content}
        </Text>
      </div>
    </Container>
  );
};

export default HowItWorksBox;
