import { CircularProgress } from "@mui/material";
import React from "react";

export interface ButtonProps {
  className?: string;
  content: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  loading?: boolean;
  disabled?: boolean;
  customStyle?: React.CSSProperties;
}

const Button: React.FC<ButtonProps> = ({
  className,
  content,
  onClick,
  loading,
  disabled,
  customStyle,
}) => {
  return (
    <button
      disabled={disabled || loading}
      className={`${className} ${
        disabled ? "bg-black" : "bg-primary"
      } rounded-full`}
      onClick={onClick}
      style={customStyle}
    >
      {loading ? (
        <CircularProgress size={20} color="warning" />
      ) : (
        <>{content ? content : "BUY TICKETS"}</>
      )}
    </button>
  );
};

export default Button;
// "bg-primary h-[75px] text-[#2D2022] text-[29px] font-extrabold w-[245px] p-4 "
