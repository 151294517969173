import { useEffect, useState } from "react";
import WithNonAbsolutedSidebarNavbarFooter from "../../../Layout/WithNonAbsolutedSidebarNavbarFooter";
import VSpacer from "../../../Components/common/Spacer/VSpacer";
import CategoryContainer from "../../../Components/User Pages Components/View Result/Containers/Category/CategoryContainer";
import Category from "../../../Components/User Pages Components/View Result/Others/Category";
import DetailsContainer from "../../../Components/User Pages Components/View Result/Containers/Details/DetailsContainer";
import FirstSection from "../../../Components/User Pages Components/View Result/Containers/Details/FirstSection";
import { Common } from "../../../Components/common/Common";
import Heading from "../../../Components/User Pages Components/View Result/Texts/Heading";
import Title from "../../../Components/User Pages Components/View Result/Texts/Title";
import Description from "../../../Components/User Pages Components/View Result/Texts/Description";
import Timer from "../../../Components/User Pages Components/View Result/Others/Timer";
import SecondSection from "../../../Components/User Pages Components/View Result/Containers/Details/SecondSection";
import TicketSectionText from "../../../Components/User Pages Components/View Result/Texts/TicketSectionText";
import Ticket from "../../../Components/User Pages Components/View Result/Others/Ticket";
import BackBtn from "../../../Components/User Pages Components/View Result/Buttons/BackBtn";
import DataTitle from "../../../Components/User Pages Components/View Result/Texts/DataTitle";
import DataInfo from "../../../Components/User Pages Components/View Result/Texts/DataInfo";
import Result from "../../../Components/User Pages Components/View Result/Texts/Result";
import ResultBig from "../../../Components/User Pages Components/View Result/Containers/Result/ResultBig";
import ResultImage from "../../../Components/User Pages Components/View Result/Others/ResultImage";
import CompetitionResultText from "../../../Components/User Pages Components/View Result/Texts/CompetitionResultText";
import CompetitionResultCaption from "../../../Components/User Pages Components/View Result/Texts/CompetitionResultCaption";
import ResultSmall from "../../../Components/User Pages Components/View Result/Containers/Result/ResultSmall";
import CompetitionResultTextSmall from "../../../Components/User Pages Components/View Result/Texts/CompetitionResultTextSmall";
import FinishedBtn from "../../../Components/User Pages Components/View Result/Buttons/FinishedBtn";
import { useNavigate, useParams } from "react-router-dom";
import {
    getCompetitionStatus,
    getEnteredCompetition,
} from "../../../Services/userService";

export default function ViewResult() {
    const [competition, setCompetition] = useState<any>();

    const params = useParams();
    useEffect(() => {
        document.getElementById("view_entry_main")?.scrollIntoView();
        getEnteredCompetition().then((res: any) => {
            if (res.data) {
                setCompetition(
                    res.data.find((competition: any) => {
                        return competition?.UID === params["id"];
                    })
                );
            }
        });
    }, []);
    const [won, setWon] = useState(false);
    const navigate = useNavigate();
    const navigateTo = (path: "live" | "finished") => {
        navigate(`/entries/${path}`);
    };

    useEffect(() => {
        const fetchCompetitionStatus = async () => {
            const status = await getCompetitionStatus(competition?.UID);

            if (status?.status.toLowerCase() === "won") {
                setWon(true);
            } else {
                setWon(false);
            }
        };

        if (competition?.UID && localStorage.getItem("token")) {
            fetchCompetitionStatus();
        }
    }, [competition]);

    const tickets = [
        "000001",
        "000001",
        "000001",
        "000001",
        "000001",
        "000001",
        "000001",
        "000001",
        "000001",
        "000001",
        "000001",
        "000001",
        "000001",
        "000001",
        "000001",
        "000001",
    ];

    return (
        <WithNonAbsolutedSidebarNavbarFooter
            Component={
                <div
                    id="view_entry_main"
                    className="overflow-hidden box-border bg-black w-full sm_md_lg:mx-auto sm_md_lg:w-[calc(100%-20px)]  sm_md_lg:my-[20px] xl:my-8 h-full mx-[43px] rounded-[30px] py-[63px] px-[clamp(0px,4vw,57px)] sm_md:py-[44px] sm_md:px-[11px] flex flex-col items-center justify-center "
                >
                    <Heading />
                    <VSpacer big={60} small={28} />
                    <CategoryContainer>
                        <Category
                            text="Live Competitions"
                            isActive={false}
                            onClick={() => {
                                navigateTo("live");
                            }}
                        />
                        <Category
                            text="Finished Competitions"
                            isActive={true}
                            onClick={() => {
                                navigateTo("finished");
                            }}
                        />
                    </CategoryContainer>
                    <VSpacer big={75} small={27.92} />
                    <DetailsContainer>
                        <FirstSection
                            left={
                                <Common.image
                                    src="/Images/leftAvatar.svg"
                                    height="383.06px"
                                    width="393.92px"
                                    heightSmall="317px"
                                    //widthSmall="326px"
                                    widthSmall="100%"
                                    alt="Left Avatar"
                                />
                            }
                            right={
                                <div className="sm_md:flex sm_md:flex-col sm_md:justify-center sm_md:items-center">
                                    <Title
                                        text={competition?.competitionname}
                                    />
                                    <VSpacer big={14.92} small={20.92} />
                                    <div className="bg-[#EF008F] h-[4.53px] w-[289.95px] sm_md:w-full" />
                                    <VSpacer big={13.58} small={16.55} />
                                    <Description
                                        text={
                                            competition?.competitioninformation
                                        }
                                    />
                                    <VSpacer big={34.1} small={24} />
                                    <Timer finished />
                                    <VSpacer big={46.77} small={23} />
                                    <FinishedBtn
                                        onClick={() => {
                                            navigate(
                                                `/competition/${competition?.UID}`
                                            );
                                        }}
                                    />
                                    <VSpacer big={51.07} small={27.07} />
                                </div>
                            }
                        />
                        <hr className="border-primary w-full" />
                        <VSpacer big={21} small={18} />
                        <TicketSectionText />
                        <VSpacer big={21.07} small={19} />
                        <SecondSection>
                            {tickets.map((ticket, index) => {
                                return <Ticket key={index} text={ticket} />;
                            })}
                        </SecondSection>
                        <VSpacer big={27.5} small={23.36} />
                        <hr className="border-primary w-full" />
                        <VSpacer big={28.5} small={29.39} />
                        {/* <DataTitle text="Draw Date" /> */}
                        {/* <DataInfo text="xx/xx/xx" /> */}
                        <VSpacer big={28.5} small={29.39} />
                        <DataTitle text="Prize Transfer TX Hash" />
                        <DataInfo text="xxxxxxxxxxxxxxxxxxxxxxxxxx" />
                        <VSpacer big={28.5} small={29.39} />
                        <DataTitle text="Blockchain RNG TX Hash" />
                        <DataInfo text="xxxxxxxxxxxxxxxxxxxxxxxxxx" />
                        <VSpacer big={44.34} small={27.48} />
                        <Result />
                        <ResultBig
                            varient={won ? "won" : "lost"}
                            left={<ResultImage varient="big" />}
                            right={
                                <div>
                                    <CompetitionResultText
                                        varient={won ? "won" : "lost"}
                                    />
                                    <CompetitionResultCaption
                                        varient={won ? "won" : "lost"}
                                    />
                                </div>
                            }
                        />
                        <ResultSmall
                            varient={won ? "won" : "lost"}
                            top={
                                <div className="flex justify-start items-center mb-[14px] gap-[13px]">
                                    <ResultImage varient="small" />
                                    <CompetitionResultTextSmall
                                        varient={won ? "won" : "lost"}
                                    />
                                </div>
                            }
                            bottom={
                                <div className="border-t-2 border-black pt-[14px] flex justify-center items-center text-center ">
                                    <CompetitionResultCaption
                                        varient={won ? "won" : "lost"}
                                    />
                                </div>
                            }
                        />
                        <VSpacer big={22.07} small={18} />
                        <BackBtn />
                    </DetailsContainer>
                </div>
            }
        />
    );
}
