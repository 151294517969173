import { Common } from "../../common/Common";
import ChainlinkHashContainer from "../containers/ChainlinkHashContainer";
import ChainlinkHashText from "../texts/ChainlinkHashText";

interface ChainlinkHashProps {
  value: number;
}
export default function ChainlinkHash(props: ChainlinkHashProps) {
  return (
    <ChainlinkHashContainer>
      <ChainlinkHashText />

      <Common.image
        src="/Images/copy-dark.svg"
        alt="copy"
        height="40px"
        heightSmall="21.18px"
        width="40px"
        widthSmall="21.18px"
        onClick={() => {
          navigator.clipboard.writeText(props.value.toString());
        }}
      />
    </ChainlinkHashContainer>
  );
}
