import { Common } from "../../../common/Common";

interface CategoryProps {
  text: string;
  isActive: boolean;
  onClick: () => void;
}
export default function Category(props: CategoryProps) {
  return (
    <Common.button
      onClick={props.onClick}
      text={props.text}
      paddingX="1rem"
      //maxWidthBig="421.42px"
      maxWidthBig="100%"
      maxWidthSmall="100%"
      heightBig="72.54px"
      heightSmall="50.54px"
      border={props.isActive ? "1.71px solid #ffffff" : "0px"}
      roundness="34.13px"
      backgroundColor={props.isActive ? "#EF008F" : "#383838"}
      fontFamily={props.isActive ? "Sequel100Wide95" : "Sequel100WideVF55"}
      fontSizeSmall="18px"
      fontSize="20px"
      color={props.isActive ? "#ffffff" : "#ffffff"}
      className="uppercase"
    />
  );
}
