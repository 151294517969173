import { Common } from "../../common/Common";

export default function Loadmore() {
  return (
    <Common.button
      heightBig="32px"
      heightSmall="32px"
      maxWidthBig="100%"
      maxWidthSmall="100%"
      backgroundColor="transparent"
      border="0px"
      fontFamily="Sequel100WideVF45"
      fontSize="18px"
      fontSizeSmall="18px"
      color="#ffffff"
      className="uppercase xl:hidden"
    >
      LOAD MORE
    </Common.button>
  );
}
