import React from "react";
import WithNavbarAndFooter from "../Layout/WithNavbarAndFooter";
import ResetPassword from "./Dashboard/ResetPassword";

const ResetPasswordScreen = () => {
  return (
    <div className="hero p-10 sm:p-0 md:p-0 pt-0">
      <div
        className={`auth flex flex-col sm:rounded-[0rem] xl:rounded-[2rem] lg:rounded-[2rem] md:rounded-[2rem] items-center gap-[6em] sm:gap-[4em] p-[4em] sm:px-0 md:px-0 pb-[11em] w-full`}
      >
        <h1 className="pageHeading mb-[0rem]">RESET PASSWORD</h1>
        <ResetPassword />
      </div>
    </div>
  );
};

export default WithNavbarAndFooter(ResetPasswordScreen);
