import { Common } from "../../../common/Common";

const Counter = ({ text }: { text: string }) => {
  return (
    <div className="flex flex-row items-center justify-between px-[16px] h-full">
      <img src="/Images/clock.svg" alt="" className="w-[25.62px] h-[28.96px]" />
      <Common.typography
        fontSize="23.95px"
        fontSizeSmall="18.91px"
        fontFamily="Sequel100Wide95"
        color="#000000"
        className="text-center"
      >
        {text}
      </Common.typography>
      <img src="/Images/clock.svg" alt="" className="w-[25.62px] h-[28.96px]" />
    </div>
  );
};

export default Counter;
