import { Common } from "../../common/Common";

export default function ChainlinkHashText() {
  return (
    <Common.typography
      fontFamily="Sequel100WideVF45"
      fontSize="20px"
      fontSizeSmall="14.59px"
      color="#000000"
    >
      Chainlink VRF Tx Hash
    </Common.typography>
  );
}
