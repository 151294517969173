import { createSlice, type PayloadAction } from '@reduxjs/toolkit'


export interface SidebarObject {
    height: string,
}

const initialState: SidebarObject = {
    height: "",
}

export const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState,
    reducers: {
        setSideNavHeight: (state, action: PayloadAction<string>) => {
            state.height = action.payload
        }
    },

})

export const { setSideNavHeight } = sidebarSlice.actions

export default sidebarSlice.reducer