import PropTypes from "prop-types";
import { GetScreenWidth } from "../Responsive/Responsive";
import { HTMLAttributes } from "react";

interface ContainerProps extends HTMLAttributes<HTMLDivElement> {
  height: [number, number | "full" | "auto", number | "full" | "auto"];
  width: [number, number | "full" | "auto", number | "full" | "auto"];
  doNotResizeHeight?: boolean;
  doNotResizeWidth?: boolean;
  useMinHeight?: boolean;
  useMinWidth?: boolean;
  useMaxHeight?: boolean;
  useMaxWidth?: boolean;
}

const getSizeStyle = (
  minSize: number | undefined,
  currentSize: number | string,
  maxSize: number | string | undefined,
  screenSize: number,
  doNotResize: boolean
) => {
  if (doNotResize) {
    if (currentSize === "auto" || currentSize === "full") {
      return currentSize === "full" ? "100%" : "auto";
    }
    return `${currentSize}px`;
  }

  if (typeof currentSize === "number") {
    const sizeVw = (currentSize / screenSize) * 100;
    const minSizeValue = minSize ? `${minSize}px` : "0px";
    const maxSizeValue =
      maxSize === "auto"
        ? "auto"
        : maxSize === "full"
        ? "100%"
        : `${maxSize}px` || "100%";

    return `clamp(${minSizeValue}, ${sizeVw}vw, ${maxSizeValue})`;
  }

  return currentSize;
};

const Container = ({
  height,
  width,
  doNotResizeHeight = false,
  doNotResizeWidth = false,
  style = {},
  children,
  useMinHeight,
  useMinWidth,
  useMaxHeight,
  useMaxWidth,
  ...props
}: ContainerProps) => {
  const screenWidth = GetScreenWidth();

  const getHeight = getSizeStyle(
    height[0],
    height[1],
    height[2],
    screenWidth,
    doNotResizeHeight
  );

  const getWidth = getSizeStyle(
    width[0],
    width[1],
    width[2],
    screenWidth,
    doNotResizeWidth
  );

  const mergedStyle = {
    ...style,
    ...(useMinHeight && { minHeight: getHeight }),
    ...(useMinWidth && { minWidth: getWidth }),
    ...(useMaxHeight && { maxHeight: getHeight }),
    ...(useMaxWidth && { maxWidth: getWidth }),
    height: !useMinHeight && !useMaxHeight ? getHeight : undefined,
    width: !useMinWidth && !useMaxWidth ? getWidth : undefined,
  };

  return (
    <div style={mergedStyle} {...props}>
      {children}
    </div>
  );
};

Container.propTypes = {
  height: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(["auto", "full"])])
  ).isRequired,
  width: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(["auto", "full"])])
  ).isRequired,
  doNotResizeHeight: PropTypes.bool,
  doNotResizeWidth: PropTypes.bool,
  useMinHeight: PropTypes.bool,
  useMinWidth: PropTypes.bool,
  useMaxHeight: PropTypes.bool,
  useMaxWidth: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default Container;
