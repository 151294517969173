import React from "react";
import Input from "../Input";

export interface CompetitionStatusProps {
  //   image: string;
  //   name: string;
  //   prize: number;
  //   minTickets: number;
  //   competitionInfo: string;
  //   legalInfo: string;
  //   endDate: string;
  result: boolean;
}

const CompetitionStatus: React.FC<CompetitionStatusProps> = ({ result }) => {
  const compDetails = [
    { title: "Competition Name" },
    { title: "Competition Prize" },
    { title: "Minimum Tickets to Enter" },
    { title: "Competition Information" },
    { title: "Legal Information" },
    { title: "End Date" },
  ];

  return (
    <div className="sm:w-full sm:h-full ">
      <img
        src="/Images/half_supra.png"
        alt=""
        className="w-full h-[406px] sm:w-full sm:h-[328px]"
      />
      <div className="compDetails p-10 flex flex-col gap-3 sm:p-5 sm:h-full ">
        {compDetails.map((detail, index) => (
          <div key={index} className="flex flex-col gap-2">
            <p className="text-white font-semibold text-[20px] sm:text-base">
              {detail.title}
            </p>
            <Input
              type={"text"}
              onChange={(e) => console.log(e)}
              className="w-full h-[48px] sm:w-full sm:h-[39px]"
            />
          </div>
        ))}

        <h2 className="text-[45px] font-extrabold text-white">RESULT:</h2>
        {result ? (
          <>
            <div className=" flex items-center w-full gap-5 bg-primary p-3 pl-[3em] sm:hidden ">
              <h2 className="text-[40px] font-extrabold flex items-center gap-7 sm:text-[25px] ">
                <img
                  src="/Images/white_trophy.png"
                  alt=""
                  className="sm:h-[44px]"
                />
                COMPETITION WON!
              </h2>
              <p className="text-[18px] w-[300px] sm:text-[8px]">
                A member of our team will reach out to you to give you your
                prize
              </p>
            </div>

            {/* mobile */}
            <div className=" flex items-center w-full gap-5 bg-primary p-3 pl-[3em] sm:pl-2 xl:hidden">
              <img
                src="/Images/white_trophy.png"
                alt=""
                className="sm:h-[44px]"
              />
              <div className=" flex flex-col">
                <h2 className="text-[40px] font-extrabold flex items-center gap-7 sm:text-[25px] ">
                  COMPETITION WON!
                </h2>
                <p className="text-[18px] w-[300px] sm:w-auto sm:text-[8px]">
                  A member of our team will reach out to you to give you your
                  prize
                </p>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className=" flex items-center w-full gap-5 bg-secondary p-3 pl-[3em] sm:hidden">
              <h2 className="text-[40px] font-extrabold flex items-center gap-7">
                <img src="/Images/crown_white.png" alt="" />
                COMPETITION LOST!
              </h2>
              <p className="text-[18px] w-[350px]">
                Sadly you didn't win it this time, but see our current competitions to enter more!
              </p>
            </div>

            {/* mobile */}
            <div className=" flex items-center w-full gap-5 bg-secondary p-3 pl-[3em] sm:pl-2 xl:hidden">
              <img
                src="/Images/crown_white.png"
                alt=""
                className="sm:h-[44px]"
              />
              <div className=" flex flex-col">
                <h2 className="text-[40px] font-extrabold flex items-center gap-7 sm:text-[25px] ">
                  COMPETITION LOST!
                </h2>
                <p className="text-[18px] w-[300px] sm:w-auto sm:text-[8px]">
                  Sadly you didn't win it this time, but see our current competitions to enter more!
                </p>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CompetitionStatus;
