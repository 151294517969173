import React, { useEffect, useState } from "react";
import VSpacer from "../../../Components/common/Spacer/VSpacer";
import Category from "../../../Components/User Pages Components/My Orders/Category/Category";
import OrdersTableSmall from "../../../Components/User Pages Components/My Orders/OrdersTable/OrdersTableSmall";
import WithNonAbsolutedSidebarNavbarFooter from "../../../Layout/WithNonAbsolutedSidebarNavbarFooter";
import OrdersTable from "../../../Components/User Pages Components/My Orders/OrdersTable/OrdersTable";
import { useAppDispatch } from "../../../redux/hooks";
import { userTransactions } from "../../../Services/userService";
import moment from "moment";
import _ from "lodash";
import OrdersTableTickets from "../../../Components/User Pages Components/My Orders/OrdersTableTickets/OrdersTableTickets";
import OrdersTableTicketsSmall from "../../../Components/User Pages Components/My Orders/OrdersTableTickets/OrdersTableTicketsSmall";
import { LinearProgress } from "@mui/material";

export default function MyOrders() {
    const [isMounted, setIsMounted] = useState(false);
    useEffect(() => {
        document.getElementById("my_orders_main")?.scrollIntoView();
        setIsMounted(true);
    }, []);
    const [activeCategory, setActiveCategory] = React.useState("Purchases");
    const getIsActive = (category: string) => category === activeCategory;
    const handleCategoryClick = (category: string) =>
        setActiveCategory(category);

    const [transactionData, setTransactionData] = useState<any[]>([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);

    const [isFetching, setIsFetching] = useState(false);

    const fetchTransactions = async (pageNo: number) => {
        if (isFetching) return;
        setIsFetching(true);
        try {
            const response = await userTransactions(pageNo);
            if (response.data.length > 0) {
                const sortedArray = _.orderBy(
                    response.data,
                    (o: any) => {
                        return moment(o.DATE_TIME).format("lll");
                    },
                    ["desc"]
                );

                setTransactionData(sortedArray);
                setPage(parseInt(response.page));
                setTotalPages(response.totalPages);
                setTotalRecords(response.totalRecords);
            }
            setIsFetching(false);
        } catch (error) {
            console.log(error);
            setIsFetching(false);
        }
    };

    const [table, setTable] = useState<any[]>([]);
    useEffect(() => {
        setTable((prev) =>
            transactionData !== prev
                ? [
                      ...prev,
                      ...transactionData.map((obj) => {
                          return {
                              uid: obj?.UID,
                              competitionName: obj?.NAME,
                              RunningTotal: "xxxx",
                              ticketsbought: "xxxx",
                              network: "xxxx",
                              TransactionHash: "xxxx",
                              DateTime:
                                  moment(obj?.DATE_TIME).format("M/D/YYYY") +
                                  " " +
                                  moment(obj?.DATE_TIME).format("h:mm A"),
                              Amount: obj?.COST,
                          };
                      }),
                  ]
                : prev
        );
    }, [transactionData]);

    const onScrollToBottom = () => {
        if (page >= totalPages) return;
        fetchTransactions(page + 1);
    };

    useEffect(() => {
        fetchTransactions(page);
    }, []);

    return (
        // Main View
        <WithNonAbsolutedSidebarNavbarFooter
            Component={
                // Main container
                <div
                    id="my_orders_main"
                    className="bg-black w-full sm_md_lg:mx-auto sm_md_lg:w-[calc(100%-20px)] sm_md_lg:my-[20px] xl:my-8 h-full mx-[43px] rounded-[30px] py-[63px] px-[78px] sm_md:py-[44px] sm_md:px-[31px] flex flex-col items-center justify-center "
                >
                    {/* 1 - Title */}
                    <p className="font-[Sequel100Wide95] uppercase text-[45px] sm_md_lg:text-[30px] text-white ">
                        Orders
                    </p>
                    {/* 2 - description */}
                    <p className="font-[Sequel100WideVF45] text-[20px] sm_md_lg:text-[14px] text-white text-center">
                        Click on the order numbers to view more information
                    </p>
                    {/* 3 - Spacer description and navbar */}
                    <VSpacer small={31} big={59} />
                    {/* 4 - Navbar */}
                    <div className="flex gap-[27.16px] sm_md_lg:gap-[10px] w-full justify-center sm_md_lg:flex-col">
                        {/* 4.1 - Purchases */}
                        <Category
                            text="Purchases"
                            onClick={() => handleCategoryClick("Purchases")}
                            isActive={getIsActive("Purchases")}
                        />
                        {/* 4.2 - Tickets */}
                        <Category
                            text="Tickets"
                            onClick={() => handleCategoryClick("Tickets")}
                            isActive={getIsActive("Tickets")}
                        />
                    </div>
                    {/* 5 - Spacer navbar and orders */}
                    <VSpacer small={31} big={59} />
                    {/* 6 - Orders */}
                    {activeCategory === "Purchases" ? (
                        <>
                            <OrdersTableSmall
                                isFetching={isFetching}
                                onScrolledToBottom={() => onScrollToBottom()}
                                table={table}
                            />
                            <OrdersTable
                                isFetching={isFetching}
                                onScrolledToBottom={onScrollToBottom}
                                table={table}
                            />
                        </>
                    ) : (
                        <>
                            <OrdersTableTicketsSmall
                                isFetching={isFetching}
                                onScrolledToBottom={onScrollToBottom}
                                table={table}
                            />
                            <OrdersTableTickets
                                isFetching={isFetching}
                                onScrolledToBottom={onScrollToBottom}
                                table={table}
                            />
                        </>
                    )}
                </div>
            }
        />
    );
}
