import React from "react";
import { Common } from "../../../common/Common";

export default function Button() {
  return (
    <Common.button
      backgroundColor="#DDE404"
      roundness="55.07px"
      maxWidthBig="100%"
      maxWidthSmall="100%"
      heightSmall="40.35px"
      heightBig="49px"
      fontSizeSmall="19.71px"
      fontSize="23.94px"
      color="#000000"
      fontFamily="Sequel100Wide95"
      className="uppercase shadow-lg shadow-black"
    >
      Enter Now
    </Common.button>
  );
}
