import React, { useEffect, useRef, useState } from "react";
import WithNavbarFooterAndSidebar from "../../Layout/WithNavbarFooterAndSidebar";
import Button from "../../Components/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Input from "../../Components/Input";
import Sidebar from "../../Components/Sidebar";
import ForgetPasswordPopup from "../../Components/Profile Components/ForgetPasswordPopup";
import { useAuth } from "../../Contexts/AuthContext";
import { useAppDispatch } from "../../redux/hooks";
import { setSideNavHeight } from "../../redux/reducers/sidebarSlice";
import { useNavigate } from "react-router-dom";
import EditUserDetails from "./EditUserDetails";
import CancelSubscriptionPopup from "../../Components/Profile Components/CancelSubscriptionPopup";
import AccountTransactions from "./AccountTransactions";
import WithNonAbsolutedSidebarNavbarFooter from "../../Layout/WithNonAbsolutedSidebarNavbarFooter";
import { getSize, Text } from "../../Components/react-responsive-utilities/src";

const User = () => {
  const [showResetPasswordPopUp, setShowResetPasswordPopUp] = useState(false);
  const [showCancelSubscriptionPopUp, setShowCancelSubscriptionPopUp] =
    useState(false);
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const navigateToResetPassword = () => {
    navigate("/user/resetpassword");
  };

  const [billingAddress, setBillingAddress] = useState<string>(
    user?.billingAddress || ""
  );

  const showResetPopup = () => {
    setShowResetPasswordPopUp(true);
  };
  const showCancelPopup = () => {
    setShowCancelSubscriptionPopUp(true);
  };

  const hideResetPopup = () => {
    setShowResetPasswordPopUp(false);
  };
  const hideCancelPopup = () => {
    setShowCancelSubscriptionPopUp(false);
  };

  useEffect(() => {
    dispatch(setSideNavHeight(""));
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const Heading = ({ text }: { text: string }) => {
    return (
      // <h1
      //   className="font-[MontBlancBold] text-[20px] sm:text-center"
      //   style={{
      //     fontSize: "clamp(1px,3.214460512vw,20px)",
      //   }}
      // >
      //   {text}
      // </h1>
      <Text
        textSize={[0, 30.0, 30.0]}
        className="
            text-[#FFFFFF] font-['MontBlancBold'] leading-[40px] font-[188] xl:mb-[37px] mb-[20px] block"
      >
        {text}
      </Text>
    );
  };
  const SubHeading = ({ text }: { text: string }) => {
    return (
      <h1
        className="font-[MontBlancRegular] text-[20px]"
        style={{
          fontSize: "clamp(1px,3.19183371vw,20px)",
        }}
      >
        {text}
      </h1>
    );
  };
  const Button = ({
    text,
    onClick,
    secondary,
    disabled,
    className,
  }: {
    text: string;
    onClick?: () => void;
    secondary?: string;
    disabled?: boolean;
    className?: string;
  }) => {
    return (
      <button
        disabled={disabled}
        onClick={onClick}
        className={`${
          disabled
            ? "bg-[#505050] text-[#292929]"
            : secondary
            ? "bg-secondary"
            : "bg-primary"
        } text-[#232323] font-[Sequel100Wide95] text-[24px]
      sm:text-[3vw]
      sm:w-[80%]
      uppercase text-center px-[20px] py-[10px] mt-[23px] rounded-[30px]
      ${className}
      `}
        style={{
          fontSize: "clamp(1px,3.28111653vw,24.11px)",
        }}
      >
        <Text
          textSize={[-0, 12.0, 16.0]}
          className="
              text-[#000000] text-['Sequel 100 Wide'] leading-[14px] font-[60] "
        >
          {text}
        </Text>
      </button>
    );
  };
  const Input = ({
    label,
    value,
    onChange,
    className,
    disabled,
    style,
  }: {
    label: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    className?: string;
    disabled?: boolean;
    style?: any;
  }) => {
    return (
      <input
        type="text"
        value={value}
        disabled={disabled}
        onChange={onChange}
        className={`bg-[#505050] placeholder-[#2b2b2b] font-[MontBlancBold] text-[21px]
                 px-[19px] py-[2px] rounded-[30px]
                 w-full h-[42px] ${className}`}
        placeholder={label}
      />
    );
  };
  const Spacer = ({ height }: { height: string }) => {
    return <div className={`mt-[${height}]`} />;
  };

  useEffect(() => {
    document.getElementById("user_main")?.scrollIntoView();
  }, []);
  return (
    <WithNonAbsolutedSidebarNavbarFooter
      sidebarContainerClassName="mb-[35px]"
      Component={
        <div className="hero //top-[-0.5em] !w-full p-6 sm:p-2" id="user_main">
          {/* <Sidebar height={"800px"} show={true} /> */}

          {/* Main */}
          <div
            className="flex sm:flex-col flex-col h-max  //mx-[413px] sm:mt-[29px]
        sm:items-center
        sm:w-auto
        sm:mx-0 md:mx-0 lg:mx-0 rounded-[30px]
         py-[63px] px-[71px] sm:px-[10vw]
        bg-[#00000080] //w-[1060px] w-full"
          >
            <h1
              className="font-[Sequel100Wide95] text-[45px] text-white uppercase sm:text-[7vw]"
              style={{
                fontSize: "clamp(1px,5.585075012vw,45px)",
              }}
            >
              USER PROFILE
            </h1>
            {/* Reset Password */}
            <div className="mt-[34px] text-white">
              <Heading text="Forgot your Password?" />
              <div className="sm:mt-0  sm:w-full sm:flex sm:items-center sm:justify-center">
                <Button
                  text="Reset"
                  // onClick={navigateToResetPassword}
                  onClick={showResetPopup}
                />
                <ForgetPasswordPopup
                  open={showResetPasswordPopUp}
                  handleClose={hideResetPopup}
                  rejectClick={() => setShowResetPasswordPopUp(false)}
                />
                <CancelSubscriptionPopup
                  open={showCancelSubscriptionPopUp}
                  handleClose={hideCancelPopup}
                  rejectClick={() => setShowCancelSubscriptionPopUp(false)}
                />
              </div>
            </div>
            {/* User Details */}
            {/* <div className="mt-[23px] text-white">
          <Heading text="User Details" />
          <div className="flex sm:flex-col gap-5 mt-[12px]">
            <Input label="Enter UserName" value="" onChange={() => {}} />
            <Input label="Enter User Number" value="" onChange={() => {}} />
          </div>
          <div className="sm:mt-0  sm:w-full sm:flex sm:items-center sm:justify-center">
            <Button text="Enter Details" />
          </div>
        </div> */}
            <EditUserDetails />
            {/* Billing Details */}
            <div className="mt-[23px] text-white flex flex-col sm:items-center">
              {/* <Heading text="Billing Details" />

          <Spacer height="14px" />
          <SubHeading text="Address" />

          <div className="flex sm:flex-col gap-2 mt-[12px]">
            <Input
              label="Address Line 1"
              value={billingAddress}
              onChange={(e) => setBillingAddress(e.target.value)}
            />
            <Input label="Address Line 2" value="" onChange={() => {}} />
          </div>
          <div className="flex sm:flex-col gap-2 mt-[12px]">
            <Input label="City" value="" onChange={() => {}} />
            <Input label="Country" value="" onChange={() => {}} />
          </div>
          <div className="flex sm:flex-col gap-5 mt-[12px] w-1/2 sm:w-full">
            <Input label="Postcode" value="" onChange={() => {}} />
          </div>
          <div className="sm:mt-0  sm:w-full sm:flex sm:items-center sm:justify-center">
            <Button text="Enter Details" />
          </div> */}
              <Spacer height="14px" />
              <Heading text="My Subscription" />
              <Spacer height="14px" />
              <div className="flex flex-col xl:gap-[20px] gap-[8px] mt-[12px]">
                {/* Status */}
                <div className="flex sm:flex-col gap-[9px]">
                  <Text
                    textSize={[0, 12.0, 16.0]}
                    className="
              text-[#DDE404] font-['Sequel100Wide'] leading-[14px] font-[60] w-full flex items-center"
                  >
                    Status
                  </Text>

                  <Input
                    label=""
                    value="Active"
                    onChange={() => {}}
                    disabled
                    className="xl:w-full text-[#292929]"
                  />
                </div>
                {/* Membership Level */}
                <div className="flex sm:flex-col gap-[9px]">
                  <Text
                    textSize={[0, 12.0, 16.0]}
                    className="
              text-[#DDE404] font-['Sequel100Wide'] leading-[14px] font-[60] w-full flex items-center"
                  >
                    Membership level
                  </Text>

                  <Input
                    label=""
                    value="Bronze"
                    onChange={() => {}}
                    disabled
                    className="xl:w-full text-[#292929]"
                  />
                </div>
                {/* Ticket Allowence (per month) */}
                <div className="flex sm:flex-col gap-[9px]">
                  <Text
                    textSize={[0, 12.0, 16.0]}
                    className="
              text-[#DDE404] font-['Sequel100Wide'] leading-[14px] font-[60] w-full flex items-center"
                  >
                    Ticket Allowence (per month)
                  </Text>

                  <Input
                    label=""
                    value="250"
                    onChange={() => {}}
                    disabled
                    className="xl:w-full text-[#292929]"
                  />
                </div>
                {/* Subscription Fee (per month) */}
                <div className="flex sm:flex-col gap-[9px]">
                  <Text
                    textSize={[0, 12.0, 16.0]}
                    className="
              text-[#DDE404] font-['Sequel100Wide'] leading-[14px] font-[60] w-full flex items-center"
                  >
                    Subscription Fee (per month)
                  </Text>

                  <Input
                    label=""
                    value="$230"
                    onChange={() => {}}
                    disabled
                    className="xl:w-full text-[#292929]"
                  />
                </div>
                {/* Discount Rate */}
                <div className="flex sm:flex-col gap-[9px]">
                  <Text
                    textSize={[0, 12.0, 16.0]}
                    className="
              text-[#DDE404] font-['Sequel100Wide'] leading-[14px] font-[60] w-full flex items-center"
                  >
                    Status
                  </Text>

                  <Input
                    label=""
                    value="Active"
                    onChange={() => {}}
                    disabled
                    className="xl:w-full text-[#292929]"
                  />
                </div>
                {/* Monthly Saving */}
                <div className="flex sm:flex-col gap-[9px]">
                  <Text
                    textSize={[0, 12.0, 16.0]}
                    className="
              text-[#DDE404] font-['Sequel100Wide'] leading-[14px] font-[60] w-full flex items-center"
                  >
                    Monthly Saving
                  </Text>

                  <Input
                    label=""
                    value="$20.00"
                    onChange={() => {}}
                    disabled
                    className="xl:w-full text-[#292929]"
                  />
                </div>
              </div>
              <div className="grid xl:grid-cols-2 grid-cols-1 gap-4 xl:mt-[69px] mt-[31px] w-full *:w-full">
                <Button
                  disabled
                  text="Activate Subscription"
                  className="!w-full"
                />
                <Button text="Upgrade Subscription" className="!w-full" />
                <Button
                  text="Cancel Subscription"
                  onClick={showCancelPopup}
                  className="!w-full"
                />
                <Button text="Buy Tickets" className="!w-full" />
              </div>
            </div>
            {/* <div className="sm:mt-0 mb-[131px] sm:w-full sm:flex sm:items-center sm:justify-center">
              <Button
                text="Cancel"
                onClick={showCancelPopup}
                secondary="true"
              />
            </div> */}
            <div className="flex flex-col xl:mt-[98px] mt-[63px] w-full sm:justify-center sm:items-center">
              <Heading text="Security" />
              <div className="flex gap-[9px] items-center xl:mt-[20px] mt-[10px]">
                <Input
                  style={{
                    fontSize: getSize([0, 12, 16]),
                  }}
                  label="Change Password"
                  value=""
                  onChange={() => {}}
                  className=" !h-full !px-[20px] !py-[10px]" // we need to adjust the height
                />
                <Button text="Reset" className="!mt-0" />
              </div>
            </div>

            <div className="flex gap-[9px] items-center xl:mt-[20px] mt-[10px]">
              <Input
                style={{
                  fontSize: getSize([0, 12, 16]),
                }}
                label="Change Email Address"
                value=""
                onChange={() => {}}
                className=" !h-full !px-[20px] !py-[10px]" // we need to adjust the height
              />
              <Button text="Reset" className="h-full !mt-0" />
            </div>

            <div className="flex gap-[9px] items-center xl:mt-[20px] mt-[10px]">
              <Input
                style={{
                  fontSize: getSize([0, 12, 16]),
                }}
                label="Change Telegram Handle"
                value=""
                onChange={() => {}}
                className=" !h-full !px-[20px] !py-[10px]" // we need to adjust the height
              />
              <Button text="Reset" className="h-full !mt-0" />
            </div>
          </div>
        </div>
      }
      ComponentBeforeFooter={<AccountTransactions />}
    />
  );
};
//1115px
//export default WithNavbarFooterAndSidebar(User);
export default User;
