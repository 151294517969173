import { Common } from "../../../common/Common";
interface TitleProps {
  text: string;
}
export default function Title(props: TitleProps) {
  return (
    <Common.typography
      className="uppercase"
      fontFamily="Sequel100Wide95"
      fontSize="45.28px"
      fontSizeSmall="30px"
      color="#fff"
    >
      {props.text}
    </Common.typography>
  );
}
