import React from "react";
import { Common } from "../../../common/Common";

interface CompetitonResultTextSmallProps {
  varient: "lost" | "won";
}
export default function CompetitionResultTextSmall(
  props: CompetitonResultTextSmallProps
) {
  return (
    <div className="flex flex-col w-[50%]">
      <Common.typography
        fontFamily="Sequel100Wide95"
        fontSizeSmall="18.82px"
        fontSize="18.82px"
        color="#000"
        className="uppercase leading-none"
      >
        competiton
      </Common.typography>
      <Common.typography
        fontFamily="Sequel100Wide95"
        fontSizeSmall="49.87px"
        fontSize="49.87px"
        color="#000"
        className="uppercase leading-none"
      >
        {props.varient === "won" ? "Won!" : "Lost"}
      </Common.typography>
    </div>
  );
}
