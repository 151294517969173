import React from "react";
import axios from "axios";
import { Provider } from "react-redux";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
//import "./../public/fonts/Sequel100Wide95.woff2"
import "./App.css";
import AuthProvider from "./Contexts/AuthContext";
import Routes from "./Routes/Routes";
import "./Styles/Dashboard.css";
import "./Styles/LandingPage.css";
import "./Styles/Slider.css";
import { store } from "./redux/store";

axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}`;

function App() {
  return (
    <Provider store={store}>
      <AuthProvider>
        <Routes />
      </AuthProvider>
    </Provider>
  );
}

export default App;
