import { Common } from "../../../common/Common";

export default function TicketSectionText() {
  return (
    <Common.typography
      className="sm_md:text-center"
      fontFamily="MontBlancSemiBold"
      fontSize="18px"
      fontSizeSmall="16px"
      color="#a1a1a1"
    >
      Ticket Number (s)
    </Common.typography>
  );
}
