import React from "react";
import { Common } from "../../../common/Common";

interface DescriptionProps {
  description: string;
}
export default function Description(props: DescriptionProps) {
  return (
    <Common.typography
      fontFamily="MontBlancRegular"
      fontSize="15.55px"
      fontSizeSmall="11.59px"
      color="#fff"
      className="w-[calc(100%-68.03px)] sm_md:w-[calc(100%-16.42px)]"
    >
      {props.description}
    </Common.typography>
  );
}
