export default function Avatar({
  onClick,
  src,
}: {
  onClick: () => void;
  src: string;
}) {
  return (
    <img
      onClick={onClick}
      src={src}
      alt="avatar"
      className="sm_md:w-[104px] sm_md:h-[104px] rounded-[20px] h-[90px] w-[90px] cursor-pointer"
    />
  );
}
