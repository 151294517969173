import { ChangeEventHandler, FC, MouseEvent, useState } from "react";

function mapToRange(value: number, min: number, max: number) {
  // Ensure the value is within the specified range
  value = Math.min(Math.max(value, min), max);

  // Calculate the percentage of the value within the range
  const percentage = (value - min) / (max - min);

  // Map the percentage to the range 0 to 100
  const mappedValue = percentage * 100;

  return mappedValue;
}

interface Props {
  min: number;
  max: number;
  value: number;
  buffered: number;
  onChange?(value: number): void;
  onDragStart?(): void;
  onDragEnd?(value: number): void;
  className: string;
}

const RangeSlider: FC<Props> = ({
  min,
  max,
  value,
  buffered,
  onChange,
  onDragStart,
  onDragEnd,
  className,
}) => {
  const [isDragging, setIsDragging] = useState(false);

  const handleOnChange: ChangeEventHandler<HTMLInputElement> = (evt) => {
    if (!onChange) return;

    const { value } = evt.target;
    onChange(+value);
  };

  const handleDragStart = () => {
    if (onDragStart) onDragStart();
    setIsDragging(true);
  };

  const handleDragEnd = (evt: MouseEvent<HTMLInputElement>) => {
    if (onDragEnd) onDragEnd(+evt.currentTarget.value);
    setIsDragging(false);
  };

  return (
    <div
      className={`relative range-container flex items-center rounded w-full ${className}`}
    >
      <input
        min={min}
        max={max}
        value={value}
        className="range-slider w-full"
        type="range"
        onChange={handleOnChange}
        onMouseDown={handleDragStart}
        onMouseUp={handleDragEnd}
        style={{ zIndex: isDragging ? 1 : 2 }}
      />

      {/* buffered value */}
      <div
        style={{ width: mapToRange(buffered, min, max) + "%" }}
        className="absolute h-full bg-gray-300 rounded"
      ></div>

      {/* Actual/slider value */}
      <div
        style={{ width: mapToRange(value, min, max) + "%" }}
        className="absolute h-full bg-secondary rounded"
      >
        <div className="thumb absolute right-0 top-0 bg-[#F00290] rounded-full m-[-5px]">
          <svg
            className="w-4 h-4 text-[#F00290] select-none"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <circle cx="10" cy="10" r="8" />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default RangeSlider;
