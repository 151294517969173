interface ChainlinkHashContainerProps {
  children: React.ReactNode;
}
export default function ChainlinkHashContainer(
  props: ChainlinkHashContainerProps
) {
  return (
    <div className="flex gap-x-[23px] absolute bottom-[41px] sm_md:bottom-[20.44px]">
      {props?.children}
    </div>
  );
}
