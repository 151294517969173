import TableTitle from "../texts/TableTitle";
import TableValue from "../texts/TableValue";

interface TableProps {
  children: React.ReactNode;
}
export default function Table() {
  return (
    <table className="overflow-auto sm_md_lg:hidden">
      <thead className="border-b-[3px] border-primary">
        <tr>
          <th className="px-8">
            <TableTitle title="WALLET ADDRESS" />
          </th>
          <th className="px-8">
            <TableTitle title="TX HASH" />
          </th>
          <th className="px-8">
            <TableTitle title="MIN" />
          </th>
          <th className="px-8">
            <TableTitle title="MAX" />
          </th>
          <th className="px-8">
            <TableTitle title="WINNING NUMBER" />
          </th>
        </tr>
        <tr className="h-[32.75px] " />
      </thead>
      <tbody>
        <tr className="h-[32.75px] " />
        <tr>
          <td className="px-8">
            <TableValue title="ASKCJA28189S" copyable />
          </td>
          <td className="px-8">
            <TableValue copyable title="1928189S" />
          </td>
          <td className="px-8">
            <TableValue copyable title="1" />
          </td>
          <td className="px-8">
            <TableValue copyable title="999999999999" />
          </td>
          <td className="px-8">
            <TableValue copyable title="432,329,000" />
          </td>
        </tr>
      </tbody>
    </table>
  );
}
