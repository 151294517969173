import React, { useEffect } from "react";
import { useRef, useState } from "react";
import AdvertBox from "../Components/Page Components/AdvertBox";
import CompetitionDetails from "../Components/Page Components/CompetitionDetails";
import {
  CompetitionProps,
  Competitions,
} from "../Components/Page Components/Competitions";
import FAQ from "../Components/Page Components/FAQ";
import HowToPlay from "../Components/Page Components/HowToPlay";
import WithNavbarAndFooter from "../Layout/WithNavbarAndFooter";
import { useAppSelector } from "../redux/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { CompetitionObject } from "../redux/reducers/competitionsSlice";
import { scrollTo } from "../utils/ScrollTo";
import { getUserListCompetition } from "../Services/userService";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import Button from "../Components/Button";
import PhoneStores from "../Components/Page Components/PhoneStores";
import Carousel from "../Components/common/Carousel/Carousel";

const CompetitionPage = () => {
  const params = useParams();
  const [showCompetition, setShowCompetition] = useState(true);
  const [competition, setCompetition] = useState();
  const [currentFilter, setCurrentFilter] = useState("Live Competitions");
  // const itemsPerPage = 12;
  const [itemsPerPage, setItemsPerPage] = useState(12);
  // const competitions = useAppSelector((state) => state.competitions.data);
  const [loader, setLoader] = useState<boolean>(false);
  const [competitionsList, setCompetitionsList] = useState<CompetitionProps[]>(
    []
  );
  const [allCompetitionsLoaded, setAllCompetitionsLoaded] = useState(false);
  const [displayedAllCompetitions, setDisplayedAllCompetitions] = useState<
    CompetitionProps[]
  >([]);

  const [lastChanceCompetitions, setLastChanceCompetitions] = useState<
    CompetitionProps[]
  >([]);

  const [finishedCompetitions, setFinishedCompetitions] = useState<
    CompetitionProps[]
  >([]);

  const [instantWinCompetitions, setInstantWinCompetitions] = useState<
    CompetitionProps[]
  >([]);

  const navigate = useNavigate();
  const liveComp = useRef<HTMLDivElement>(null);
  const lastChance = useRef<HTMLDivElement>(null);
  const drawnComp = useRef<HTMLDivElement>(null);
  const instantWinComp = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (window.screen.width < 640) {
      setItemsPerPage(999);
    } else {
      setItemsPerPage(12);
    }
  }, [window.screen.width]);

  useEffect(() => {
    getCompetitions("");
  }, [currentFilter]);

  const extractLastChanceCompetitions = (competitions: CompetitionObject[]) => {
    const now = new Date();
    const in24Hours = new Date(now.getTime() + 24 * 60 * 60 * 1000);
    const categories = [
      "BITCOIN",
      "WEN LAMBO",
      "WATCHES",
      "HIGH ROLLERS",
      "NFTS",
      "ALTCOINS",
    ];
    return competitions.filter((competition) => {
      const endDate = new Date(competition?.competitionenddate);
      // return endDate > now && endDate < in24Hours;
      return (
        categories.includes(competition?.category) &&
        endDate > now &&
        endDate < in24Hours
      );
    });
  };

  const getCompetitions = async (category: string) => {
    try {
      setAllCompetitionsLoaded(false);
      setLoader(true);
      const response = await getUserListCompetition({
        category,
      });

      const fc: CompetitionProps[] = [];
      const lc: CompetitionProps[] = [];
      const ic: CompetitionProps[] = [];

      const filteredCompetitions = response?.filter((competition: any) => {
        if (
          competition?.category === "BITCOIN" ||
          competition?.category === "bitcoin" ||
          competition?.category === "WEN LAMBO" ||
          competition?.category === "WATCHES" ||
          competition?.category === "HIGH ROLLERS" ||
          competition?.category === "NFTS" ||
          competition?.category === "ALTCOINS"
        ) {
          return competition;
        }
      });

      const filteredInstantWinCompetitions = response?.filter(
        (competition: any) => {
          if (
            competition?.instant === 1 &&
            !finishedCompetition(competition?.competitionenddate)
          ) {
            return competition;
          }
        }
      );

      filteredCompetitions?.map((competition: any) => {
        if (finishedCompetition(competition?.competitionenddate)) {
          fc.push(competition);
        } else {
          lc.push(competition);
        }
      });

      filteredInstantWinCompetitions?.map((competition: any) => {
        ic.push(competition);
      });

      // setInstantWinCompetitions(ic);
      const sortedInstantWinCompetitionList = ic?.sort(
        (a: CompetitionProps, b: CompetitionProps) => {
          // return moment(a?.competitionenddate).diff(moment(b?.competitionenddate));
          let dateA = new Date(a?.competitionenddate || 0) as any;
          let dateB = new Date(b?.competitionenddate || 0) as any;
          return dateA - dateB;
        }
      );

      setInstantWinCompetitions(sortedInstantWinCompetitionList);

      const lcc = extractLastChanceCompetitions(response);
      const sortedCompetitionLastChanceList = lcc?.sort(
        (a: CompetitionProps, b: CompetitionProps) => {
          // return moment(a?.competitionenddate).diff(moment(b?.competitionenddate));
          let dateA = new Date(a?.competitionenddate || 0) as any;
          let dateB = new Date(b?.competitionenddate || 0) as any;
          return dateA - dateB;
        }
      );
      setLastChanceCompetitions(sortedCompetitionLastChanceList);
      setFinishedCompetitions(fc);

      const sortedCompetitionList = lc?.sort(
        (a: CompetitionProps, b: CompetitionProps) => {
          // return moment(a?.competitionenddate).diff(moment(b?.competitionenddate));
          let dateA = new Date(a?.competitionenddate || 0) as any;
          let dateB = new Date(b?.competitionenddate || 0) as any;
          return dateA - dateB;
        }
      );
      setCompetitionsList(sortedCompetitionList);
      setDisplayedAllCompetitions(sortedCompetitionList.slice(0, itemsPerPage));
      console.log(response, fc);
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  const fetchMoreCompetitions = async () => {
    if (allCompetitionsLoaded) return;
    const nextDisplayedItems = competitionsList.slice(
      0,
      displayedAllCompetitions.length + itemsPerPage
    );
    setDisplayedAllCompetitions(nextDisplayedItems); // Update displayed items

    console.log("dsajda", nextDisplayedItems.length, competitionsList.length);

    setAllCompetitionsLoaded(
      nextDisplayedItems.length === competitionsList.length
    ); // Check if all competitions are loaded
  };

  const handleLoadMoreClick = () => {
    fetchMoreCompetitions();
  };

  const scrollToTarget = (filter: string) => {
    // for desktop
    const navbarId = window.screen.width > 1024 ? "navbar" : "navbar-mobile";
    if (filter === "Last Chance Competitions") {
      if (lastChance.current) {
        // Scroll to the target element
        // lastChance.current.scrollIntoView({ behavior: "smooth" });
        scrollTo(lastChance, navbarId);
      }
    }
    if (filter === "Live Competitions") {
      if (liveComp.current) {
        // Scroll to the target element
        // liveComp.current.scrollIntoView({ behavior: "smooth" });
        scrollTo(liveComp, navbarId);
      }
    }
    if (filter === "Instant Win Competitions") {
      if (instantWinComp.current) {
        // Scroll to the target element
        // instantWinComp.current.scrollIntoView({ behavior: "smooth" });
        scrollTo(instantWinComp, navbarId);
      }
    }
    if (filter === "Drawn Competitions") {
      if (drawnComp.current) {
        // Scroll to the target element
        // drawnComp.current.scrollIntoView({ behavior: "smooth" });
        scrollTo(drawnComp, navbarId);
      }
    }
  };

  // useEffect(() => {
  //   if (competitions) {
  //     //last chance competitions are competitions that are about to end in 24 hours
  //     const lastChanceCompetitions =
  //       extractLastChanceCompetitions(competitions);
  //     console.log("lastChanceCompetitions", lastChanceCompetitions);
  //     setLastChanceCompetitions(lastChanceCompetitions);
  //   }
  // }, [competitions]);

  useEffect(() => {
    if (params.all === "all") {
      setShowCompetition(false);
    } else {
      setShowCompetition(true);
    }
  }, [params]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function changeFilter(filter: string) {
    setCurrentFilter(filter);
  }

  function buyTicket() {}

  const filters = [
    {
      content: "Live Competitions",
    },
    {
      content: "Instant Win Competitions",
    },
    {
      content: "Last Chance Competitions",
    },
    {
      content: "Drawn Competitions",
    },
  ];

  const finishedCompetition = (competitionenddate?: Date) => {
    const currentDate = new Date();
    const competitionEndDate = new Date(competitionenddate ?? new Date());
    if (currentDate > competitionEndDate) {
      return true;
    } else {
      return false;
    }
  };

  function loadCompetition(competition?: any, UID?: string) {
    navigate(`/competition/${UID}`);
    setCompetition(competition);
  }

  const navigateToResult = (competition?: any, UID?: string) => {
    navigate(`/competitionResult/${UID}`);
  };

  return (
    <div className="hero ">
      {showCompetition ? (
        // competition details
        <div>
          <CompetitionDetails competition={competition} />

          <HowToPlay />

          <FAQ className={"faq"} fontSize={"text-[60px] sm:text-[28px]"} />
        </div>
      ) : (
        <div className="w-full flex flex-col items-center ">
          {/* list of competitions */}
          {/* Live competition */}
          <div className="flex flex-col items-center liveCompetition //bg-bg_small p-[28px] xl:p-10 pt-0 w-full">
            <div
              className={
                "grid grid-cols-4 gap-4 mb-0 gap-y-4 w-full sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 p-10 pb-0 sm:p-0 md:p-0 mt-3 //mb-[3em] sm_md_lg:mt-[30px] //bg-bg_small "
              }
            >
              {filters.map((filter, index) => (
                <button
                  key={index}
                  onClick={(e) => {
                    e.preventDefault();
                    changeFilter(filter.content);
                    scrollToTarget(filter.content);
                  }}
                  className={`${
                    filter.content === currentFilter
                      ? "bg-primary font-extrabold text-black border-2 border-white font-[Sequel100Wide95] "
                      : "bg-none text-white bg-[#4B4B4B] //bg-general_bg font-[Sequel100WideVF45]"
                  } xl:h-[85.02px] h-max rounded-full   w-full xl:px-16 sm_md:px-4 sm_md_lg:h-[46.15px] //p-3 sm:w-full md:w-full  transition-all sm:text-[4vw] md:text-[2vw] xl:text-[1rem] text-center uppercase mx-auto `}
                >
                  <p
                    className="text-center  sm:w-full md:w-full mx-auto lg:w-[80%]"
                    style={{
                      fontSize: "clamp(0px,3.496512479vw,20px)",
                    }}
                  >
                    {filter.content}
                  </p>
                </button>
              ))}
            </div>

            <h1
              className="pageHeading mb-0 font-[Sequel100Wide95] text-center"
              ref={liveComp}
              style={{
                fontSize: "clamp(1px,5.619128597vw,45px)",
              }}
            >
              LIVE COMPETITIONS
            </h1>
            {loader && (
              <div className="flex justify-center items-center w-full h-[200px]">
                <CircularProgress />
              </div>
            )}

            {!loader &&
              competitionsList.length === 0 &&
              lastChanceCompetitions.length === 0 &&
              finishedCompetitions?.length !== 0 && (
                <div className="w-full mt-[5em]">
                  <div
                    className={`text-[2rem] font-[Sequel100WideVF55] sm:text-[20px] flex flex-col h-[10rem]  relative  w-full sm:w-full rounded-2xl text-center justify-center text-primary`}
                  >
                    No Live Competition Found.
                  </div>
                </div>
              )}
            <div
              // className="grid 1366:w-max 1366:scale-[0.97] grid-cols-3 xl:grid-cols-4 gap-8 gap-y-10 sm:grid-cols-2 md:grid-cols-2 sm:gap-4 w-full above-1367:w-[94%]  "
              className="xl:grid lg:grid md:grid hidden max-800:hidden md:grid-cols-2 between-641-800:grid-cols-1 grid-cols-3 sm:grid-cols-1 lg:grid-cols-2 sm:w-full between-200-299:grid-cols-1 between-200-299:min-w-[100%] xl:gap-x-[27.59px] xl:gap-y-[53px] gap-x-[13.63px] gap-y-[11.92px] m-auto w-max max-w-[100%] *:m-auto mt-[3em]"
            >
              {!loader &&
                displayedAllCompetitions.map(
                  (competition, index) =>
                    !finishedCompetition(competition?.competitionenddate) && (
                      <Competitions
                        {...competition}
                        onClick={() =>
                          loadCompetition(competition, competition?.UID)
                        }
                        isHighRoller={competition?.categroy === "HIGH ROLLERS"}
                        key={index}
                        width={"w-[244px]"}
                      />
                    )
                )}
            </div>
            <div className="xl:hidden lg:hidden md:hidden max-800:block block mt-[40.37px]">
              <Carousel>
                {displayedAllCompetitions.map((competition, index) => (
                  <Competitions
                    {...competition}
                    isHighRoller={competition?.categroy === "HIGH ROLLERS"}
                    onClick={() => loadCompetition(competition?.UID)}
                    key={index}
                    width={"w-[244px]"}
                  />
                ))}
              </Carousel>
            </div>
            <div className="sm:hidden">
              {competitionsList.length > displayedAllCompetitions.length &&
                !allCompetitionsLoaded && (
                  <Button
                    content="Load More"
                    onClick={handleLoadMoreClick}
                    className="text-black font-[Sequel100Wide95] text-[24px]
            sm:text-[3vw]
            sm:w-[80%]
            uppercase text-center px-[20px] py-[10px] mt-[23px] rounded-[30px]"
                  />
                )}
            </div>
          </div>
          {/* Instant Win Competition */}
          <div className="flex   flex-col items-center //mt-[3em] w-full ">
            <h1
              className="flex uppercase text-[40px] //font-[Sequel100WideVF] font-[Sequel100Wide95] items-center font-extrabold text-black  bg-white h-[180px] sm:text-[26px] justify-center md:text-[28px] w-full  pt-[-1em]  sm:pt-0 sm:text-center text-center"
              ref={instantWinComp}
              style={{
                fontSize: "clamp(1px,5.81vw,40px)",
              }}
            >
              Instant Win Competitions
            </h1>
            {instantWinCompetitions.length === 0 && (
              <div className="w-full //font-[Sequel100WideVF55] font-[Sequel100Wide95] mt-[5em]">
                <div
                  className={`text-[2rem] uppercase font-[] sm:text-[20px] flex flex-col h-[10rem] relative  w-full sm:w-full rounded-2xl text-center justify-center text-primary`}
                >
                  No Instant Win Competition Found
                </div>
              </div>
            )}
            {/* <div className="grid 1366:w-max 1366:scale-[0.97] grid-cols-3 xl:grid-cols-4 gap-8 gap-y-10 w-full sm:grid-cols-2 md:grid-cols-2 sm:gap-4 py-10 p-[18px] above-1367:w-[94%]"> */}
            <div className="md:grid-cols-2 between-641-800:grid-cols-1 grid-cols-3 sm:grid-cols-1 lg:grid-cols-2 p-[28px] xl:grid lg:grid md:grid hidden max-800:hidden sm:w-full between-200-299:grid-cols-1 between-200-299:min-w-[100%] xl:gap-x-[27.59px] xl:gap-y-[53px] gap-x-[13.63px] gap-y-[11.92px] m-auto w-max max-w-[100%] *:m-auto mt-[3em]">
              {!loader &&
                instantWinCompetitions.map((competition, index) => (
                  <Competitions
                    {...competition}
                    onClick={() =>
                      loadCompetition(competition, competition?.UID)
                    }
                    key={index}
                    width={"w-[244px]"}
                  />
                ))}
            </div>
            <div className="sm:block max-800:block hidden mt-[43px]">
              <Carousel>
                {instantWinCompetitions.map((competition, index) => (
                  <Competitions
                    {...competition}
                    onClick={() => loadCompetition(competition?.UID)}
                    key={index}
                    width={"w-[244px]"}
                  />
                ))}
              </Carousel>
            </div>
          </div>

          {/* Last Chance Competition */}
          <div className="flex   flex-col items-center //mt-[3em] w-full lastChance">
            <h1
              className="flex text-[40px] //font-[Sequel100WideVF] font-[Sequel100Wide95] items-center font-extrabold text-black  bg-primary h-[180px] sm:text-[26px] justify-center md:text-[28px] w-full  pt-[-1em]  sm:pt-0 sm:text-center text-center"
              ref={lastChance}
              style={{
                fontSize: "clamp(1px,5.81vw,40px)",
              }}
            >
              LAST CHANCE COMPETITIONS
            </h1>
            {lastChanceCompetitions.length === 0 && (
              <div className="w-full //font-[Sequel100WideVF55] font-[Sequel100Wide95] mt-[5em]">
                <div
                  className={`text-[2rem] font-[] sm:text-[20px] flex flex-col h-[10rem] relative  w-full sm:w-full rounded-2xl text-center justify-center text-primary`}
                >
                  No Last Chance Competition Found
                </div>
              </div>
            )}
            {/* <div className="grid 1366:w-max 1366:scale-[0.97] grid-cols-3 xl:grid-cols-4 gap-8 gap-y-10 w-full sm:grid-cols-2 md:grid-cols-2 sm:gap-4 py-10 p-[18px] above-1367:w-[94%]"> */}
            <div className="md:grid-cols-2 between-641-800:grid-cols-1 grid-cols-3 sm:grid-cols-1 lg:grid-cols-2 p-[28px] xl:grid lg:grid md:grid hidden max-800:hidden sm:w-full between-200-299:grid-cols-1 between-200-299:min-w-[100%] xl:gap-x-[27.59px] xl:gap-y-[53px] gap-x-[13.63px] gap-y-[11.92px] m-auto w-max max-w-[100%] *:m-auto mt-[3em]">
              {!loader &&
                lastChanceCompetitions.map((competition, index) => (
                  <Competitions
                    {...competition}
                    onClick={() =>
                      loadCompetition(competition, competition?.UID)
                    }
                    key={index}
                    width={"w-[244px]"}
                    isHighRoller={competition?.categroy === "HIGH ROLLERS"}
                  />
                ))}
            </div>
            <div className="sm:block max-800:block hidden mt-[43px]">
              <Carousel>
                {lastChanceCompetitions.map((competition, index) => (
                  <Competitions
                    {...competition}
                    isHighRoller={competition?.categroy === "HIGH ROLLERS"}
                    onClick={() => loadCompetition(competition?.UID)}
                    key={index}
                    width={"w-[244px]"}
                  />
                ))}
              </Carousel>
            </div>
          </div>

          <div className="flex  flex-col items-center w-full drawnCompetition bg-bg_small ">
            <h1
              className="flex text-[40px] //font-[Sequel100WideVF] font-[Sequel100Wide95] items-center font-extrabold text-white //text-black  bg-secondary h-[180px] sm:text-[26px] justify-center md:text-[28px] w-full  pt-[-1em]  sm:pt-0 sm:text-center text-center"
              ref={drawnComp}
              style={{
                fontSize: "clamp(1px,5.81vw,40px)",
              }}
            >
              DRAWN COMPETITIONS
            </h1>
            {finishedCompetitions.length === 0 && (
              <div className="w-full font-[Sequel100WideVF55] mt-[5em]">
                <div
                  className={`text-[2rem] font-[] sm:text-[20px] flex flex-col h-[10rem] relative  w-full sm:w-full rounded-2xl text-center justify-center text-primary`}
                >
                  No Drawn Competition Found
                </div>
              </div>
            )}
            {/* <div className="grid grid-cols-3 1366:w-max 1366:scale-[0.97] xl:grid-cols-4 gap-8 gap-y-10 w-full p-[18px] pt-10 sm:grid-cols-2 md:grid-cols-2 sm:gap-7 above-1367:w-[94%]"> */}
            <div className="p-[28px] xl:grid lg:grid md:grid hidden max-800:hidden md:grid-cols-2 between-641-800:grid-cols-1 grid-cols-3 sm:grid-cols-1 lg:grid-cols-2 sm:w-full between-200-299:grid-cols-1 between-200-299:min-w-[100%] xl:gap-x-[27.59px] xl:gap-y-[53px] gap-x-[13.63px] gap-y-[11.92px] m-auto w-max max-w-[100%] *:m-auto mt-[3em]">
              {!loader &&
                finishedCompetitions.map((competition, index) => (
                  <Competitions
                    isCompetitionFinished={true}
                    {...competition}
                    onClick={() =>
                      // console.log("prnt")
                      navigateToResult(competition, competition?.UID)
                    }
                    key={index}
                    isHighRoller={competition?.categroy === "HIGH ROLLERS"}
                    width={"w-[244px]"}
                  />
                ))}
            </div>
            <div className="sm:block hidden mt-[43px] max-800:block">
              <Carousel>
                {finishedCompetitions.map((competition, index) => (
                  <Competitions
                    {...competition}
                    isHighRoller={competition?.categroy === "HIGH ROLLERS"}
                    onClick={
                      () => navigateToResult(competition?.UID)
                      // console.log("prnt")
                    }
                    key={index}
                    width={"w-[244px]"}
                  />
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      )}

      <AdvertBox />
      <PhoneStores background="bg-gradient-to-r from-[#a51269] to-[#2b2b2b] h-screen " />
    </div>
  );
};

export default WithNavbarAndFooter(CompetitionPage);
