interface ITextFieldProp extends React.InputHTMLAttributes<HTMLInputElement> {
  placeholder?: string;
  backgroundColor: string;
  maxWidthBig: string;
  maxWidthSmall: string;
  heightBig: string;
  heightSmall: string;
  fontFamily:
    | "MontBlancRegular"
    | "MontBlancBold"
    | "MontBlancSemiBold"
    | "MontBlancBlack"
    | "Sequel100Wide"
    | "Sequel100Wide95"
    | "Sequel100WideVF45"
    | "Sequel100WideVF55"
    | "Sequel100Wide-65"
    | "Sequel100WideVF"
    | "Sequel100Wide85"
    | "Sequel100Wide46";
  fontSizeBig: string;
  fontSizeSmall: string;
  textColor: string;
  roundness: string;
  paddingX: string;
  paddingXSmall: string;
  value?: string;
}

export default function textfield(props: ITextFieldProp) {
  return (
    <>
      <input
        {...props}
        value={props.value}
        className={"sm_md:hidden placeholder:text-white " + props.className}
        placeholder={props.placeholder}
        style={{
          maxWidth: props.maxWidthBig || "100%",
          height: props.heightBig || "3rem",
          fontFamily: props.fontFamily || "MontBlancRegular",
          fontSize: props.fontSizeBig || "1rem",
          color: props.textColor || "#000",
          backgroundColor: props.backgroundColor || "#fff",
          borderRadius: props.roundness || "0",
          width: "100%",
          border: "none",
          paddingLeft: props.paddingX || "0.5rem",
          paddingRight: props.paddingX || "0.5rem",
        }}
      />
      <input
        {...props}
        value={props.value}
        className={
          "hidden sm_md:block placeholder:text-white " + props.className
        }
        placeholder={props.placeholder}
        style={{
          maxWidth: props.maxWidthSmall || "100%",
          height: props.heightSmall || "2rem",
          fontFamily: props.fontFamily || "MontBlancRegular",
          fontSize: props.fontSizeSmall || "1rem",
          color: props.textColor || "#000",
          backgroundColor: props.backgroundColor || "#fff",
          borderRadius: props.roundness || "0",
          width: "100%",
          border: "none",
          paddingLeft: props.paddingXSmall || "0.5rem",
          paddingRight: props.paddingXSmall || "0.5rem",
        }}
      />
    </>
  );
}
