import VSpacer from "../../common/Spacer/VSpacer";
import LeftContainer from "./Containers/LeftContainer";
import MainContainer from "./Containers/MainContainer";
import RightContainer from "./Containers/RightContainer";
import WinnerContainer from "./Containers/WinnerContainer";
import WinnerItem from "./others/WinnerItem";
import Description from "./Typography/Description";
import Title from "./Typography/Title";

interface PrizeCardProps {
  title: string;
  description: string;
  image: string;
  date?: string;
  winningTicket?: string;
  winner?: string;
}
export default function PrizeCard(props: PrizeCardProps) {
  return (
    <MainContainer
      varient={
        props?.date && props?.date < new Date().toISOString()
          ? "winner"
          : "normal"
      }
      left={
        <LeftContainer>
          <img
            className="h-full w-full object-cover absolute top-0 left-0"
            src={props?.image}
            alt="placeholder"
          />
        </LeftContainer>
      }
      right={
        <RightContainer>
          {props?.date && props?.date < new Date().toISOString() ? (
            <VSpacer big={16.52} small={12.43} />
          ) : (
            <VSpacer big={34.71} small={15.26} />
          )}
          <Title title={props?.title} />
          <VSpacer big={9.73} small={5.21} />
          <Description description={props?.description} />
          <VSpacer big={14.86} small={11.88} />
          {props?.date && props?.date < new Date().toISOString() && (
            <WinnerContainer>
              <WinnerItem itemType="ticket" itemValue={props?.winningTicket} />
              <WinnerItem itemType="winner" itemValue={props?.winner} />
            </WinnerContainer>
          )}
        </RightContainer>
      }
    />
  );
}
