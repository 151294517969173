import React, { useEffect, useState } from "react";
import VSpacer from "./common/Spacer/VSpacer";
import { Text } from "./react-responsive-utilities/src";
import { getAvailableTickets } from "../Services/userService";

interface TicketResponse {
    data: {
        Ticket: number;
        Name: string | null;
        Date: string | null;
    }[];
    currentPage: number;
    pageSize: number;
    totalItems: number;
    totalPages: number;
}

// const numbers = [
//   { num: 1, isPreSelected: true, isUserSelected: false },
//   { num: 2, isPreSelected: true, isUserSelected: false },
//   { num: 3, isPreSelected: true, isUserSelected: false },
//   { num: 4, isPreSelected: true, isUserSelected: false },
//   { num: 5, isPreSelected: true, isUserSelected: false },
//   { num: 6, isPreSelected: true, isUserSelected: false },
//   { num: 7, isPreSelected: true, isUserSelected: false },
//   { num: 8, isPreSelected: true, isUserSelected: false },
//   { num: 9, isPreSelected: true, isUserSelected: false },
//   { num: 10, isPreSelected: true, isUserSelected: false },
//   { num: 11, isPreSelected: true, isUserSelected: false },
//   { num: 12, isPreSelected: true, isUserSelected: false },
//   { num: 13, isPreSelected: true, isUserSelected: false },
//   { num: 14, isPreSelected: true, isUserSelected: false },
//   { num: 15, isPreSelected: true, isUserSelected: false },
//   { num: 16, isPreSelected: false, isUserSelected: false },
//   { num: 17, isPreSelected: true, isUserSelected: false },
//   { num: 18, isPreSelected: false, isUserSelected: false },
//   { num: 19, isPreSelected: true, isUserSelected: false },
//   { num: 20, isPreSelected: true, isUserSelected: false },
//   { num: 21, isPreSelected: true, isUserSelected: false },
//   { num: 22, isPreSelected: true, isUserSelected: false },
//   { num: 23, isPreSelected: true, isUserSelected: false },
//   { num: 24, isPreSelected: true, isUserSelected: false },
//   { num: 25, isPreSelected: true, isUserSelected: false },
//   { num: 26, isPreSelected: true, isUserSelected: false },
//   { num: 27, isPreSelected: true, isUserSelected: false },
//   { num: 28, isPreSelected: true, isUserSelected: false },
//   { num: 29, isPreSelected: true, isUserSelected: false },
//   { num: 30, isPreSelected: true, isUserSelected: false },
//   { num: 31, isPreSelected: true, isUserSelected: false },
//   { num: 32, isPreSelected: true, isUserSelected: false },
//   { num: 33, isPreSelected: false, isUserSelected: false },
//   { num: 34, isPreSelected: true, isUserSelected: false },
//   { num: 35, isPreSelected: true, isUserSelected: false },
//   { num: 36, isPreSelected: true, isUserSelected: false },
//   { num: 37, isPreSelected: true, isUserSelected: false },
//   { num: 38, isPreSelected: true, isUserSelected: false },
//   { num: 39, isPreSelected: true, isUserSelected: false },
//   { num: 40, isPreSelected: true, isUserSelected: false },
//   { num: 41, isPreSelected: true, isUserSelected: false },
//   { num: 42, isPreSelected: true, isUserSelected: false },
//   { num: 43, isPreSelected: true, isUserSelected: false },
//   { num: 44, isPreSelected: true, isUserSelected: false },
//   { num: 45, isPreSelected: true, isUserSelected: false },
//   { num: 46, isPreSelected: true, isUserSelected: false },
//   { num: 47, isPreSelected: false, isUserSelected: false },
//   { num: 48, isPreSelected: true, isUserSelected: false },
//   { num: 49, isPreSelected: false, isUserSelected: false },
//   { num: 50, isPreSelected: true, isUserSelected: false },
//   { num: 51, isPreSelected: true, isUserSelected: false },
//   { num: 52, isPreSelected: true, isUserSelected: false },
//   { num: 53, isPreSelected: true, isUserSelected: false },
//   { num: 54, isPreSelected: true, isUserSelected: false },
//   { num: 55, isPreSelected: true, isUserSelected: false },
//   { num: 56, isPreSelected: true, isUserSelected: false },
//   { num: 57, isPreSelected: true, isUserSelected: false },
//   { num: 58, isPreSelected: true, isUserSelected: false },
//   { num: 59, isPreSelected: true, isUserSelected: false },
//   { num: 60, isPreSelected: true, isUserSelected: false },
//   { num: 61, isPreSelected: true, isUserSelected: false },
//   { num: 62, isPreSelected: true, isUserSelected: false },
//   { num: 63, isPreSelected: true, isUserSelected: false },
//   { num: 64, isPreSelected: false, isUserSelected: false },
//   { num: 65, isPreSelected: true, isUserSelected: false },
//   { num: 66, isPreSelected: false, isUserSelected: false },
//   { num: 67, isPreSelected: true, isUserSelected: false },
//   { num: 68, isPreSelected: true, isUserSelected: false },
//   { num: 69, isPreSelected: true, isUserSelected: false },
//   { num: 70, isPreSelected: true, isUserSelected: false },
//   { num: 71, isPreSelected: true, isUserSelected: false },
//   { num: 72, isPreSelected: true, isUserSelected: false },
//   { num: 73, isPreSelected: true, isUserSelected: false },
//   { num: 74, isPreSelected: true, isUserSelected: false },
//   { num: 75, isPreSelected: true, isUserSelected: false },
//   { num: 76, isPreSelected: true, isUserSelected: false },
//   { num: 77, isPreSelected: true, isUserSelected: false },
//   { num: 78, isPreSelected: true, isUserSelected: false },
//   { num: 79, isPreSelected: true, isUserSelected: false },
//   { num: 80, isPreSelected: true, isUserSelected: false },
//   { num: 81, isPreSelected: true, isUserSelected: false },
//   { num: 82, isPreSelected: true, isUserSelected: false },
//   { num: 83, isPreSelected: true, isUserSelected: false },
//   { num: 84, isPreSelected: true, isUserSelected: false },
//   { num: 85, isPreSelected: true, isUserSelected: false },
//   { num: 86, isPreSelected: true, isUserSelected: false },
//   { num: 87, isPreSelected: true, isUserSelected: false },
//   { num: 88, isPreSelected: true, isUserSelected: false },
//   { num: 89, isPreSelected: true, isUserSelected: false },
//   { num: 90, isPreSelected: true, isUserSelected: false },
//   { num: 91, isPreSelected: true, isUserSelected: false },
//   { num: 92, isPreSelected: false, isUserSelected: false },
//   { num: 93, isPreSelected: true, isUserSelected: false },
//   { num: 94, isPreSelected: true, isUserSelected: false },
//   { num: 95, isPreSelected: true, isUserSelected: false },
//   { num: 96, isPreSelected: false, isUserSelected: false },
//   { num: 97, isPreSelected: true, isUserSelected: false },
//   { num: 98, isPreSelected: true, isUserSelected: false },
//   { num: 99, isPreSelected: true, isUserSelected: false },
//   { num: 100, isPreSelected: false, isUserSelected: false },
// ];

export const NumberPicker = (): JSX.Element => {
    const [numbers, setNumbers] = useState<Item[]>([]);
    const [pageData, setPageData] = useState<any[]>([]);

    const [ticketsResponse, setTicketsResponse] =
        useState<TicketResponse | null>(null);

    const [selectedTickets, setSelectedTickets] = useState<number[]>([]);
    const competitionId = window.location.pathname.split("/")[3];

    const handleGetAvailableTickets = async () => {
        const response = await getAvailableTickets(
            "aa787eb6ae9ada29690ab18482211733"
        );
        setTicketsResponse(response?.data);
    };

    useEffect(() => {
        handleGetAvailableTickets();
    }, []);

    useEffect(() => {
        if (ticketsResponse) {
            const pageData = ticketsResponse.data.map((item, index) => {
                return {
                    range: `${item.Ticket}-${item.Ticket + 100}`,
                    index: index,
                };
            });
            setPageData(pageData);

            const numbers = ticketsResponse.data.map((item) => {
                return {
                    num: item.Ticket,
                    isPreSelected: true,
                    isUserSelected: false,
                };
            });

            setNumbers(numbers);
        }
    }, [ticketsResponse]);

    // const pageData = [
    //   {
    //     range: "100-200",
    //     index: 0,
    //   },
    //   {
    //     range: "200-300",
    //     index: 1,
    //   },
    //   {
    //     range: "300-400",
    //     index: 2,
    //   },
    //   {
    //     range: "400-500",
    //     index: 3,
    //   },
    //   {
    //     range: "500-600",
    //     index: 4,
    //   },
    // ];

    type Item = {
        num: number;
        isPreSelected: boolean;
        isUserSelected: boolean;
    }; // Define the structure of each object

    const [page, setPage] = useState(0);
    const [number, setNumber] = useState<Item[]>([]);

    const addItem = (item: Item) => {
        setNumber((prevNumbers) => [...prevNumbers, item]);
    };

    // Function to delete an item by id
    const deleteItem = (id: number) => {
        setNumber((prevNumbers) =>
            prevNumbers.filter((item) => item.num !== id)
        );
    };

    const pageContainer = (items: Array<any>) => {
        return (
            <>
                <div className="sm:hidden ">
                    <div className="flex flex-row  flex-wrap   gap-y-3 gap-x-2 justify-center mx-[1.5%]">
                        {items.map((item, index) => (
                            <div
                                key={index}
                                onClick={() => setPage(item.index)}
                                className={`xl:w-[225px] lg:w-[215px] md:w-[215px]  h-[47px] flex flex-wrap items-center justify-center rounded-[13px] border ${
                                    index === page
                                        ? "border-[#FFFFFF]"
                                        : "border-none"
                                } ${index === page ? "bg-primary" : ""} `}
                                style={{
                                    backgroundColor:
                                        index !== page
                                            ? "rgba(255, 255, 255, 0.15)"
                                            : undefined,
                                }}
                            >
                                <p
                                    className={`font-['Sequel100Wide-65'] text-[12px] font-normal ${
                                        index === page
                                            ? "text-[#280508]"
                                            : "text-[#FFFFFF]"
                                    }`}
                                    style={{
                                        fontSize:
                                            "clamp(1px, 2.815621311vw, 14.18px)",
                                    }}
                                >
                                    {item.range}
                                </p>
                            </div>
                        ))}
                    </div>

                    <div className="xl:h-[35px] lg: h-[35px]"></div>
                    <div className="flex  flex-row align-center justify-between mx-[1.5%]">
                        <img
                            //src="/Images/THEPRIZE_TokenLogo_EH-03.svg"
                            src="/Images/left_arrow.svg"
                            alt=""
                            className="h-[21px] w-[16px] "
                        />
                        <p className="font-['Sequel100WideVF45'] text-[15px] text-center text-white text-opacity-50 ">
                            PAGE 1
                        </p>

                        <img
                            //src="/Images/THEPRIZE_TokenLogo_EH-03.svg"
                            src="/Images/right_arrow.svg"
                            alt=""
                            className="h-[21px] w-[16px] "
                        />
                    </div>
                </div>
            </>
        );
    };

    const numContainer = (items: Array<any>) => {
        return (
            <div className="xl:grid grid-cols-10 lg:flex flex  flex-row flex-wrap gap-y-3 gap-x-2 justify-center ml-[10px] mr-[10px]">
                {items.map((item, index) => (
                    <div
                        onClick={() => {
                            if (item.isPreSelected == false) {
                                if (item.isUserSelected == false) {
                                    addItem(item);
                                    item.isUserSelected = true;
                                } else {
                                    deleteItem(item.num);
                                    item.isUserSelected = false;
                                }
                            }
                        }}
                        key={index}
                        className={`relative m-auto max-w-[109px] min-w-0 w-full h-[48px] rounded-[13px] flex items-center justify-center overflow-hidden 
                ${
                    item.isUserSelected
                        ? "bg-primary"
                        : "bg-[rgba(255,255,255,0.15)]"
                }  ${
                            !item.isPreSelected === true
                                ? "border border-[#DDE404]"
                                : ""
                        }`}
                    >
                        {item.isPreSelected == true &&
                        item.isUserSelected != true ? (
                            <div
                                className="absolute top-[14px]  right-[16px] w-[0.5px] h-[170%] bg-white bg-opacity-50"
                                style={{
                                    transform: "rotate(75deg)",
                                    transformOrigin: "top right",
                                }}
                            ></div>
                        ) : (
                            <></>
                        )}

                        <p
                            className={`font-['Sequel100Wide'] text-[16px] text-center
                
                
                ${
                    item.isPreSelected != true && item.isUserSelected != true
                        ? "text-[#DDE404]"
                        : item.isUserSelected === true
                        ? "text-[#393939]"
                        : "text-[#280508]"
                }`}
                        >
                            {item.num}
                        </p>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <>
            <VSpacer big={34} small={34} />
            <div className="w-auto h-auto flex flex-col justify-center align-center items-center   bg-[rgba(35,35,35,0.6)]  ml-[3%] mr-[3%] rounded-3xl">
                <div className="xl:h-[35px] lg:h-[35px] md:h-[30px] sm:h-[25px]"></div>
                <Text
                    textSize={[30, 30.0, 50.0]}
                    className="text-primary text-center xl:font-['Sequel100Wide95'] lg:font-['Sequel100Wide95'] md:font-['Sequel100Wide'] sm:font-['Sequel100Wide']   xl:font-[60px] lg:font-[60px] md:font-[45px] sm:font-[30px]   "
                >
                    NUMBER PICKER
                </Text>

                {/* Numer Picker Box */}

                <div className="xl:hidden lg:hidden md:hidden">
                    <div className="flex flex-row  flex-wrap   gap-y-3 gap-x-2 justify-center ml-[20px] mr-[20px]">
                        {pageData.map((item, index) => (
                            <div
                                key={index}
                                onClick={() => setPage(item.index)}
                                className={`w-[200px]  h-[40px] flex flex-wrap items-center justify-center rounded-[5px] border ${
                                    index === page
                                        ? "border-[#FFFFFF]"
                                        : "border-none"
                                } ${index === page ? "bg-primary" : ""} `}
                                style={{
                                    backgroundColor:
                                        index !== page
                                            ? "rgba(255, 255, 255, 0.15)"
                                            : undefined,
                                }}
                            >
                                <p
                                    className={`font-['Sequel100Wide-65'] text-[8px]  ${
                                        index === page
                                            ? "text-[#280508]"
                                            : "text-[#FFFFFF]"
                                    }`}
                                    style={{
                                        fontSize:
                                            "clamp(1px, 2.815621311vw, 14.18px)",
                                    }}
                                >
                                    {item.range}
                                </p>
                            </div>
                        ))}
                    </div>

                    <div className="xl:h-[58.65px] lg:h-[58.65px] md:h-[40px] sm:h-[25px]"></div>
                    <div className="flex  flex-row align-center justify-center ml-[12px] mr-[12px]">
                        <img
                            //src="/Images/THEPRIZE_TokenLogo_EH-03.svg"
                            src="/Images/left_arrow.svg"
                            alt=""
                            className="h-[21px] w-[16px] "
                        />
                        <p className="font-['Sequel100WideVF45'] text-[15px] text-center text-white text-opacity-50 ml-8 mr-8">
                            PAGE 1
                        </p>

                        <img
                            //src="/Images/THEPRIZE_TokenLogo_EH-03.svg"
                            src="/Images/right_arrow.svg"
                            alt=""
                            className="h-[21px] w-[16px] "
                        />
                    </div>
                </div>
                <div className="xl:h-[58.65px] lg:h-[58.65px] md:h-[40px] sm:h-[25px]"></div>

                <div
                    className="              
  w-[90%] 
  border border-primary 
  rounded-3xl  

 
"
                >
                    <div
                        className=" 

                         
  md:p-8 
  lg:p-8 
  xl:p-8 
  sm:p-0                      
  h-auto 
  max-h-[911px] 
                           overflow-y-auto
  custom-scrollbar
"
                    >
                        {pageContainer(pageData)}

                        <div className="xl:h-[25px] lg: h-[25px]"></div>

                        <div className="flex flex-row justify-center"></div>

                        {numContainer(numbers)}

                        <div className="xl:h-[20px] lg: h-[20px]"></div>

                        <hr className="mx-auto  sm:w-[400px] w-full border-b-[1px] border-t-0 border-primary  sm:block md:block h-[3.14px] rounded-full" />

                        <div className="xl:h-[27px] lg: h-[27px]"></div>

                        <div className="flex flex-row  mx-[1.5%] justify-between flex-wrap justify-center items-center">
                            <div className="sm:w-full  md:w-full lg:w-full xl:w-[710px] h-[76.21px] rounded-[13px] border border-primary flex items-center pl-[30px] pr-[30px]">
                                <p className="font-['Sequel100Wide'] text-[15px] text-left text-white text-opacity-50 font-normal">
                                    Selected Tickets:{" "}
                                    {number.map((item) => item.num).join(", ")}
                                </p>
                            </div>
                            <div className="sm:mt-[15px] sm:mb-[15px] md:mt-[15px] md:mb-[15px] lg:mt-[15px] lg:mb-[15px] sm:w-full md:w-full lg:w-full xl:w-[421px]   h-[77.2px] rounded-[13px] flex items-center justify-center bg-[#B6B6B6]">
                                <p className="text-[#2D2022] text-[29px] sm:text-[18px] font-['Sequel100Wide'] text-center">
                                    CHECKOUT
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="h-[15px]"></div>
            </div>
        </>
    );
};
