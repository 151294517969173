import React from "react";
import { Link } from "react-router-dom";

export interface PageLinkProps {
  src: string;
  content: string;
  className?: string;
}

const PageLink: React.FC<PageLinkProps> = ({ src, content, className }) => {
  return (
    <Link to={src} className={className}>
      {content}
    </Link>
  );
};

export default PageLink;
