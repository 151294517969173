import { Common } from "../../../common/Common";

interface CompetitionResultTextProps {
  varient: "lost" | "won";
}
export default function CompetitionResultText(
  props: CompetitionResultTextProps
) {
  return (
    <Common.typography
      fontFamily="Sequel100Wide95"
      fontSizeSmall="43.91px"
      fontSize="43.91px"
      color="#000"
      className="uppercase leading-none"
    >
      {props.varient === "won" ? "Competition Won!" : "Competition Lost"}
    </Common.typography>
  );
}
