import React, { useEffect, useRef, useState } from "react";
import WithNavbarFooterAndSidebar from "../../Layout/WithNavbarFooterAndSidebar";
import Button from "../../Components/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import _ from "lodash";
import Input from "../../Components/Input";
import Sidebar from "../../Components/Sidebar";
import ForgetPasswordPopup from "../../Components/Profile Components/ForgetPasswordPopup";
import { useAuth } from "../../Contexts/AuthContext";
import { useAppDispatch } from "../../redux/hooks";
import { setSideNavHeight } from "../../redux/reducers/sidebarSlice";
import { useNavigate } from "react-router-dom";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { editUserDetails, getUserDetails } from "../../Services/userService";
import { Alert } from "@mui/material";
import { Text } from "../../Components/react-responsive-utilities/src";

const schema = yup.object().shape({
    //UserName: yup.string().email().required("email is required"),
    UserName: yup.string().optional(),
    // Name: yup.string().required("Name is required"),
    Name: yup.string().optional(),
    MobileNumber: yup.string().required("Mobile Number is required"),
    AddressLine1: yup.string().required("Address Line 1 is required"),
    AddressLine2: yup.string().required("Address Line 2 is required"),
    City: yup.string().required("City is required"),
    Country: yup.string().required("Country is required"),
    Postcode: yup.string().required("Postcode is required"),
    TelegramHandle: yup.string().optional(),
    TelephoneNumber: yup.string().optional(),
    Email: yup.string().optional(),
});

const Heading = ({ text }: { text: string }) => {
    return (
        // <h1 className="font-[MontBlancBold] text-[20px] sm:text-center">{text}</h1>
        <Text
            textSize={[0, 30.0, 30.0]}
            className="
              text-[#FFFFFF] font-['MontBlancBold'] leading-[40px] font-[188] xl:mb-[37px] mb-[20px] block"
        >
            {text}
        </Text>
    );
};
const SubHeading = ({ text }: { text: string }) => {
    return (
        <h1
            className="font-[MontBlancRegular] text-[20px]"
            style={{
                fontSize: "clamp(1px,3.19183371vw,20px)",
            }}
        >
            {text}
        </h1>
    );
};

const Spacer = ({ height }: { height: string }) => {
    return <div className={`mt-[${height}]`} />;
};
const EditUserDetails = () => {
    const methods = useForm({
        mode: "onChange",
        defaultValues: {},
        resolver: yupResolver(schema),
    });

    const {
        watch,
        control,
        reset,
        handleSubmit,
        formState: { isValid, isSubmitting, errors, dirtyFields },
    } = methods;
    watch();

    const [error, setError] = React.useState<string>("");
    const [success, setSuccess] = React.useState<string>("");
    const [loading, setLoading] = React.useState(false);
    const { user, fetchUser } = useAuth();

    useEffect(() => {
        fetchUser();
    }, []);

    useEffect(() => {
        if (!user) {
            return;
        }
        reset({
            UserName: user?.userName ?? "",
            Name: user?.name ?? "",
            MobileNumber: user?.phone ?? "",
            AddressLine1: user?.address1 ?? "",
            AddressLine2: user?.address2 ?? "",
            City: user?.city ?? "",
            Country: user?.country ?? "",
            Postcode: user?.postcode ?? "",
        });
    }, [user]);

    const onSubmit = async (data: any) => {
        try {
            setLoading(true);
            const result = await editUserDetails({
                Email: data?.UserName,
                Name: data?.Name,
                MobileNumber: data?.MobileNumber,
                AddressLine1: data?.AddressLine1,
                AddressLine2: data?.AddressLine2,
                City: data?.City,
                Country: data?.Country,
                Postcode: data?.Postcode,
            });
            setLoading(false);
            if (result) {
                setSuccess(result?.message);
                localStorage.setItem("emailaddress", data?.UserName);
                setTimeout(() => {
                    setSuccess("");
                }, 3000);
                fetchUser();
            }
        } catch (error: any) {
            console.log("error", error);
            setLoading(false);
            setError(error?.response?.data?.error);
            setTimeout(() => {
                setError("");
            }, 3000);
        }
    };

    return (
        <FormProvider {...methods}>
            <div className="mt-[23px] text-white flex flex-col sm:items-center">
                <Heading text="User Details" />
                {error && (
                    <Alert
                        className="rounded-full font-['Sequel100WideVF45']"
                        variant="filled"
                        sx={{
                            borderRadius: 20,
                            fontFamily: "Sequel100WideVF45",
                        }}
                        severity="error"
                    >
                        {error}
                    </Alert>
                )}
                {success && (
                    <Alert
                        className="rounded-full font-['Sequel100WideVF45']"
                        variant="filled"
                        sx={{
                            borderRadius: 20,
                            fontFamily: "Sequel100WideVF45",
                        }}
                        severity="success"
                    >
                        {success}
                    </Alert>
                )}
                <div className="flex sm:flex-col gap-5 mt-[12px]">
                    <Controller
                        name="Name"
                        control={control}
                        render={({ field }) => (
                            <div className="w-full">
                                <Input
                                    {...field}
                                    type={"text"}
                                    // placeholder={"Enter Username"}
                                    placeholder="Enter Username"
                                    className="font-[MontBlancBold] text-[21px] px-[19px] py-[2px] rounded-[30px] w-full h-[42px]"
                                />

                                {errors.Name && (
                                    <div className="xl:pl-[15rem] lg:pl-[15rem]">
                                        <p className="text-red-500 font-['Sequel100WideVF45']">
                                            {errors.Name.message}
                                        </p>
                                    </div>
                                )}
                            </div>
                        )}
                    />
                </div>
                <div className="flex sm:flex-col gap-5 mt-[12px]">
                    <Controller
                        name="TelegramHandle"
                        control={control}
                        render={({ field }) => (
                            <div className="w-full">
                                <Input
                                    {...field}
                                    type={"text"}
                                    placeholder={"Telegram Handle"}
                                    className="font-[MontBlancBold] text-[21px] px-[19px] py-[2px] rounded-[30px] w-full h-[42px]"
                                />

                                {errors.TelegramHandle && (
                                    <div className="xl:pl-[15rem] lg:pl-[15rem]">
                                        <p className="text-red-500 font-['Sequel100WideVF45']">
                                            {errors.TelegramHandle.message}
                                        </p>
                                    </div>
                                )}
                            </div>
                        )}
                    />

                    <Controller
                        name="TelephoneNumber"
                        control={control}
                        render={({ field }) => (
                            <div className="w-full">
                                <Input
                                    {...field}
                                    type={"number"}
                                    placeholder={"Telephone Number"}
                                    className="font-[MontBlancBold] text-[21px] px-[19px] py-[2px] rounded-[30px] w-full h-[42px]"
                                />

                                {errors.TelephoneNumber && (
                                    <div className="xl:pl-[15rem] lg:pl-[15rem]">
                                        <p className="text-red-500 font-['Sequel100WideVF45']">
                                            {errors.TelephoneNumber.message}
                                        </p>
                                    </div>
                                )}
                            </div>
                        )}
                    />
                </div>
                <div className="flex sm:flex-col gap-5 mt-[12px]">
                    <Controller
                        name="Email"
                        control={control}
                        render={({ field }) => (
                            <div className="w-full">
                                <Input
                                    {...field}
                                    type={"text"}
                                    // placeholder={"Enter Username"}
                                    placeholder="Email Address"
                                    className="font-[MontBlancBold] text-[21px] px-[19px] py-[2px] rounded-[30px] w-full h-[42px]"
                                />

                                {errors.Name && (
                                    <div className="xl:pl-[15rem] lg:pl-[15rem]">
                                        <p className="text-red-500 font-['Sequel100WideVF45']">
                                            {errors.Name.message}
                                        </p>
                                    </div>
                                )}
                            </div>
                        )}
                    />
                </div>
            </div>
            <div className="mt-[23px] text-white">
                <Heading text="Billing Details" />
                {/* Spacer component =  <div className="mt-[14px]" /> */}
                <Spacer height="14px" />
                <SubHeading text="Address" />
                {/* flex box with 2 inputs in one row each */}
                <div className="flex sm:flex-col gap-2 mt-[12px]">
                    <Controller
                        name="AddressLine1"
                        control={control}
                        render={({ field }) => (
                            <div className="w-full">
                                <Input
                                    {...field}
                                    type={"text"}
                                    placeholder={"Address Line 1"}
                                    className="font-[MontBlancBold] text-[21px] px-[19px] py-[2px] rounded-[30px] w-full h-[42px]"
                                />

                                {errors.AddressLine1 && (
                                    <div className="xl:pl-[15rem] lg:pl-[15rem]">
                                        <p className="text-red-500 font-['Sequel100WideVF45']">
                                            {errors.AddressLine1.message}
                                        </p>
                                    </div>
                                )}
                            </div>
                        )}
                    />
                    <Controller
                        name="AddressLine2"
                        control={control}
                        render={({ field }) => (
                            <div className="w-full">
                                <Input
                                    {...field}
                                    type={"text"}
                                    placeholder={"Address Line 2"}
                                    className="font-[MontBlancBold] text-[21px] px-[19px] py-[2px] rounded-[30px] w-full h-[42px]"
                                />

                                {errors.AddressLine2 && (
                                    <div className="xl:pl-[15rem] lg:pl-[15rem]">
                                        <p className="text-red-500 font-['Sequel100WideVF45']">
                                            {errors.AddressLine2.message}
                                        </p>
                                    </div>
                                )}
                            </div>
                        )}
                    />
                </div>
                <div className="flex sm:flex-col gap-2 mt-[12px]">
                    <Controller
                        name="City"
                        control={control}
                        render={({ field }) => (
                            <div className="w-full">
                                <Input
                                    {...field}
                                    type={"text"}
                                    placeholder={"City"}
                                    className="font-[MontBlancBold] text-[21px] px-[19px] py-[2px] rounded-[30px] w-full h-[42px]"
                                />

                                {errors.City && (
                                    <div className="xl:pl-[15rem] lg:pl-[15rem]">
                                        <p className="text-red-500 font-['Sequel100WideVF45']">
                                            {errors.City.message}
                                        </p>
                                    </div>
                                )}
                            </div>
                        )}
                    />
                    <Controller
                        name="Country"
                        control={control}
                        render={({ field }) => (
                            <div className="w-full">
                                <Input
                                    {...field}
                                    type={"text"}
                                    placeholder={"Country"}
                                    className="font-[MontBlancBold] text-[21px] px-[19px] py-[2px] rounded-[30px] w-full h-[42px]"
                                />

                                {errors.Country && (
                                    <div className="xl:pl-[15rem] lg:pl-[15rem]">
                                        <p className="text-red-500 font-['Sequel100WideVF45']">
                                            {errors.Country.message}
                                        </p>
                                    </div>
                                )}
                            </div>
                        )}
                    />
                </div>
                <div className="flex sm:flex-col gap-5 mt-[12px] w-1/2 sm:w-full">
                    <Controller
                        name="Postcode"
                        control={control}
                        render={({ field }) => (
                            <div className="w-full">
                                <Input
                                    {...field}
                                    type={"text"}
                                    placeholder={"Postcode"}
                                    className="font-[MontBlancBold] text-[21px] px-[19px] py-[2px] rounded-[30px] w-full h-[42px]"
                                />

                                {errors.Postcode && (
                                    <div className="xl:pl-[15rem] lg:pl-[15rem]">
                                        <p className="text-red-500 font-['Sequel100WideVF45']">
                                            {errors.Postcode.message}
                                        </p>
                                    </div>
                                )}
                            </div>
                        )}
                    />
                </div>
                <div className="sm:mt-0 sm:w-full sm:flex sm:items-center sm:justify-center">
                    <Button
                        disabled={_.isEmpty(dirtyFields) || !isValid}
                        loading={isSubmitting}
                        content={"Enter Details"}
                        onClick={handleSubmit(onSubmit)}
                        className="text-[#232323] font-[Sequel100Wide95] text-[24px]
            sm:text-[3vw]
            sm:w-[80%]
            uppercase text-center px-[20px] py-[10px] mt-[23px] rounded-[30px]"
                    ></Button>
                </div>
            </div>
        </FormProvider>
    );
};
//1115px
export default EditUserDetails;
