interface DataInfoProps {
  text: string;
}
export default function DataInfo(props: DataInfoProps) {
  return (
    <p className="font-[Sequel100WideVF45] sm_md:text-[14px] text-[20px] text-white leading-none">
      {props.text}
    </p>
  );
}
