import React, { useEffect, useState } from "react";
import MembershipBox, { MembershipBoxProps } from "../MembershipBox";
import CurrencyBox, { CurrencyBoxProps } from "../CurrencyBox";
import { getPaymentOptions } from "../../Services/userService";
import Button from "../Button";
import { useNavigate } from "react-router-dom";
import { loginByEmailPassword } from "../../Services/jwtService";
import { useAuth } from "../../Contexts/AuthContext";
import { Container, Text } from "../react-responsive-utilities/src";
import Dropdown, { PaymentOption } from "../common/Dropdown/Dropdown";
import { Common } from "../common/Common";

interface PaymentInterface {
    emailaddress?: string;
    password?: string;
    skipable?: boolean;
}

function Payments({
    emailaddress,
    password,
    skipable = true,
}: PaymentInterface) {
    const [currentFilter, setCurrentFilter] = useState<string>("Crypto");
    const [loading, setLoading] = useState(true);
    const [cryptoOptions, setCryptoOptions] = useState<CurrencyBoxProps[]>([]);
    const [fiatOptions, setFiatOptions] = useState<CurrencyBoxProps[]>([]);
    const [membershipsOptions, setMembershipsOptions] = useState<
        CurrencyBoxProps[]
    >([]);
    const [payAsYouGoOptions, setPayAsYouGoOptions] = useState<
        CurrencyBoxProps[]
    >([]);
    const [paymentMethod, setPaymentMethod] =
        useState<string>("Payment Method");
    const [paymentOption, setPaymentOption] =
        useState<string>("Number of tickets");

    const navigate = useNavigate();
    const { setToken } = useAuth();

    const login = async () => {
        if (!emailaddress || !password) {
            return;
        }
        try {
            const loginData = await loginByEmailPassword(
                emailaddress,
                password
            );
            if (loginData) {
                const token = loginData?.accessToken;
                if (token) {
                    setToken(token);
                    localStorage.setItem("emailaddress", emailaddress);
                    navigate("/entries/live", { replace: true });
                }
            }
        } catch (e) {
            console.log(e);
        }
    };

    const getPayments = async () => {
        setLoading(true);
        const response = await getPaymentOptions();
        if (response?.data?.length > 0) {
            const memberships = response?.data?.filter((item: any) =>
                item.unique_identifier.includes("sub")
            );

            const payAsYouGo = response?.data?.filter((item: any) =>
                item.unique_identifier.includes("one")
            );

            const filteredMemberships =
                currentFilter === "Crypto"
                    ? memberships?.filter((x: any) => x.method === "Crypto")
                    : memberships?.filter((x: any) => x.method === "Fiat");

            const filteredFiats =
                currentFilter === "Fiat"
                    ? payAsYouGo?.filter((x: any) => x.method === "Fiat")
                    : payAsYouGo?.filter((x: any) => x.method === "Crypto");

            // sort by price in ascending order
            setMembershipsOptions(
                filteredMemberships?.sort(
                    (a: any, b: any) =>
                        parseFloat(a?.price) - parseFloat(b?.price)
                )
            );
            setPayAsYouGoOptions(
                filteredFiats?.sort(
                    (a: any, b: any) =>
                        parseFloat(a?.price) - parseFloat(b?.price)
                )
            );
        }
        setLoading(false);
    };

    const changeFilter = (filter: string) => {
        setCurrentFilter(filter);
    };

    useEffect(() => {
        getPayments();
    }, [currentFilter]);

    const numberOfTickets: PaymentOption[] = [
        {
            value: "1",
            price: "1.00",
            logo: "star",
            savings: "0.00",
        },
        {
            value: "5",
            price: "4.95",
            logo: "star",
            savings: "0.05",
        },
        {
            value: "10",
            price: "9.80",
            logo: "star",
            savings: "0.20",
        },
        {
            value: "15",
            price: "14.55",
            logo: "star",
            savings: "0.45",
        },
        {
            value: "20",
            price: "19.20",
            logo: "star",
            savings: "0.80",
        },
        {
            value: "25",
            price: "23.75",
            logo: "star",
            savings: "1.25",
        },
        {
            value: "50",
            price: "47.00",
            logo: "star",
            savings: "3.00",
        },
        {
            value: "100",
            price: "93.00",
            logo: "star",
            savings: "7.00",
        },
        {
            value: "200",
            price: "230.00",
            logo: "star",
            savings: "20.00",
        },
        {
            value: "500",
            price: "455.00",
            logo: "doubleStar",
            savings: "45.00",
        },
        {
            value: "1000",
            price: "900.00",
            logo: "tripleStar",
            savings: "100.00",
        },
        {
            value: "2500",
            price: "2,255.00",
            logo: "uniqueStar",
            savings: "275.00",
        },
        {
            value: "5000",
            price: "4,400.00",
            logo: "diamond",
            savings: "600.00",
        },
    ];

    const getPaymentOptionFromValue = (value: string) => {
        return numberOfTickets.find((x) => x.value === value);
    };

    const paymentMethods: string[] = ["Crypto", "On-Ramp"];

    return (
        <div className="w-full flex gap-4 flex-col //sm:mx-2 //sm:w-[302px]">
            <div className="w-full flex gap-4 sm:flex-col justify-center //sm:mx-2 //sm:w-[302px]">
                <div className="dropdown xl:w-full xl:max-w-[425px] sm_md:w-full m-auto">
                    <div className="w-full uppercase rounded-[10px] bg-primary flex items-center justify-between gap-8 h-[56px] sm_md:h-[39px] p-[1em] cursor-pointer">
                        <Common.typography
                            fontFamily="Sequel100Wide95"
                            fontSize="20px"
                            fontSizeSmall="16px"
                            color="#1A1A1A"
                        >
                            PAY AS YOU GO
                        </Common.typography>
                    </div>

                    <div className="border-4 xl:px-[13px] px-[15px] w-full items-left flex-wrap border-primary mt-[-20px] border-t-0 flex gap-4 //items-end sm:flex-col pt-[3em] py-[2em] justify-center sm:px-[15px] rounded-b-[1.5rem] flex-col">
                        <div className="max-w-[100%] m-auto">
                            <Common.typography
                                fontFamily="Sequel100Wide-65"
                                fontSize="14px"
                                fontSizeSmall="14px"
                                color="#FFFFFF"
                            >
                                SELECT THE QUANTITY OF TICKETS YOU WISH TO
                                PURCHASE. THE OTHER FIELDS WILL FILL OUT
                                AUTOMATICALLY.
                            </Common.typography>
                        </div>
                        {/* Number of tickets */}
                        <div className="relative z-20">
                            <Dropdown
                                key={1}
                                options={numberOfTickets}
                                selected={paymentOption}
                                optionType="payments"
                                setSelected={(value) => {
                                    setPaymentOption(value);
                                }}
                            />
                        </div>
                        <div className="flex w-full gap-[11px] sm:flex-col">
                            {/* Price per ticket */}
                            <Common.textfield
                                className="placeholder:text-[#959595] placeholder:uppercase"
                                fontFamily="Sequel100Wide-65"
                                fontSizeBig="10px"
                                fontSizeSmall="14px"
                                textColor="#959595"
                                heightBig="51px"
                                heightSmall="45px"
                                maxWidthBig="194px"
                                maxWidthSmall="100%"
                                placeholder="price per ticket"
                                backgroundColor="#2c2c2c"
                                roundness="10px"
                                paddingX="17px"
                                paddingXSmall="18px"
                                value={
                                    "$" +
                                    (getPaymentOptionFromValue(paymentOption)
                                        ?.price || 0)
                                }
                                disabled
                            />
                            {/* Discount */}
                            <Common.textfield
                                className="placeholder:text-[#959595] placeholder:uppercase"
                                fontFamily="Sequel100Wide-65"
                                fontSizeBig="10px"
                                fontSizeSmall="14px"
                                textColor="#959595"
                                heightBig="51px"
                                heightSmall="45px"
                                maxWidthBig="194px"
                                maxWidthSmall="100%"
                                placeholder="discount"
                                backgroundColor="#2c2c2c"
                                roundness="10px"
                                paddingX="17px"
                                paddingXSmall="18px"
                                value={
                                    "$" +
                                    (getPaymentOptionFromValue(paymentOption)
                                        ?.savings || 0)
                                }
                                disabled
                            />
                        </div>
                        {/* Total Price */}
                        <Common.textfield
                            className="placeholder:text-[#959595] placeholder:uppercase"
                            fontFamily="Sequel100Wide-65"
                            fontSizeBig="10px"
                            fontSizeSmall="14px"
                            textColor="#959595"
                            heightBig="51px"
                            heightSmall="45px"
                            maxWidthBig="100%"
                            maxWidthSmall="100%"
                            placeholder="total price"
                            backgroundColor="#2c2c2c"
                            roundness="10px"
                            paddingX="17px"
                            paddingXSmall="18px"
                            value={
                                "$" +
                                (
                                    parseFloat(
                                        getPaymentOptionFromValue(paymentOption)
                                            ?.price || "0"
                                    ) -
                                    parseFloat(
                                        getPaymentOptionFromValue(paymentOption)
                                            ?.savings || "0"
                                    )
                                ).toFixed(2)
                            }
                            disabled
                        />
                        {/* Payment Method */}
                        <Dropdown
                            key={2}
                            options={paymentMethods}
                            selected={paymentMethod}
                            optionType="normal"
                            setSelected={(value) => {
                                setPaymentMethod(value);
                            }}
                        />

                        <button className="bg-[#dde406] xl:py-[13px] w-full rounded-[10px] xl:text-[20px] font-[Sequel100wide] h-[45px] xl:h-[51px]">
                            Pay Now
                        </button>
                    </div>
                </div>
            </div>
            {skipable && (
                <div className="flex flex-row justify-center mt-4 w-full">
                    <Button
                        loading={loading}
                        onClick={login}
                        content="Skip"
                        className={`w-[149px] h-[45px] xl:h-[51px] font-['MontBlancBlack'] text-[14px] xl:text-[20px] text-center text-[#2D2022] font-extrabold uppercase bg-primary  border-2 border-white`}
                    />
                </div>
            )}
        </div>
    );
}

export default Payments;
